import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Tooltip.styles';

const Tooltip = ({ text, children }) => {
    return (

        <Styled.Tooltip>
            <Styled.ContentWrapper >
                {text && <Styled.ContentText>{text}</Styled.ContentText>}
                {children}
            </Styled.ContentWrapper>
        </Styled.Tooltip>

    );
};

Tooltip.defaultProps = {
    text: null,
    children: null,
};

Tooltip.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
};

export default Tooltip;
