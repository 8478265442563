/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../../config';
import { MENUFACTURS } from '../../../../constants';

let Presentation;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        Presentation = require(
            '../../../CommercialConfigurator/Presentation/InternalPresentation',
        ).default;
        break;
    default:
        Presentation = require('./Presentation').default;
}

export default Presentation;
