import React from 'react';
import { useCurrentAppCommonData, useUniqueCars } from '../../../hooks/app_common_data';
import { isArrayEmpty } from '../../../utils/commonUtils';
import { parseOnlineCarsByModelGroups } from '../../Steps/Model/Model.utils';
import { COLORS, FILTER_BY } from '../../../constants';
import STRINGS from '../../../language';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import FooterNavigation from '../FooterNavigation/FooterNavigation';
import FooterNavigationItem from '../FooterNavigationItem/FooterNavigationItem';

const MODEL_GROUP = 'modelGroup';

const ModelsAndSpecificationsNav = () => {
    const carItemsByModelGroup = useUniqueCars(MODEL_GROUP);
    const { isCupra, isAudi, onlineCars } = useCurrentAppCommonData();
    const modelGroupsItemsByModel = parseOnlineCarsByModelGroups(onlineCars, FILTER_BY.BY_MODEL);
    const checkIsMultipleCarsInGroup = (modelGroupCode) => {
        const currentGroup = modelGroupsItemsByModel.find((group) => {
            return group.id === `modelGroupCode-${modelGroupCode}`;
        });

        return currentGroup?.cars?.length > 1;
    };

    const renderCarsModel = (cars) => {
        if (!cars || isArrayEmpty(cars)) {
            return (
                <ErrorMessage
                    color={ (isCupra || isAudi) ? COLORS.WHITE : COLORS.BLACK }
                    errorMessage={ STRINGS.NO_CARS_TO_DISPLAY }
                />
            );
        }

        const models = cars.map((car) => {
            const {
                id,
                modelGroup,
                modelGroupCode,
                hrefToConfigurator,
                hrefToModels,
            } = car;
            
            const isMultipleCarsInGroup = checkIsMultipleCarsInGroup(modelGroupCode);

            return (
                <FooterNavigationItem
                    key={ `${id}` }
                    title={ modelGroup }
                    href={ isMultipleCarsInGroup ? hrefToModels : hrefToConfigurator }
                />
                
            );
        });

        const specifications = cars.map((car) => {
            const { hebrewModelGroup, catalogLink } = car;
            
            return (
                <FooterNavigationItem
                    key={ `${hebrewModelGroup}` }
                    title={ `${STRINGS.SPECIFICATION} ${hebrewModelGroup}` }
                    href={ catalogLink }
                    target="_blank"
                />
                
            );
        });

        return [...models, ...specifications];
    };

    return (
        <FooterNavigation>
            {renderCarsModel(carItemsByModelGroup)}
        </FooterNavigation>
    );
};

export default ModelsAndSpecificationsNav;
