import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import * as Styled from './Payment.style';

// todo: need to fine a much more elegant solution than showExtendedIframe
//          we need to change the logic for success/payment url in the iframe

function Payment({ iframeUrl, showExtendedIframe }) {
    return (
        <Styled.PaymentContainer isExtended={ showExtendedIframe }>
            <Styled.InnerContent>
                <Styled.Header isHidden={ showExtendedIframe }>
                    <Styled.HeaderText>{STRINGS.PAYMENT_TITLE}</Styled.HeaderText>
                    <Styled.Text>{STRINGS.PAYMENT_MESSAGE}</Styled.Text>
                </Styled.Header>

                <Styled.PaymentIframe
                    src={ iframeUrl }
                    title="Payment-iframe"
                    frameBorder="0"
                    isExtended={ showExtendedIframe }
                />
            </Styled.InnerContent>
        </Styled.PaymentContainer>
    );
}

Payment.propTypes = {
    iframeUrl: PropTypes.string.isRequired,
    showExtendedIframe: PropTypes.bool,
};

Payment.defaultProps = {
    showExtendedIframe: false,      // if need full page iframe
};

export default Payment;
