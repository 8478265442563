import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentAppCommonData, useCurrentBrand, useCurrentCar, useCurrentConfigOptions,
} from '../../../../hooks';
import { getIconFromAssets } from '../../../../utils/assetsUtils';
import { CONFIGURATOR_STEPS, ICONS_NAMES } from '../../../../constants';
import { getStepTitle, getStepContinueBtnTitle } from '../../../../utils/configuratorUtils';
import PaymentDetails from '../../../../components/PaymentDetails/PaymentDetails';
import STRINGS from '../../../../language';
import {
    useDataForPaymentDetails,
} from '../../../../components/PaymentDetails/PaymentDetails.hooks';
import { isArrayAndNotEmpty } from '../../../../utils/commonUtils';
import  QuestionMarkTooltip  from '../../../../components/QuestionMarkTooltip/QuestionMarkTooltip';
import ContinueThemeButton from '../../../../components/ContinueThemeButton/ContinueThemeButton';
import PaymentDetailsModal from './PaymentDetailsModal/PaymentDetailsModal';
import * as Styled from './ConfigurationalLeft.styles';

const ConfigurationalLeft = ({
    component,
    onContinue,
    nextStep,
    currentStep,
    configOptions,
}) => {
    const [arePaymentDetailsShown, setPaymentDetailsShown] = useState(false);
    const {
        externalColors, internalColors, originalPackages, modelLocalGroups, externalColorsError,
    } =    useCurrentConfigOptions();
    const currentBrand = useCurrentBrand();
    const { isCupra } = useCurrentAppCommonData();
    const { carHasDiscount } = useCurrentCar();
    const ConfComponent = component || (() => null);
    const isNotFirstStep = currentStep !== CONFIGURATOR_STEPS.MODEL;
    const paymentDetailsToggleHandler = () => {
        setPaymentDetailsShown((shown) => !shown);
    };
    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
        totalPricesBeforeDiscount,
    } = useDataForPaymentDetails();

    const fullPriceParsed = parseFloat(totalPricesBeforeDiscount.total).toLocaleString();
    const leftArrowIcon = getIconFromAssets(ICONS_NAMES.ARROW_LEFT);
    const hideTooltip = isCupra || !carHasDiscount;

    const { isLoading: isLoadingInternalColors, error: internalColorsError } = internalColors;
    const { isLoading: isLoadingOriginalPackages, error: originalPackagesError } = originalPackages;
    const { isLoading: isLoadingLocalGroups, error: modelLocalGroupsError } = modelLocalGroups;

    const disableContinueBtnHandler = () => {
        return isLoadingInternalColors
            || isLoadingOriginalPackages
            || isLoadingLocalGroups
            || !isArrayAndNotEmpty(externalColors)
            || externalColorsError
            || internalColorsError
            || originalPackagesError
            || modelLocalGroupsError;
    };

    const renderTooltip = () => {
        if (hideTooltip) return null;
        
        return  <QuestionMarkTooltip text={ STRINGS.TOOLTIP_PRICE_INNER_TEXT } />;
    };

    return (
        <>
            <Styled.ConfigurationalLeft>
                <Styled.Header>
                    <Styled.Title key={ currentStep }>{getStepTitle(currentStep)}</Styled.Title>
                </Styled.Header>
                <Styled.Body>
                    <ConfComponent configOptions={ configOptions } />
                </Styled.Body>
                <Styled.Footer>
                    <Styled.FooterRightWrapper>
                        <Styled.TooltipWrapper>
                            <Styled.TooltipTitle>{STRINGS.PRICE}</Styled.TooltipTitle>
                            {renderTooltip()}
                        </Styled.TooltipWrapper>
                        <Styled.FullPrice>{fullPriceParsed} &#8362;</Styled.FullPrice>
                        <Styled.PriceDescription>
                            {STRINGS.INCLUDES_VAR_AND_LICENSING_FEE}
                        </Styled.PriceDescription>
                        <Styled.PaymentDetailsButton onClick={ paymentDetailsToggleHandler }>
                            {STRINGS.PAYMENT_DETAILS}
                        </Styled.PaymentDetailsButton>
                    </Styled.FooterRightWrapper>
                    <ContinueThemeButton
                        icon={ leftArrowIcon }
                        title={ getStepContinueBtnTitle(nextStep) }
                        isDisabled={ disableContinueBtnHandler() }
                        onClick={ onContinue }
                        isCupra={ isCupra }
                    />
                </Styled.Footer>

            </Styled.ConfigurationalLeft>
            <PaymentDetailsModal
                isVisible={ arePaymentDetailsShown && isNotFirstStep }
                onClose={ paymentDetailsToggleHandler }
            >
                <PaymentDetails
                    specifications={ specifications }
                    addOns={ addOns }
                    originalAdditions={ originalAdditions }
                    equipmentPackage={ equipmentPackage }
                    carPrices={ carPrices }
                    licenseFeePrices={ licenseFeePrices }
                    totalPrices={ totalPrices }
                    currentBrand={ currentBrand }
                    onClose={ paymentDetailsToggleHandler }
                />
            </PaymentDetailsModal>
        </>
    );
};

ConfigurationalLeft.defaultProps = {
    component: null,
    onContinue: () => {},
    currentStep: null,
    nextStep: null,
};

ConfigurationalLeft.propTypes = {
    component: PropTypes.elementType,
    onContinue: PropTypes.func,
    currentStep: PropTypes.number,
    nextStep: PropTypes.number,
    configOptions: PropTypes.object.isRequired,
};

export default ConfigurationalLeft;
