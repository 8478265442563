import styled, { css } from 'styled-components';
import { ScrollSyncPane as SSP } from 'react-scroll-sync';

const ScrollSyncPane = styled(SSP)``;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 16px 0;
  padding: 0 12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ $isSticky }) => $isSticky && css`
      position: sticky;
      top: 50px;
      margin: 0;
      z-index: 10;
      background-color: white;
  `};
`;

const ItemWrapper = styled.div`
  min-width: 50%;
  width: 50%;
  position: relative;
  ${({ $isSticky }) => !$isSticky && css`
    :not(:last-of-type)::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 100%;
      background-color: #C6C6C6;
    }
  `}
`;

const Styled = {
    ScrollSyncPane,
    Container,
    ItemWrapper,
};

export default Styled;
