import React from 'react';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import * as Style from './CustomOtpInput.style';

const CustomOtpInput = ({
    value,
    onChange,
    numInputs,
    hasErrored,
    errorMessage,
}) => {
    return (
        <Style.Container>
            <OtpInput
                value={ value }
                onChange={ onChange }
                numInputs={ numInputs }
                inputStyle={ Style.InputStyle }
                containerStyle={ Style.ContainerStyle }
                hasErrored={ hasErrored }
                shouldAutoFocus
            />
            { hasErrored && (
                <Style.ErrorMessage>
                    { errorMessage }
                </Style.ErrorMessage>
            ) }
        </Style.Container>
    );
};

CustomOtpInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    numInputs: PropTypes.number.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
};

export default CustomOtpInput;
