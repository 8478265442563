import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';

import {
    Label,
    InputFormGroup,
    Asterisk,
    ErrorMessage,
} from '../../../../InputFormGroup/InputFormGroup.styles';

const { DEVICE_SIZE } = CONFIG;

export const FormContainer = styled.div`
  flex:1;
  ${Label} {
    font-size: ${pxToRem(14)};
    margin-bottom: 7px;
  }
  ${InputFormGroup} {
    margin-bottom: 10px;
  }
  ${Asterisk} {
    display: none;
  }
  ${ErrorMessage} {
    margin-top: 0;
  }

 
`;
export const Title = styled.h2`
  margin: 0 0 5px 0;
  font-size: ${pxToRem(26)};
`;
export const Description = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: normal;
  margin: 0;
  line-height: 23px;
`;
export const Form = styled.form`
  margin-top: 45px;
`;
export const Terms = styled.div`
  margin-top: 50px;
  position: relative;
`;

export const ModalFormContent = styled.div`
  display:flex;
`;

export const ImageContainer = styled.figure`
  margin:0;
  flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width:465px;
      width: 100%;
    }
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      display:none;
    }
`;
