import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';
import { COLORS } from '../../../../constants';
import { nisCurrencySign } from '../../../common/styles';

export const AfterDiscountPriceItem = styled.div`
  padding: 10px 0 15px 0;
  border-bottom: 3px solid ${COLORS.BLACK};
  display: flex;
  justify-content: space-between;
`;
export const Name = styled.p`
  font-weight: 700;
  font-size: ${pxToRem(15)};
  margin: 0;
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DiscountPrice = styled(Name)`
  margin:0;
  font-size: ${pxToRem(13)};
  position: relative;
  direction: ltr;
  ${nisCurrencySign}
`;
