import {
    IS_VERIFIED_AUTHORIZATION,
    SESSION_AUTHORIZATION_TOKEN,
} from '../../constants';

export const sendSmsPending = (state) => ({
    ...state,
    sms: {
        ...state.sms,
        isLoading: true,
        isSuccess: false,
        error: null,
    },
});

export const sendSmsSuccess = ((state) => {
    return {
        ...state,
        sms: {
            ...state.sms,
            isSuccess: true,
            isLoading: false,
        },
    };
});

export const sendSmsFailure = ((state, action) => {
    const { error } = action;
    
    return {
        ...state,
        sms: {
            ...state.sms,
            isSuccess: false,
            isLoading: false,
            error,
        },
    };
});
export const saveToken = (state, action) => {
    const { token } = action;
    
    return {
        ...state,
        auth: {
            ...state.auth,
            token,
        },
    };
};
export const isAuthenticatedPending = (state) => ({
    ...state,
    auth: {
        ...state.auth,
        isLoading: true,
    },
});

export const isAuthenticatedSuccess = ((state) => {
    return {
        ...state,
        auth: {
            ...state.auth,
            isAuthenticated: true,
            isLoading: false,
        },
        sms: {
            ...state.sms,
            isSuccess: false,
            isLoading: false,
            error: null,
        },
    };
});

export const isAuthenticatedFailure = ((state, action) => {
    const { error } = action;
 
    return {
        ...state,
        auth: {
            ...state.auth,
            isAuthenticated: false,
            isLoading: false,
            error,
        },
    };
});

export const checkOtpPending = (state) => {
    sessionStorage.removeItem(SESSION_AUTHORIZATION_TOKEN);
    
    return {
        ...state,
        otp: {
            ...state.otp,
            isLoading: true,
        },
    };
};

export const checkOtpSuccess = ((state) => {
    return {
        ...state,
        otp: {
            ...state.otp,
            isLoading: false,
            isVerified: true,
            error: null,
        },
    };
});

export const checkOtpFailure = ((state, action) => {
    const { error } = action;
    
    return {
        ...state,
        otp: {
            ...state.otp,
            isLoading: false,
            isVerified: false,
            error,
        },
    };
});

export const initializeOtp = (state) => {
    return {
        ...state,
        otp: {
            ...state.otp,
            isLoading: false,
            error: null,
        },
    };
};

export const initializeOtpVerification = (state) => {
    return {
        ...state,
        otp: {
            ...state.otp,
            isLoading: false,
            isVerified: false,
            error: null,
        },
    };
};

export const otpVerificationSuccess = (state) => {
    return {
        ...state,
        otp: {
            ...state.otp,
            isVerified: true,
            error: null,
        },
    };
};

export const setMaskedPhoneNumber = (state, action) => {
    const { maskedPhoneNumber } = action;

    return {
        ...state,
        otp: {
            ...state.otp,
            maskedPhoneNumber,
            error: null,
        },
    };
};

export const initializeSms = (state) => {
    return {
        ...state,
        sms: {
            ...state.sms,
            isLoading: false,
            isSuccess: false,
            error: null,
        },
    };
};

export const initializeAuthentication = (state) => {
    sessionStorage.removeItem(IS_VERIFIED_AUTHORIZATION);

    return {
        ...state,
        sms: {
            isLoading: false,
            isSuccess: false,
            error: null,
        },
        otp: {
            isLoading: false,
            isVerified: false,
            maskedPhoneNumber: null,
            error: null,
        },
        auth: {
            isLoading: false,
            isAuthenticated: false,
            token: null,
            error: null,
        },
    };
};
