import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import * as Styled from './CarRank.styles';

const CarRank = ({ safety, pollution }) => {
    const renderPollutionData = () => {
        return pollution.map(
            (pollutionItem) => (
                <Styled.CarPollutionSquare key={ pollutionItem } $pollution={ pollutionItem } />
            ),
        );
    };

    const renderSafetyData = () => {
        return safety.map(
            (safetyItem) => (
                <Styled.CarSafetySquare key={ safetyItem } $safety={ safetyItem } />
            ),
        );
    };

    return (
        <Styled.CarRank>
            <Styled.CarRankDescription>
                { renderPollutionData() }
                {STRINGS.POLLUTION_LEVEL}
            </Styled.CarRankDescription>
            <Styled.CarRankDescription>
                { renderSafetyData() }
                {STRINGS.SAFETY_EQUIPMENT}
            </Styled.CarRankDescription>
        </Styled.CarRank>
    );
};

CarRank.propTypes = {
    safety: PropTypes.array.isRequired,
    pollution: PropTypes.array.isRequired,
};

export default CarRank;
