import React, { useEffect, createRef, useRef } from 'react';
import IconAndSubTitle from '../IconAndSubTitle/IconAndSubTitle';
import {
    useSelectedDealership,
    useCheckoutIsFinished,
    useCreditGuardUrl,
    useCheckout,
    useCurrentAppCommonData,
} from '../../../hooks';
import {
    useAuthenticationAuth,
} from '../../../hooks/authentication';
import { PROGRESS_STEPS } from '../Checkout.config';
import { CHECKOUT_PAGE_PROGRESS_STEPS, ICON_AND_TITLE_OFFSET } from '../../../constants';
import AudiPaymentSuccess from '../../AudiCheckout/PaymentSuccess/AudiPaymentSuccess';
import * as Styled from './ProgressScreenSteps.styles';

const {
    IDENTIFICATION_AND_REGISTRATION,
    PICK_UP_AND_FINANCING,
    PAYMENT_AND_DOWN_PAYMENT,
    SUCCESS,
    FAILURE,
} = CHECKOUT_PAGE_PROGRESS_STEPS;

const ProgressScreenSteps = () => {
    const selectedDealership = useSelectedDealership();
    const creditGuardUrl = useCreditGuardUrl();
    const { isAuthenticated } = useAuthenticationAuth();
    const isPaymentFinished = useCheckoutIsFinished();
    const { isAudi } = useCurrentAppCommonData();
    const { isPaymentFailed } = useCheckout();

    const stepsContainerRef = useRef(null);
    const scrollRefs = useRef(Object.keys(PROGRESS_STEPS(isAudi)).reduce((acc, item) => {
        acc.push(createRef());

        return acc;
    }, []));

    const smoothScrollHandler = (step) => {
        const yOffset = ICON_AND_TITLE_OFFSET;
        const element = scrollRefs.current[step].current;
        const y = element.offsetTop + yOffset;

        stepsContainerRef.current.scrollTo({ top: y, behavior: 'smooth' });
    };

    const isStepVisible = (step) => {
        switch (step) {
            case IDENTIFICATION_AND_REGISTRATION:
                return !isPaymentFinished && !isAuthenticated && !isPaymentFailed;
            case PICK_UP_AND_FINANCING:
                return !isPaymentFinished && isAuthenticated;
            case PAYMENT_AND_DOWN_PAYMENT:
                return !isPaymentFinished && isAuthenticated && creditGuardUrl;
            case SUCCESS:
                return isPaymentFinished;
            case FAILURE:
                return isPaymentFailed;
            default:
                return false;
        }
    };

    const isStepDisabled = (step) => {
        switch (step) {
            case IDENTIFICATION_AND_REGISTRATION:
                return false;
            case PICK_UP_AND_FINANCING:
                return !isAuthenticated;
            case PAYMENT_AND_DOWN_PAYMENT:
                return !creditGuardUrl || !selectedDealership || !isAuthenticated;
            case SUCCESS:
            case FAILURE:
            default:
                return false;
        }
    };

    const renderStep = (step) => {
        const {
            step: stepId, subTitle, icon, ScreenStep,
        } = step;

        return (
            <Styled.StepContainer
                key={ stepId }
                ref={ scrollRefs.current[stepId] }
                $isVisible={ isStepVisible(stepId) }
                $isDisabled={ isStepDisabled(stepId) }
            >
                {icon && <IconAndSubTitle icon={ icon } subTitle={ subTitle } />}
                <ScreenStep
                    scrollHandler={ smoothScrollHandler }
                />
            </Styled.StepContainer>
        );
    };

    const renderSteps = () => {
        return PROGRESS_STEPS(isAudi).map((step) => {
            if (isAudi && step.step === SUCCESS) {
                step.ScreenStep = AudiPaymentSuccess;
            }

            return renderStep(step);
        });
    };

    useEffect(() => {
        stepsContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isPaymentFinished]);
    
    return (
        <Styled.ProgressScreenSteps ref={ stepsContainerRef }>
            {renderSteps()}
        </Styled.ProgressScreenSteps>
    );
};

export default ProgressScreenSteps;
