import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { getBgColorForRuler } from '../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const CarRank = styled.div`
  display: flex;
  border-top: 1px solid ${COLORS.BORDER_COLOR_ITEM_CAR_RANK};
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${COLORS.WHITE};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    justify-content: center;
  }
`;

export const CarRankDescription = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin-left:15px;
  display: flex;
  align-items: center;
`;

export const CarPollutionSquare = styled.span`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  &:before{
      content:'';
      width: 22px;
      height: 22px;
      display: flex;
      vertical-align: middle;
      margin-left: 8px;
      font-weight: 700;
      font-size: 13px;
      border-radius: 2px;
      justify-content: center;
      align-items: center;
      content:'${({ $pollution }) => $pollution}';
      color: ${COLORS.COLOR_TEXT_ITEM_CAR_RANK_POLLUTION};
      background-color:${({ $pollution }) => getBgColorForRuler($pollution, 'pollution')};
  }
`;

export const CarSafetySquare = styled(CarPollutionSquare)`
  &:before{
    content:'${({ $safety }) => $safety}';
    color:${COLORS.COLOR_TEXT_ITEM_CAR_RANK_SAFETY};
    background-color:${({ $safety }) => getBgColorForRuler($safety, 'safety')}
  }
`;
