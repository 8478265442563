import React from 'react';
import PropTypes from 'prop-types';
import { NoEntranceIcon } from '../../../common/styles';
import STRINGS from '../../../../language';
import Styled from './PickerItem.style';

const PickerItem = ({
    icon,
    value,
    isSelected,
    isTooltipDisabled,
    tooltipContent,
    isPackageRelated,
    price,
    inStockString,
    onSelect,
}) => {
    const hasPrice = price !== 0;

    const renderTooltipContent = () => {
        if (!isPackageRelated) {
            return (
                <>
                    <Styled.TooltipTitle>{ tooltipContent }</Styled.TooltipTitle>
                    <Styled.NoPackageTooltipContainer>
                        <NoEntranceIcon />
                        <Styled.TooltipDescription>
                            { STRINGS.THIS_COLOR_IS_NOT_INCLUDED_IN_THE_MODEL_YOU_CHOSE }
                        </Styled.TooltipDescription>
                    </Styled.NoPackageTooltipContainer>
                </>
            );
        }
        
        return (
            <>
                <Styled.TooltipTitle>{tooltipContent}</Styled.TooltipTitle>
                <Styled.TooltipDescription>
                    { hasPrice ? ` ${parseFloat(price).toLocaleString()} ₪` :  STRINGS.WITH_NO_EXTRA_COST }
                </Styled.TooltipDescription>
                { !!inStockString && (
                    <Styled.TooltipInStock>
                        { inStockString }
                    </Styled.TooltipInStock>
                )}
            </>
        );
    };

    return (
        <Styled.PickerItemContainer
            $isSelected={ isSelected }
            data-tip
            data-for={ value }
            data-tip-disable={ isTooltipDisabled }
        >
            <Styled.ToolTip
                aria-haspopup="true"
                className="extraClass"
                effect="solid"
                id={ value }
            >
                { renderTooltipContent() }
            </Styled.ToolTip>
            <Styled.PickerItem
                $icon={ icon }
                onClick={ onSelect }
                disabled={ !isPackageRelated }
            />
            { isSelected && (
                <Styled.CheckMark>
                    <Styled.CheckMarkIcon />
                </Styled.CheckMark>
            ) }
            { !isPackageRelated && <NoEntranceIcon /> }
        </Styled.PickerItemContainer>
    );
};

PickerItem.propTypes = {
    icon: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isTooltipDisabled: PropTypes.bool.isRequired,
    tooltipContent: PropTypes.string.isRequired,
    isPackageRelated: PropTypes.bool.isRequired,
    price: PropTypes.number,
    inStockString: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
};

PickerItem.defaultProps = {
    price: 0,
    inStockString: '',
};

export default PickerItem;
