import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useCurrentBrand  } from '../../../hooks';
import HEBREW from '../../../language/hebrew';
import Specifications from './Specifications';
import * as Styled from './SpecificationsModal.styles';

const SpecificationsModal = ({ isVisible, onClose }) => {
    const currentBrand = useCurrentBrand();
    const backdrop = React.useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && onClose();

    const modal = (
        <CSSTransition in={ isVisible } timeout={ 500 } classNames="show-up" unmountOnExit>
            <Styled.Container ref={ backdrop } onClick={ clickHandler }>
                <Styled.SpecificationsModalContainer className="modal-content">
                    <Styled.CloseButton $brand={ currentBrand } onClick={ onClose }>{ HEBREW.CLOSE }</Styled.CloseButton>
                    <Styled.SpecificationsContainer>
                        <Specifications />
                    </Styled.SpecificationsContainer>
                </Styled.SpecificationsModalContainer>
            </Styled.Container>
        </CSSTransition>
    );

    return ReactDOM.createPortal(modal, document.body);
};

SpecificationsModal.defaultProps = {
    modelCode: '',
    isVisible: false,
    onClose: () => {},
};

SpecificationsModal.propTypes = {
    modelCode: PropTypes.string,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default SpecificationsModal;
