import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const TabItem = styled.li`
  padding: 10px 50px;
  border-bottom: 1px solid ${({ $isSelected }) => $isSelected ? '#0f0f0f' : 'transparent'};
  font-size: ${pxToRem(14)};
  text-align: center;
  cursor: pointer;
  font-weight: ${({ $isSelected }) => $isSelected ? 'bold' : 400};
  &:hover{
    font-weight: bold;
    border-bottom: 1px solid #0f0f0f;
  }
  @media (max-width: ${DEVICE_SIZE.VERY_SMALL}px) {
    padding:10px 25px;
    flex: 1;
  }
`;
