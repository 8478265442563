/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import PropTypes from 'prop-types';
import { getWarrentyLink } from '../../../../utils/assetsUtils';
import { spreadAllManufacturingDates } from '../../../../utils/commonUtils';

// todo : replace the following content  with real data from the server

const CarInfoAudi = ({ brand, ManufacturingDates }) => {
    return (
        <>
            {spreadAllManufacturingDates(ManufacturingDates)}
            <p>מצב מכני של הרכב : מצב חדש ללא נזק בטיחותי</p>
            <p> ללא תקלות בטיחותיות סדרתיות </p>
            <p>מדינת הייצור : ספרד</p>
            <p>תקופת האחריות: 3 שנים. פירוט תנאי האחריות ניתן למצוא <a href={ getWarrentyLink(brand) } rel="noreferrer" target="_blank">כאן</a>
            </p>
        </>
    );
};

CarInfoAudi.propTypes = {
    brand: PropTypes.string.isRequired,
    ManufacturingDates: PropTypes.array.isRequired,
};

export default CarInfoAudi;
