import React from 'react';
import PropTypes from 'prop-types';
import { parseMultipleModelsUrl, renderNisSymbol } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import SelectButton from '../../../SelectButton/SelectButton';
import Styled from './ModelItem.style';

const ModelItem = ({
    model,
}) => {
    const {
        manufacturer,
        totalPrice,
        image,
        imageAlt,
        coverColor,
    } = model;

    return (
        <Styled.ModelItem>
            <Styled.UpperContent>
                <Styled.ColorfulContainer color={ coverColor }>
                    <Styled.ModelName>{ manufacturer }</Styled.ModelName>
                    <Styled.ModelDescription>{ manufacturer }</Styled.ModelDescription>
                </Styled.ColorfulContainer>
                <Styled.Image
                    alt={ imageAlt }
                    src={ image }
                />
            </Styled.UpperContent>
            <Styled.PriceContainer>
                <Styled.PriceWrapper>
                    <Styled.PriceTitle>{ STRINGS.STARTING_FROM }-</Styled.PriceTitle>
                    <Styled.Price>
                        <Styled.NisSymbol>{ renderNisSymbol() }</Styled.NisSymbol>
                        { Number(totalPrice).toLocaleString() }
                    </Styled.Price>
                </Styled.PriceWrapper>
                { STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE }
            </Styled.PriceContainer>
            <Styled.ButtonWrapper>
                <SelectButton
                    text={ STRINGS.CHOOSE }
                    href={ parseMultipleModelsUrl(manufacturer) }
                />
            </Styled.ButtonWrapper>
        </Styled.ModelItem>
    );
};

ModelItem.propTypes = {
    model: PropTypes.any.isRequired,
};

export default ModelItem;
