import styled from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../config';
import { presentationalFadeIn } from '../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const Car360 = styled(motion.div).attrs({
    ...presentationalFadeIn,
})`
  height: 100%;
  position: relative;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height:${({ theme }) => theme.presentation.mobile.height};
  }
`;

export const Iframe = styled.iframe.attrs(() => ({
    name: 'Car360',
}))`
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border: none;
`;

export const ImageWrapper = styled(motion.div).attrs({
    ...presentationalFadeIn,
})`
height:100%;
@media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   height:${({ theme }) => theme.presentation.mobile.height};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 90%;
    max-height: 100%;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    img{
      position:static;
    }
  }
`;
