import * as actionTypes from '../../actionTypes';
import {
    getModelLocalGroups,
    getAddOnMoreInformationHtml,
} from '../../../fetch/configurator_options';
import { throwErrorIfNoData } from '../../../fetch/fetchUtils';
import { mapModalLocalGroups } from '../../dispatchers/utils';

const getModelLocalGroupsPending = () => ({
    type: actionTypes.GET_MODEL_LOCAL_GROUPS_PENDING,
});

const getModelLocalGroupsSuccess = (data, sOriginalAdditions) => ({
    type: actionTypes.GET_MODEL_LOCAL_GROUPS_SUCCESS,
    data,
    selectedHoopCode: sOriginalAdditions,
});

const getModelLocalGroupsFailure = (error) => ({
    type: actionTypes.GET_MODEL_LOCAL_GROUPS_FAILURE,
    error,
});

export const getModelLocalGroupsAsync = (modelCode, sOriginalAdditions, carVersion) => {
    return async (dispatch) => {
        dispatch(getModelLocalGroupsPending());
        try {
            const response = await getModelLocalGroups(
                modelCode,
                sOriginalAdditions,
                carVersion,
            );
            const { data } = response;

            throwErrorIfNoData(data);

            const mappedLocalGroups = data.map(mapModalLocalGroups);

            dispatch(getModelLocalGroupsSuccess(mappedLocalGroups, sOriginalAdditions));

            return mappedLocalGroups;
        } catch (error) {
            dispatch(getModelLocalGroupsFailure(error));

            return [];
        }
    };
};

export const removeAddOn = (addOn) => ({
    type: actionTypes.REMOVE_ADD_ON,
    addOn,
});

const getAddOnsExtraInfoPending = () => ({
    type: actionTypes.GET_ADD_ONS_EXTRA_INFO_PENDING,
});

const getAddOnsExtraInfoSuccess = (data) => ({
    type: actionTypes.GET_ADD_ONS_EXTRA_INFO_SUCCESS,
    data,
});

const getAddOnsExtraInfoFailure = (error) => ({
    type: actionTypes.GET_ADD_ONS_EXTRA_INFO_FAILURE,
    error,
});

export const getAddOnsExtraInfoAsync = (brand, groupCode, listLocalPackages) => {
    return async (dispatch) => {
        dispatch(getAddOnsExtraInfoPending());
        try {
            const addOnLocalCodesArray = listLocalPackages.reduce((acc, addOn) => {
                acc.push(addOn.local);

                return acc;
            }, []);

            const addOnMoreInfo = listLocalPackages.map(async (addOn) => {
                const response = await getAddOnMoreInformationHtml(brand, addOn.local);
                     
                return response.data;
            });

            const finalInfo = await Promise.allSettled(addOnMoreInfo);

            const addOnCodeWithInfo = addOnLocalCodesArray.reduce((acc, addOnLocalCode, index) => {
                const { value } = finalInfo[index];

                const dataObj = {
                    [addOnLocalCode]: {
                        value,
                    },
                };

                acc.push(dataObj);

                return acc;
            }, []);
            
            const data = {
                [groupCode]: {
                    ...addOnCodeWithInfo,
                },
            };

            dispatch(getAddOnsExtraInfoSuccess(data));
        } catch (error) {
            dispatch(getAddOnsExtraInfoFailure(error));
        }
    };
};
