import styled from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Header = styled.div`
    position: relative;
    display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
    height: 65px;
    width: 100%;
    align-items: center;
    background-color:${({ theme, $isCupra }) =>  $isCupra ? theme.buttonColor : theme.footerBackgroundColor};
    border-bottom: 4px solid ${({ theme }) => theme.primaryColor};
    &::after{
        position: absolute;
        bottom: 0;
        content: '';
        border-bottom: 1px solid white;
        width: 100%;
        display: block;
    }
`;

export const HeaderText = styled.p`
    color: white;
    font-size: 1.5375rem;
    font-weight: 700;
    margin-right: 15px;
    line-height: 26px;
`;

export const CheckoutProgress = styled.div`
/* flex: 0 1 calc(100% - 450px); */
flex:1;
margin-left: auto;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
       margin-left:0;
       padding-top: 25px;
    }
`;
