import React from 'react';
import STRINGS from '../../../../../language';
import * as Styled from './SystemDetailsLegend.styles';

const SystemDetailsLegend = () => {
    return (
        <Styled.SystemDetailsLegend>
            <Styled.Title>{STRINGS.LEGEND}</Styled.Title>
            <Styled.LgendList>
                <Styled.LegendItem>{STRINGS.SYSTEM_IS_INSTALLED}</Styled.LegendItem>
                <Styled.LegendItem>{STRINGS.SYSTEM_IS_NOT_INSTALLED}</Styled.LegendItem>
                <Styled.LegendItem>{STRINGS.SYSTEM_IS_OPTIONAL}</Styled.LegendItem>
            </Styled.LgendList>
        </Styled.SystemDetailsLegend>
    );
};

export default SystemDetailsLegend;
