import styled, { css } from 'styled-components';
import { ImageBox, Image, Figure } from '../../../../Steps/Model/ModelImage/ModelImage.styles';
import { pxToRem } from '../../../../../utils/commonUtils';
import { COLORS } from '../../../../../constants';
import CONFIG from '../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ModelGroupItem = styled.li`
  flex: 0 0 25%;
  max-width: 400px;
  min-width: 400px;
  cursor: pointer;
  order:2;
  position: relative;
  
  @media (min-width: ${DEVICE_SIZE.LARGE}px) {
    ${({ $isGroupSelected }) => !$isGroupSelected
    && css`
      &:hover::before {
        content: "";
        width: 80%;
        height: 5px;
        background-color: ${({ theme }) => theme.primaryColor};
        position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    `}
  }


  @media (min-width: ${DEVICE_SIZE.MEDIUM + 1}px) {
    ${({ $isDisabled }) => $isDisabled && css`
      pointer-events: none;
      opacity: 0.3;
  `};
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;
  }

`;

export const ModelGroup = styled.div.attrs(() => ({
    role: 'button',
}))`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  ${ImageBox} {
    max-width: 100%;
    height: 170px;
    margin: auto;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      height: auto;
    }
  }
  ${Image} {
    width: 100%;
  
  }
  padding:0 20px ;
  
  ${({ $isGroupSelected }) => $isGroupSelected
    && css`
      padding-bottom: 0px;
      &:after {
        content: "";
        height: 4px;
        background-color: ${({ theme }) => theme.primaryColor};
        width: 90%;
        position: absolute;
        bottom: -2px;
        right: 0;
        z-index: 1;
      }
    `};

    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      flex-direction: row;
      padding-bottom: 0;
      border-bottom: 1px solid #d8d8d8;
      background-color:${({ $isGroupSelected }) => $isGroupSelected ? COLORS.BG_COLOR_MODEL_ITEM_SELECTED : 'transparent'};
      ${ImageBox} {
        display: flex;
        align-items: center;
      }
      ${Figure}{
        width: 35%;
        margin: auto;
      }
      &:after{
        display: none;
      }
}
`;

export const Information = styled.div`
  padding: 20px 0 15px 0;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column;
  }
`;

export const GroupModelTitle = styled.p`
  margin: 0;
  color: ${COLORS.COLOR_ITEM_CAR_FIGCAPTION};
  font-size: ${pxToRem(32)};
  font-weight: 700;
  text-decoration: none;
  margin-left: 12px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin: 0;
    font-size: ${pxToRem(20)};
    font-weight: bold;
    line-height: 1.5;
  }
`;

export const Description = styled.p`
  height: 36px;
  margin: 0;
  font-weight: 700;
  font-size: ${pxToRem(18)};
  line-height: 2;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin: 0;
    font-size: ${pxToRem(15)};
    line-height: 1.5;
    font-weight:normal;
  }

`;

export const ModelItems = styled.ul`
  cursor:initial;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
  position:relative;
  display: flex;
  width: ${({ width }) => `${width}px`};
  margin-right: ${({ $offsetMargin }) => `-${$offsetMargin}px`};
  transform: scaleY(0);    
  transform-origin: top;
  visibility: hidden;
  max-height:0;
  transition: all .5s ease;
  ${({ $isGroupSelected }) => $isGroupSelected
    && css`
      transform: scaleY(1);
      border-top: 1px solid #343434;
      visibility: visible;
      max-height:100000px;
      transition: transform .5s ease;
    `};
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      border:none;
    }
    &:after{
      content:'';
      position:absolute;
      width:4px;
      height:40px;
      left:-1px;
      border:1px solid ${COLORS.WHITE};
      top:-20px;
      background-color:${COLORS.WHITE};
    }
`;

export const HideModelItemsButton = styled.button`
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  background-color: ${COLORS.WHITE};
  outline:none;
  position: absolute;
    left: 0;
    transform: translate(5px,-15px);
    z-index: 0;
    cursor: pointer;
  &:after{
    content:'X';
    font-size: 18px;
    font-weight: bold;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;
