/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../config';
import { MENUFACTURS } from '../../../constants';

let ConfiguratorSteps;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        ConfiguratorSteps = require(
            '../../../components/CommercialConfigurator/ConfiguratorSteps/ConfiguratorSteps',
        ).default;
        break;
    default:
        ConfiguratorSteps = require('./ConfiguratorSteps').default;
}

export default ConfiguratorSteps;
