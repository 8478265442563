import {
    getCurrentBrandFromEnv,
    isArrayEmpty,
    parseMultipleModelsUrl,
} from '../../../utils/commonUtils';
import STRINGS from '../../../language';
import { TYPES, DEFAULT_WHEEL } from '../../../constants';

const {
    COLOR_OUT,
    COLOR_INT,
    JANT,
    EQUIPMENT_PACKAGE,
} = TYPES;

const {
    CHOOSE,
    AUDI_CHOOSE_DELIVERY_COMPLEX,
} = STRINGS;

export const mapOnlineCars = (car) => {
    const currentBrand = getCurrentBrandFromEnv();
    const {
        code: groupModelCode,
        description: groupModelDescription,
        header: groupModelHeader,
        coverColor,
        fullDescription: groupModelFullDescription,
        catalogLink,
        models,
    } = car;

    return models.map((model) => {
        const {
            code: modelCode,
            description: modelDescription,
            engineType,
            version,
            additionalDetails,
            pricing,
            estimatedArrival,
            isDiscountExist,
            mainFeatures,
            pollutionAndSafety,
            style,
        } = model;

        const {
            base: basePrice,
            registrationTax,
            total: totalPrice,
            priceList,
        } = pricing;

        const {
            code: priceListCode,
            validity,
        } = priceList;

        const {
            pollutionLevel,
            safetyLevel,
        } = pollutionAndSafety;

        const {
            fuelCombined,
            electricConsumption,
            range,
        } = additionalDetails;

        const {
            code: styleLevelCode,
            description: styleLevelDescription,
        } = style;
        
        const carId = `${modelCode}-${styleLevelCode}`;
        const hrefToConfigurator = `/configurator?modelCode=${modelCode}&trimLevelCode=${styleLevelCode}&modelGroupCode=${groupModelCode}`;

        const mappedCarObject = {
            id: carId,
            brand: currentBrand,
            modelGroup: groupModelDescription,
            modelGroupCode: parseInt(groupModelCode),
            engineCapacity: null, // should be float
            engineType,
            modelDescription,
            modelCode,
            trimLevel: styleLevelDescription,
            trimLevelCode: parseInt(styleLevelCode),
            description: groupModelHeader,
            hebrewModelGroup: groupModelFullDescription,
            mainCharacteristics: mainFeatures,
            price: parseInt(basePrice),
            licenseFee: parseInt(registrationTax),
            pollutionLevel,
            safetyEquipmentLevel: safetyLevel,
            totalPrice: parseInt(totalPrice),
            carVersion: parseInt(version),
            carHasDiscount: isDiscountExist,
            priceList: parseInt(priceListCode),
            priceListDate: validity,
            catalogLink,
            avergaeFuelConsumption: parseFloat(fuelCombined),
            electricConsumption,
            range,
            safetySystemDetails: [], // mapSafetySystemDetails(safetySystemDetails)
            arrivalDate: estimatedArrival,
            coverColor,
        };

        return {
            ...mappedCarObject,
            isSelected: false,
            hrefToConfigurator,
            hrefToSpecifications: `/specifications/${modelCode}`, // To Do: Note that "hrefToSpecifications" is not used anywhere and if you plan on using it note that it might have to be changed to accept "originalAdditions". 
            hrefToModels: parseMultipleModelsUrl(groupModelDescription, groupModelCode),
        };
    });
};

export const mapSubModels = (model, subModel) => {
    const currentBrand = getCurrentBrandFromEnv();
    const {
        version,
        originalAdditions,
        pricing,
        estimatedArrival,
        isDiscountExist,
        engineType,
        additionalDetails,
        originalAdditionDetails,
        equipmentPackageCode,
        equipmentPackageDescription,
        equipmentPackageDetails,
        pollutionAndSafety,
        links,
    } = subModel;

    const {
        base: basePrice,
        registrationTax,
        total: totalPrice,
        priceList,
    } = pricing;

    const {
        code: priceListCode,
        validity,
    } = priceList;

    const {
        fuelCombined,
        electricConsumption,
        range,
    } = additionalDetails;

    const {
        pollutionLevel,
        safetyLevel,
    } = pollutionAndSafety;

    const originalAdditionsCodeUrlReadyHashTag = encodeURIComponent(originalAdditions); // Encoding original additions to be URL friendly (mainly to replace '#' with '%23')

    let carId;

    let hrefToConfigurator;

    const {
        modelGroup,
        modelCode,
        modelDescription,
        trimLevelCode,
        trimLevel,
        modelGroupCode,
        catalogLink,
        hrefToModels,
    } = model;

    if (originalAdditionsCodeUrlReadyHashTag) {
        carId = `${modelCode}-${trimLevelCode}-${originalAdditions}`;
        hrefToConfigurator = `/configurator?modelCode=${modelCode}&trimLevelCode=${trimLevelCode}&modelGroupCode=${modelGroupCode}&originalAdditions=${originalAdditionsCodeUrlReadyHashTag}`;
    } else {
        carId = `${modelCode}-${trimLevelCode}`;
        hrefToConfigurator = `/configurator?modelCode=${modelCode}&trimLevelCode=${trimLevelCode}&modelGroupCode=${modelGroupCode}`;
    }

    const mappedCarObject = {
        id: carId,
        brand: currentBrand,
        modelGroup,
        modelGroupCode,
        modelCode,
        modelDescription,
        engineType,
        trimLevel,
        trimLevelCode,
        equipmentPackageCode,
        equipmentPackageDescription,
        equipmentPackageDetails,
        originalAdditionsCode: originalAdditions,
        originalAdditionDescriptions: originalAdditionDetails,
        price: parseInt(basePrice),
        licenseFee: parseInt(registrationTax),
        pollutionLevel,
        safetyEquipmentLevel: safetyLevel,
        totalPrice: parseInt(totalPrice),
        carVersion: parseInt(version),
        carHasDiscount: isDiscountExist,
        priceList: parseInt(priceListCode),
        priceListDate: validity,
        catalogLink,
        avergaeFuelConsumption: parseFloat(fuelCombined),
        electricConsumption,
        range,
        safetySystemDetails: [], // mapSafetySystemDetails(safetySystemDetails)
        arrivalDate: estimatedArrival,
        links,
    };

    return {
        ...mappedCarObject,
        isSelected: false,
        hrefToConfigurator,
        hrefToModels,
    };
};

const mapSafetySystemDetails = (safetySystemDetails) => {
    return safetySystemDetails.map((detail) => {
        const {
            Description, Value, installationStatus,
        } = detail;

        return {
            name: Description,
            value: Value,
            valueType: installationStatus,
        };
    });
};

const sortColorsByPriceAsc = (colors) => {
    colors.sort((a, b) => {
        return parseFloat(a.price) - parseFloat(b.price);
    });
};

const sortDataByPackageRelatedness = (data) => {
    data.sort((a, b) => {
        return b.isPackageRelated - a.isPackageRelated;
    });
};

export const mapExternalColors = (externalColors) => {
    const mappedExternalColors = externalColors.map((externalColor) => {
        const {
            code,
            description,
            price,
            estimatedArrival,
            isSelectable,
            images,
            welcomePicture,
        } = externalColor;

        const {
            icon,
            threeSixty,
            static: staticImages,
        } = images;
        
        return {
            value: code,
            description,
            price: parseInt(price),
            inStock: null, // boolean
            arrivalDate: estimatedArrival,
            link360: threeSixty,
            carImages: staticImages,
            welcomePicture,
            icon,
            inStockString: estimatedArrival,
            isPackageRelated: isSelectable,
            type: COLOR_OUT,
        };
    });

    sortColorsByPriceAsc(mappedExternalColors);
    sortDataByPackageRelatedness(mappedExternalColors);

    return mappedExternalColors;
};

export const mapInternalColors = (internalColors) => {
    const mappedInternalColors = internalColors.map((internalColor) => {
        const {
            code,
            description,
            price,
            estimatedArrival,
            isSelectable,
            images,
        } = internalColor;

        const {
            icon,
            threeSixty,
            static: staticImages,
        } = images;

        return {
            value: code,
            description,
            price: parseInt(price),
            inStock: null, // boolean
            arrivalDate: estimatedArrival,
            link360: threeSixty,
            carImages: staticImages,
            icon,
            inStockString: estimatedArrival,
            isPackageRelated: isSelectable,
            type: COLOR_INT,
        };
    });

    sortColorsByPriceAsc(mappedInternalColors);
    sortDataByPackageRelatedness(mappedInternalColors);

    return mappedInternalColors;
};

export const mapConfigurationOptions = (availableColors) => {
    // const {
    //     GroupModelDescription,
    //     GroupModelCode,
    //     GroupModelHebDescription,
    //     ModelCode,
    //     ModelDescription,
    //     Standard,
    //     AccessoryLevel,
    //     PollutionLevel,
    //     ListExternalColors,
    //     ListSafetyAccessories,
    //     Brand,
    //     FuelCombined,
    //     StyleLevelDescription,
    //     isBeforeProduction,
    // } = configuationObject;
    
    // const {
    //     groupModelDescription,
    //     styleLevelDescription,
    //     standard,
    //     safetySystems,
    // } = pollutionAndSafety;

    return {
        id: null,
        // modelGroup: groupModelDescription,
        modelGroupCode: null,
        modelCode: null,
        modelDescription: 'ggg',
        safetyEquipmentLevel: null,
        pollutionLevel: null,
        hebrewModelGroup: null,
        // regulation: standard,
        averageFuelConsumption: null,
        // safetySystemDetails: mapSafetySystemDetails(safetySystems),
        externalColors: mapExternalColors(availableColors),
        // trimLevel: styleLevelDescription,
        brand: null,
        isBeforeProduction: null, // boolean

    };
};

/**
 * 
 * @param {originalPackages} data 
 * @returns {hoop} 
 * this parser functions has been commneted untill decision will be made how many 
   hoops/packages should be displayed/rendered
 * meanwhile there is a temoprary function (below) 
 */

// export const parseOriginalPackagesToHoops = (originalPackages) => {
//     if (!isArrayAndNotEmpty(originalPackages)) {
//         return [];
//     }

//     return originalPackages.reduce((acc, item) => {
//         const weels =   item.ListPackages.reduce((packagesWithWeels, listPackage) => {
//             const {
//                 Wheel, Price, ListAdditions, Original, Description,
//             } = listPackage;

//             if (!Wheel) {
//                 return packagesWithWeels;
//             }
//             const weel = {
//                 code: Original,
//                 name: Description,
//                 price: Number.parseInt(Price),
//             };

//             if (isArrayEmpty(ListAdditions)) {
//                 packagesWithWeels.push(weel);
                
//                 return packagesWithWeels;
//             }

//             ListAdditions.forEach((addition) => {
//                 const { AdditionInPackageWheel, AdditionInPackageDescription } = addition;

//                 if (!AdditionInPackageWheel) {
//                     return false;
//                 }

//                 weel.name = AdditionInPackageDescription;
//                 packagesWithWeels.push(weel);
//             });

//             return packagesWithWeels;
//         }, []);

//         return acc.concat(weels);
//     }, []);
// };

/**
 * 
 * @param {object} originalPackage 
 * @returns {wheel}
 */

export const parseOriginalPackageToHoops = (originalPackage, trimLevel, originalAdditionsCode) => {
    const {
        code,
        description,
        image,
    } = originalPackage;
    // const { AdditionInPackageDescription, AdditionInPackageImage } = WheelAddition;
    const isDefault = description === DEFAULT_WHEEL; // TODO: 'Default Wheel' needs a constant code that we will be able to compare against. 
    const name = isDefault ? `${STRINGS.HOOP_FOR_TRIM_LEVEL}: ${trimLevel}` : description;
    /**
     * isPackageRelated 
     * TODO::
       remove this (make it dynamic) when it will be decided to show more then one hoop/original package
       and then, some of these items would be related or not related to the equipment package 
     */
    const isPackageRelated = true;
                                       
    return {
        value: originalAdditionsCode,
        description: name,
        icon: image,
        price: 0,
        isDefault,
        isPackageRelated,
        type: JANT,
    };
};

export const parseSpecificationsData = (data) => {
    if (isArrayEmpty(data) || !data) {
        return [];
    }

    const tabsAndScreenDataInit = {
        tabs: [],
        screenData: {},
        
    };
      
    const tabsAndScreenData = data.reduce((acc, item) => {
        const {
            header, specifications,
        } = item;

        acc.tabs.push({
            headerDescription: header,
            headerCode: null,
            key: header,
        });
        
        acc.screenData[header] = specifications.map((specification, key) => {
            const {
                description, value,
            } = specification;
            
            return {
                key,
                lineCode: null,
                lineDescription: value,
                headerDescription: description,
                status: null,
            };
        });
      
        return acc;
    }, tabsAndScreenDataInit);

    return tabsAndScreenData;
};

const mapLocalPackages = (localPackage) => {
    const currentBrand = getCurrentBrandFromEnv();
    const {
        code,
        description,
        discount,
        image,
        price,
        isDiscountExist,
        priceAfterDiscount,
    } = localPackage;

    return {
        brand: currentBrand,
        description,
        discount,
        image,
        local: code,
        price,
        priceAfterDiscount,
        isDiscount: isDiscountExist,
    };
};

export const mapModalLocalGroups = (localGroup) => {
    const {
        code,
        description,
        localPackagesInGroup,
        onlyOneAddition,
    } = localGroup;

    return {
        groupCode: code,
        groupDescription: description,
        listLocalPackages: localPackagesInGroup.map(mapLocalPackages),
        onlyOneAddition,
    };
};

export const mapDealershipsToOptions = (dealerships, isAudi = false) => {
    dealerships.unshift({
        dealershipId: 0,
        dealershipName: isAudi ? AUDI_CHOOSE_DELIVERY_COMPLEX : CHOOSE,
        Address: '',
    });

    return dealerships.map((dealership) => {
        const { dealershipId, dealershipName } = dealership;

        return {
            value: parseInt(dealershipId),
            text: dealershipName,
            description: null,
        };
    });
};

export const mapEquipmentPackages = (equipmentPackage) => {
    const {
        code, description, price, links, properties,
    } = equipmentPackage;

    const {
        packageImage,
        rimIcons,
        availableColorIconImages,
    } = links;
    const isBasicEquipmentPackage = code === '';

    return {
        id: code,
        image: packageImage,
        name: description,
        price: parseInt(price),
        hoops: rimIcons,
        colors: availableColorIconImages,
        properties,
        isBasic: isBasicEquipmentPackage,
        type: EQUIPMENT_PACKAGE,
    };
};

export const mapPollutionAndSafetyData = (data) => {
    // check if group or single
    if (data.length) {
        return data.map((singleData, index) => {
            return {
                ...singleData,
                key: index,
            };
        });
    }

    return data;
};
