import { useSelector } from 'react-redux';

export const useSelectedCurrentData = () => {
    return useSelector(({ selectedCurrentDataReducer }) => selectedCurrentDataReducer);
};

export const useCurrentStep = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedStep } = selectedCurrentData;
    
    return selectedStep;
};

export const useCurrentExternalColor = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedExternalColor } = selectedCurrentData;
    
    return selectedExternalColor;
};
export const useCurrentInternalColor = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedInternalColor } = selectedCurrentData;
    
    return selectedInternalColor;
};

export const useCurrentCar = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedCar } = selectedCurrentData;

    return selectedCar;
};

export const useCurrentHoop = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedHoop } = selectedCurrentData;

    return selectedHoop;
};

export const useCurrentAddOns = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedAddOns } = selectedCurrentData;

    return selectedAddOns;
};

export const useCurrentAddOnGroup = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { selectedAddOnGroup } = selectedCurrentData;

    return selectedAddOnGroup;
};

export const useCurrentIsInSummary = () => {
    const selectedCurrentData = useSelectedCurrentData();
    const { isInSummary } = selectedCurrentData;

    return isInSummary;
};
