import { useDataForPaymentDetails } from '../components/PaymentDetails/PaymentDetails.hooks';
import { GTM_EVENT_TYPES } from '../constants';
import {  useSelectedCurrentData } from '../hooks';
import { pushGtmEvent, parseGtmDataToDimension3Format } from './gtmUtils';

const { CHECKOUT, PURCHASE } = GTM_EVENT_TYPES;
/**
 * 
 * @param {{
 *   step:number
 *   eventType:string
 *   category:string
 *   quantity:number
 * }} 
 * @returns 
 */

export const useGtm = ({
    step = null,
    eventType = CHECKOUT,
    category = 'online',
    quantity = 1,
} = {}) => {
    const showPricesAfterDiscount = true;
    const { totalPrices } = useDataForPaymentDetails(showPricesAfterDiscount);
    const {
        selectedCar, selectedExternalColor, selectedInternalColor, gtmData,
    } = useSelectedCurrentData();
    const { id, modelDescription, brand } = selectedCar;

    const dimension3 = parseGtmDataToDimension3Format(gtmData);

    return (moreDataForProduct, transactionId) => {
        const actionField = step ? { step } : {
            id: transactionId,
            revenue: totalPrices.total.toLocaleString(),
        };
        const action = eventType === CHECKOUT ? CHECKOUT : PURCHASE;

        pushGtmEvent({
            event: eventType,
            ecommerce: {
                [action]: {
                    actionField,
                    products: [
                        {
                            id,
                            name: modelDescription,
                            price: totalPrices.total,
                            brand,
                            category,
                            quantity,
                            dimension1: selectedExternalColor.value,
                            dimension2: selectedInternalColor.value,
                            dimension3,
                            ...moreDataForProduct,
                        },
                    ],
                },
            },
        });
    };
};

export const useGA4Gtm = ({
    eventType = CHECKOUT,
    category = 'online',
    quantity = 1,
} = {}) => {
    const showPricesAfterDiscount = true;
    const { totalPrices } = useDataForPaymentDetails(showPricesAfterDiscount);
    const {
        selectedCar, selectedExternalColor, selectedInternalColor, gtmData,
    } = useSelectedCurrentData();
    const { id, modelDescription, brand } = selectedCar;

    const dimension3 = parseGtmDataToDimension3Format(gtmData);

    return (transactionId = undefined) => {
        pushGtmEvent({ ecommerce: null });
        pushGtmEvent({
            transaction_id: transactionId,
            event: eventType,
            ecommerce: {
                currency: 'ILS',
                value: totalPrices.total,
                items: [{
                    item_id: id,
                    item_name: modelDescription,
                    item_brand: brand,
                    item_category: category,
                    item_category2: selectedExternalColor.value,
                    item_category3: selectedInternalColor.value,
                    item_category4: dimension3,
                    price: totalPrices.total,
                    quantity,
                }],
            },
        });
    };
};

// transactionComplete
