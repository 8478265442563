/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const PrivacyPolicyData = () => {
    return (
        <>
            <ol type="1">
                <li><strong>כללי</strong>
                    <ol type="1">
                        <li>כחלק ממדיניותנו, חברת צ&apos;מפיון מוטורס בע&quot;מ (להלן: ״<strong>החברה</strong>״), ושאיפותינו לשקיפות ולבהירות, אנו מפרסמים מסמך זה, המפרט את מדיניות הפרטיות באתר שלנו<span>.</span></li>
                        <li>החברה מכבדת את פרטיותם של המשתמשים הנכנסים לאתר ו/או המשתמשים בשירותים השונים המוצעים במסגרתו. מטרת התנאים המפורטים במדיניות פרטיות זו, הנה לסקור, בין היתר, את האופן שבו משתמשת החברה במידע שנמסר לה על ידי המשתמשים באתר ו/או נאסף על ידה בעת השימוש באתר ו/או בשירותים הדיגיטליים לרבות שירות חשבונית הזיכוי הדיגיטלי, וכן את האופן שבו מבצע המשתמש שימוש בתוכן המצוי באתר ו/או אליו נחשף המשתמש באמצעותו<span>.</span></li>
                        <li>תנאי מדיניות הפרטיות המפורטים להלן, מהווים חלק בלתי נפרד &nbsp;מתנאי השימוש.<span>&nbsp;</span><span>החברה רשאית לשנות מעת לעת את הוראות מדיניות הפרטיות שלה, כדי שישקפו שינויים טכנולוגיים, עסקיים, משפטיים או רגולטורים. במקרה שכזה, השינוי יעודכן במסמך זה ויפורסם באתר. שימושך באתר&nbsp;</span>ו/או בשירותים הדיגיטליים לאחר ביצוע השינויים, יעיד על הסכמת המשתמש לשינויים אלו, ועל כן החברה ממליצה לעיין במדיניות זו מעת לעת<span>.</span></li>
                        <li>כל ההגדרות והמונחים במדיניות הפרטיות יהיו כמשמעם בתנאי השימוש, אלא אם הקשרם של הדברים מחייב אחרת<span>.</span></li>
                    </ol>
                </li>
            </ol>
            <p><span>&nbsp;</span></p>
            <ol start="2" type="1">
                <li><strong>רישום והעברת מידע לחברה</strong>
                    <ol type="1">
                        <li>חלק מהשירותים טעונים מסירת מידע אישי. במסגרת רכישת רכב באתר ו/או פניה לחברה באמצעות האתר, או שימוש בשירותים הדיגיטליים, יהיה על המשתמש למסור מידע אישי, כדוגמת כתובת <span>IP</span><span>,</span> שם המשתמש, כתובתו, דרכי ההתקשרות עמו ו/או כתובת הדואר האלקטרוני שלו ותמצית פנייתו לחברה. השדות, שחובה למלא, יסומנו ככאלו במפורש<span>.</span></li>
                        <li>לחברה מאגר מידע רשום בו יישמרו פרטי המשתמשים באתר, כמפורט לעיל, אשר בין מטרותיו ניהול מועדון לקוחות, לרבות לצורך מתן שירות ללקוחות החברה, תפעול וניהול המכירות, טיפוח ועידוד נאמנות המשתמשים באמצעות ניתוח הרגלי הקנייה וקיום מבצעים ותמריצים, דיוור ישיר למטרות אלו ולצרכים שיווקיים, עריכת ניתוחים, סקרים ומחקרים, שירותי דיוור ישיר והעברת המידע לחברות בנות ו/או סוכנים מורשים ו/או חברות הקשורים לחברה לצורך משלוח הצעות מיוחדות ואחרות למשתמשי האתר<span>.</span></li>
                        <li>מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור את המידע כאמור וכן שמסירת המידע תלויה ברצונו ובהסכמתו של המשתמש. עם זאת, מילוי חלק מהשדות בטופס הפניה ליצירת קשר מהווה תנאי הכרחי לרכישת רכב ו/או לפנייה לחברה דרך האתר<span>.</span></li>
                        <li>המשתמש מצהיר ומתחייב בזאת, כי כל המידע שימסור ו/או יעדכן באתר, הינו נכון, אמין ומדויק וכן, כי ביצע את ההרשמה ו/או מסר את המידע בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש. המידע שמסר המשתמש, אף במצב אורח, וכן מידע נוסף שיצטבר אודותיו ופעולותיו באמצעות האתר יישמרו במאגר המידע של החברה, המפורט לעיל, וייעשה בו שימוש בהתאם למטרות המאגר המפורטות בסעיף 2.2 לעיל ובהתאם למדיניות פרטיות זו ו/או בהתאם להוראות כל דין<span>.</span></li>
                    </ol>
                </li>
            </ol>
            <p><span>&nbsp;</span></p>
            <ol start="3" type="1">
                <li><strong>איסוף ושימוש במידע</strong>
                    <ol type="1">
                        <li>החברה רשאית לאסוף וכן לעשות שימוש במידע שנמסר על ידי המשתמש במועד ההרשמה ו/או במידע אשר הצטבר אודות המשתמש, לרבות במהלך השימוש באתר או בשירותים דיגיטליים, על מנת לשפר, להעשיר או לשנות (לרבות שינוי של האתר המוצג למשתמש) את האתר, לתעד את מתן השירותים, לרבות הדיגיטליים ואת התכנים המוצעים באתר, וכן על מנת לעדכן את המשתמש באשר למבצעים ו/או שירותים המוצעים על ידי החברה, חברות קשורות, מי מטעמה ו/או על ידי צדדים שלישיים עימם יש לחברה שיתוף פעולה עסקי. בנוסף, רשאית החברה לעשות שימוש במידע למטרות שיווקיות וסטטיסטיות, ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים וזאת בכפוף לתנאי מדיניות הפרטיות. מידע סטטיסטי זה לא יזהה את המשתמש אישית<span>.</span></li>
                        <li>כמו כן, רשאית החברה לעשות שימוש במידע שנתקבל מאת המשתמש ו/או במידע שנאסף אודותיו, גם במקרים הבאים: לשם יצירת קשר עם המשתמש במקרה הצורך; לצורך תחזוקת האתר ו/או המערכת; לצורך שיפור המידע והתכנים שהחברה תציע במסגרת האתר למשתמשים באופן אישי ו/או לכלל ו/או לחלק מן המשתמשים באתר<span>.</span></li>
                        <li>ככל שתבחר לעשות שימוש בשירותים הדיגיטליים נמליץ לך לשמור את התוצרים מקומית, היות וזמן תקפות הלינק דרכו יתקבלו המסמכים מושא השירותים מוגבל.</li>
                        <li>מבלי לגרוע מהאמור לעיל, בכפוף למסירת המשתמש את פרטי ההתקשרות שלו לחברה והסכמתו לקבלת דיוור ישיר, תהיה החברה רשאית לשלוח למשתמש, מעת לעת, בדואר אלקטרוני ו/או באמצעי התקשרות אחרים שמסר המשתמש, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי של החברה. המשתמש יהיה רשאי לבטל בכל עת את הסכמתו ולחדול מקבלת המידע כאמור,&nbsp;באמצעות לחצן &quot;הסרה&quot; אשר יופיע בתחתית ההודעה<span>;</span></li>
                        <li>האתר משתמש בתוכנות צוברות מידע, בעוגיות ודומיהן (להלן:<span><span>&nbsp;&quot;</span><strong>Cookies</strong>&quot;&nbsp;</span><span>ו/או</span><span>&nbsp;&quot;</span>עוגיות״) לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפות האישיות של המשתמש, לאפיין את המוצרים המתאימים לו, להתאים לגולש פרסומות מותאמות אף בגלישתו באתרים אחרים ולצרכי אבטחת מידע. עוגיות הן קבצי טקסט, שהדפדפן של המשתמש יוצר לפי פקודה ממחשבי החברה. חלק מהעוגיות יפקעו כאשר המשתמש יסגור את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב המשתמש. העוגיות מכילים מידע מגוון כדוגמת הדפים שבהם ביקר המשתמש, משך הזמן שהמשתמש שהה באתר, מהיכן הגיע המשתמש אל האתר, מדורים ומידע שהמשתמש מבקש לראות בעת הכניסה לאתר ועוד. כמו כן יכול והעוגיות ישמרו אף מידע בדבר הרגלי הגלישה של המשתמש באתרים אחרים, לרבות האתרים בהם גלש, העמודים באתרים וכל פעולה אחרת בהם. המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות כדי להבטיח שרק מחשבי החברה או מי מטעמה יוכלו לקרוא ולהבין את המידע האגור בהם<span>.</span></li>
                        <li>עוגיות עשויות להיות מטעם צד ג&apos; &ndash; למשל עוגיות של חברת <span>Google</span><span>&nbsp;כגון אלו המוטמעות תוך שימוש ב-</span><span><span>G</span>oogle Ads</span><span>.</span></li>
                        <li>אם המשתמש לא רוצה לקבל עוגיות, ניתן להימנע מכך על ידי שינוי ההגדרות בדפדפן המשתמש. לשם כך נא היוועץ בקובץ העזרה של הדפדפן. זכור, עם זאת, שנטרול העוגיות עלול לגרום לכך שהמשתמש לא יוכל להשתמש בחלק מהשירותים והתכונות באתר<span>.</span></li>
                    </ol>
                </li>
            </ol>
            <p><span>&nbsp;</span></p>
            <ol start="4" type="1">
                <li><strong>מסירת מידע לצד שלישי</strong>
                    <ol type="1">
                        <li>החברה תנקוט באמצעים סבירים ומקובלים על מנת לשמור ולמנוע העברת פרטים אישיים המזהים את המשתמש בשמו ו/או בפרטי זהותו, כגון שם וכתובת המשתמש, לצדדים שלישיים, וזאת למעט במקרים כדלקמן<span>:</span>
                            <ol type="1">
                                <li>על פי דרישת המשתמש ו/או בהסכמתו המפורשת<span>;</span></li>
                                <li>במסגרת שיתופי פעולה עם חברות קשורות עסקית עם החברה<span>;</span></li>
                                <li>אם המשתמש נרשם לאתר באמצעות שימוש בחשבונו ברשת חברתית<span>;</span></li>
                                <li>בכל מקרה בו הפר המשתמש את תנאי השימוש, לרבות תנאי מדיניות הפרטיות ו/או במקרים בהם בצע המשתמש או ניסה לבצע המשתמש ו/או מי מטעמו, באמצעות האתר ו/או בקשר איתו, פעולות המנוגדות להוראות תנאי השימוש, לרבות מדיניות הפרטיות, לרבות הוראות כל דין<span>;</span></li>
                                <li>בשל צו שיפוטי המורה לחברה למסור את המידע לצדדים שלישיים<span>;</span></li>
                                <li>בשל כל מחלוקת, טענה, תביעה, דרישה ו/או הליכים משפטיים אשר יתנהלו בין המשתמש ו/או מטעמו לבין החברה ו/או מי מטעמה<span>;</span></li>
                                <li>בכל מקרה בו תסבור החברה, כי מסירת המידע נחוצה על מנת למנוע נזק חמור לרכושם ו/או לגופם של החברה, של המשתמש ו/או של צדדים שלישיים, או על מנת למנוע נזק חמור אחר לפי שיקול דעתה הבלעדי<span>;</span></li>
                                <li>במקרה בו העבירה ו/או המחתה החברה לצד שלישי את פעילותה ו/או את זכויותיה וחובותיה כלפי המשתמש לצד שלישי ובלבד שצד שלישי כאמור יקבל על עצמו את ההוראות המפורטות במדיניות פרטיות זו<span>.</span></li>
                            </ol>
                        </li>
                        <li>אין באמור לעיל בכדי לגרוע מזכות החברה להעביר לצדדים שלישים מידע שאינו אישי, אשר אין בו בכדי לזהות את המשתמש בשמו ו/או בפרטי זהותו באופן ישיר<span>.</span></li>
                    </ol>
                </li>
            </ol>
            <p><span>&nbsp;</span></p>
            <ol start="5" type="1">
                <li><strong>אבטחת מידע</strong>
                    <ol type="1">
                        <li>החברה עושה ככל שיכולתה בכדי להגן על סודיות הנתונים שנמסרו על ידי משתמשי האתר ולקוחותיה, זאת תוך נקיטת אמצעי זהירות מקובלים ושימוש בטכנולוגיות אבטחה מתקדמות. ידוע למשתמש כי החברה מקדישה משאבים ונוקטת אמצעים מחמירים למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיות המשתמש, אולם היא אינה יכולה למנוע שיבושים באתר באופן מוחלט<span>.</span></li>
                        <li>החברה תשתמש באמצעי הגנה והצפנה לפעולות של מסירת מידע מקוון, ובהתאם לנדרש על פי כל דין. אמצעי ההגנה האמור נועדו לאבטחת זרימת מידע מוצפנת בין מחשב המשתמש בזמן ביצוע הרכישה לבין שרתי האתר והחברה<span>.</span></li>
                        <li>החברה עושה כל שביכולתה ונעזרת באמצעי אבטחה טכנולוגיים וארגוניים מתקדמים כדי לאבטח את האתר, את התקשורת דרכו ואת המידע המצוי בשליטתה, כנגד ניצול מקרי או מכוון, אובדן, הרס או כנגד גישה על ידי אנשים בלתי מוסמכים או מורשים. ההתקשרות בין המחשב של המשתמש או המבקר באתר לבין מחשבי החברה מאובטחת באמצעות שיטות הצפנה מקובלות, בהתאם לתקנים הנהוגים. כמו כן, החברה נוקטת באמצעים סבירים להגנה על האתר ועל רכיבי חומרה ותוכנה הקשורים בתפעולו ודואגת לעדכנם באופן שוטף, בין היתר, כדי להגן על האתר ועל תוכנו מפני חדירות, פרצות או האזנות בלתי מורשות<span>.</span></li>
                        <li>החברה מתעדכנת באופן שוטף בהתפתחויות הטכנולוגיות בתחומי התוכנה והחומרה על-מנת לספק למשתמשי האתר ולקוחותיה את ההגנה הטובה ביותר מפני חדירה או פריצה, לרבות כניסה לא מורשית למאגרי המידע שלה. עם זאת החברה מבהירה, כי במקרים שאינם בשליטתה ו/או נובעים מכח עליון, היא איננה מתחייבת, שהאתר יתנהל כסדרו, ללא כל הפרעה, ו/או שהאתר ו/או הנתונים שנאספו ו/או נמסרו כאמור לעיל יהיו חסינים באופן מוחלט מפני גישה ו/או חדירה בלתי מורשית למאגרי המידע של החברה וכי ידוע למשתמש שהחברה לא תישא באחריות בגין כל נזק ו/או אובדן, ישירים או עקיפים, מכל סוג שהוא, שנגרמו כתוצאה מכך, לרבות בשל פגיעה בפרטיות<span>.</span></li>
                        <li>החברה אינה אוגרת מספרי כרטיסי אשראי במחשביה או שרתיה<span>.</span></li>
                        <li>החברה משתמשת בשירותי חברות סליקת כרטיסי אשראי ואבטחה מהמובילות בישראל לצורך בדיקת ואבטחת המידע העומדת בתקנים קפדניים של סטנדרטים ונהלי אבטחת מידע לפי דרישת חברות האשראי תקן<span>&nbsp;.PCI DSS&nbsp;</span><span>האתר מאובטח באמצעות פרוטוקול</span><span>&nbsp;SSL,&nbsp;</span><span>כלומר כל התקשורת שבין הדפדפן (אצל המשתמש) לאתר (כלומר שרת האינטרנט המאחסן את העמודים) הינה מוצפנת כך שלא ניתן לפענח את המידע המועבר בין הדפדפן לאתר</span><span>.</span></li>
                    </ol>
                </li>
            </ol>
            <p><span>&nbsp;</span></p>
            <p><span>&nbsp;</span></p>
            <ol start="6" type="1">
                <li><strong>יצירת קשר בנושא פרטיות</strong></li>
                <ol>
                    <p><span>על-פי חוק הגנת הפרטיות, התשמ&quot;א-1981, המשתמש, או מי מטעמו, רשאי לעיין במידע אודות המשתמש אשר מצוי במאגר החברה ואף לבקש מהחברה לתקן מידע זה אם אינו נכון, שלם או מדויק. כדי לממש זכות זו, תוכלו לחייג אלינו באמצעות מספר הטלפון</span><span>&nbsp;</span><a href="tel:*6363">*6363</a><span>.</span></p>
                </ol>
            </ol>

        </>
    );
};

export default PrivacyPolicyData;
