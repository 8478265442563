import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../../utils/commonUtils';
import { SYSTEM_DETAILS_LEGEND, COLORS } from '../../../../../../constants';
import CONFIG from '../../../../../../config';

const { DEVICE_SIZE } = CONFIG;

const renderSystemDetails = ({ $valueType, $value }) => {
    let content = null;

    let color = null;

    switch ($valueType) {
        case SYSTEM_DETAILS_LEGEND.INSTALLED:
            content = '●';
            color = COLORS.SYSTEM_DETAILS_LEGEND.INSTALLED;
            break;

        case SYSTEM_DETAILS_LEGEND.NOT_INSTALLED:
            content = 'x';
            color = COLORS.SYSTEM_DETAILS_LEGEND.NOT_INSTALLED;
            break;

        case SYSTEM_DETAILS_LEGEND.OPTIONAL_INSTALLATION:
            content = '○';
            color = COLORS.SYSTEM_DETAILS_LEGEND.OPTIONAL_INSTALLATION;
            break;

        case SYSTEM_DETAILS_LEGEND.VALUE:
            content = $value;
            color = COLORS.SYSTEM_DETAILS_LEGEND.DEFAULT;
            break;
        default:
            break;
    }

    return css`
        content:'${content}';
        color:${color};
    `;
};

export const SystemDetailItem = styled.li`
     @media(max-width:${DEVICE_SIZE.SMALL}px){
       flex:1 1 50%;
    }
`;
export const Detail = styled.p`
    border: 1px solid #000;
    margin:0;
    display:flex;
    /* white-space: nowrap; */
    font-size:${pxToRem(13)};
    line-height: 1.5;
    font-weight: 500;
    padding-left:2px;
    height: 100%;
    /* @media(max-width:${DEVICE_SIZE.SMALL}px){
        white-space: initial; 
    } */
 &:before{
    ${renderSystemDetails}
    font-weight: bold;
    border-left: 1px solid #000;
    min-width: 20px;
    display: inline-block;
    text-align: center;
    margin-left: .5rem;
 }
`;
