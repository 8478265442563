import styled from 'styled-components';

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #C6C6C6;
`;

const Styled = {
    Divider,
};

export default Styled;
