import styled, { css } from 'styled-components';
import { ReactComponent as Arrow } from '../../../../../assets/icons/accordion-arrow.svg';
import { COLORS } from '../../../../../constants';

const AccordionArrow = styled(Arrow)`
  transition: transform 0.6s;
`;

const AddOnGroupItem = styled.li`
  border-bottom: 1px solid ${COLORS.GROUP_ITEM_COLOR_BORDER_BOTTOM};
  padding: 15px 0;
  ${({ $isActive }) => $isActive
    && css`
      ${AccordionArrow} {
        transform: rotateX(180deg);
      }
  `}
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const AddOnGroup = styled.div``;

const AddOnItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const Title = styled.h5`
  margin: 0;
  padding: 10px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`;

const Styled = {
    AddOnGroupItem,
    TitleContainer,
    AccordionArrow,
    AddOnGroup,
    AddOnItems,
    Title,
};

export default Styled;
