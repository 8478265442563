import STRINGS from '../../language';
import { ICONS_NAMES, CHECKOUT_PAGE_PROGRESS_STEPS } from '../../constants';
import { getIconFromAssets } from '../../utils/assetsUtils';
import IdentificationAndRegistration from
    './ProgressScreenSteps/IdentificationAndRegistration/IdentificationAndRegistration';
import PickUpAndFinancing from './ProgressScreenSteps/PickUpAndFinancing/PickUpAndFinancing';
import PaymentAndDownPayment from
    './ProgressScreenSteps/PaymentAndDownPayment/PaymentAndDownPayment';
import PaymentSuccess from './ProgressScreenSteps/PaymentSuccess/PaymentSuccess';
import PaymentFailure from './ProgressScreenSteps/PaymentFailure/PaymentFailure';

const {
    IDENTIFICATION_AND_REGISTRATION,
    PICK_UP_AND_FINANCING,
    PAYMENT_AND_DOWN_PAYMENT,
    SUCCESS,
    FAILURE,
} = CHECKOUT_PAGE_PROGRESS_STEPS;
const {
    IDENTIFY_ICON,
    IDENTIFY_ICON_AUDI,
    PAYMENT,
} = ICONS_NAMES;

export const PROGRESS_STEPS = (isAudi) => [
    {
        step: IDENTIFICATION_AND_REGISTRATION,
        title: STRINGS.IDENTIFICATION_AND_REGISTRATION,
        subTitle: STRINGS.IDENTIFICATION,
        icon: getIconFromAssets(isAudi ? IDENTIFY_ICON_AUDI : IDENTIFY_ICON),
        showStep: true,
        ScreenStep: IdentificationAndRegistration,
    },
    {
        step: PICK_UP_AND_FINANCING,
        title: STRINGS.PICK_UP_AND_FINANCING,
        subTitle: null,
        icon: null,
        showStep: true,
        ScreenStep: PickUpAndFinancing,
    },
    {
        step: PAYMENT_AND_DOWN_PAYMENT,
        title: STRINGS.PAYMENT_AND_DOWN_PAYMENT,
        subTitle: STRINGS.PAYMENT,
        icon: getIconFromAssets(PAYMENT),
        showStep: true,
        ScreenStep: PaymentAndDownPayment,
    },
    {
        step: SUCCESS,
        title: '',
        subTitle: null,
        icon: null,
        showStep: false,
        ScreenStep: PaymentSuccess,
    },
    {
        step: FAILURE,
        title: '',
        subTitle: null,
        icon: null,
        showStep: false,
        ScreenStep: PaymentFailure,
    },
];
