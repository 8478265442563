import { CHECKOUT_PAGE_FORMS } from '../../constants';
import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const { IDENTIFICATION_FORM } = CHECKOUT_PAGE_FORMS;

const initialState = {
    isInCheckout: false,
    customer: {
        firstName: null,
        lastName: null,
        fullName: null,
        customerNumber: null,
        phone: null,
        mobilePhoneNumber: null,
        email: null,
        idNumber: null,
        address: null,
    },
    identification: {
        currentIdentificationForm: IDENTIFICATION_FORM,
        isLoading: false,
        error: null,
        idNumber: null,
    },
    pickUpAndFinancing: {
        dealerships: [],
        selectedDealership: {
            value: '',
            text: null,
            description: null,
        },
        isFinancing: false,
        isLoading: true,
        error: null,
    },
    commision: {
        isLoading: false,
        error: null,
        creditGuardUrl: null,
        totalPayment: null,
    },
    registration: {
        registerReason: null,
        isLoading: false,
        error: null,
    },
    order: {
        orderId: null,
    },
    isFinished: false,
    isPaymentFailed: false,
};

export const checkout = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_FIRST_IDENTIFICATION_PENDING:
            return dispatchers.customerFirstIdentificationPending(state);
        case actionTypes.CUSTOMER_FIRST_IDENTIFICATION_SUCCESS:
            return dispatchers.customerFirstIdentificationSuccess(state, action);
        case actionTypes.CUSTOMER_FIRST_IDENTIFICATION_FAILURE:
            return dispatchers.customerFirstIdentificationFailure(state, action);
        case actionTypes.CUSTOMER_IDENTIFICATION_PENDING:
            return dispatchers.customerIdentificationPending(state);
        case actionTypes.CUSTOMER_IDENTIFICATION_SUCCESS:
            return dispatchers.customerIdentificationSuccess(state, action);
        case actionTypes.CUSTOMER_IDENTIFICATION_FAILURE:
            return dispatchers.customerIdentificationFailure(state, action);
        case actionTypes.CUSTOMER_REGISTRATION_PENDING:
            return dispatchers.customerRegistrationPending(state);
        case actionTypes.CUSTOMER_REGISTRATION_SUCCESS:
            return dispatchers.customerRegistrationSuccess(state, action);
        case actionTypes.CUSTOMER_REGISTRATION_FAILURE:
            return dispatchers.customerRegistrationFailure(state, action);
        case actionTypes.SAVE_CUSTOMER_ID_NUMBER:
            return dispatchers.saveCustomerIdNumber(state, action);
        case actionTypes.GET_DEALERSHIPS_PENDING:
            return dispatchers.getDealershipsPending(state);
        case actionTypes.GET_DEALERSHIPS_SUCCESS:
            return dispatchers.getDealershipsSuccess(state, action);
        case actionTypes.GET_DEALERSHIPS_FAILURE:
            return dispatchers.getDealershipsFailure(state, action);
        case actionTypes.SELECT_DEALERSHIP:
            return dispatchers.selectDealership(state, action);
        case actionTypes.SET_IS_FINANCING:
            return dispatchers.setIsFinancing(state, action);
        case actionTypes.HOLD_COMMISION_PENDING:
            return dispatchers.holdCommisionPending(state);
        case actionTypes.HOLD_COMMISION_SUCCESS:
            return dispatchers.holdCommisionSuccess(state, action);
        case actionTypes.HOLD_COMMISION_FAILURE:
            return dispatchers.holdCommisionFailure(state, action);
        case actionTypes.INITALIZE_CHECKOUT:
            return dispatchers.initializeCheckout(initialState);
        case actionTypes.SET_IS_FINISHED:
            return dispatchers.setIsFinished(state);
        case actionTypes.SET_EDITED_ORDER_ID:
            return dispatchers.setEditedOrderId(state, action);
        case actionTypes.SET_IS_IN_CHECKOUT:
            return dispatchers.setIsInCheckout(state, action);
        case actionTypes.SET_CURRENT_IDENTIFICATION_FORM:
            return dispatchers.setCurrentIdentificationForm(state, action);
        case actionTypes.SET_IS_PAYMENT_FAILED:
            return dispatchers.setIsPaymentFailed(state);
        case actionTypes.SAVE_PHONE_NUMBER:
            return dispatchers.saveCustomerPhoneNumber(state, action);
        case actionTypes.SET_REGISTER_REASON:
            return dispatchers.setRegisterReason(state, action);
        default:
            return state;
    }
};
