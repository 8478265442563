import styled from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const InnerPagesHeader = styled.header`
  width: 100%;
  margin-bottom: 80px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    margin-bottom: 32px;
  }
`;
