import { NEW_API_METHODS, STATUS_CODES } from '../constants';
import STRINGS from '../language';
import { AppError } from '../constants/errors';
import axios from './axios';

const { CREATE_LEAD, CREATE_LEAD_WITH_ORDER_DETAILS } = NEW_API_METHODS.LEAD;

/**
 * @POST request
 * @param {string} firstName
 * @param {string} phone
 * @param {string} email
 * @param {string} sNotes
 * @param {string} sCID
 * @param {string} sTRAFICSOURCE
 * @param {string} sTRAFICSOURCETYPE
 * @param {boolean} bMarketingInfo
 */

export const sendLeadWithCustomerConnectionInfo = async (
    firstName = ' ',
    lastName = ' ',
    requestReason = ' ',
    message = ' ',
    contactEmail = ' ',
    contactPhone = ' ',
) => {
    const postData = {
        firstName,
        lastName,
        requestReason,
        message,
        contactInfo: {
            email: contactEmail,
            phone: contactPhone,
        },
    };
    const response = {};

    try {
        await axios.post(
            CREATE_LEAD,
            postData,
        );
        
        response.isSuccess = true;
        response.error = null;
    } catch (error) {
        response.isSuccess = false;
        response.error = error.message;
        // throw new AppError(STRINGS.SOMETHING_WENT_WRONG);
    }

    return Promise.resolve(response);
};

export const sendLeadWithOrderDetails = async ({
    firstName = ' ',
    lastName = ' ',
    phone = ' ',
    email = '',
    orderDescriptions = { },
    hasRequestedMarketing = false,
}) => {
    const postData = {
        firstName,
        lastName,
        hasRequestedMarketing,
        orderDescriptions,
        contactInfo: {
            email,
            phone: phone?.replace('-', ''),
        },
    };

    const response = {};

    try {
        const result = await axios.post(
            CREATE_LEAD_WITH_ORDER_DETAILS,
            postData,
        );
        
        const { status } = result;
        const isSuccess = status === STATUS_CODES.SUCCESS;

        if (!isSuccess) {
            throw new AppError(STRINGS.SOMETHING_WENT_WRONG);
        }
        response.isSuccess = true;
        response.error = null;
    } catch (error) {
        response.isSuccess = false;
        response.error = error.message;
    }

    return Promise.resolve(response);
};

// export const sendLeadWithCustomerConnectionInfoSplit = async (
//     firstName = ' ',
//     lastName = ' ',
//     phone = ' ',
//     email = ' ',
//     modelGroupId,
//     orderDescriptions = ' ',
//     bMarketingInfo = false,
//     closeResonId = '0',
// ) => {
//     console.log(orderDescriptions);
//     const { campaignId, sourceId1, sourceId2 } = getLeadCustomConnectionInfoByBrand(currentBrand);

//     const postData = {
//         senderID: SENDER_ID,
//         campaignId,
//         leadId: LEAD_ID,
//         firstName,
//         lastName,
//         phone,
//         email,
//         dealerID: DEALER_ID,
//         sourceId1,
//         sourceId2,
//         modelGroupId,
//         senderName: SENDER_NAME,
//         closeResonId,
//         orderDescriptions,
//         sCID: S_CID,
//         sTRAFICSOURCE: STRAFIC_SOURCE,
//         sTRAFICSOURCETYPE: STRAFIC_SOURCE_TYPE,
//         bMarketingInfo,
//         bIsAllDivisions: IS_ALL_DIVISIONS,
//     };
//     const response = {};

//     try {
//         const result = await axios.post(
//             CREATE_LEAD,
//             postData,
//         );
//         const { data } = result;
//         const { Result: isSuccess } = JSON.parse(data.oResult);

//         if (!isSuccess) {
//             throw new AppError(STRINGS.SOMETHING_WENT_WRONG);
//         }
//         response.isSuccess = true;
//         response.error = null;
//     } catch (error) {
//         response.isSuccess = false;
//         response.error = error.message;
//     }

//     return Promise.resolve(response);
// };
