import React from 'react';
import PropTypes from 'prop-types';
import QuestionMarkTp from '../../../../../../components/QuestionMarkTooltip/QuestionMarkTooltip';
import { getIconFromAssets } from '../../../../../../utils/assetsUtils';
import { ICONS_NAMES } from '../../../../../../constants';
import * as Styled from './EquipmentScreenItem.styles';

const EquipmentScreenItem = ({ isAvailable, name }) => {
    const isAvailableIcon = getIconFromAssets(ICONS_NAMES.CHECK_MARK_WHITE);
    
    return (
        <Styled.EquipmentScreenItem>
            <Styled.Name>
                <Styled.TextWithEllipsis>{name}</Styled.TextWithEllipsis>
                <Styled.ToolTipContainer>
                    <QuestionMarkTp text={ name } />
                </Styled.ToolTipContainer>
            </Styled.Name>
            <Styled.Icon
                $isAvailable={ isAvailable }
                $icon={ isAvailableIcon }
            />
        </Styled.EquipmentScreenItem>
    );
};

EquipmentScreenItem.defaultProps = {
    isAvailable: false,
    name: '',
};

EquipmentScreenItem.propTypes = {
    isAvailable: PropTypes.bool,
    name: PropTypes.string,
};

export default EquipmentScreenItem;
