import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Answer.styles';

const Answer = ({ answer, isSelected }) => {
    return (
        <Styled.Answer $isSelected={ isSelected }>
            <Styled.Text>{answer}</Styled.Text>
        </Styled.Answer>
    );
};

Answer.defaultProps = {
    isSelected: false,
};

Answer.propTypes = {
    isSelected: PropTypes.bool,
    answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

export default Answer;
