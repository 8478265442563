import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS, ICONS_NAMES } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import { NoEntranceIcon } from '../../../../common/styles';
import { Tooltip } from '../../../../Configurator/Tooltip/Tooltip.styles';
import CONFIG from '../../../../../config';
import { staggerHorizontalChildVariants } from '../../../../../utils/animationsConfig';

const checkMarkIcon = getIconFromAssets(ICONS_NAMES.CHECK_MARK_WHITE);
const { DEVICE_SIZE } = CONFIG;

export const InternalColorItem = styled(motion.li).attrs({
    variants: staggerHorizontalChildVariants,
})`
  position: relative;
  padding: ${({ $isAudi }) => $isAudi ? '0' :  '30px 0'};
  border-bottom: ${({ $isAudi }) => $isAudi ? 'none' :  `1px solid ${COLORS.INTERNAL_COLOR_ITEM_BORDER_BOTTOM}`};
  &:first-child {
    padding-top: 0;
  }
  ${NoEntranceIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding:25px 0;
    border-bottom-color: transparent;
    &:first-child {
      padding-top:25px;
  }
    &:not(:last-child){
    margin-left:10px;
    }
    ${Tooltip}{
      display:none;
    }
  
  }
`;
export const Content = styled.div`
  opacity: ${({ $isPackageRelated }) => ($isPackageRelated ? 1 : '0.34')};
  display: flex;
`;
export const Picker = styled.div`
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  border-radius: ${({ theme }) => theme.configuratorStepItemBorderRadius};
  border: 1px solid #ddd;
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ $image }) => $image});
  position: relative;
  cursor: initial;
  ${({ $isPackageRelated }) => $isPackageRelated
    && css`
      cursor: pointer;
      ${({ $selected, $isAudi }) => $selected && !$isAudi
        && css`
          box-shadow: 0 1px 8px 1px rgb(117 108 88 / 64%);
          border: 3px solid ${COLORS.INTERNAL_COLOR_ITEM_PICKER_BORDER};
          &:after {
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            border-radius: 50%;
            top: 0;
            background-color: ${({ theme }) => theme.checkMarkBackgroundColor};
            background-image: url(${checkMarkIcon});
            background-repeat: no-repeat;
            background-position: center;
            /*/**************************************
              picker check mark position calculcation based on itemPicker border radious
              1. if item picker border radius === 50% (circle) , so the initial values are 
                    left : -8px;
                    translate :0 ;
              2. else the item picker has a regular radius(in cupra for example), so the values are:
                 left = 0; 
                 translate = '-45%, -45%';
            */
            ${(theme) => {
        const { configuratorStepItemBorderRadius: radius } = theme;

        let translate = 0;

        let left = '-8px';

        if (radius !== '50%') {
            left = 0;
            translate = '-45%, -45%';
        }

        return css`
                transform: translate(${translate});
                left: ${left};
              `;
    }};

            /**************************************/
            @media(max-width:${DEVICE_SIZE.MEDIUM}px){
              left: 0;
              right: 0;
              margin: auto;
              transform: translateY(-50%);
            }

          }
        `}
    `};
`;

export const Details = styled.div`
  margin-right: 30px;
  display: ${({ $isAudi }) => $isAudi ? 'none' :  'static'};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:none;
  }
`;

const Text = styled.p`
  margin: 0;
  font-weight: 700;
  padding: 5px;
`;

export const Description = styled(Text)`
  font-size: ${pxToRem(15)};
`;
export const Price = styled(Text)`
  font-size: ${pxToRem(13)};
`;
export const InStock = styled(Text)`
  font-weight: 400;
  font-size: ${pxToRem(12)};
`;

export const NoPackageTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${NoEntranceIcon} {
    position: static;
    margin-left: 10px;
    transform: translate(0);
  }
`;
export const TooltipTitle = styled.h6`
  font-size: ${pxToRem(14)};
  margin: 0;
  margin-bottom:10px;
  text-align: center;
  color: ${COLORS.WHITE};
`;

export const TooltipInnerText = styled.p`
  color: #eee;
  font-weight: 300;
  font-size: 0.9rem;
  margin: 0;
`;
