import styled from 'styled-components';
import { coolSlideRight } from '../common/styles';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ $icon }) => $icon ? '40px auto auto' : 'auto auto'};
    margin-top: 10px;
    min-height: 35px;
    animation: ${coolSlideRight} 0.8s ease-in-out 1 both;
    align-items: center;
    &.modelPrice {
        font-weight: bold;
    }
`;

export const Icon = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background-color: #FFFFFF;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    ${({ $icon }) => $icon && `background-image: url('${$icon}');`}  
`;

export const Title = styled.div`
    font-size: ${({ isTotalPrice }) => isTotalPrice ? '1.5rem' : '1rem'};
    font-weight : ${({ $icon }) => $icon ? '400' : 'bold'};
    text-align: right;
`;

export const Price = styled.div`
    font-size: ${({ isTotalPrice }) => isTotalPrice ? '1.5rem' : '1rem'};
    text-align: left;
    direction: ltr;
    .isTotalPrice {
        font-weight: bold;
    }

    @media (max-width: 1100px) {
        display: inline-grid;
    }
`;

export const Price2 = styled.span`
    font-size: ${({ isTotalPrice }) => isTotalPrice ? '1.5rem' : '1rem'};
    text-align: left;
    unicode-bidi: plaintext;
    padding-right: 9px;
    ${({ isTotalPrice }) => isTotalPrice && 'font-weight: bold;'}
    .isTotalPrice {
        font-weight: bold;
    }
    &::before {
        content: '₪';
        padding-right: 5px;
    }

    @media (max-width: 1100px) {
        grid-row-start: 2;
    }
`;

export const PriceBeforeDiscount = styled.span`
    font-size: ${({ isTotalPrice }) => isTotalPrice ? '1.5rem' : '1rem'};
    text-align: left;
    text-decoration: line-through;
    color: #8a8a8a;
    .isTotalPrice {
        font-weight: bold;
    }
    
    &::before {
        content: '₪';
        padding-right: 5px;
    }

    @media (max-width: 1100px) {
        grid-row-start: 1;
    }
`;

const Style = {
    Wrapper,
    Icon,
    Title,
    Price,
    Price2,
    PriceBeforeDiscount,
};

export default Style;
