import React from 'react';
import PropTypes from 'prop-types';
import Styled from './SelectButton.style';

const SelectButton = ({
    text,
    href,
}) => {
    return (
        <Styled.SelectButton
            to={ href }
        >
            { text }
        </Styled.SelectButton>
    );
};

SelectButton.propTypes = {
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
};

export default SelectButton;
