export const SELECT_CHOSEN_CAR = 'SELECT_CHOSEN_CAR';
export const SELECT_CURRENT_STEP = 'SELECT_CURRENT_STEP';
export const SELECT_CURRENT_EXTERNAL_COLOR = 'SELECT_CURRENT_EXTERNAL_COLOR';
export const SELECT_CURRENT_INTERNAL_COLOR = 'SELECT_CURRENT_INTERNAL_COLOR';
export const SELECT_CURRENT_HOOP = 'SELECT_CURRENT_HOOP';
export const SELECT_CURRENT_ADD_ON_GROUP = 'SELECT_CURRENT_ADD_ON_GROUP';
export const SELECT_CURRENT_ADD_ON = 'SELECT_CURRENT_ADD_ON';
export const REMOVE_CURRENT_ADD_ON = 'REMOVE_CURRENT_ADD_ON';
export const SELECT_IS_IN_SUMMARY = 'SELECT_IS_IN_SUMMARY';
export const SELECT_MORE_INFO_MODAL_PENDING = 'SELECT_MORE_INFO_MODAL_PENDING';
export const SELECT_MORE_INFO_MODAL_SUCCESS = 'SELECT_MORE_INFO_MODAL_SUCCESS';
export const SELECT_MORE_INFO_MODAL_FAILURE = 'SELECT_MORE_INFO_MODAL_FAILURE';
export const SELECT_EQUIPMENT_PACKAGE = 'SELECT_EQUIPMENT_PACKAGE';
export const INITIALIZE_SELECTED_CURRENT_DATA =  'INITIALIZE_SELECTED_CURRENT_DATA';
export const INITIALIZE_SELECTED_EQUIPMENT_PACKAGE = 'INITIALIZE_SELECTED_EQUIPMENT_PACKAGE';
export const INITIALIZE_CURRENT_EXTERNAL_COLOR = 'INITIALIZE_CURRENT_EXTERNAL_COLOR';
export const INITIALIZE_ALL_CURRENT_SELECTED_DATA = 'INITIALIZE_ALL_CURRENT_SELECTED_DATA';
