import React from 'react';
import ProgressScreenSteps from '../ProgressScreenSteps/ProgressScreenSteps';
import { useCurrentAppCommonData } from '../../../hooks/app_common_data';
import { useCheckoutIsFinished } from '../../../hooks';
import HEBREW from '../../../language/hebrew';
import * as Styled from './CheckoutProgress.styles';

const CheckoutProgress = () => {
    const isPaymentFinished = useCheckoutIsFinished();
    const { isCupra, isAudi } = useCurrentAppCommonData();

    return (
        <Styled.CheckoutProgress>
            { !isAudi && (
                <Styled.Header $isVisible={ !isPaymentFinished } $isCupra={ isCupra }>
                    <Styled.HeaderText>{HEBREW.LAST_DETAILS}</Styled.HeaderText>
                </Styled.Header>
            ) }
            <ProgressScreenSteps />
        </Styled.CheckoutProgress>
    );
};

export default CheckoutProgress;
