import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import CONFIG from '../../../config';
import { COLORS } from '../../../constants';

const { DEVICE_SIZE } = CONFIG;

export const SocialLinks = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    border-top: 1px solid ${COLORS.BORDER_SOCIAL_LINKS_SECTION};
    padding-top: 20px;
    width: 100%;
    justify-content: center;
  }
`;

export const SocialLinkItem = styled.li`
  width: 30px;
  height: 30px;
  &:first-child {
    height: 70px;
    width: 70px;
    ${({ $isCupra }) => $isCupra && css`
      width: 260px;
      height: 30px;
    `};
  }
  &:not(:first-child) {
    margin-right: 10px;
  }
`;

export const SocialLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(${({ $icon }) => $icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
