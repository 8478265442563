import { useState } from 'react';

export const useSelectModelItem = () => {
    const [selectedModelItem, setSelectedModelItem] = useState(null);

    const selectModelItemHandler =  (modelItemId) => {
        const id = selectedModelItem === modelItemId ? null : modelItemId;

        setSelectedModelItem(id);
    };

    return [selectedModelItem, selectModelItemHandler];
};
