import styled from 'styled-components';
import { getIconFromAssets } from '../../utils/assetsUtils';
import { ICONS_NAMES } from '../../constants';

const downChevronIcon = getIconFromAssets(ICONS_NAMES.DOWN_CHEVRON);

export const Container = styled.div`
    position: fixed;
    height: 20%;
    right: 0;
    left: 0;
    bottom: 0;
    transform: ${({ $isVisible }) => $isVisible ? 'translateY(1%)' : 'translateY(100%)'};
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
    mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    background-color: rgba(0, 0, 0, 1);
`;

export const Text = styled.p`
    color: white;
    font-size: 24px ;
`;

export const ArrowsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 18px;
`;

export const Arrow = styled.div`
    display: flex;
    height: 26px;
    width: 26px;
    margin-top: -6px;
    margin-bottom: -6px;
    background-image: url(${downChevronIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-animation: action 0.5s infinite  alternate;
    animation: action 0.5s infinite  alternate;

    @-webkit-keyframes action {
        0% { transform: translateY(0); opacity: 0.3 }
        100% { transform: translateY(-10px); opacity: 1}
    }

    @keyframes action {
        0% { transform: translateY(0); opacity: 0.3 }
        100% { transform: translateY(-10px); opacity: 1}
    }
`;
