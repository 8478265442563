import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import STRING from '../../language';
import {
    getCarPlaceholderImageUrl,
    getMutagBlackLogo,
    getOutCarImageUrl,
} from '../../utils/assetsUtils';
import * as Styled from './CarImage.style';

const CarImage = ({
    brand, outColor, modelGroup, trimLevelCode,
}) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        // if props change, we want to load a new image
        setHasError(false);
    }, [brand, outColor]);

    const handleImageError = () => {
        setHasError(true);
    };

    if (hasError) {
        return (
            <Styled.NoImage
                alt={ STRING.NO_CAR_IMAGE_ALT }
                className="CarImage"
                src={ getCarPlaceholderImageUrl(brand) }
            />
        );
    }

    return (
        <Image
            showLoading={ false }
            className="CarImage"
            onImageError={ handleImageError }
            fallbackSrc={ getMutagBlackLogo(brand) }
            src={ getOutCarImageUrl(brand, modelGroup, trimLevelCode, outColor) }
            alt={ STRING.CAR_IMAGE_ALT }
            style={ {
                width: '100%',
            } }
        />
    );
};

CarImage.propTypes = {
    brand: PropTypes.string.isRequired,
    outColor: PropTypes.string.isRequired,
    modelGroup: PropTypes.string.isRequired,
    trimLevelCode: PropTypes.number.isRequired,
};

CarImage.defaultProps = {
};

export default React.memo(CarImage);
