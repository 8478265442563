import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './FooterNavigationItem.styles';

const FooterNavigationItem = ({ title, href, target }) => {
    return (
        <Styled.NavigationItem>
            <Styled.NavigationLink target={ target } to={ href } rel="noindex nofollow">
                {title}
            </Styled.NavigationLink>
        </Styled.NavigationItem>
    );
};

FooterNavigationItem.defaultProps = {
    title: '',
    href: '/',
    target: '',
};

FooterNavigationItem.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
};

export default FooterNavigationItem;
