import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as Styled from './TermsOfUseModal.styles';

const Modal = ({ children, isVisible, onClose }) => {
    if (isVisible) {
        return (
            <Styled.ModalContainer onClick={ onClose }>
                <Styled.BackDrop id="TermsOfUseModalBackDrop">
                    <Styled.Modal>
                        <Styled.ScrollBar>
                            <Styled.CloseButton id="TermsOfUseModalCloseButton" />
                            <Styled.Content>
                                {children}
                            </Styled.Content>
                        </Styled.ScrollBar>
                    </Styled.Modal>
                </Styled.BackDrop>
            </Styled.ModalContainer>
        );
    }

    return null;
};

const TermsOfUseModal = ({ children, isVisible, onClose }) => {
    return ReactDOM.createPortal(
        <Modal onClose={ onClose } isVisible={ isVisible }>{children}</Modal>,
        document.body,
    );
};

Modal.defaultProps = {
    isVisible: false,
    onClose: () => {},
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default TermsOfUseModal;
