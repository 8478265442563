import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../utils/commonUtils';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const Footer = styled.footer`
    max-width: 1920px;
    margin: auto;
`;

export const ContentTopContainer = styled.section`
    background-color:${({ theme }) =>  theme.footerContentTopBackgroundColor};
`;

export const ContentBottomContainer = styled.section`
    background-color:${({ theme }) => theme.footerBackgroundColor};
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      padding-bottom: 100px;
  }
`;

export const ContentTop = styled.div`
 padding:45px 0;
 display:flex;
 width:90%;
 max-width: 1500px;
 margin:0 auto;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    flex-direction:column;
  }
`;
export const ContentBottom = styled.div`
  padding:30px 0;
  width:90%;
  max-width: 1500px;
  margin:0 auto;
`;
export const ModelsAndSpecifications = styled.div`
 margin-left:70px;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    margin-left:0;
    margin-bottom:25px;
  }
`;
export const OnlinePurchase = styled.div`
`;
export const NavTitle = styled.h2`
  font-size: ${pxToRem(16.5)};
  color:${({ theme }) => theme.footerTopNavItemColor};
  margin:0 0 35px 0;

`;

export const DisclaimerAndSocialLinks = styled.div`
display:flex;
justify-content:space-between;
@media(max-width:${DEVICE_SIZE.MEDIUM}px){
  flex-direction:column;
  align-items:center;
  text-align:center;
  }
`;

export const DisclaimerAndLinkToChampion = styled.ul`
  display:flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const LinkToChampionMotors = styled.li`
  text-decoration:none;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
  }
`;

export const LinkToWebsite = styled(Link)`
  font-size: ${pxToRem(13.5)};
  color:${({ theme }) => theme.disclaimerTextColor};
  text-decoration:none;
  &:hover{
    text-decoration:underline;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding: 5px;
  }
`;
