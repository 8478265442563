import React from 'react';
import PropTypes from 'prop-types';
import { getCarPlaceholderImageUrl } from '../../../../../utils/assetsUtils';
import STRINGS from '../../../../../language';
import ModelImage from '../../ModelImage/ModelImage';
import { renderNisSymbol } from '../../../../../utils/commonUtils';
import * as Styled from './HeaderMobile.styles';

const HeaderMobile = ({
    imageAlt, imageSrc, brand, title, onSelect, description, fullPrice,
}) => {
    return (
        <Styled.HeaderMobile onClick={ onSelect }>
  
            <ModelImage
                alt={ imageAlt }
                src={ imageSrc }
                fallbackSrc={ getCarPlaceholderImageUrl(brand) }
            />
            <Styled.Information>
                <Styled.Title>{title}</Styled.Title>
                <Styled.Description>{description}</Styled.Description>
                <Styled.FullPriceWrapper>
                    <Styled.PriceTitle>{STRINGS.STARTING_FROM}- </Styled.PriceTitle>
                    <Styled.Price>
                        <Styled.NisSymbol>{renderNisSymbol()}</Styled.NisSymbol>
                        {fullPrice}
                    </Styled.Price>
                </Styled.FullPriceWrapper>
            </Styled.Information>
           
        </Styled.HeaderMobile>
    );
};

HeaderMobile.defaultProps = {
    onSelect: () => {},
};

HeaderMobile.propTypes = {
    imageAlt: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fullPrice: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
};

export default HeaderMobile;
