import { CONFIGURATOR_STEPS } from '../../constants';
import { getAddOnMoreInformationHtml } from '../../fetch/configurator_options';
import * as actionTypes from '../actionTypes';

export const selectChosenCar = (selectedCar) => {
    return {
        type: actionTypes.SELECT_CHOSEN_CAR,
        selectedCar,
    };
};

export const selectCurrentStep = (selectedStep) => {
    return {
        type: actionTypes.SELECT_CURRENT_STEP,
        selectedStep,
    };
};

export const selectCurrentHoop = (selectedHoop) => {
    return {
        type: actionTypes.SELECT_CURRENT_HOOP,
        selectedHoop,
    };
};

export const selectCurrentAddOnGroup = (selectedAddOnGroup) => {
    return {
        type: actionTypes.SELECT_CURRENT_ADD_ON_GROUP,
        selectedAddOnGroup,
    };
};

export const selectCurrentAddOn = (selectedAddOn) => {
    return {
        type: actionTypes.SELECT_CURRENT_ADD_ON,
        selectedAddOn,
    };
};

export const removeCurrentAddOn = (selectedAddOn) => {
    return {
        type: actionTypes.REMOVE_CURRENT_ADD_ON,
        selectedAddOn,
    };
};

export const initializeSelectedCurrentData = (stepNumber = CONFIGURATOR_STEPS.MODEL) => ({
    type: actionTypes.INITIALIZE_SELECTED_CURRENT_DATA,
    stepNumber,
});

export const selectCurrentExternalColor = (color) => {
    return {
        type: actionTypes.SELECT_CURRENT_EXTERNAL_COLOR,
        color,
        
    };
};
export const selectCurrentInternalColor = (color) => {
    return {
        type: actionTypes.SELECT_CURRENT_INTERNAL_COLOR,
        color,
        
    };
};

export const selectIsInSummary = (isInSummary) => ({
    type: actionTypes.SELECT_IS_IN_SUMMARY,
    isInSummary,
});

const selectMoreInfoModalPending = () => ({
    type: actionTypes.SELECT_MORE_INFO_MODAL_PENDING,
});

const selectMoreInfoModalSuccess = (moreInfoHtmlString, addOnLocalCode) => ({
    type: actionTypes.SELECT_MORE_INFO_MODAL_SUCCESS,
    moreInfoHtmlString,
    addOnLocalCode,
});

const selectMoreInfoModalFailure = (error) => ({
    type: actionTypes.SELECT_MORE_INFO_MODAL_FAILURE,
    error,
});

export const selectMoreInfoModalAsync = (brand, addOnLocalCode) => {
    return async (dispatch) => {
        dispatch(selectMoreInfoModalPending());
        try {
            const response = await getAddOnMoreInformationHtml(brand, addOnLocalCode);
            const { data } = response;
           
            dispatch(selectMoreInfoModalSuccess(data, addOnLocalCode));
        } catch (error) {
            dispatch(selectMoreInfoModalFailure(error));
        }
    };
};

export const selectEquipmentPackage = (equipmentPackage) => ({
    type: actionTypes.SELECT_EQUIPMENT_PACKAGE,
    equipmentPackage,
});

export const initializeSelectedEquipmentPackage = () => ({
    type: actionTypes.INITIALIZE_SELECTED_EQUIPMENT_PACKAGE,
});

export const initializeCurrenExternalColor = () => ({
    type: actionTypes.INITIALIZE_CURRENT_EXTERNAL_COLOR,
});

export const initializeAllCurrentSelectedData = () => ({
    type: actionTypes.INITIALIZE_ALL_CURRENT_SELECTED_DATA,
});
