import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Action.styles';

const Action = ({
    fontSize, actionText, buttonText, onAction,
}) => {
    return (
        <Styled.Action>
            <Styled.Text fontSize={ fontSize }>
                {actionText}
                <Styled.Button type="button" onClick={ onAction }>
                    {buttonText}
                </Styled.Button>
            </Styled.Text>
        </Styled.Action>
    );
};

Action.defaultProps = {
    onAction: () => {},
};

Action.propTypes = {
    fontSize: PropTypes.number.isRequired,
    actionText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onAction: PropTypes.func,
};

export default Action;
