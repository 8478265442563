import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './InnerPageContainer.styles';

const InnerPageContainer = ({ children, maxWidth }) => {
    return (
        <Styled.InnerPageContainer $maxWidth={ maxWidth }>
            {children}
        </Styled.InnerPageContainer>
    );
};

InnerPageContainer.defaultProps = {
    maxWidth: 1160,
};

InnerPageContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    maxWidth: PropTypes.number,
};

export default InnerPageContainer;
