import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import { COLORS } from '../../../../../constants';
import ColorsRangeRuler from '../../../../../components/ColorsRangeRuler/ColorsRangeRuler';
import * as Styled from './SafetyRange.styles';

const SafetyRange = ({ safetyEquipmentLevel, isManyModels }) => {
    const colors = Object.values(COLORS.BG_COLORS_SAFETY_LEVEL_EQUIPMENT);
    
    return (
        <Styled.SafetyRange>
            <Styled.Title>{STRINGS.SAFETY_EQUIPMENT_LEVEL}:</Styled.Title>
            <ColorsRangeRuler
                isManyModels={ isManyModels }
                colors={ colors }
                selectedColorNumber={ parseInt(safetyEquipmentLevel) }
                highRangeTitle={ STRINGS.HIGH_SAFETY_LEVEL }
                lowRangeTitle={ STRINGS.LOW_SAFETY_LEVEL }
            />
        </Styled.SafetyRange>
    );
};

SafetyRange.defaultProps = {
    isManyModels: false,
};

SafetyRange.propTypes = {
    safetyEquipmentLevel: PropTypes.array.isRequired,
    isManyModels: PropTypes.bool,
};

export default SafetyRange;
