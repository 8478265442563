import * as actionTypes from '../../actionTypes';
import {
    getExternalColors,
    GetPollutionAndSafetyDetails,
    GetGroupPollutionAndSafetyDetails,
    getModelsAdditionalOptions,
} from '../../../fetch/configurator_options';
import { mapExternalColors, mapSubModels } from '../../dispatchers/utils';

const getConfiguratorOptionsPending = () => ({
    type: actionTypes.GET_CONFIGURATOR_OPTIONS_PENDING,
});

const getConfiguratorOptionsSuccess = (data) => ({
    type: actionTypes.GET_CONFIGURATOR_OPTIONS_SUCCESS,
    data,
});

const getConfiguratorOptionsFailure = (error) => ({
    type: actionTypes.GET_CONFIGURATOR_OPTIONS_FAILURE,
    error,
});

export const getExternalColorsAsync = (
    modelCode,
    styleLevelCode,
    OriginalAdditions,
) => {
    return async (dispatch) => {
        dispatch(getConfiguratorOptionsPending());

        try {
            const response = await getExternalColors(
                modelCode,
                styleLevelCode,
                OriginalAdditions,
            );
            const { data } = response;

            const externalColors = mapExternalColors(data);

            dispatch(getConfiguratorOptionsSuccess(externalColors));

            return externalColors;
        } catch (error) {
            dispatch(getConfiguratorOptionsFailure(error));

            return [];
        }
    };
};

export const setExternalColorsError = (error) => ({
    type: actionTypes.SET_EXTERNAL_COLORS_ERROR,
    error,
});

export const initializeConfiguratorOptions = () => ({
    type: actionTypes.INITIALIZE_CONFIGURATOR_OPTIONS,
});

export const initializeConfiguratorData = () => ({
    type: actionTypes.INITIALIZE_CONFIGURATOR_DATA,
});

const getPollutionAndSafetyDetailsDataPending = () => ({
    type: actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_PENDING,
});

const getPollutionAndSafetyDetailsDataSuccess = (data) => ({
    type: actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_SUCCESS,
    data,
});

const getPollutionAndSafetyDetailsDataFailure = (error) => ({
    type: actionTypes.GET_POLLUTION_AND_SAFETY_DETAILS_DATA_FAILURE,
    error,
});

export const getPollutionAndSafetyDetailsAsync = (
    isShouldGetGroup,
    brand,
    modelCode,
    StyleLevelCode,
    groupModelCode,
) => {
    return async (dispatch) => {
        dispatch(getPollutionAndSafetyDetailsDataPending());
        try {
            let response;
            
            if (isShouldGetGroup) {
                response = await GetGroupPollutionAndSafetyDetails(
                    groupModelCode,
                );
            } else {
                response = await GetPollutionAndSafetyDetails(
                    brand,
                    modelCode,
                    StyleLevelCode,
                );
            }
            
            const { data } = response;

            return dispatch(getPollutionAndSafetyDetailsDataSuccess(data));
        } catch (error) {
            return dispatch(getPollutionAndSafetyDetailsDataFailure(error));
        }
    };
};

const getModelsAdditionalOptionsPending = () => ({
    type: actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_PENDING,
});

const getModelsAdditionalOptionsSuccess = (data) => ({
    type: actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_SUCCESS,
    data,
});

const getModelsAdditionalOptionsFailure = (error) => ({
    type: actionTypes.GET_MODELS_ADDITIONAL_OPTIONS_FAILURE,
    error,
});

export const getModelsAdditionalOptionsAsync = (
    groupModelCode, modelCode, styleLevelCode, selectedCar,
) => async (dispatch) => {
    dispatch(getModelsAdditionalOptionsPending());
    try {
        const {
            data,
        } = await getModelsAdditionalOptions(groupModelCode, modelCode, styleLevelCode);

        const parsedData = data.map((subModel) => mapSubModels(selectedCar, subModel));

        dispatch(getModelsAdditionalOptionsSuccess({ data: parsedData, selectedCar }));

        return parsedData;
    } catch (error) {
        dispatch(getModelsAdditionalOptionsFailure(error));

        return [];
    }
};
