import React from 'react';
import STRINGS from '../../../../../language';
import { ROUTES } from '../../../../../constants';
import {
    isEmail, isPhonenumber, formatPhoneNumber, encodePhoneNumber,
} from '../../../../../utils/commonUtils';

export const AUDI_INPUT_FIELDS =  [
    {
        name: 'fullName',
        type: 'text',
        value: '',
        label: STRINGS.FULL_NAME,
        isRequired: true,
        isValid: false,
        validator: (value) => value.trim().length > 0,
        errorMessage: STRINGS.THIS_IS_REQUIRED_FIELD,
        isErrorShown: false,
        maxlength: null,
    },
    {
        name: 'firstName',
        type: 'text',
        value: '',
        label: STRINGS.FIRST_NAME,
        isRequired: true,
        isValid: false,
        validator: (value) => value.trim().length > 0,
        errorMessage: STRINGS.THIS_IS_REQUIRED_FIELD,
        isErrorShown: false,
        maxlength: null,
    },
    {
        name: 'lastName',
        type: 'text',
        value: '',
        label: STRINGS.LAST_NAME,
        isRequired: true,
        isValid: false,
        validator: (value) => value.trim().length > 0,
        errorMessage: STRINGS.THIS_IS_REQUIRED_FIELD,
        isErrorShown: false,
        maxlength: null,
    },
    {
        name: 'phone',
        type: 'tel',
        value: '',
        validator: (value) => isPhonenumber(value),
        formatter: (value) => formatPhoneNumber(value),
        encoder: (value) => encodePhoneNumber(value),
        label: STRINGS.AUDI_PHONE,
        isRequired: true,
        isValid: false,
        errorMessage: STRINGS.PHONE_NUM_FIELD_MUST_BE_VALID_AND_10_CHARACTERS,
        isErrorShown: false,
        maxlength: 7,
        encodedValue: '',
    },
    {
        name: 'email',
        type: 'email',
        value: '',
        label: STRINGS.AUDI_EMAIL_ADDRESS,
        isRequired: true,
        isValid: false,
        validator: (value) => isEmail(value),
        errorMessage: STRINGS.EMAIL_FIELD_MUST_BE_VALID,
        isErrorShown: false,
        maxlength: null,
    },

];

const privacyPolicyLabel = (
    <span>
        {`${STRINGS.ACCEPT_USE_DETAILS} `}
        <a target="_blank"
            rel="noreferrer"
            href={ ROUTES.PRIVACY_POLICY }
        >
            {STRINGS.FOR_PRIVACY_POLICY}
        </a> {STRINGS.OF_THE_COMPANY}
    </span>
);

const acceptAdvertisingLabel = (
    <span>
        {`${STRINGS.ACCEPT_ADVERTISING} `}
        <a target="_blank"
            rel="noreferrer"
            href={ ROUTES.PRIVACY_POLICY }
        >
            {STRINGS.FOR_PRIVACY_POLICY}
        </a> {STRINGS.OF_CHAMPION_MOTORS}
    </span>
);

export const CHECKBOX_FIELDS =  [
    {
        id: 'privacy_policy',
        name: 'privacy_policy',
        label: privacyPolicyLabel,
        type: 'checkbox',
        isRequired: true,
        isValid: true,
        isChecked: true,
        errorMessage: STRINGS.YOU_MUST_ACCEPT_PRIVACY_POLICY,
        isErrorShown: false,
    },
    {
      
        id: 'accept_advertising',
        name: 'accept_advertising',
        type: 'checkbox',
        label: acceptAdvertisingLabel,
        isRequired: false,
        isValid: true,
        isChecked: false,
        errorMessage: null,
        isErrorShown: false,
    },

];
