import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Header = styled.header`
  padding: 15px;
`;

export const Figure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0;
  padding: 15px;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;
export const Img = styled.img`
  max-width: 100%;
  width: 300px;
`;
export const Figcaption = styled.figcaption`
  max-width: 400px;
  margin-right:15px;
`;
export const Title = styled.h2`
  margin: 0;
  font-size: ${pxToRem(41)};
  font-weight: 700;
  line-height: 45px;
`;
export const SubTitle = styled.p`
  margin: 0;
  font-size: ${pxToRem(26)};
  font-weight: 400;
  line-height: 37px;
`;
