import React from 'react';

import {
    MENUFACTURS_FULL_NAMES,
    MENUFACTURS_WEBSITES,
    MENUFACTURS_CONTACT_INFORMATION,
    COLORS,
    MENUFACTURS,
    DEVELOPMENT_ENV,
    SESSION_AUTHORIZATION_TOKEN,
    TRIM_LEVELS_COMPARISON_LINK,
    MODEL_FURTHER_INFORMATION_LINK,
    LEAD_CUSTOM_CONNECTION_INFO,
    MUTAG_PATH_NAME,
    MENUFACTURS_DISCLAIMER_FULL_NAMES,
} from '../constants';

import { getAddOnIconUrl } from './assetsUtils';

/**
 * // receive a px number, returns rem string => pxToRem(16) => 1rem;
 * @param {number} px
 * @param {number} defaultPx
 * @return {string}
 */
export const pxToRem = (px, defaultPx = 16) => `${px / defaultPx}rem`;

/**
 * receive a breaking point and a css properties (string literal ) 
 * returns a representation of a valid media query as follows:
 * media (max-width: 768px)
    .XkFlN {
        ///
    }
 * @param {number} deviceSize 
 * @param {string} css 
 */
export const setBreakingPoint = (deviceSize, css) => {
    return `@media(max-width:${deviceSize}px){
     ${css}
  }`;
};

/**
 * receive the brand name (one letter) , returns the full manufacturer name (in english or hebrew);
 * @param {string} brandName 
   @param {string} language 
   @return {string}  
 */
export const getFullManufacturer = (brandName, language = 'english') => {
    if (!brandName) {
        return null;
    }
    const langUpperCase = language.toUpperCase();
    const brandUpperCase = brandName.toUpperCase();

    return (
    MENUFACTURS_FULL_NAMES?.[brandUpperCase]?.[langUpperCase]
    ?? MENUFACTURS_FULL_NAMES[MENUFACTURS.CHAMPION][langUpperCase]
    );
};
/**
 * receive the brand name (one letter) , returns the full manufacturer name (in english or hebrew);
 * @param {string} brandName 
   @param {string} language 
   @return {string}  
 */
export const getFullManufacturerForDisclaimer = (brandName, language = 'english') => {
    if (!brandName) {
        return null;
    }
    const langUpperCase = language.toUpperCase();
    const brandUpperCase = brandName.toUpperCase();

    return (
    MENUFACTURS_DISCLAIMER_FULL_NAMES?.[brandUpperCase]?.[langUpperCase]
    ?? MENUFACTURS_DISCLAIMER_FULL_NAMES[MENUFACTURS.CHAMPION][langUpperCase]
    );
};

export const getManufacturerWebsite = (brandName) => {
    if (!brandName) {
        return '/';
    }

    return MENUFACTURS_WEBSITES[brandName] ?? MENUFACTURS_WEBSITES.CHAMPION;
};

/**
 * receives the brand name of the relevant manufacturer
 * returns an object with the following contact information :email, phone
 * @param {brand} string
 * @return {Object}
 */

export const getManufacturerContactInfo = (brandName) => {
    if (!brandName) {
        return MENUFACTURS_CONTACT_INFORMATION[MENUFACTURS.CHAMPION];
    }

    const brandUpperCase = brandName.toUpperCase();

    return (
        MENUFACTURS_CONTACT_INFORMATION[brandUpperCase]
    ?? MENUFACTURS_CONTACT_INFORMATION[MENUFACTURS.CHAMPION]
    );
};

/**
 * receives a key and a ruller type (in order to get the relevant enum)
 * returns the relevant color which was in given key
 * @param {number} key
 * @param {string} rullerType
 * @return {string}
 */

export const getBgColorForRuler = (key, rullerType = 'safety') => {
    let enumName = null;

    switch (rullerType.toLocaleLowerCase()) {
        case 'safety':
            enumName = 'BG_COLORS_SAFETY_LEVEL_EQUIPMENT';
            break;
        case 'pollution':
            enumName = 'BG_COLORS_POLLUTION_LEVEL';
            break;
        default:
            return null;
    }
    if (!COLORS[enumName][key]) {
        return null;
    }

    return COLORS[enumName][key];
};

/**
 * receives an array of objects, and the key to map by
 * for example: [{name:'a',age:32},name:'b',age:33],'a'
   which will resolve to : name[a];
 * returns a new mapped array with unique objects
 * @param {array} arr 
 * @param {string} value 
 */
export const distinctObj = (arr, key) => {
    const hashObject = {};

    arr.forEach((obj) => {
        hashObject[obj[key]] = obj;
    });

    return Object.values(hashObject);
};

/**
 * receives a string. if it contains more then one word, returns these words
   with "-" seperator ; otherwise it will return the same word
 * @param {string} str 
 */
export const parseUrl = (str) => {
    const strArr = str.toLowerCase().split(' ');
    const result = strArr.reduce((acc, item) => {
        acc.push(item);

        return acc;
    }, []);

    return result.join('-');
};

/**
 * takes in an object with key values pairs {age:32}
 * returns a new object with swap places {32:age}
 * @param {Object} obj
 * @return {Object}
 */

export const switchObjValuesAndKeys = (obj) => {
    return Object.entries(obj).reduce((acc, item) => {
        const key = item[0];
        const value = item[1];

        acc[value] = key;

        return acc;
    }, {});
};

/**
 * receives an object
 * returns true if it is empty; false otherwise
 * @param {object} obj
 */

export const isObjectEmpty = (obj) => {
    if (!obj) return true;
    
    return Object.keys(obj)?.length === 0;
};

/**
 * receives an array
 * returns true if it is empty; false otherwise
 * @param {array} arr
 */

export const isArrayEmpty = (arr) => {
    return arr && arr.length === 0;
};

export const renderNisSymbol = () => {
    return '₪';
};

/**
 * validates an email adress
 * returns true if it has the correct email format; return false otherwise
 * @param {string} email
 */

export const isEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
};

/**
 * check whether the input is numeric - it it is - returns true, return false otherwise
 * returns true if it d
 * @param {string} input
 */

export const isNumeric = (input) => {
    return /^\d+$/.test(input);
};

/**
 * gets an email string format
 * returns href in mailto representation - mailto:someone@example.com
 * @param {string} email
 * @returns
 */

export const getEmailHrefString = (email) => {
    return `mailto:${email}`;
};

/**
 * gets an phone string format 
 * gets an phone string format
 * returns href in mailto representation - tel:555-555-5555
 * @param {string} phone
 * @returns
 */

export const getTelHrefString = (phone) => {
    return `tel:${phone}`;
};

/*
 * @param {string} brand
 * @returns base url with relevant param brand as following: /?brand=champion
 */

export const getBaseUrlByBrand = (brand) => {
    const manufacturerName = getFullManufacturer(brand);

    return `/?brand=${manufacturerName.toLocaleLowerCase()}`;
};
/**
 *
 * @param {number} price
 */

export const parsePriceToDiscountObject = (price) => {
    return {
        price,
        discount: 0,
        total: price,
        isDiscount: false,
    };
};

export const consoleLogInDev = (logOutPut) => {
    const isDevEnv = process.env.NODE_ENV === DEVELOPMENT_ENV;
    
    if (isDevEnv) {
        console.log(logOutPut);
    }
};

// /**
//  * https://gist.github.com/freak4pc/6802be89d019bca57756a675d761c5a8
//  * @param {numeric} input 
//  * @returns true if it matches israeli id pattern, false otherwise
//  */

export const isValidIsraeliID = (input) => {
    let id = String(input).trim();

    if (id.length > 9 || id.length < 5 || Number.isNaN(id)) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? (`00000000${id}`).slice(-9) : id;

    return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
                    
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
};

export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }
    const phoneNumberArray = phoneNumber.split('');
    const numbers = phoneNumberArray.filter((char) => isNumeric(char));

    if (numbers.length > 3) {
        numbers.splice(3, 0, '-');
    }

    return numbers.join('');
};

export const isPhonenumber = (input) => {
    const phoneno = /^[0][5][0-9]{1}[-]{0,1}[0-9]{7}$/;

    if (input.match(phoneno)) {
        return true;
    }

    return false;
};

/**
 * 
 * @param {string} phoneNumber - for example : 052-1234567
 * @returns encoded phone number with the following format XXX-XXXX687 
 */

export const encodePhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace('-', '');
    const xxxFormat = 'XXX-XXXX';
    
    if (!isPhonenumber(formattedPhoneNumber)) {
        return xxxFormat;
    }
    const numberArray = formattedPhoneNumber.split('');
    const char7 = numberArray[7];
    const char8 = numberArray[8];
    const char9 = numberArray[9];
  
    return `${xxxFormat}${char7}${char8}${char9}`;
};

/**
 * 
 * @param {string} template 
 * @param {arrray} wordsToReplace 
 * @returns {string} new template without placeholders
 */
export const replacePlaceholdersInTemplate = (template, wordsToReplace) => {
    const newTemplate = wordsToReplace.reduce((acc, word, index) => {
        const placeholder = `%${index + 1}%`;
      
        return acc.replace(placeholder, word);
    }, template);
  
    return newTemplate;
};

/**
 * 
 * @param {string} modelGroup 
 * @returns route which is seautible to the following pattern: configurator/models/:modelGroup/:modelGroupCode
 * 
 */

export const parseMultipleModelsUrl = (modelGroup) => {
    const initialRoute = '/configurator/models';
    const parsedUrlByModelGroup = parseUrl(modelGroup);
    
    return `${initialRoute}/${parsedUrlByModelGroup}`;
};

/**
 * 
 * @param {string} brand
 * @param {string} modelCode
 * @param {number} trimLevel
 * @param {number} modelGroupCode
 * @returns route which is seautible to the following pattern: configurator/equipment-packages?brand=&modelCode=&trimLevelCode=&modelGroupCode=
 * 
 */

export const parseEquipmentPackagesUrl = (modelCode, trimLevel, modelGroupCode) => {
    const initialRoute = '/configurator/equipment-packages';
    // const parsedUrlByModelGroup = parseUrl(modelGroup);
    
    return `${initialRoute}?modelCode=${modelCode}&trimLevelCode=${trimLevel}&modelGroupCode=${modelGroupCode}`;
};

/*
 * @param {array} arr 
 * @returns true if it is array and it is not empty ; false otherwise
 */
export const isArrayAndNotEmpty = (arr) =>  Array.isArray(arr) && !isArrayEmpty(arr);

/* @param {string} brand 
 * @returns https://personal.championmotors.co.il?brand=brand
 */

export const getCustomerSiteUrlByBrand = (brand) => {
    const manufacturerName = getFullManufacturer(brand);
    const personalSiteUrl = 'https://personal.championmotors.co.il';

    if (!manufacturerName || manufacturerName === 'Champion') {
        return personalSiteUrl;
    }
    
    return `${personalSiteUrl}/?brand=${manufacturerName}`;
};

export const navigateProgrammatically = (path) => {
    window.open(path, '_blank').focus();
};

export const getSessionStorageItem = (itemName) => {
    return sessionStorage.getItem(itemName);
};
export const getSessionStorageAuthToken = () => {
    return getSessionStorageItem(SESSION_AUTHORIZATION_TOKEN);
};

/**
 * 
 * @param {string} brand 
   @param {number} modelGroupCode 
 * @returns camparison link
 */

export const getComparisonLinkByBrandOrModelGroupCode = (brand, modelGroupCode) => {
    const linkByBrand = TRIM_LEVELS_COMPARISON_LINK[brand.toUpperCase()];

    if (!brand || !linkByBrand || !isNumeric(modelGroupCode)) {
        return null;
    }
    if (typeof  linkByBrand === 'string') {
        return linkByBrand;
    } if (typeof linkByBrand[modelGroupCode] === 'string') {
        return linkByBrand[modelGroupCode];
    }
    
    return null;
};

/**
 * 
 * @returns {string} brand(mutag) according to the current env variable , on which the app was rendered upon
 */

export const getCurrentBrandFromEnv = () => {
    const { REACT_APP_BRAND, REACT_APP_SUB_BRAND } = process.env;
 
    return REACT_APP_SUB_BRAND ?? REACT_APP_BRAND;
};

export const swapArrayElements = (arr, indexA, indexB) => {
    const temp = arr[indexA];

    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
};
/**
 * 
 * @param {string} path 
 * @returns readble - example: path path/to/something/
 */

export const parseWindowsPathToGlobalPath = (path) => {
    return path.replace(/\\/g, '/');
};

/**
 * 
 * @param {string} brand 
 * @param {string} modelGroupCode 
 * @returns {string} 
 */

export const getModelFurtherInfoLink = (brand, modelGroupCode) => {
    const link = MODEL_FURTHER_INFORMATION_LINK[brand?.toUpperCase()]?.[[modelGroupCode]];

    if (!brand || !isNumeric(modelGroupCode) || !link) {
        return null;
    }
    
    return link;
};

export const getLeadCustomConnectionInfoByBrand = (brand) => {
    if (!brand || !LEAD_CUSTOM_CONNECTION_INFO[brand]) {
        return {
            campaignId: '',
            sourceId1: '0',
            sourceId2: '0',
        };
    }
    
    return LEAD_CUSTOM_CONNECTION_INFO[brand];
};

export const toILS = (priceInAgorot) => {
    return parseFloat(priceInAgorot) / 100;
};

/**
 * Takes all the selectedCurrentData fields and return a link for share.
/* @param {object} selectedEquipmentPackage 
/* @param {string} externalColor 
/* @param {string} internalColor 
/* @param {string} hoops 
/* @param {object} addOns 
 */
export const getShareUrl = (
    selectedEquipmentPackage,
    externalColor,
    internalColor,
    hoops,
    addOns,
    currentBrand,
) => {
    const selectedAddons = (Object.values(addOns));

    let baseUrl;

    if (currentBrand === MENUFACTURS.CUPRA) {
        const arrayWithSplittedUrl = window.location.href.split('?');

        baseUrl = `${arrayWithSplittedUrl[0]}/equipment-packages?${arrayWithSplittedUrl[1]}&externalColor=${externalColor}&internalColor=${internalColor}`;
    } else {
        baseUrl = `${window.location.href}&externalColor=${externalColor}&internalColor=${internalColor}`;
    }

    if (Object.keys(selectedEquipmentPackage).length !== 0) {
        // To Do: Check a vehicle with an equipmentPackage to use the code of the package as a param.

        const equipmentPackageCode = selectedEquipmentPackage?.id.replace('#', '');

        baseUrl += `&equipmentPackage=${equipmentPackageCode}`;
    }

    if (currentBrand === MENUFACTURS.CUPRA && Object.keys(selectedEquipmentPackage).length === 0) {
        baseUrl += '&equipmentPackage=default';
    }

    let paramsOfSelectedOptions = '';

    if (hoops) {
        const hoopCode = hoops.replace('#', '');

        paramsOfSelectedOptions = `&hoops=${hoopCode}`;
    } else {
        paramsOfSelectedOptions = '&hoops=default';
    }

    if (selectedAddons.length > 0) {
        const addonCode = selectedAddons.reduce((allAddonLocalCodes, addon) => {
            return allAddonLocalCodes + addon.local;
        }, '&addOns=');

        paramsOfSelectedOptions += addonCode;
    } else {
        const addonCode = '&addOns=default';

        paramsOfSelectedOptions += addonCode;
    }
    
    return baseUrl + paramsOfSelectedOptions;
};

export const parseAddonsFromShareUrl = (addonsCodesFromUrl) => {
    return addonsCodesFromUrl?.match(/.{3}/g);
};

export function addAddonsToState(
    addonCodesFromUrl,
    addOns,
    hoopCode,
    selectAddOnHandler,
    selectCurrentStep,
) {
    const arrayOfAddonsFromUrl = parseAddonsFromShareUrl(addonCodesFromUrl);

    if (arrayOfAddonsFromUrl) {
        arrayOfAddonsFromUrl.map((addon) => {
            return addOns[hoopCode]?.forEach((group) => {
                const matchingAddon = group.listLocalPackages?.find((singleAddon) => {
                    return singleAddon.local === addon;
                });

                if (matchingAddon) {
                    const {
                        description: title,
                        groupDescription,
                        price,
                        local,
                        priceAfterDiscounts,
                        isDiscount,
                        familyCode,
                        groupCode,
                        brand,
                    } = matchingAddon;

                    const addonForState = {
                        title,
                        groupDescription,
                        groupCode: parseInt(groupCode),
                        price: parseInt(price),
                        image: getAddOnIconUrl(brand, familyCode),
                        local,
                        priceAfterDiscount: parseInt(priceAfterDiscounts),
                        isDiscount,
                    };

                    selectAddOnHandler(addonForState);

                    selectCurrentStep();
                }
            });
        });
    }
}

export const removeParamsFromUrl = (urlParams, history) => {
    urlParams.delete('addOns');
    urlParams.delete('hoops');
    urlParams.delete('internalColor');
    urlParams.delete('externalColor');
    urlParams.delete('equipmentPackage');
    history.replace({
        search: urlParams.toString(),
    });
};

export const getMutagNameByBrand = (brand) => {
    switch (brand) {
        case MENUFACTURS.SEAT:
            return MUTAG_PATH_NAME.SEAT;
        case MENUFACTURS.SKODA:
            return MUTAG_PATH_NAME.SKODA;
        case MENUFACTURS.VOLKSWAGEN:
            return MUTAG_PATH_NAME.VOLKSWAGEN;
        case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
            return MUTAG_PATH_NAME.VOLKSWAGEN_COMMERCIAL;
        case MENUFACTURS.CUPRA:
            return MUTAG_PATH_NAME.CUPRA;
        case MENUFACTURS.AUDI:
            return MUTAG_PATH_NAME.AUDI;
        default:
            return MUTAG_PATH_NAME.CHAMPION;
    }
};

export const spreadAllManufacturingDates = (ManufacturingDates) => {
    if (ManufacturingDates !== null && ManufacturingDates) {
        return ManufacturingDates.map((date) => {
            return (
                <div style={ { display: 'flex' } }>
                    <p style={ { direction: 'ltr' } }>{date}</p>
                </div>
            );
        });
    }

    return null;
};

export const renderUniqueSafetyAndPollutionTableRows = (
    displayedModelsArray,
    modelToDisplay,
) => {
    const parsedModel = JSON.stringify(modelToDisplay);
   
    return displayedModelsArray?.find((currentModel) => {
        const currentModelInJSON = JSON.stringify(currentModel);

        return parsedModel === currentModelInJSON;
    });
};

export const isserverUnderMaintenance = (startTime, endTime) => {
    if (!startTime || !endTime) {
        return false;
    }
    const now = Date.now();

    return now >= startTime && now <= endTime;
};
// Initialize an object to store original additions and their links
export const getOriginalAdditionsDetailsLinks = (data) => {
    const result = {};
    
    // Process each object in the array
    data.forEach((item) => {
        Object.entries(item.originalAdditionDescriptions).forEach(([key]) => {
            if (item.links.originalAdditions[key]) {
                result[key] = item.links.originalAdditions[key];
            }
        });
    });

    return result;
};
