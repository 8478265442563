import React from 'react';
import PropTypes from 'prop-types';
import FullPriceAndTooltip from '../../../Model/ModelItemCollapsible/FullPriceAndTooltip';
import LicenseFeePrice from '../../../Model/ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice';
import STRINGS from '../../../../../language';
import SelectButton from '../SelectButton/SelectButton';
import Styled from './AdditionalOptionHeaderMobile.styles';

const AdditionalOptionHeaderMobile = ({
    hrefToConfigurator,
    price,
    licenseFeePrice,
    fullPrice,
    arrivalDate,
    onSelect,
}) =>  {
    const isInStock = arrivalDate === STRINGS.IN_STOCK;

    return (
        <Styled.Container>
            <Styled.PricesWrapper>
                <FullPriceAndTooltip
                    isShowInMobile
                    hideTooltip={ false }
                    fullPrice={ fullPrice.toLocaleString() }
                    isShowFullPriceDescription={ false }
                />
                <LicenseFeePrice
                    price={ price.toLocaleString() }
                    licenseFee={ licenseFeePrice.toLocaleString() }
                    showDetailedPrice={ false }
                />
            </Styled.PricesWrapper>
            <Styled.ArrivalDateContainer>
                <Styled.ArrivalDate
                    $isBold={ isInStock }
                >
                    { arrivalDate }
                </Styled.ArrivalDate>
            </Styled.ArrivalDateContainer>
            <SelectButton
                to={ hrefToConfigurator }
                onClick={ onSelect }
            >
                {STRINGS.PICK}
            </SelectButton>
        </Styled.Container>
    );
};

AdditionalOptionHeaderMobile.propTypes = {
    hrefToConfigurator: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    licenseFeePrice: PropTypes.string.isRequired,
    fullPrice: PropTypes.string.isRequired,
    arrivalDate: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default AdditionalOptionHeaderMobile;
