import styled from 'styled-components';
import { COLORS } from '../../../../../../constants';
import { pxToRem } from '../../../../../../utils/commonUtils';

export const Action = styled.div`
  margin-bottom: 40px;
 `;
export const Text = styled.span`
  font-weight: 400;
  font-size: ${({ fontSize }) => pxToRem(fontSize)};
`;
export const Button = styled.button`
  font-size: inherit;
  font-weight: inherit;
  color: ${COLORS.COLOR_BUTTON_RESEND_SMS};
  font-family: ${({ theme }) => theme['font-family']};
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
`;
