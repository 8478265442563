import styled from 'styled-components';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const CarInfo = styled.div`
  padding:10px  0 40px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    background-color: ${COLORS.BG_COLOR_INNER_PAGE_MOBILE}
  }

  p{
    font-size:16px;
    margin:0 0 10px 0;
  }
  a{
    color: #1979c3;
    text-decoration: none;
    font-weight:700;
  }

`;
export const Body = styled.div`
  width: 100%;
`;
