import { isObjectEmpty } from '../../utils/commonUtils';

export const selectChosenCar = (state, action) => {
    const { selectedCar } = action;
    const { isSelected } = selectedCar;
    
    return {
        ...state,
        selectedCar: {
            ...state.selectedCar,
            ...selectedCar,
            isSelected: !isSelected,
        },
        
    };
};

export const selectCurrentExternalColor = (state, action) => {
    const { color } = action;

    return {
        ...state,
        selectedExternalColor: color,
        
    };
};

export const selectCurrentInternalColor = (state, action) => {
    const { color } = action;
    
    return {
        ...state,
        selectedInternalColor: color,
        
    };
};

export const selectCurrentStep = (state, action) => {
    const { selectedStep } = action;

    return {
        ...state,
        selectedStep,
    };
};

export const selectCurrentHoop = (state, action) => {
    const { selectedHoop } = action;
    const updatedGtmData = [...state.gtmData];

    updatedGtmData.push(selectedHoop);
    
    return {
        ...state,
        selectedHoop,
        gtmData: updatedGtmData,
    };
};

export const selectCurrentAddOnGroup = (state, action) => {
    const { selectedAddOnGroup } = action;
    
    return {
        ...state,
        selectedAddOnGroup,
    };
};

export const selectCurrentAddOn = (state, action) => {
    const { selectedAddOn } = action;
    
    if (isObjectEmpty(selectedAddOn)) {
        return {
            ...state,
            selectedAddOns: {},
            gtmData: [],
        };
    }
    const updatedGtmData = [...state.gtmData];

    updatedGtmData.push(selectedAddOn);
    const updatedSelectedAddOns = { ...state.selectedAddOns };
    const selectedAddOnGroupCode = state.selectedAddOnGroup?.groupCode || selectedAddOn.groupCode;

    if (updatedSelectedAddOns[selectedAddOnGroupCode]
        && updatedSelectedAddOns[selectedAddOnGroupCode].title === selectedAddOn.title) {
        delete updatedSelectedAddOns[selectedAddOnGroupCode];
    } else {
        updatedSelectedAddOns[selectedAddOnGroupCode] = selectedAddOn;
    }

    return {
        ...state,
        selectedAddOns: updatedSelectedAddOns,
        gtmData: updatedGtmData,
    };
};

export const deleteSelectedCurrentAddOn = (state, action) => {
    const { selectedAddOn } = action;

    if (isObjectEmpty(selectedAddOn)) {
        return {
            ...state,
            selectedAddOns: {},
            gtmData: [],
        };
    }

    const selectedAddOnsCopy = { ...state.selectedAddOns };

    const updatedSelectedAddOns = Object.entries(selectedAddOnsCopy)
        .filter(([, value]) => {
            return value.title !== selectedAddOn.name;
        });

    const newSelectedAddOns = Object.fromEntries(updatedSelectedAddOns);

    return {
        ...state,
        selectedAddOns: newSelectedAddOns,
    };
};

export const selectMoreInfoModalPending = (state) => ({
    ...state,
    selectedAddOnsInformation: {
        ...state.selectedAddOnsInformation,
        isLoading: true,
        error: null,
    },
    
});

export const selectMoreInfoModalSuccess = (state, action) => {
    const { moreInfoHtmlString, addOnLocalCode } = action;
    
    return {
        ...state,
        selectedAddOnsInformation: {
            ...state.selectedAddOnsInformation,
            isLoading: false,
            [addOnLocalCode]: moreInfoHtmlString,
        },
    };
};

export const selectMoreInfoModalFailure = (state, action) => {
    const { error } = action;
    
    return {
        ...state,
        selectedAddOnsInformation: {
            ...state.selectedAddOnsInformation,
            isLoading: false,
            error,
        },
    };
};

export const selectEquipmentPackage = (state, action) => {
    const { equipmentPackage } = action;
    const updatedGtmData = [...state.gtmData];

    updatedGtmData.push(equipmentPackage);
    
    return {
        ...state,
        selectedEquipmentPackage: equipmentPackage,
        gtmData: updatedGtmData,
    };
};

export const initializeSelectedCurrentData = (initState, action) => {
    const { stepNumber } = action;
    
    return {
        ...initState,
        selectedStep: stepNumber,
    };
};

export const initializeSelectedEquipmentPackage = (state) => {
    return {
        ...state,
        selectedEquipmentPackage: {},
    };
};

export const initializeCurrenExternalColor = (state) => {
    return {
        ...state,
        selectedExternalColor: {},
    };
};

export const SelectIsInSummary = (state, action) => {
    const { isInSummary } = action;

    return {
        ...state,
        isInSummary,
    };
};

export const initializeCurrentSelectedData = (state) => {
    return {
        ...state,
        selectedCar: {},
        selectedExternalColor: {},
        selectedInternalColor: {},
        selectedHoop: {},
        selectedAddOnGroup: null,
        selectedAddOns: {},
        selectedEquipmentPackage: {},
        selectedAddOnsInformation: {
            isLoading: false,
            error: null,
        },
        isInSummary: false,
        gtmData: [],
    };
};
