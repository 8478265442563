import styled from 'styled-components';
import {
    Specifications,
    Title as SpecTitle,
    List as SpecList,
} from '../Specifications/Specifications.styles';

export const AddOns = styled(Specifications)``;
export const Title = styled(SpecTitle)``;
export const List = styled(SpecList)``;
