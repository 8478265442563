import styled from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../config';
import { staggerParentConfig } from '../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const ColorPicker = styled(motion.div).attrs({
    ...staggerParentConfig,
})`
  display: flex;
  flex-flow: row;
  /* max-width:225px; */
  flex-wrap:wrap;
  animation: reveal 300ms ease-out forwards;

  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    max-width:100%;
    flex-wrap:nowrap;
    overflow: auto;
  }

  .PickerItem {
    margin: ${({ $isAudi }) => $isAudi ? '3px' : '10px'};
    height:${({ theme }) => theme.steps.color.pickerItemHeight};
    width:${({ theme }) => theme.steps.color.pickerItemWidth};
    min-height:${({ theme }) => theme.steps.color.pickerItemHeight};
    min-width:${({ theme }) => theme.steps.color.pickerItemWidth};
  }

  .PickerItem-inner{
    width:100%;
    height:100%;
  }

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
