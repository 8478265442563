/* eslint-disable import/no-mutable-exports */
import CONFIG from '../../../config';
import { MENUFACTURS } from '../../../constants';

let Configuration;

switch (CONFIG.brand) {
    case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
        // eslint-disable-next-line react/react-in-jsx-scope
        Configuration = require('./IconAndSubtitleMock').default;
        break;
    default:
        Configuration = require('./IconAndSubTitle').default;
}

export default Configuration;
