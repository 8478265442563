import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { Body, PaymentDetails } from '../../../PaymentDetails/PaymentDetails.styles';

const { DEVICE_SIZE } = CONFIG;

export const CheckoutSideBar = styled.aside`
  flex: 0 1 560px;
  padding-right: 25px;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: auto;
    body[data-page-name="audi-checkout"] & {
      display: none;
    }
    ${PaymentDetails} {
      padding-top: 25px;
    }
    ${Body} {
      padding-bottom: 0;
    }
  }
`;

export const ImageWrapper = styled.div`
  max-width: 400px;
  margin: auto;
  img {
    height: 100%;
    object-fit: cover;
  }
`;

export const InnerContent = styled.div`
  margin-right: auto;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    background-color: ${COLORS.BG_COLOR_CHECKOUT_SIDER_BAR_MOBILE};
    border: 1px solid ${COLORS.BORDER_COLOR_CHECKOUT_SIDER_BAR};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 150;
    max-width: 100%;
    max-height: calc(100% - 90px);
    overflow: scroll;
    transform: ${({ $isToggled }) => {
        const yPercentage = !$isToggled ? '-100%' : '0%';

        return `translate3d(0, calc(${yPercentage} + 60px), 0)`;
    }};
    transition: transform 0.35s ease-in-out;
    img.CarImage {
      display: none;
    }
  }
`;
