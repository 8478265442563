import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { pxToRem } from '../../../utils/commonUtils';
import { COLORS } from '../../../constants';

const Container = styled.li`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  ${({ $icon }) => $icon && css`
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 0 0 10px;
      background-image: url(${$icon});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  `}
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${COLORS.COLOR_HEADER_NAV_LINK};
  font-size: ${pxToRem(24)};
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;
  &:hover {
    color: ${COLORS.COLOR_HEADER_NAV_LINK_HOVER};
  }
`;

const Style = {
    Container,
    Link,
};

export default Style;
