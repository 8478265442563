import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../../../utils/commonUtils';

export const SpecificationsItem = styled.li`
 margin-bottom:10px;
`;
export const SpecificationLink = styled.p`
text-decoration:none;
font-weight:500;
font-size:${pxToRem(16)};
cursor: pointer;
color:${({ theme }) => theme.configuratorFooterLinksColor};
&:hover{
    text-decoration:underline;
}
`;
