import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLORS } from '../../../../constants';
import CONFIG from '../../../../config';
import { configurationLeftScrollBar } from '../../../common/styles';
import { stepTitleAnimation } from '../../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

const ConfigurationalRight = styled.aside`
  flex: 1;
  max-width: 40%;
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: auto;
  transition: max-height .3s ease-out;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100%;
    max-width: 100%;
    background: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_FOOTER_MOBILE};
    position: fixed;
    z-index: 99;
    bottom: 0;
    background: #fff;
    position: relative;
  }
`;

const Header = styled.header`
  padding: 50px 30px 12px 0;
  background-color: ${COLORS.BG_COLOR_CONFIGURATION_LEFT_HEADER};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

const Title = styled(motion.h2).attrs({
    ...stepTitleAnimation,
})`
  text-align: right;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
`;

const Body = styled.div`
  ${configurationLeftScrollBar}
  position: relative;
  height:${({ theme }) => theme.configurator.desktop.height};
  padding: 20px;
  overflow-y: auto;
  width: 97%;
  margin: auto;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: ${({ theme }) => theme.configurator.mobile.height};
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    position: sticky;
  }
`;

const Styled = {
    ConfigurationalRight,
    Header,
    Title,
    Body,
    ButtonWrapper,
};

export default Styled;
