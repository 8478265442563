import styled, { css } from 'styled-components';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as Close } from '../../../assets/icons/close-thin.svg';
import {
    nisCurrencySign,
    lineThrough,
    configurationLeftScrollBar,
} from '../../common/styles';

export const PaymentDetails = styled.div``;

export const Header = styled.header`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0 10px;
  gap: 8px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

export const InfoIcon = styled(Info)``;

export const CloseButton = styled(Close).attrs({
    role: 'button',
})`
  width: 26px;
  height: 26px;
  display: block;
  margin: 10px;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
`;

export const Content = styled.div`
  ${configurationLeftScrollBar}
  height: 90%;
  overflow-y: auto;
  ${({ $isInSummary }) => $isInSummary && css`
    height: 100%;
    overflow-y: visible;
  `}
`;

export const Body = styled.div`
  padding: 20px;
  ${({ $isInSummary }) => $isInSummary && css`
    height: auto;
  `};
`;

export const TotalSum = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

export const TotalString = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalPrice = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  ${nisCurrencySign}
`;

export const TotalPriceBeforeDiscount = styled(TotalPrice)`
  margin:0 0 0 10px;
  font-size: 16px;
  position: relative;
  ${lineThrough}
`;
