import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentBrand } from '../../../../hooks';
import { getFullManufacturer } from '../../../../utils/commonUtils';
import SpecificationsItem from './SpecificationsItem/SpecificationsItem';
import * as Styled from './Specifications.styles';

const Specifications = ({
    cars,
    modelGroupDescription,
    onClick,
}) => {
    const currentBrand = useCurrentBrand();
    const manufacturerName = getFullManufacturer(currentBrand);

    const displayedModelsArray = [];

    const renderSpecifications = () => {
        return cars.map((car) => {
            const {
                modelGroupCode,
                modelCode,
                modelDescription,
                id,
                originalAdditionsCode,
                carVersion,
                trimLevelCode,
            } = car;

            if (!displayedModelsArray?.includes(modelCode)) {
                displayedModelsArray.push(modelCode);

                return (
                    <SpecificationsItem
                        modelDescription={ modelDescription }
                        onClick={
                            () => onClick(
                                modelGroupCode,
                                modelCode,
                                modelDescription,
                                originalAdditionsCode,
                                carVersion,
                                trimLevelCode,
                                id,
                            )
                        }
                        key={ id }
                    />
                );
            }
            
            return null;
        });
    };

    return (
        <Styled.Specifications>
            <Styled.Title>{`${manufacturerName} ${modelGroupDescription}`}</Styled.Title>
            <Styled.List>
                {renderSpecifications()}
            </Styled.List>
        </Styled.Specifications>
    );
};

Specifications.propTypes = {
    cars: PropTypes.array.isRequired,
    modelGroupDescription: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Specifications;
