/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
    getCurrentBrandFromEnv,
    replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import { getHomePageThumbnail } from '../../../../../utils/assetsUtils';
import modelCarShape from '../../../../Steps/Model/modelItemShape';
import STRINGS from '../../../../../language';
import Header from '../../../../Steps/Model/ModelItemCollapsible/HeaderDesktop/HeaderDesktop';
import FullPriceAndTooltip from '../../../../Steps/Model/ModelItemCollapsible/FullPriceAndTooltip';
import MainCharacteristicsAndSpecification from '../../../../Steps/Model/ModelItemCollapsible/MainCharacteristicsAndSpecification/MainCharacteristicsAndSpecification';
import LicenseFeePrice from '../../../../Steps/Model/ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice';
import CarRank from '../../../../Steps/Model/ModelItemCollapsible/CarRank/CarRank';
import * as Styled from './ModelItem.style';

const ModelItem = ({
    model,
}) => {
    const {
        price: carPrice,
        id,
        licenseFee,
        mainCharacteristics,
        originalAdditionsCode,
        modelDescription,
        modelCode,
        safetyEquipmentLevel,
        pollutionLevel,
        modelGroupCode,
        trimLevelCode,
        hrefToConfigurator,
        carVersion,
    } = model;

    const price = parseFloat(carPrice).toLocaleString();
    const licenseFeePrice = parseFloat(licenseFee).toLocaleString();
    const fullPrice = parseFloat(licenseFee) + parseFloat(carPrice);
    const figCaption = modelDescription;
    const brand = getCurrentBrandFromEnv();
    const imageSrc = getHomePageThumbnail(brand, modelGroupCode, trimLevelCode);
    const imageAlt = replacePlaceholdersInTemplate(
        STRINGS.PREVIEW_OF_MODEL_DESCRIPTION,
        [modelDescription],
    );
    const hideTooltip = true;
    
    return (
        <Styled.ModelItem id={ id }>
            <Styled.Car>
                <Header
                    imageAlt={ imageAlt }
                    imageSrc={ imageSrc }
                    brand={ brand }
                    figCaption={ figCaption }
                    href={ hrefToConfigurator }
                />
                <Styled.Wrapper>
                    <Styled.Body>
                        {/* <Styled.Description>{description}</Styled.Description> */}
                        <FullPriceAndTooltip
                            hideTooltip={ hideTooltip }
                            fullPrice={ fullPrice.toLocaleString() }
                        />
                        {/* <Styled.ArrivelDate>{ arrivalDate }</Styled.ArrivelDate> */}
                        <MainCharacteristicsAndSpecification
                            mainCharacteristics={ mainCharacteristics }
                            groupModelCode={ modelGroupCode }
                            modelCode={ modelCode }
                            modelDescription={ modelDescription }
                            version={ carVersion }
                            id={ id }
                            trimLevelCode={ trimLevelCode }
                            originalAdditionsCode={ originalAdditionsCode }
                        />
                    </Styled.Body>
                    <Styled.Footer>
                        <Styled.SelectButton to={ hrefToConfigurator }>
                            {STRINGS.BUILD_THE_MODEL}
                        </Styled.SelectButton>
                        {/* <Styled.FullPriceDescriptionMobileOnly>
                        {STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                    </Styled.FullPriceDescriptionMobileOnly> */}
                        <LicenseFeePrice price={ price } licenseFee={ licenseFeePrice } />
                        <CarRank
                            safety={ safetyEquipmentLevel }
                            pollution={ pollutionLevel }
                        />
                    </Styled.Footer>
                </Styled.Wrapper>
            </Styled.Car>
        </Styled.ModelItem>
    );
};

ModelItem.propTypes = {
    model: PropTypes.shape(modelCarShape).isRequired,
};

export { ModelItem as AudiModelItem };
