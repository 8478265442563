import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import {
    getFullManufacturerForDisclaimer,
    getManufacturerWebsite,
    replacePlaceholdersInTemplate,
} from '../../../utils/commonUtils';
import * as Styled from './Disclaimer.styles';

const Disclaimer = ({ currentBrand }) => {
    const renderDisclaimerAccordingToBrand = (brandName) => {
        const manufacturerInEnglish = getFullManufacturerForDisclaimer(brandName);
        const manufacturerInHebrew = getFullManufacturerForDisclaimer(currentBrand, 'hebrew');
        const toBrandWebsite = replacePlaceholdersInTemplate(
            STRINGS.TO_BRAND_WEBSITE, [manufacturerInHebrew],
        );
        const linkToManufacturerWebsite = (
            <Styled.LinkToWebsite
                target="_blank"
                key="linkToManufacturerWebsite"
                to={ getManufacturerWebsite(brandName) }
            >
                
                {toBrandWebsite}
            </Styled.LinkToWebsite>
        );
        // /:/g
        const disclaimerWithBrand = STRINGS.DISCLAIMER
            .replace(/<<brandName>>/g, manufacturerInEnglish);
        const stringWithJsx = disclaimerWithBrand.split('<<website>>');
        
        stringWithJsx.splice(1, 0, linkToManufacturerWebsite);
        
        return stringWithJsx;
    };
    
    return (
        <Styled.Disclaimer>
            <Styled.DisclaimerText>
                {renderDisclaimerAccordingToBrand(currentBrand)}
            </Styled.DisclaimerText>
            <Styled.DisclaimerText>
                {STRINGS.FOR_ILLUSTRATIVE_PURPOSES}
            </Styled.DisclaimerText>
        </Styled.Disclaimer>
    );
};

Disclaimer.defaultProps = {
    currentBrand: '',
};

Disclaimer.propTypes = {
    currentBrand: PropTypes.string,
};

export default Disclaimer;
