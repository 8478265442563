import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import { ROUTES } from '../constants';
import STRINGS from '../language';
import { isIE } from '../utils';
import AppLayout from '../components/AppLayout/AppLayout';
import { useCurrentAppCommonData } from '../hooks';
import { isserverUnderMaintenance } from '../utils/commonUtils';
import Main from './Main';
import { Maintenance } from './Maintenance';
import Configurator from './Configurator/Configurator';
import FailureScreenBody from './FailureScreen/FailureScreenBody';
import OrderAndCancelTerms from './InnerPages/OrderAndCancelTerms/OrderAndCancelTerms';
import Terms from './InnerPages/Terms/Terms';
import Faq from './InnerPages/Faq/Faq';
import PrivacyPolicy from './InnerPages/PrivacyPolicy/PrivacyPolicy';
import CarInfo from './InnerPages/CarInfo/CarInfo';
import ContactUs from './InnerPages/ContactUs/ContactUs';
import Checkout from './Checkout';
import AudiCheckout from './AudiCheckout/AudiCheckout';
import Specifications from './InnerPages/Specifications/Specifications';
import PageNotFound from './PageNotFound/PageNotFound';

const { REACT_APP_MAINTENANCE_START, REACT_APP_MAINTENANCE_END } = process.env;

const Router = () => {
    const { isAudi } = useCurrentAppCommonData();
    const isUnderMaintenance = isserverUnderMaintenance(
        REACT_APP_MAINTENANCE_START, REACT_APP_MAINTENANCE_END);

    if (isIE()) {
        return <FailureScreenBody errorMessage={ STRINGS.ERRMSG_UNSUPPORTED_BROWSER } />;
    }
    const pathName = window.location.pathname;

    const CheckoutComponent = isAudi ? AudiCheckout : Checkout;

    const renderProceedOrCarOnline = () => {
        if (pathName === ROUTES.PAYMENT) {
            return <Route exact path={ ROUTES.PAYMENT } element={ <Main /> } />;
        }
        
        return (
            <Route element={ <AppLayout /> }>
                <Route exact path={ ROUTES.HOME } element={ <Configurator /> } />
                <Route exact path={ ROUTES.CONFIGURATOR } element={ <Configurator /> } />
                <Route
                    path={ ROUTES.OLD_CONFIGURATOR_WITH_EQUIPMENT_PACKAGES }
                    element={ <Navigate to={ ROUTES.HOME } replace /> }
                />
                <Route
                    path={ ROUTES.MODELS }
                    element={ <Navigate to={ ROUTES.HOME } replace /> }
                />
                <Route
                    path={ ROUTES.OLD_CONFIGURATOR_WITH_MODELS }
                    element={ <Navigate to={ ROUTES.CONFIGURATOR_WITH_MODELS } replace /> }
                />
                <Route exact
                    path={ ROUTES.CONFIGURATOR_WITH_MODELS }
                    element={ <Configurator /> }
                />

                {/* Inner Pages */}
                <Route exact
                    path={ ROUTES.ORDER_AND_CANCEL_TERMS }
                    element={ <OrderAndCancelTerms /> }
                />
                <Route exact path={ ROUTES.TAKANON } element={ <Terms /> } />
                <Route path={ ROUTES.FAQ } element={ <Faq /> } />
                <Route path={ ROUTES.PRIVACY_POLICY } element={ <PrivacyPolicy /> } />
                <Route path={ ROUTES.SPECIFICATIONS } element={ <Specifications /> } />
                <Route path={ ROUTES.CAR_INFO } element={ <CarInfo /> } />
                <Route path={ ROUTES.CONTACT } element={ <ContactUs /> } />
                <Route path={ ROUTES.CHECKOUT } element={ <CheckoutComponent /> } />
                {/* 404 - PAGE NOT FOUND */}
                <Route path="*" element={ <PageNotFound /> } />
            </Route>
        );
    };

    return isUnderMaintenance ? (
        <Maintenance />
    ) : (
        <BrowserRouter>
            <Routes>
                {renderProceedOrCarOnline()}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
