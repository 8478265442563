import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGoToRoute } from '../../../../../hooks/utils';
import STRINGS from '../../../../../language';
import { ROUTES } from '../../../../../constants';
import { useDataForPaymentDetails } from '../../../../PaymentDetails/PaymentDetails.hooks';
import {
    REVEAL_UNIQUE_ONLINE_PRICE, SPEAK_WITH_REPRESENTATIVE,
} from '../../../../Steps/Summary/ModalForms/ModalForm/ModalForm.fields';
import CheckBoxFormGroup from '../../../../CheckBoxFormGroup/CheckBoxFormGroup';
import ContinueButton from '../../../ConfiguratorLayout/ContinueButton/ContinueButton';
import * as Styled from './OpenModalFormsButtons.styles';

const OpenModalFormsButtons = ({ onOpen, continueToPayment, isDisabled }) => {
    const goToRoute = useGoToRoute();
    const [isAcceptOrderTerms, setIsAcceptOrderTerms] = useState();
    const [acceptOrderTermsError, setAcceptOrderTermsError] = useState(null);
    const {
        totalPrices,
    } = useDataForPaymentDetails(true);
    const fullPriceParsed = parseFloat(totalPrices.total).toLocaleString();

    const continueToPaymentHandler = () => {
        if (isAcceptOrderTerms) {
            goToRoute(ROUTES.CHECKOUT);
            setAcceptOrderTermsError(null);
        } else {
            setAcceptOrderTermsError(STRINGS.MUST_FILL_YOU_READ_ORDER_AGREEMENT);
        }
    };

    const revealUniqueOnlinePriceHandler = () => {
        onOpen(REVEAL_UNIQUE_ONLINE_PRICE);
    };

    const speakWithRepresentativeHandler = () => {
        onOpen(SPEAK_WITH_REPRESENTATIVE);
    };

    const checkBoxHandler = () => {
        setAcceptOrderTermsError(null);
        setIsAcceptOrderTerms(!isAcceptOrderTerms);
    };

    const renderContinueBtn = () => {
        const buttonTitle = continueToPayment
            ? STRINGS.CONTINUE_TO_PAYMENT
            : STRINGS.REVEAL_UNIQUE_ONLINE_PRICE;
        const buttonHandler = continueToPayment
            ? continueToPaymentHandler
            : revealUniqueOnlinePriceHandler;

        return (
            <ContinueButton
                disabled={ isDisabled }
                title={ buttonTitle }
                onClick={ buttonHandler }
            />
        );
    };

    const orderAgreementLabel = (
        <Styled.CheckboxLabel>
            {`${STRINGS.I_READ_AND_ACCEPT} `}
            <Styled.CheckboxLink href={ ROUTES.ORDER_AND_CANCEL_TERMS } >
                {STRINGS.ORDER_AGREEMENT}
            </Styled.CheckboxLink>
        </Styled.CheckboxLabel>
    );

    return (
        <Styled.MobileWrapper>
            { renderContinueBtn() }
            <Styled.Container>
                { continueToPayment && (
                    <CheckBoxFormGroup
                        isChecked={ isAcceptOrderTerms }
                        onCheck={ checkBoxHandler }
                        errorMessage={ acceptOrderTermsError }
                        isErrorShown={ acceptOrderTermsError }
                        label={ orderAgreementLabel }
                    />
                )}
                <Styled.PriceContainer>
                    <Styled.PriceDescription>
                        { STRINGS.FOOTER_PRICE_INCLUDES_VAR_AND_LICENSING_FEE }
                    </Styled.PriceDescription>
                    <Styled.FullPrice>{fullPriceParsed} &#8362;</Styled.FullPrice>
                </Styled.PriceContainer>
                <Styled.OpenModalFormsButtons>
                    <Styled.SpeakWithRepresentativeButton
                        onClick={ speakWithRepresentativeHandler }
                    >
                        <Styled.PhoneIcon />
                        { STRINGS.SPEAK_WITH_REPRESENTATIVE }
                    </Styled.SpeakWithRepresentativeButton>
                    { renderContinueBtn() }
                </Styled.OpenModalFormsButtons>
            </Styled.Container>
        </Styled.MobileWrapper>
    );
};

OpenModalFormsButtons.defaultProps = {
    onOpen: () => {},
    continueToPayment: false,
    isDisabled: false,
};

OpenModalFormsButtons.propTypes = {
    onOpen: PropTypes.func,
    continueToPayment: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default OpenModalFormsButtons;
