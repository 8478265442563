import {
    useState, useEffect, useRef, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getIconFromAssetsByBrand } from '../utils/assetsUtils';
import { getCurrentBrandFromEnv } from '../utils/commonUtils';
import {
    getAccessibilityScriptByBrand,
    renderAccessibilityForSeat,
} from '../utils/accessibility.utils';
import { MENUFACTURS } from '../constants';
import { useCurrentBrand } from './app_common_data';
/** 
 * this custom hook is being updated each step the user makes - according to the chosen price of the chosen item.
   it returns the most updated and current price 
 */

export const useUpdateCarTotalPrice = (
    currentSelectedCar,
    currentExternalColor,
    currentInternalColor,
    currentHoop,
    currentAddOns,
    currentEquipmentPackage,
) => {
    const { auth }  = useSelector(({ authenticationReducer }) => authenticationReducer);
    const { isAuthenticated } = auth;

    let additionalPrice = 0;
    const selectedAddOnsArray = Object.values(currentAddOns);

    const { totalPrice } = currentSelectedCar;

    // The price of an equipmentPackage is included now in the base price of a vehicle
    // if (currentEquipmentPackage?.price > 0) {
    //     additionalPrice += currentEquipmentPackage.price;
    // }

    if (currentExternalColor.price > 0) {
        additionalPrice += currentExternalColor.price;
    }
    if (currentInternalColor.price > 0) {
        additionalPrice += currentInternalColor.price;
    }
    if (currentHoop.price > 0) {
        additionalPrice += currentHoop.price;
    }
    if (selectedAddOnsArray.length > 0) {
        const addOnsPrice = selectedAddOnsArray.reduce((acc, currentAddOn) => {
            const { price, isDiscount, priceAfterDiscount } = currentAddOn;
            const currentPrice = isDiscount && isAuthenticated && priceAfterDiscount ? priceAfterDiscount : price;
         
            return  acc + currentPrice;
        }, 0);

        additionalPrice += addOnsPrice;
    }
    
    return totalPrice + additionalPrice || additionalPrice;
};

/**
  * gets the icon name and returns the relevant path according to the current brand
  * @param {string} iconName 
  */

export const useIconFromAssets = (iconName) => {
    const brand = useCurrentBrand();
    
    return getIconFromAssetsByBrand(iconName, brand);
};

export const useRenderImageSrcOrFallback = (src, fallbackSrc) => {
    const [_src, setSrc] = useState(src);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (hasError) {
            setSrc(fallbackSrc);
        }
    }, [hasError]);

    return [_src, setHasError];
};

/**
 * 
 * @returns navigate.push referance 
 */

export const useGoToRoute = () => {
    const navigate = useNavigate();

    return navigate;
};

/**
 * @returns A URLSearchParams object instance with the current querystring params
 */

export const useUrlSearchParams = () => {
    const { search } = useLocation();
    
    return new URLSearchParams(search);
};

/**
 *
 * @param {function} callback  // function which return @{Promise}
 * @param {array} dependencies
 */

export default function useAsync(callback, dependencies = []) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [value, setValue] = useState();
  
    const callbackMemoized = useCallback(() => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        callback()
            .then(setValue)
            .catch(setError)
            .finally(() => setLoading(false));
    }, dependencies);
  
    useEffect(() => {
        callbackMemoized();
    }, [callbackMemoized]);
  
    return { loading, error, value };
}

/**
 * 
 * @param {string} url 
 * @param {string} appendTo 
 * @returns {PROMISE}
 * if the script is valid, appending it to head/header 
 */

export const useScript = (url, appendTo = 'head') => {
    return useAsync(() => {
        if (!url) {
            return Promise.reject(new Error('No Url'));
        }
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
  
        return new Promise((resolve, reject) => {
            script.addEventListener('load', resolve);
            script.addEventListener('error', reject);
            document[appendTo].appendChild(script);
        });
    }, [url]);
};

export const useAccessibility = () => {
    const brand = getCurrentBrandFromEnv();
    const accessibilityScript = getAccessibilityScriptByBrand(brand);

    const { value } = useScript(accessibilityScript);

    useEffect(() => {
        if (value && brand === MENUFACTURS.SEAT) {
            renderAccessibilityForSeat();
        }
    }, [value]);
};

export const useClickOutside = (handler) => {
    const domNode = useRef();

    useEffect(() => {
        const clickOutside = (e) => {
            if (!domNode.current.contains(e.target)) {
                handler();
            }
        };

        document.addEventListener('mousedown', clickOutside);
    
        return () => {
            document.removeEventListener('mousedown', clickOutside);
        };
    }, []);

    return domNode;
};

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener('scroll', updatePosition);
        updatePosition();
        
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return scrollPosition;
};
