import styled from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';
import { ContinueThemeButton } from
    '../../../components/ContinueThemeButton/ContinueThemeButton.styles';

const { DEVICE_SIZE } = CONFIG;

export const AudiPaymentSuccess = styled.div`
margin-top: 20px;
  ${ContinueThemeButton}{
       margin-top: 44px;
       margin-left:auto;
  }

  @media (min-width: ${DEVICE_SIZE.SMALL}px) and (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width:60%;
    position: relative;
  }
`;
export const TitleText = styled.div`
`;
export const Title = styled.div`
  margin:0 0 15px 0;
  line-height: 1;
  max-width:60%;
  display: flex;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width:100%;
    position: relative;
  }
`;
export const TitleSmallText = styled.h1`
  margin:0;
  font-size:${pxToRem(36)};
  font-weight:400;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size:${pxToRem(24)};
  }
`;
export const Icon = styled.img`
    width: 96px;
    height: 89px;
    margin-right: 39px;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 65px;
    height: 59px;
    position: absolute;
    top: -35px;
    left: 0;
    margin-right: 0;
  }
`;
export const TitleBoldText = styled.h3`
  margin:0;
  font-size:${pxToRem(72)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size:${pxToRem(48)};
  }
`;
export const ContentContainer = styled.div`
 max-width: 54%;
@media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
  max-width: 100%;
  }
`;
export const Text = styled.h2`
  font-size:${pxToRem(18)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size:${pxToRem(18)};
  }
  font-weight:400;
`;

export const Congratulations = styled.p`
  margin:15px 0 0 0;
  font-weight:700;
  font-size:${pxToRem(24)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 55%;
  }
`;
export const Disclaimer = styled.p`
  margin:15px 0 0 0;
  font-weight:400;
  font-size:${pxToRem(18)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size:${pxToRem(18)};
    max-width: 100%;
  }
`;

export const ActionContainer = styled.div`
margin-top:20px;
display:flex;
 flex-direction:column;
 align-items: baseline;
`;

export const Action = styled.button`
border: none;
outline: none;
background-color: transparent;
padding: 0;
font-family: ${({ theme }) => theme['font-family']};
font-size: ${pxToRem(15)};
font-weight: bold;
text-decoration: underline;
display: flex;
align-items: center;
margin-bottom: 10px;
cursor: pointer;

&:before{
    content:'';
    width:24px;
    height:24px;
    display:block;
    background-image:url(${({ icon }) => icon});
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    margin-left:10px;
}
`;

export const RegularText = styled.p`
  font-size: 16px;
`;

export const BoldText = styled(RegularText)`
  font-weight: bold;
`;
export const Link = styled.a`
  color: black;
  margin: 0;
  font-size: 18px;
`;

export const ContactInfoContainer = styled.div`
  display: block;
`;

export const ContactText = styled.p`
  margin: 0;
`;
