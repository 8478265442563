import React from 'react';
import PropTypes from 'prop-types';
import { PaymentDetail } from '../../../constants/propTypes';
import STRINGS from '../../../language';
import {
    useCurrentCar,
    useCurrentIsInSummary,
} from '../../../hooks';
import PriceItem from './PriceItem/PriceItem';
import AfterDiscountPriceItem from './AfterDiscountPriceItem/AfterDiscountPriceItem';
import Specifications from './Specifications/Specifications';
import AddOns from './AddOns/AddOns';
import OriginalAdditions from './OriginalAdditions/OriginalAdditions';
import EquipmentPackageItem from './EquipmentPackageItem/EquipmentPackageItem';
import * as Styled from './PaymentDetails.styles';

const {
    PAYMENT_TOTAL_PRICE_LIST,
    PAYMENT_TITLE_MODEL,
    LICENSE_FEE,
    TOTAL_DISCOUNT_FOR_ONLINE_PURCHASE,
} = STRINGS;

const PaymentDetails = ({
    totalPrices,
    carPrices,
    licenseFeePrices,
    specifications,
    addOns,
    originalAdditions,
    equipmentPackage,
}) => {
    const currentCar = useCurrentCar();
    const isInSummary = useCurrentIsInSummary();

    const { price, total } = totalPrices;
    const { links } = currentCar;
    const {
        interiorImage: equipmentPackageImage,
    } = links;
    const parsedBeforeDiscountPrice = parseFloat(price).toLocaleString();
    const parsedAfterDiscountPrice = parseFloat(total).toLocaleString();

    const TOTAL_SUM_STRING = PAYMENT_TOTAL_PRICE_LIST;

    return (
        <Styled.PaymentDetails $isInSummary={ isInSummary }>
            <Styled.Content $isInSummary={ isInSummary }>
                <Styled.Body>
                    <PriceItem
                        name={ PAYMENT_TITLE_MODEL }
                        prices={ carPrices }
                    />
                    <Specifications specifications={ specifications } />
                    { equipmentPackage && (
                        <EquipmentPackageItem
                            equipmentPackage={ equipmentPackage }
                            equipmentPackageImage={ equipmentPackageImage }
                        />
                    ) }
                    <OriginalAdditions originalAdditions={ originalAdditions } />
                    <AddOns addOns={ addOns } />
                    <PriceItem name={ LICENSE_FEE } prices={ licenseFeePrices } />
                    {totalPrices.isDiscount && (
                        <AfterDiscountPriceItem
                            name={ TOTAL_DISCOUNT_FOR_ONLINE_PURCHASE }
                            prices={ totalPrices }
                        />
                    )}
                    <Styled.TotalSum>
                        <Styled.TotalString>{TOTAL_SUM_STRING}</Styled.TotalString>
                        <Styled.TotalPriceContainer>
                            {parsedBeforeDiscountPrice !== parsedAfterDiscountPrice && (
                                <Styled.TotalPriceBeforeDiscount>
                                    {parsedBeforeDiscountPrice}
                                </Styled.TotalPriceBeforeDiscount>
                            )}
                            <Styled.TotalPrice>{parsedAfterDiscountPrice}</Styled.TotalPrice>
                        </Styled.TotalPriceContainer>
                    </Styled.TotalSum>
                </Styled.Body>
            </Styled.Content>
        </Styled.PaymentDetails>
    );
};

PaymentDetails.defaultProps = {
    specifications: [],
    addOns: [],
    originalAdditions: [],
    equipmentPackage: undefined,
};

PaymentDetails.propTypes = {
    totalPrices: PropTypes.object.isRequired,
    carPrices: PropTypes.object.isRequired,
    licenseFeePrices: PropTypes.object.isRequired,
    addOns: PropTypes.arrayOf(PaymentDetail),
    specifications: PropTypes.arrayOf(PaymentDetail),
    originalAdditions: PropTypes.array,
    equipmentPackage: PropTypes.string,
};

export default React.memo(PaymentDetails);
