import styled from 'styled-components';
import  {
    ContinueThemeButton,
} from '../../../../components/ContinueThemeButton/ContinueThemeButton.styles';
import { pxToRem } from '../../../../utils/commonUtils';

export const ContactResponseContainer = styled.div`
   padding:40px 100px;
    flex:1 1 50%;

    ${ContinueThemeButton}{
        max-width:235px;
        height:66px;
        font-size:21px;
        text-align:center;
        margin-top:25px;
    }

`;

export const Title = styled.h1`
    font-size:${pxToRem(54)};
    font-weight:800;
    margin: 0 0 10px 0;
    
    `;

export const Text = styled.p`
    margin:0;
    font-size:${pxToRem(14)};
    font-weight:400;
    line-height:22px;
`;
