import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './FooterNavigation.styles';

const FooterNavigation = ({ children }) => {
    return (
        <Styled.Navigation>
            <Styled.NavigationMenu>
                {children}
            </Styled.NavigationMenu>
        </Styled.Navigation>
    );
};

FooterNavigation.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
};

export default FooterNavigation;
