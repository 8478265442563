import styled from 'styled-components';
import Button from '../Button/Button';
import { getIconFromAssets } from '../../utils/assetsUtils';
import { COLORS, ICONS_NAMES } from '../../constants';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

const hamburgerIcon = getIconFromAssets(ICONS_NAMES.HAMBURGER);

export const HamburgerButton = styled(Button)`
 
    flex: 0 0 60px;
    max-width: 60px;
    margin-right: auto;
    border:none;
    outline:none;
    background-color:${COLORS.BG_COLOR_HAM_BUTTON};
    border-right: 1px solid ${COLORS.BORDER_RIGHT_NAV};
    justify-content:center;
    align-items:center;
    padding:0;
    display:none;
    &:after{
      content:'';
      display:block;
      width:20px;
      height:20px;
      background-image:url(${hamburgerIcon});
    background-repeat: no-repeat;
    background-position: center;
    
    }

    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display:flex;
    }
    
`;
