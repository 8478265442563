import { NEW_API_METHODS, OtpHeaders } from '../constants';
import axios from './axios';

const {
    GET_DEALERSHIPS,
} = NEW_API_METHODS.ORGANIZATION;

const {
    REQUEST_OTP_BY_CLIENT,
} = NEW_API_METHODS.AUTH;

const {
    HOLD_COMMISION,
    CONFIRM_PAYMENT,
} = NEW_API_METHODS.ORDER;

const {
    CREATE_CUSTOMER,
    IS_ADDITIONAL_INFORMATION_FILLING_REQUIRED,
} = NEW_API_METHODS.CUSTOMER;

/**
 * @POST request (champion equivalent - FirstCustomEntrance)
 * @param {*} sIdCard
 */
export const customerIdentification = async (sIdCard) => {
    const result = await axios.get(REQUEST_OTP_BY_CLIENT, {
        headers: OtpHeaders,
        params: {
            id: sIdCard,
        },
    });
    
    return result;
};

/**
 * @POST request (champion equivalent - NewCustomerEntrance)
 * @param {string} brand  (champion equivalent - sMutag)
 * @param {string} customerFirstName (champion equivalent - sFName)
 * @param {string} customerLastName  (champion equivalent - sLName)
 * @param {string} customerPhoneNumber (champion equivalent - sPhoneNum)
 * @param {string} sIdCard
 * @param {string} customerEmail (champion equivalent - sEmail)
 * @param {boolean} isAgreeToTerms (champion equivalent - bConsentWithAgreements)
 * @param {boolean} isAgreeToMarketing (champion equivalent - bConsentWithMarketingInfo)
 * @returns
 */
export const customerRegistration = async (
    brand,
    customerFirstName,
    customerLastName,
    customerPhoneNumber,
    sIdCard,
    customerEmail,
    isAgreeToTerms,
    isAgreeToMarketing,
) => {
    const data = {
        firstName: customerFirstName,
        lastName: customerLastName,
        contactInfo: {
            email: customerEmail,
            phoneNumber: customerPhoneNumber.replace('-', ''),
        },
        nationalId: sIdCard,
        marketingConsent: isAgreeToMarketing,
        termsConsent: true, // there is validation in front
    };

    const result = await axios.post(CREATE_CUSTOMER, data);

    return result;
};

/**
 * @POST request (champion equivalent - GetDestinations)
 * @param {string} brand  (champion equivalent - sMutag)
 * @param {string} subBrand  (champion equivalent - sSubMutag)
 */
export const getDealerships = async () => {
    const result = await axios.get(GET_DEALERSHIPS);

    return result;
};

/**
 * 
 * @param {string} brand (champion equivalent - sMutag)
 * @param {number} customerNumber (champion equivalent - sCust)
 * @param {string} modelCode (champion equivalent - sModel)
 * @param {string} internalColor (champion equivalent - sColor)
 * @param {string} chosenHoopsString (champion equivalent - sTosMkr)
 * @param {number} carVersion (champion equivalent - sGirsa)
 */

export const holdCommision = async (
    modelCode,
    externalColorCode,
    internalColorCode,
    version,
    addOnsCodes,
    equipmentPackageCode,
) => {
    const data = {
        modelCode,
        colorCode: externalColorCode,
        upholsteryCode: internalColorCode,
        version,
        originalAdditions: equipmentPackageCode,
    };

    const result = await axios.post(HOLD_COMMISION, data);

    return result;
};

/**
 * 
 * @param {string} brand 
 * @param {string} customerNumber 
 * @param {string} customerPhoneNumber 
 * @param {string} selectedAddOnsString 
 * @param {string} cardToken 
 * @param {string} cardExp 
 * @param {numstringber} authNumber 
 * @param {string} dealershipCode 
 * @param {boolean} isFinancing 
 * @returns {Promise}
 */
export const createOrder = async ({
    confirmationCode,
    cardToken,
    cardExp,
    destinationDealership,
    localAdditions,
    hasRequestedFunding,
},
) => {
    const data = {
        confirmationCode,
        cardToken,
        creditCardExpirationDate: cardExp,
        destinationDealership,
        localAdditions,
        hasRequestedFunding,
    };

    const result = await axios.post(CONFIRM_PAYMENT, data);

    return result;
};

/**
 * 
 * @returns {Promise}
 */
export const IsAdditionalInformationFillingRequired = async () => {
    const result = await axios.post(IS_ADDITIONAL_INFORMATION_FILLING_REQUIRED);

    return result;
};
