import styled from 'styled-components';
import { getIconFromAssets } from '../../../utils/assetsUtils';
import { ICONS_NAMES } from '../../../constants';

const arrowRightIcon = getIconFromAssets(ICONS_NAMES.ARROW_RIGHT);

export const GoBackButton = styled.button`
  border: none;
  outline: none;
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius:50%;
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
    justify-content: center;
    align-items: center;
    padding:0;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 10;
    cursor: pointer;
  &:before{
    content:'';
    display:block;
    width:23px;
    height:22px;
    background-image:url(${arrowRightIcon});
    background-repeat: no-repeat;
    background-position: center;
    border-radius:50%;
  }
`;
