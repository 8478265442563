import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../../../utils/commonUtils';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';

const { DEVICE_SIZE } = CONFIG;

export const SelectButton = styled(Link)`
  width: 100%;
  min-width: 108px;
  max-width: 280px;
  height: 38px;
  font-weight: 700;
  line-height: 1;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  border: none;
  outline: none;
  padding: 0 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.buttonTextColor};
  font-size: ${pxToRem(16)};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.buttonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 10px;
  
  @media (min-width: ${DEVICE_SIZE.LARGE}px) {
    &:hover{
      color: ${({ theme }) => theme.buttonTextHoverColor};
      background-color: ${({ theme }) =>  theme.buttonHoverBackgroundColor};
      border: ${({ $isAudi, $isCupra }) =>  $isAudi || $isCupra ? `2px solid ${COLORS.BLACK}` : 'none'};
      transition: background-color 0.5s;
    }
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: auto;
    max-width: 100%;
    height: 40px;
  }
`;

const Styled = {
    SelectButton,
};

export default Styled;
