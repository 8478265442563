/* eslint-disable max-len */
import React, { useEffect } from 'react';
import InnerPagesHeader from '../../../components/InnerPages/InnerPagesHeader/InnerPagesHeader';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import { useCurrentBrand, useActionDispatch, useCurrentAppCommonData } from '../../../hooks';
import { getFullManufacturer, replacePlaceholdersInTemplate } from '../../../utils/commonUtils';
import { LANGUAGE, MENUFACTURS } from '../../../constants';
import STRINGS from '../../../language';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import CarInfoSkoda from './CarInfoSkoda/CarInfoSkoda';
import CarInfoSeat from './CarInfoSeat/CarInfoSeat';
import CarInfoVolkswagen from './CarInfoVolkswagen/CarInfoVolkswagen';
import CarInfoVolkswagenCommercial from './CarInfoVolkswagenCommercial/CarInfoVolkswagenCommercial';
import CarInfoCupra from './CarInfoCupra/CarInfoCupra';
import CarInfoAudi from './CarInfoAudi/CarInfoAudi';
import * as Styled from './CarInfo.styles';

/*
 * TODO: add cupra related data (instead of Seat )
 */

const CarInfo = () => {
    const currentBrand = useCurrentBrand();
    const { GetManufacturingDates } = useActionDispatch();
    const { ManufacturingDates } = useCurrentAppCommonData();
    const manufacturerName = getFullManufacturer(currentBrand, LANGUAGE.HEBREW);
    const pageTitle = replacePlaceholdersInTemplate(
        STRINGS.MORE_INFORMATION_ON_MANUFACTURER_VEHICLES,
        [manufacturerName],
    );

    useEffect(() => {
        GetManufacturingDates(currentBrand);
    }, []);

    const renderCarInfo = (brand) => {
        switch (brand) {
            case MENUFACTURS.SEAT:
                return <CarInfoSeat brand={ brand } ManufacturingDates={ ManufacturingDates } />;
            case MENUFACTURS.SKODA:
                return <CarInfoSkoda brand={ brand } ManufacturingDates={ ManufacturingDates } />;
            case MENUFACTURS.VOLKSWAGEN:
                return  <CarInfoVolkswagen ManufacturingDates={ ManufacturingDates } />;
            case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
                return  <CarInfoVolkswagenCommercial ManufacturingDates={ ManufacturingDates } />;
            case MENUFACTURS.CUPRA:
                return <CarInfoCupra brand={ brand } ManufacturingDates={ ManufacturingDates } />;
            case MENUFACTURS.AUDI:
                return <CarInfoAudi brand={ brand } ManufacturingDates={ ManufacturingDates } />;
            default:
                return null;
        }
    };

    return (
        <Styled.CarInfo>
            <HelmetTitleDescription canonical={ window.location.href } />
            <InnerPageContainer>
                <InnerPagesHeader title={ pageTitle } />
                <Styled.Body>
                    {renderCarInfo(currentBrand)}
                </Styled.Body>
            </InnerPageContainer>
        </Styled.CarInfo>
    );
};

export default CarInfo;
