import { NEW_API_METHODS } from '../constants';
import { replaceUrlParams } from '../utils/fetchUtils';
import axios from './axios';

/**
 * responsible for getting the all specifications - {equipment,engine,measurements} 
 */

const {
    GET_SPECIFICATIONS,
    GET_ENGINE_SPECIFICATIONS,
    GET_SIZE_SPECIFICATIONS,
} = NEW_API_METHODS.VEHICLE;

/**
 * @POST request - equivalent (Champion) api call is GetSpecifications
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {string} sOriginalAdditions (champion equivalent - sOriginalAdditions) 
 * @param {string} carVersion (champion equivalent - sVersion) 
 */

export const getEquipmentSpecifications = async (modelCode, styleLevelCode, version) => {
    const getEquipmentSpecificationsUrl = replaceUrlParams(GET_SPECIFICATIONS, { modelCode });

    const result = await axios.get(getEquipmentSpecificationsUrl, {
        params: {
            styleLevelCode,
            version,
        },
    });

    return result;
};

/**
 * @POST request - equivalent (Champion) api call is GetEngineSpecifications
 * @param {string} modelCode (champion equivalent - sModel) 
 * @param {string} sOriginalAdditions (champion equivalent - sOriginalAdditions) 
 * @param {string} sVersion (champion equivalent - sVersion) 
 */

export const getEngineSpecifications = async (modelCode, styleLevelCode, sVersion) => {
    const getEngineSpecificationsUrl = replaceUrlParams(GET_ENGINE_SPECIFICATIONS, { modelCode });

    const result = await axios.get(getEngineSpecificationsUrl, {
        params: {
            styleLevelCode,
            version: sVersion,
        },
    });

    return result;
};

/**
 * @POST request - equivalent (Champion) api call is GetSizeSpecifications
*  @param {string} modelGroupCode (champion equivalent - sModelGroup)

 */

export const getMeasurementsSpecifications = async (groupModelCode) => {
    const getMeasurementsSpecificationsUrl = replaceUrlParams(GET_SIZE_SPECIFICATIONS, {
        groupModelCode,
    });

    const result = await axios.get(getMeasurementsSpecificationsUrl);

    return result;
};
