import * as actionTypes from '../../actionTypes';
import { getFinalDiscountPrice } from '../../../fetch/configurator_options';
import {
    CUSTOMER_NAME_AUTHORIZATION,
    PHONE_NUMBER_AUTHORIZATION,
} from '../../../constants';
import { initializeOtpVerification, OtpVerificationSuccess } from '../authentication';

const getFinalDiscountPricePending = () => ({
    type: actionTypes.GET_FINAL_DISCOUNT_PRICE_PENDING,
});

const getFinalDiscountPriceSuccess = (data, chosenHoopsString) => ({
    type: actionTypes.GET_FINAL_DISCOUNT_PRICE_SUCCESS,
    data,
    chosenHoopsString,
});

const getFinalDiscountPriceFailure = (error) => ({
    type: actionTypes.GET_FINAL_DISCOUNT_PRICE_FAILURE,
    error,
});

export const resetFinalDiscountPrices = () => ({
    type: actionTypes.RESET_FINAL_DISCOUNT_PRICES,
});

export const getFinalDiscountPriceAsync = (
    modelCode,
    externalColorCode,
    internalColorCode,
    carVersion,
    chosenHoopsString,
    chosenAddOnsString,
) => {
    return async (dispatch) => {
        dispatch(getFinalDiscountPricePending());
        try {
            const response = await getFinalDiscountPrice(
                modelCode,
                externalColorCode,
                internalColorCode,
                carVersion,
                chosenHoopsString,
                chosenAddOnsString,
            );
            const { data } = response;

            dispatch(getFinalDiscountPriceSuccess(data, chosenHoopsString));
            dispatch(OtpVerificationSuccess());
            
            return true;
        } catch (error) {
            dispatch(getFinalDiscountPriceFailure(error));
            dispatch(resetFinalDiscountPrices());
            dispatch(initializeOtpVerification());
            sessionStorage.removeItem(PHONE_NUMBER_AUTHORIZATION);
            sessionStorage.removeItem(CUSTOMER_NAME_AUTHORIZATION);

            return false;
        }
    };
};
