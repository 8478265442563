import React from 'react';
import PropTypes from 'prop-types';
import TextArea from '../TextArea/TextArea';
import * as Styled from './TextAreaGroup.styles';

const TextAreaGroup = ({
    name,
    value,
    onChange,
    label,
    maxLength,
}) => {
    return (
        <Styled.TextAreaGroup>
            <Styled.Label>
                {label}
            </Styled.Label>
            <Styled.TextAreaContainer>
                <TextArea
                    maxLength={ maxLength }
                    name={ name }
                    value={ value }
                    onChange={ onChange }
                />
            </Styled.TextAreaContainer>
        </Styled.TextAreaGroup>
    );
};

TextAreaGroup.defaultProps = {
    value: '',
    maxLength: null,
};

TextAreaGroup.propTypes = {

    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,

};

export default TextAreaGroup;
