import styled from 'styled-components';

export const InnerPageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
 /* width:95%; */
 margin:auto;
 max-width:${({ $maxWidth }) => $maxWidth}px;
`;
