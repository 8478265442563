import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../language';
import { COLORS } from '../../constants';
import * as Styled from './ErrorMessage.styles';

const ErrorMessage = ({ errorMessage, color }) => {
    return <Styled.ErrorMessage color={ color }>{errorMessage}</Styled.ErrorMessage>;
};

ErrorMessage.defaultProps = {
    errorMessage: STRINGS.SOMETHING_WENT_WRONG,
    color: COLORS.BLACK,
};
ErrorMessage.propTypes = {
    errorMessage: PropTypes.string,
    color: PropTypes.string,
};

export default ErrorMessage;
