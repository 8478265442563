import styled, { css } from 'styled-components';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';

export const InputFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .input-text,.input-tel,.input-email{
    font-size:${pxToRem(17)};
      border:none;
      background-color:transparent;
      ${({ isError }) => {
        const borderBottomColor = isError ? COLORS.COLOR_ERROR_RED_GENERAL_1 : COLORS.BLACK;
        
        return `border-bottom:1px solid ${borderBottomColor}!important;`;
    }};
  }
`;
export const Label = styled.label`
  font-size: ${pxToRem(19)};
  padding: 5px 0;
  font-weight: 400;
  ${({ isError }) => isError
    && css`
      color: ${COLORS.COLOR_ERROR_LABEL_INPUT_FIELD_CHECKOUT};
      font-weight:400;
    `}
    color: ${({ isReadOnly }) => isReadOnly ? 'rgba(0, 0, 0, 0.5)' : ''};
`;
