import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ModelImage from '../ModelImage/ModelImage';
import ModelItem from '../ModelItem/ModelItem';
import STRINGS from '../../../../language';
import { renderNisSymbol } from '../../../../utils/commonUtils';
import * as Styled from './ModelGroupItem.styles';

const ModelGroupItem = ({
    image,
    imageAlt,
    imageFallback,
    title,
    price: carPrice,
    description,
    modelItems,
    modelListBounding,
    id,
    onToggleGroup,
    isSelected,
    selectedModelGroup,
    isCupra,
}) => {
    const price = parseFloat(carPrice).toLocaleString();
    const isDisabled = !isSelected && selectedModelGroup !== null;
    const modelGroupItemRef = useRef();
    const modelGroupRef = useRef();
    const { width: modelListWidth, right: modelListRight } = modelListBounding;

    const calculateOffsetMargin = () => {
        if (!modelGroupItemRef.current) {
            return null;
        }
        const modelGroupItemOffset = modelListRight - modelGroupItemRef.current.getBoundingClientRect().right;

        return modelGroupItemOffset;
    };

    const renderModelItems = () => {
        return modelItems.map((car) => {
            const { id: carId } = car;

            return <ModelItem model={ car } isCupra={ isCupra } key={ carId } />;
        });
    };

    return (
        <Styled.ModelGroupItem
            ref={ modelGroupItemRef }
            $isGroupSelected={ isSelected }
            $isDisabled={ isDisabled }
            id={ id }
        >
            <Styled.ModelGroup
                ref={ modelGroupRef }
                onClick={ () => onToggleGroup(id, modelGroupItemRef.current) }
                $isGroupSelected={ isSelected }
            >
                <ModelImage alt={ imageAlt } src={ image } fallbackSrc={ imageFallback } />
                <Styled.Information>
                    <Styled.TitlePriceAndDescriptionWrapper>
                        <Styled.Header>
                            <Styled.GroupModelTitle>{title}</Styled.GroupModelTitle>
                            <Styled.PriceWrapper>
                                <Styled.PriceTitle>{STRINGS.STARTING_FROM}- </Styled.PriceTitle>
                                <Styled.Price>
                                    <Styled.NisSymbol>{renderNisSymbol()}</Styled.NisSymbol>
                                    {price}
                                </Styled.Price>
                            </Styled.PriceWrapper>
                        </Styled.Header>
                        <Styled.Description>
                            {description}
                        </Styled.Description>
                    </Styled.TitlePriceAndDescriptionWrapper>
                    <Styled.PriceWrapperForMobile>
                        <Styled.PriceTitle>{STRINGS.STARTING_FROM}- </Styled.PriceTitle>
                        <Styled.Price>
                            <Styled.NisSymbol>{renderNisSymbol()}</Styled.NisSymbol>
                            {price}
                        </Styled.Price>
                    </Styled.PriceWrapperForMobile>
                </Styled.Information>
            </Styled.ModelGroup>
            <Styled.ModelItems
                $offsetMargin={ calculateOffsetMargin() ?? 'auto' }
                $isGroupSelected={ isSelected }
                width={ modelListWidth }
            >
                {renderModelItems()}
                <Styled.HideModelItemsButton onClick={ () => onToggleGroup(id) } />
            </Styled.ModelItems>
        </Styled.ModelGroupItem>
    );
};

ModelGroupItem.defaultProps = {
    image: null,
    modelItems: [],
    onToggleGroup: () => {},
    isSelected: null,
    selectedModelGroup: null,
};

ModelGroupItem.propTypes = {
    imageAlt: PropTypes.string.isRequired,
    imageFallback: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    modelItems: PropTypes.array,
    modelListBounding: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    onToggleGroup: PropTypes.func,
    isSelected: PropTypes.bool,
    selectedModelGroup: PropTypes.string,
    isCupra: PropTypes.bool.isRequired,
};

export default ModelGroupItem;
