import React, { useRef, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import confetti from 'canvas-confetti';
import {
    useCurrentBrand,
    useCheckoutIsFinished,
    useCheckoutCustomer,
    useCheckout,
} from '../../../hooks';
import useIsMobile from '../../../hooks/useWindowSize';
import {
    getEmailHrefString,
    getManufacturerContactInfo,
    getTelHrefString,
} from '../../../utils/commonUtils';
import { getIconFromAssets } from '../../../utils/assetsUtils';
import CONFIG from '../../../config';
import { ICONS_NAMES } from '../../../constants';
import STRINGS from '../../../language';
import { conffeti as conffetiConfig } from './AudiPaymentSuccess.config';
import * as Styled from './AudiPaymentSuccess.styles';

const { delay, durationInMilliseconds, conffetiThemeColors } = conffetiConfig;
const { DEVICE_SIZE } = CONFIG;
const { CHEERS_ICON } = ICONS_NAMES;

const AudiPaymentSuccess  = () => {
    const themeContext = useContext(ThemeContext);
    const animationRef = useRef();
    const timerAnimationRef = useRef();
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const { email: customerEmail } = useCheckoutCustomer();
    const customerEmailHref = getEmailHrefString(customerEmail);
    const isPaymentFinished = useCheckoutIsFinished();
    const { order } = useCheckout();
    const { orderId } = order;
    const cheersIcon = getIconFromAssets(CHEERS_ICON);
    const currentBrand = useCurrentBrand();
    const manufacturerContactInfo = getManufacturerContactInfo(currentBrand);
    const { EMAIL, PHONE } = manufacturerContactInfo;
    const emailHref = getEmailHrefString(EMAIL);
    const phoneHref = getTelHrefString(PHONE);

    useEffect(() => {
        const conffetiColors = conffetiThemeColors([themeContext.primaryColor, '#ffffff']);
        const end = Date.now() + (durationInMilliseconds); // 4000 = 4 sec
        
        const frame = () => {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0, y: 1 },
                colors: conffetiColors,
                startVelocity: 90,
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1, y: 1 },
                colors: conffetiColors,
                startVelocity: 90,
            });

            if (Date.now() < end) {
                animationRef.current = requestAnimationFrame(frame);
            }
        };

        if (isPaymentFinished) {
            timerAnimationRef.current =  setTimeout(() => {
                frame();
            }, delay);
        }

        return () => {
            const { current: setTimeOutId } = timerAnimationRef;

            if (setTimeOutId) {
                clearTimeout(setTimeOutId);
                cancelAnimationFrame(animationRef.current);
                confetti.reset();
            }
        };
    }, [isPaymentFinished]);

    return (
        <Styled.AudiPaymentSuccess>
            <Styled.Title>
                <Styled.TitleText>
                    <Styled.TitleSmallText>{STRINGS.AUDI_CONGRATULATIONS}</Styled.TitleSmallText>
                    <Styled.TitleBoldText>
                        {STRINGS.AUDI_YOUR_ORDER_IS_RECEIVED}
                    </Styled.TitleBoldText>
                </Styled.TitleText>
                <Styled.Icon src={ cheersIcon } />
            </Styled.Title>

            <Styled.ContentContainer>
                <Styled.Text>
                    {STRINGS.ORDER_ID}{ orderId }
                </Styled.Text>
                <Styled.Text>
                    { STRINGS.AUDI_SUCCESS_MESSAGE_SENT }
                    <Styled.Link href={ `${customerEmailHref}` }>{ customerEmail }</Styled.Link>.
                </Styled.Text>
                <Styled.Text isMobile={ isMobile }>
                    {STRINGS.AUDI_PAYMENT_SUCCESS_OUR_REPRESENTATIVE_WILL_CONTACT_YOU_SHORTLY}
                </Styled.Text>
                <Styled.Disclaimer>{STRINGS.AUDI_PAYMENT_SUCCESS_DISCLAIMER}</Styled.Disclaimer>
                <Styled.ContactInfoContainer>
                    <Styled.BoldText>{ STRINGS.YOU_CAN_CONTACT_US_VIA }</Styled.BoldText>
                    <Styled.ContactText>{ STRINGS.EMAIL } :
                        <Styled.Link href={ `${emailHref}` }> { EMAIL }</Styled.Link>
                    </Styled.ContactText>
                    <Styled.ContactText >{ STRINGS.PHONE } :
                        <Styled.Link href={ `${phoneHref}` }> { PHONE }</Styled.Link>
                    </Styled.ContactText>
                </Styled.ContactInfoContainer>
            </Styled.ContentContainer>
        </Styled.AudiPaymentSuccess>
    );
};

export default AudiPaymentSuccess;
