import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { ImageBox } from '../../ModelImage/ModelImage.styles';

const { DEVICE_SIZE } = CONFIG;

export const HeaderDesktop = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:none;
  }
`;
export const Header = styled.header`
  max-width: 320px;
  ${ImageBox} {
    height: 170px;
    display: flex;
    align-items: center;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      height: auto;
    }
  }
`;
