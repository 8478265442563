import styled from 'styled-components';
import { COLORS } from '../../../constants';

export const Specifications = styled.div``;
export const SpecificationsDataContainer = styled.div`
 background-color:${COLORS.BG_SPECIFICATIONS_CONTAINER_COLOR};
`;
export const LoaderContainer = styled.div`
    height: calc(100vh - 68px - 455px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
