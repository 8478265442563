import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './PriceItem.styles';

const PriceItem = ({ name, prices, isAudi }) => {
    const { price, total, isDiscount } = prices;

    const totalPrice = parseFloat(total).toLocaleString();
    const renderPriceBeforeDiscount = () => {
        if (!isDiscount) {
            return null;
        }
        const priceBeforeDiscount = parseFloat(price).toLocaleString();
        
        return (
            <Styled.PriceBeforeDiscount $isAudi={ isAudi }>
                {priceBeforeDiscount}
            </Styled.PriceBeforeDiscount>
        );
    };
    
    return (
        <Styled.PriceItem $isAudi={ isAudi }>
            <Styled.Name $isAudi={ isAudi }>{name}</Styled.Name>
            <Styled.PriceContainer>
                {renderPriceBeforeDiscount()}
                <Styled.Price $isAudi={ isAudi }>{totalPrice}</Styled.Price>
            </Styled.PriceContainer>
        </Styled.PriceItem>
    );
};

PriceItem.defaultProps = {
    isAudi: false,
};

PriceItem.propTypes = {
    name: PropTypes.string.isRequired,
    prices: PropTypes.object.isRequired,
    isAudi: PropTypes.bool,
};

export default PriceItem;
