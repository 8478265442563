import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { SpecificationMeasurementType } from '../../../../constants/propTypes';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import Loader from '../../../../components/Loader';
import { isArrayEmpty } from '../../../../utils/commonUtils';
import MeasurementItem from './MeasurementItem/MeasurementItem';
import * as Styled from './Measurements.styles';

const Measurements = ({ measurements }) => {
    const { data, isLoading } = measurements;

    if (isArrayEmpty(data)) {
        return <ErrorMessage errorMessage={ STRINGS.NO_DATA_TO_DISPLAY } />;
    }

    if (isLoading) {
        return <Loader />;
    }
    
    const renderMeasurementData = () => {
        return data.map((dataItem) => {
            const { title, image } = dataItem;
            
            return (
                <MeasurementItem
                    key={ image }
                    title={ title }
                    image={ image }
                />
            );
        });
    };
    
    return (
        <Styled.Measurements>
            {renderMeasurementData()}
        </Styled.Measurements>
    );
};

Measurements.defaultProps = {
    measurements: {
        data: [],
    },
};

Measurements.propTypes = {
    measurements: PropTypes.shape({
        data: PropTypes.arrayOf(SpecificationMeasurementType),
        isLoading: PropTypes.bool.isRequired,
    }),
};

export default Measurements;
