import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isArrayEmpty } from '../../utils/commonUtils';

import FaqItem from './FaqItem/FaqItem';
import * as Styled from './FaqAccordion.styles';

const FaqAccordion = ({ faqItems }) => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const selectQuestionHandler = (question) => {
        setSelectedQuestion((prevQuestion) => {
            if (prevQuestion === question) {
                return null;
            }
            
            return question;
        });
    };

    if (isArrayEmpty(faqItems)) {
        return 'אין שאלות או תשובות';
    }

    const _faqs = faqItems.map((item) => {
        const { q, a } = item;
        
        return (
            <FaqItem
                key={ q }
                question={ q }
                answer={ a }
                isSelected={ selectedQuestion === q }
                onSelect={ selectQuestionHandler }
            />
        );
    });

    return (
        <Styled.FaqAccordion>
            <Styled.List>
                {_faqs}
            </Styled.List>
        </Styled.FaqAccordion>
    );
};

FaqAccordion.defaultProps = {
    faqItems: [],
};

FaqAccordion.propTypes = {
    faqItems: PropTypes.arrayOf(
        PropTypes.shape({
            q: PropTypes.string.isRequired,
            a: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ]),
        }).isRequired,
    ),
};

export default FaqAccordion;
