import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Question.styles';

const Question = ({ question, isSelected }) => {
    return <Styled.Question $isSelected={ isSelected }>{question}</Styled.Question>;
};

Question.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
};

export default Question;
