import styled from 'styled-components';

const InternalColorConfiguration = styled.div``;

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorWrapper = styled(LoaderWrapper)``;

const Styled = {
    InternalColorConfiguration,
    LoaderWrapper,
    ErrorWrapper,
};

export default Styled;
