import { isValidIsraeliID } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';

export const IDENTIFICATION_FORM_GROUP = {
    name: 'id',
    type: 'text',
    value: '',
    label: STRINGS.ID_NUMBER,
    isRequired: true,
    validator: (value) => isValidIsraeliID(value),
    isValid: false,
    errorMessage: STRINGS.ID_NUMBER_IS_NOT_CORRECT_PLEASE_TRY_AGAIN,
    isErrorShown: false,
};
