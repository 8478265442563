import styled from 'styled-components';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const MeasurementItem = styled.figure`
  margin: 0;
  flex: 0 1 50%;
  text-align: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-basis: 100%;
  }
`;
export const Image = styled.img`
  height: auto;
  max-width: 100%;
  margin-bottom: 10px;
`;
export const Figcaption = styled.figcaption`
  font-size: ${pxToRem(15)};
  font-weight: 500;
  margin-bottom: 10px;
`;
