import React from 'react';
import PropTypes from 'prop-types';
import QuestionMarkTooltip from '../../../../QuestionMarkTooltip/QuestionMarkTooltip';
import { renderNisSymbol } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import * as Styled from './FullPriceAndTooltip.styles';

const FullPriceAndTooltip = ({
    fullPrice,
    isPriceTitle,
    isAudi,
    hideTooltip,
    isShowInMobile,
    isShowFullPriceDescription,
}) => {
    const renderTooltip = () => {
        if (hideTooltip) return null;
        
        return (
            <Styled.TooltipWrapper>
                <QuestionMarkTooltip text={ STRINGS.TOOLTIP_PRICE_INNER_TEXT } isAudi={ isAudi } />
            </Styled.TooltipWrapper>
        );
    };
    
    return (
        <Styled.FullPriceAndTooltip>
            <Styled.FullPriceWrapper>
                {isPriceTitle && <Styled.PriceTitle>{STRINGS.STARTING_FROM}- </Styled.PriceTitle>}
                <Styled.Price>
                    <Styled.NisSymbol>{
                        !isAudi && renderNisSymbol()
                    }
                    </Styled.NisSymbol>{fullPrice} {isAudi && STRINGS.NIS_IN_HEBREW}
                </Styled.Price>
                {renderTooltip()}
            </Styled.FullPriceWrapper>
            { isShowFullPriceDescription && (
                <Styled.FullPriceDescription $isShowInMobile={ isShowInMobile }>
                    {STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                </Styled.FullPriceDescription>
            ) }
        </Styled.FullPriceAndTooltip>
    );
};

FullPriceAndTooltip.defaultProps = {
    isPriceTitle: true,
    isAudi: false,
    hideTooltip: false,
    isShowInMobile: false,
    isShowFullPriceDescription: true,
};

FullPriceAndTooltip.propTypes = {
    fullPrice: PropTypes.string.isRequired,
    isPriceTitle: PropTypes.bool,
    isAudi: PropTypes.bool,
    hideTooltip: PropTypes.bool,
    isShowInMobile: PropTypes.bool,
    isShowFullPriceDescription: PropTypes.bool,
};

export default FullPriceAndTooltip;
