import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import { useCurrentAppCommonData, useCurrentIsInSummary } from '../../../hooks';
import * as Styled from './AdditionalInformation.styles';

const AdditionalInformation = ({
    inStockString,
    priceList,
    priceListDate,
    manufacturerInHebrew,
    modelDescription,
}) => {
    const { isAudi } = useCurrentAppCommonData();
    const isInSummary = useCurrentIsInSummary();
    const additionalInformation = `
    ${STRINGS.MODEL} ${manufacturerInHebrew} ${modelDescription} 
    ${STRINGS.ACCORDING_TO_COMPANY_PRICE_LIST_NUMBER} ${priceList} ${STRINGS.STARTING_FROM} - ${priceListDate}`;

    return (
        <Styled.AdditionalInformation $isAudi={ isAudi } $isInSummary={ isInSummary }>
            <Styled.StockDetails>{inStockString}</Styled.StockDetails>
            <Styled.AdditionalInformationList $isAudi={ isAudi }>
                <Styled.AdditionalInformationItem>
                    { STRINGS.FOR_YOUR_ATTENTION_LEGAL_TEXT }
                </Styled.AdditionalInformationItem>
                <Styled.AdditionalInformationItem>
                    {additionalInformation}
                </Styled.AdditionalInformationItem>
                <Styled.AdditionalInformationItem>
                    {STRINGS.SUCCESS_WITH_TAX}
                </Styled.AdditionalInformationItem>
                <Styled.AdditionalInformationItem>
                    {STRINGS.MORE_INFO_REGARDING_THE_VEHICLE_YOU_CAN_FIND}
                    <Styled.CarInfoLink to="/car-info" target="_blank">
                        {STRINGS.HERE}
                    </Styled.CarInfoLink>
                </Styled.AdditionalInformationItem>
            </Styled.AdditionalInformationList>
        </Styled.AdditionalInformation>
    );
};

AdditionalInformation.propTypes = {
    inStockString: PropTypes.string.isRequired,
    priceList: PropTypes.number.isRequired,
    priceListDate: PropTypes.string.isRequired,
    manufacturerInHebrew: PropTypes.string.isRequired,
    modelDescription: PropTypes.string.isRequired,
};

export default AdditionalInformation;
