import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import * as Styled from './MobilePriceBar.styles';

const MobilePriceBar = ({ onToggle, totalPrice }) => {
    return (
        <Styled.MobilePriceBar>
            <Styled.Content>
                <Styled.PriceDetails>
                    <Styled.Title>
                        {STRINGS.PAYMENT_TITLE_TOTAL_INCLUDES_VAT}
                    </Styled.Title>
                    <Styled.TotalPrice>
                        { totalPrice.toLocaleString()}
                    </Styled.TotalPrice>
                    <Styled.ToggleButton onClick={ onToggle }>
                        {STRINGS.TO_THE_ORDER_SUM_UP}
                    </Styled.ToggleButton>
                </Styled.PriceDetails>
                <Styled.ToggleRoundButton onClick={ onToggle } />
            </Styled.Content>
        </Styled.MobilePriceBar>
    );
};

MobilePriceBar.defaultProps = {
    onToggle: () => {},
};

MobilePriceBar.propTypes = {
    onToggle: PropTypes.func,
    totalPrice: PropTypes.number.isRequired,
};

export default MobilePriceBar;
