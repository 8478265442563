import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import useIsMobile from '../../../../hooks/useWindowSize';
import { useCurrentAppCommonData } from '../../../../hooks';
import CONFIG from '../../../../config';
import {
    AUDI_INPUT_FIELDS,
} from '../../../AudiConfigurator/Steps/Summary/ContactRepresentativeModal/AudiModalForm.fields';
import ModalForm from './ModalForm/ModalForm';
import {
    SPEAK_WITH_REPRESENTATIVE,
    REVEAL_UNIQUE_ONLINE_PRICE,
    INPUT_FIELDS,
} from './ModalForm/ModalForm.fields';

const { DEVICE_SIZE } = CONFIG;

const speakWithRepresenativeFormHeight = 540;
const revealUniqueOnlinePriceFormHeight = 530;
const speakWithRepresenativeFormWidth = 1090;
const revealUniqueOnlinePriceFormWidth = 500;

const ModalForms = ({
    formNames,
    textInputs,
    checkBoxInputs,
    onChange,
    onCheck,
    onSubmit,
    isVisible,
    onClose,
    isLoading,
}) => {
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const { isAudi } = useCurrentAppCommonData();

    const renderModalForms = () => {
        let title = null;

        let height = null;
    
        let width = null;
    
        let submitButtonTile = STRINGS.SEND;
    
        let description = null;
    
        // Field to show in UI
        let inputFields = INPUT_FIELDS;
    
        let isRightImage = false;

        return formNames.map((formName) => {
            switch (formName) {
                case SPEAK_WITH_REPRESENTATIVE:
                    if (isMobile && isAudi) {
                        title = STRINGS.SPEAK_WITH_REPRESENTATIVE;
                        inputFields = AUDI_INPUT_FIELDS.filter((input) => input.name !== 'fullName');
                        height = speakWithRepresenativeFormHeight;
                        width = speakWithRepresenativeFormWidth;
                        isRightImage = false;

                        break;
                    }
                    title = STRINGS.SPEAK_WITH_REPRESENTATIVE;
                    inputFields =  INPUT_FIELDS.filter((input) => input.name !== 'fullName');
                    height = speakWithRepresenativeFormHeight;
                    width = speakWithRepresenativeFormWidth;
                    isRightImage = true;
                    submitButtonTile = STRINGS.CONTACT_ME_BY_PHONE;
                    description = null;

                    break;
                case REVEAL_UNIQUE_ONLINE_PRICE:
                    title = STRINGS.REVEAL_UNIQUE_ONLINE_PRICE;
                    submitButtonTile = STRINGS.REVEAL_UNIQUE_ONLINE_PRICE;
                    description = STRINGS.FILL_OUT_THE_DETAILS_AND_RECEIVE_A_CODE;
                    inputFields =  INPUT_FIELDS.filter((input) => input.name !== 'email'
                        && input.name !== 'firstName'
                        && input.name !== 'lastName');
                    height = revealUniqueOnlinePriceFormHeight;
                    width = revealUniqueOnlinePriceFormWidth;
                    isRightImage = false;
                    break;
                default:
                    break;
            }

            return (
                <ModalForm
                    title={ title }
                    key={ title }
                    height={ height }
                    width={ width }
                    isLoading={ isLoading }
                    submitButtonTitle={ submitButtonTile }
                    inputs={ textInputs[formName] } // inputs state
                    checkBoxInputs={ checkBoxInputs[formName] }
                    description={ description }
                    formName={ formName }
                    onChangeInput={ onChange }
                    onCheck={ onCheck }
                    onSubmit={ onSubmit }
                    inputFields={ inputFields } // inputs for UI
                    isVisible={ isVisible[formName] }
                    onClose={ () => onClose(formName) }
                    isRightImage={ isRightImage }
                />
            );
        });
    };

    return <>{renderModalForms()}</>;
};

ModalForms.defaultProps = {
    onChange: () => {},
    onCheck: () => {},
    onSubmit: () => {},
    onClose: () => {},
    isVisible: false,
};

ModalForms.propTypes = {
    formNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    textInputs: PropTypes.object.isRequired,
    checkBoxInputs: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onCheck: PropTypes.func,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isVisible: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,

};

export default ModalForms;
