import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../../config';
import { pxToRem } from '../../../../utils/commonUtils';
import { staggerParentConfig } from '../../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const InternalColorConfiguration = styled.div`
  /* border:10px solid red; */
`;
export const InternalColors = styled(motion.ul).attrs({
    ...staggerParentConfig,
})`
  list-style-type: none;
  padding: 0;
  margin: 0;
  ${({ $isAudi }) => $isAudi
  && css`
    display: flex;
    gap: 5px;
  `};
  .PickerItem-inner {
    margin: ${({ $isAudi }) => $isAudi ? '0' : '10px'};
    height:${({ theme }) => theme.steps.color.pickerItemHeight};
    width:${({ theme }) => theme.steps.color.pickerItemWidth};
    min-height:${({ theme }) => theme.steps.color.pickerItemHeight};
    min-width:${({ theme }) => theme.steps.color.pickerItemWidth};
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:flex;
    justify-content:  ${({ $isAudi }) => $isAudi ? 'right' : 'center'};
    flex-wrap:wrap;
  }
`;
export const Disclaimer = styled.p`
  margin: 10px 0;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    text-align:center;
  }
`;
export const LoaderWrapper = styled.div`
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const ErrorWrapper = styled(LoaderWrapper)``;

export const ColorPickerHeader = styled.h2`
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 4px;
`;
