import styled, { css } from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import { COLORS } from '../../../constants';

export const ColorsRangeRulerItem = styled.li`
  font-size:${pxToRem(10)};
  font-weight:bold;
  display:flex;
  justify-content:center;
  align-items:center;
  width:30px;
  height: 21px;
  background-color: ${({ $bg }) => $bg};
  border: 1px solid ${COLORS.BG_COLOR_BORDER_SAFETY_LEVEL_EQUIPMENT};
  color: ${({ $textColor }) => $textColor || COLORS.BG_COLOR_TEXT_SAFETY_LEVEL_EQUIPMENT};
  transform: ${({ $isSelected, $isManyModels }) => $isSelected && !$isManyModels ? 'scale(1.2)' : 'none'};

  &:after{
    ${({ $isSelected, $isManyModels, $bg }) => $isSelected && !$isManyModels && css`
      content:'';
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;   
      border-top: 8px solid ${$bg};
      position:absolute;
      left:50%;
      top:-10px;
      transform: translatex(-50%);
      `}
  }

`;
