import styled from 'styled-components';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';
import { pxToRem } from '../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const Faq = styled.div`
  padding-bottom: 80px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    background-color: ${COLORS.BG_COLOR_INNER_PAGE_MOBILE}
  }
`;

export const Title = styled.h2`
 margin: 2rem 2rem 0 0;
 font-size: ${pxToRem(18)};
 font-weight: bold;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
  margin: 3rem 0 0 0;
  text-align: center;
}
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 850px;
  max-width: 100%;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Paragraph = styled.p`
 margin: 0 2rem 2rem 0;
 font-size: ${pxToRem(38)};
 font-weight: bold;
 @media(max-width:${DEVICE_SIZE.MEDIUM}px){
  margin: 0 0 2rem 0;
  text-align: center;
}
`;
export const Body = styled.div``;
