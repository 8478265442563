import styled from 'styled-components';
import { ContinueThemeButton } from
    '../../../../components/ContinueThemeButton/ContinueThemeButton.styles';
import { COLORS, MENUFACTURS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';
import CONFIG from '../../../../config';

const { DEVICE_SIZE, brand } = CONFIG;

const MARGIN_RIGHT = brand === MENUFACTURS.VOLKSWAGEN_COMMERCIAL ? '0' : '88px';

export const PickUpAndFinancing = styled.div`
  position: relative;
  //
  ${ContinueThemeButton}{
    margin-right: 88px;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    margin-right:0;
  }
  }
`;
export const LoaderContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.p`
  margin:20px 0;
  color:${COLORS.COLOR_ERROR_RED_GENERAL_1};
  font-weight:bold;
  text-align:center;
`;
export const ContactUsForTradeIn = styled.p`
  margin-right: ${MARGIN_RIGHT};
  font-size:${pxToRem(17)};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    margin-right:0;
  }
`;
