/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { spreadAllManufacturingDates } from '../../../../utils/commonUtils';

const CarInfoVolkswagen = ({ ManufacturingDates }) => {
    return (
        <>
            {spreadAllManufacturingDates(ManufacturingDates)}
            <p>מצב מכני של הרכב - מצב חדש ללא נזק בטיחותי</p>
            <p>הרכב לא נרשם במדינת חוץ</p>
            <p> ללא תקלות בטיחותיות סדרתיות </p>
            <p>מספר הקילומטרים של כלל נסיעות הרכב ממועד ייצורו לא עולה על 150 ק"מ</p>
            <p>
                תקופת האחריות: האחריות הניתנת לרכב זה הינה אחריות שנתן היצרן, כמפורט
                בתעודת האחריות הניתנת להורדה מתחתית האתר ובכפוף לאמור בה ובתנאיה (להלן
                "תעודת האחריות"). בנוסף ניתנה מהיבואן עבור הרכב כהטבה ללא תשלום אחריות
                נוספץ מעבר לתקופת האחריות שנתן היצרן.
            </p>
            <p>
                האחריות הנוספת הנה בתנאי תעודת האחריות והיא תקפה ל-100,000 (מאה אלף) ק"מ
                מיום המסירה של הרכב או עד תום השנה השלישית מיום המסירה של הרכב, המוקדם
                מביניהם. שווי ההטבה 1,000 ש"ח כולל מע"מ וניתן להמירה לזיכוי כספי במעמד
                הזמנת הרכב, בתיאום טלפוני עם נציגי החברה
            </p>
        </>
    );
};

CarInfoVolkswagen.propTypes  = {
    ManufacturingDates: PropTypes.array.isRequired,
};

export default CarInfoVolkswagen;
