import styled, { css } from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';

export const Answer = styled.div`
  display: flex;
  align-items: center;
  max-height: 0;
  padding: 0 15px;
  visibility:hidden;
  opacity:0;
  transition:all .2s linear;
  ${({ $isSelected }) => $isSelected
    && css`
      max-height: 1000px;
      visibility:visible;
      padding: 15px;
      opacity:1;
    `}
`;

export const Text = styled.div`
  margin: 0 2.5rem 0 0;
  font-size: ${pxToRem(16)};
  font-weight: 400;
  line-height: 23px;
`;
