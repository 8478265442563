import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TableData.styles';

const TableData = ({ data, bg, fontColor }) => {
    return (
        <Styled.TableData $fontColor={ fontColor } $bg={ bg }>
            {data}
        </Styled.TableData>
    );
};

TableData.defaultProps = {
    bg: null,
    fontColor: null,
    data: null,
};
TableData.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    bg: PropTypes.string,
    fontColor: PropTypes.string,
};

export default TableData;
