import React from 'react';
import PropTypes from 'prop-types';
import { SpecificationsInnerTabItem } from '../../../../constants';
import TabItem from './TabItem/TabItem';
import * as Styled from './InnerTabs.styles';

const InnerTabs = ({
    tabs, onSelect, selectedTab,
}) => {
    const renderTabs = () => {
        return tabs.map((tab) => {
            const { key, headerDescription } = tab;
            const isSelectedTab = selectedTab?.headerDescription === headerDescription;
            
            return (
                <TabItem
                    key={ key }
                    isSelected={ isSelectedTab }
                    onSelect={ () => onSelect(tab) }
                    name={ headerDescription }
                />
            );
        });
    };

    return (
        <Styled.InnerTabs>
            <Styled.Tabs>
                {renderTabs()}
            </Styled.Tabs>
        </Styled.InnerTabs>
    );
};

InnerTabs.defaultProps = {
    tabs: [],
    selectedTab: null,
};
InnerTabs.propTypes = {
    tabs: PropTypes.arrayOf(SpecificationsInnerTabItem),
    onSelect: PropTypes.func.isRequired,
    selectedTab: PropTypes.object,
};

export default InnerTabs;
