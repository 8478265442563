import styled from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Model = styled.div`
  max-width:1600px;
  margin: auto auto 30px auto;
`;
export const ModelHeader = styled.header`
  padding-top: 30px;
  margin-bottom: 30px;
`;

export const ErrorWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModelTitle = styled.h1`
  font-weight: 800;
  font-size: ${pxToRem(37)};
  margin: 0;
  text-align: right;
  padding: 0 20px;

`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

export const ModelList = styled.ul`
  display: flex;
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    justify-content: center;
  }
`;

export const CustomLoader = styled.div`
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: red;
`;
