import React, { useState } from 'react';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import { isArrayEmpty } from '../../../utils/commonUtils';
import { useCurrentBrand } from '../../../hooks';
import { getContactUsPageImage } from '../../../utils/assetsUtils';
import { MUTAGS } from '../../../constants';
import { parseInputFieldsToState } from '../../../utils/formUtils';
import Loader from '../../../components/Loader/Loader';
import { sendLeadWithCustomerConnectionInfo } from '../../../fetch/leads';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import {
    INPUT_FIELDS,
    SELECT_OPTIONS,
    SELECT_NAME,
    TEXT_AREA_NAME,
} from './Form/Form.fields';
import Image from './Image/Image';
import Form from './Form/Form';
import ContactResponse from './ContactResponse/ContactResponse';
import * as Styled from './ContactUs.styles';

const contentMaxLength = 200;

const stateInputFields = {
    ...parseInputFieldsToState(INPUT_FIELDS),
    [SELECT_NAME]: {
        value: SELECT_OPTIONS[0].value,
    },
    [TEXT_AREA_NAME]: {
        value: '',
        maxLength: contentMaxLength - (SELECT_OPTIONS[2].value.trim().length + 3),
    },
};

const CONTACT_US_RESPONSE = {
    NONE: 1,
    SUCCESS: 2,
    FAILURE: 3,
};

const { NONE, SUCCESS, FAILURE } = CONTACT_US_RESPONSE;

const ContactUs = () => {
    const [input, setInput] = useState(stateInputFields);
    const [isLoading, setIsLoading] = useState(false);
    const [contactUsResponse, setContactUsResponse] = useState(NONE);
    const currentBrand = useCurrentBrand();
    const image = getContactUsPageImage(currentBrand);
    const onChangeInputHandler = (e) => {
        const { name, value } = e.target;

        /**
         * Input Form Fields are : @{./Form/Form.fields} : fullName,email,phone
         */

        setInput((prevState) => {
            const currentInput = prevState[name];
            const isValid = currentInput.validator?.(value) ?? true;

            return {
                ...prevState,
                [name]: {
                    ...currentInput,
                    value,
                    isValid,
                    isErrorShown: !isValid,
                },
            };
        });
    };

    const setErrorOnInputHandler = (inputName) => {
        setInput((prevState) => ({
            ...prevState,
            [inputName]: {
                ...prevState[inputName],
                isErrorShown: true,
            },
        }));
    };

    const filterValidateInputFields = () => {
        const inputFields = Object.values(input);

        return inputFields.filter((inputField) => {
            if ('isValid' in inputField && !inputField.isValid) {
                return true;
            }

            return false;
        });
    };

    const returnToContactUs = () => {
        setContactUsResponse(null);
    };

    const contactUs = async (fullName, phone, email, requestedSubject, applyingContent) => {
        setIsLoading(true);
        const result = await sendLeadWithCustomerConnectionInfo(
            fullName,
            '',
            requestedSubject,
            applyingContent,
            email,
            phone?.replace('-', ''),
        );

        setIsLoading(false);
        const isSuccess = result.isSuccess ? SUCCESS : FAILURE;

        setContactUsResponse(isSuccess);
        setInput(stateInputFields);
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        const notValidInputFields = filterValidateInputFields(input);

        if (!isArrayEmpty(notValidInputFields)) {
            // show errors on relevant fields
            notValidInputFields.forEach((inputField) => {
                setErrorOnInputHandler(inputField.name);
            });
        } else {
            const {
                applyingContent, email, fullName, phone, requestedSubject,
            } = input;

            contactUs(
                fullName.value,
                phone.value,
                email.value,
                requestedSubject.value,
                applyingContent.value,
            );
        }
    };
    
    const renderSuccessOrForm = () => {
        const isContactSuccessfull = contactUsResponse === SUCCESS;

        if (isContactSuccessfull || contactUsResponse === FAILURE) {
            return  (
                <ContactResponse
                    isContactSuccessfull={ isContactSuccessfull }
                    onGoBack={ returnToContactUs }
                />
            );
        }

        return (
            <Form
                input={ input }
                inputFields={ INPUT_FIELDS }
                selectOptions={ SELECT_OPTIONS }
                onChangeInput={ onChangeInputHandler }
                onSubmit={ onSubmitHandler }
            />
        );
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <Styled.LoaderContainer>
                    <Loader />
                </Styled.LoaderContainer>
            );
        }
        
        return (
            <Styled.ContentContainer>
                <Image isDefaultImage={ currentBrand === MUTAGS.CHAMPION } image={ image } />
                {renderSuccessOrForm()}
            </Styled.ContentContainer>
        );
    };

    return (
        <Styled.ContactUs>
            <HelmetTitleDescription canonical={ window.location.href } />
            <InnerPageContainer maxWidth={ 980 }>
                {renderContent()}
            </InnerPageContainer>
        </Styled.ContactUs>
    );
};

export default ContactUs;
