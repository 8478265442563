import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const initialState = {
    selectedCar: {},
    selectedStep: null,
    selectedExternalColor: {},
    selectedInternalColor: {},
    selectedHoop: {},
    selectedAddOnGroup: null,
    selectedAddOns: {},
    selectedEquipmentPackage: {},
    selectedAddOnsInformation: {
        isLoading: false,
        error: null,
    },
    isInSummary: false,
    gtmData: [],
};

export const selectedCurrentData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_CHOSEN_CAR:
            return dispatchers.selectChosenCar(state, action);
        case actionTypes.SELECT_CURRENT_STEP:
            return dispatchers.selectCurrentStep(state, action);
        case actionTypes.SELECT_CURRENT_EXTERNAL_COLOR:
            return dispatchers.selectCurrentExternalColor(state, action);
        case actionTypes.SELECT_CURRENT_HOOP:
            return dispatchers.selectCurrentHoop(state, action);
        case actionTypes.SELECT_CURRENT_ADD_ON_GROUP:
            return dispatchers.selectCurrentAddOnGroup(state, action);
        case actionTypes.SELECT_CURRENT_ADD_ON:
            return dispatchers.selectCurrentAddOn(state, action);
        case actionTypes.REMOVE_CURRENT_ADD_ON:
            return dispatchers.deleteSelectedCurrentAddOn(state, action);
        case actionTypes.SELECT_CURRENT_INTERNAL_COLOR:
            return dispatchers.selectCurrentInternalColor(state, action);
        case actionTypes.SELECT_MORE_INFO_MODAL_PENDING:
            return dispatchers.selectMoreInfoModalPending(state);
        case actionTypes.SELECT_MORE_INFO_MODAL_SUCCESS:
            return dispatchers.selectMoreInfoModalSuccess(state, action);
        case actionTypes.SELECT_MORE_INFO_MODAL_FAILURE:
            return dispatchers.selectMoreInfoModalFailure(state, action);
        case actionTypes.SELECT_EQUIPMENT_PACKAGE:
            return dispatchers.selectEquipmentPackage(state, action);
        case actionTypes.INITIALIZE_SELECTED_EQUIPMENT_PACKAGE:
            return dispatchers.initializeSelectedEquipmentPackage(state);
        case actionTypes.INITIALIZE_CURRENT_EXTERNAL_COLOR:
            return dispatchers.initializeCurrenExternalColor(state);
        case actionTypes.INITIALIZE_SELECTED_CURRENT_DATA:
            return dispatchers.initializeSelectedCurrentData(initialState, action);
        case actionTypes.SELECT_IS_IN_SUMMARY:
            return dispatchers.SelectIsInSummary(state, action);
        case actionTypes.INITIALIZE_ALL_CURRENT_SELECTED_DATA:
            return dispatchers.initializeCurrentSelectedData(state);
        default:
            return state;
    }
};
