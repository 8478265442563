import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentAppCommonData,
    useSelectedCurrentData,
} from '../../../../hooks';
import Car360 from '../../../Configurator/Car360/Car360';
import { ImageSwiper } from '../../../Configurator/ImageSwiper';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import STRINGS from '../../../../language';
import InternalColorPresentation from '../../../Configurator/Presentation/Presentation';
import {
    NoPreviewWrapper,
    LoaderWrapper,
} from '../../../Configurator/Presentation/Presentation.styles';
import {
    isArrayAndNotEmpty,
    isObjectEmpty,
} from '../../../../utils/commonUtils';
import Loader from '../../../Loader';
import {
    useCarPlaceHolderImageUrl,
    useInCarImageUrl,
} from '../../../../utils/assets.hooks';
import Image from '../../../Image';

const COVER = 'cover';

const Presentation = ({ configOptions }) => {
    const [isCover, setIsCover] = useState(true);
    const [isSlider, setIsSlider] = useState(false);
    const { selectedInternalColor, selectedCar } = useSelectedCurrentData();
    const { modelGroupCode, trimLevelCode } = selectedCar;
    const {
        carImages,
        link360,
        value: selectedColorValue,
    } = selectedInternalColor;
    const { currentBrand } = useCurrentAppCommonData();
    const inCarImageUrl = useInCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        selectedColorValue,
    );
    const fallbackSrc = useCarPlaceHolderImageUrl(currentBrand);
    const { isLoading, error } = configOptions.internalColors;
    const carHasMedia = isArrayAndNotEmpty(carImages) || !!link360;
    const carHasBoth360AndMedia = isArrayAndNotEmpty(carImages) && !!link360;

    const toggleIframeOrSliderHandler = () => {
        setIsSlider((shown) => !shown);
    };

    const onImageErrorHandler = () => {
        setIsCover(false);
    };

    const renderContent = () => {
        if (!carHasMedia) {
            return (
                <Image
                    showLoading
                    fallbackSrc={ fallbackSrc }
                    src={ inCarImageUrl }
                    alt={ STRINGS.CAR_IMAGE_ALT }
                    onImageError={ onImageErrorHandler }
                    className={ isCover ? COVER : null }
                />
            );
        }

        if (link360 && !isSlider) {
            return <Car360 src={ link360 } />;
        }

        return <ImageSwiper view={ COVER } images={ carImages } />;
    };

    if (error) {
        return (
            <NoPreviewWrapper>
                <ErrorMessage errorMessage={ STRINGS.NO_PREVIEW_IS_AVAILABLE } />
            </NoPreviewWrapper>
        );
    }

    if (isLoading || isObjectEmpty(selectedInternalColor)) {
        return (
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        );
    }

    return (
        <InternalColorPresentation
            isMediaVisible={ isSlider }
            isButtonShown={ carHasBoth360AndMedia }
            isSingleImage={ !carHasMedia }
            onToggle={ toggleIframeOrSliderHandler }
        >
            {renderContent()}
        </InternalColorPresentation>
    );
};

Presentation.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Presentation;
