import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ModelItemsHeader.style';

const ModelItemsHeader = ({
    title,
    onClose,
}) => {
    return (
        <Styled.ModelItemsHeader>
            <Styled.CloseButton onClick={ onClose } />
            <Styled.Title>{ title }</Styled.Title>
        </Styled.ModelItemsHeader>
    );
};

ModelItemsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ModelItemsHeader;
