import React from 'react';
import PropTypes from 'prop-types';
import {
    ROUTES,
    HEADER_NAV_ITEMS_IDS,
    ICONS_NAMES,
} from '../../constants';
import STRINGS from '../../language';
import {
    getCustomerSiteUrlByBrand,
    getFullManufacturer,
    getManufacturerWebsite,
    replacePlaceholdersInTemplate,
} from '../../utils/commonUtils';
import { useCurrentBrand, useIconFromAssets } from '../../hooks';
import DrawerItem from './DrawerItem/DrawerItem';
import Style from './Drawer.styles';

const navItems = [
    {
        id: HEADER_NAV_ITEMS_IDS.ONLINE_MODELS,
        title: STRINGS.OUR_MODELS_ONLINE,
        href: ROUTES.MODELS,
    },
    {
        id: HEADER_NAV_ITEMS_IDS.FAQ,
        title: STRINGS.BUYING_PROCESS,
        href: ROUTES.FAQ,
    },
    {
        id: HEADER_NAV_ITEMS_IDS.CONTACT,
        title: STRINGS.MAKE_CONTACT,
        href: ROUTES.CONTACT,
    },
];

const Drawer = ({ isOpen, onItemClicked }) => {
    const currentBrand = useCurrentBrand();
    const manufacturerName = getFullManufacturer(currentBrand, 'hebrew');
    const toBrandWebsite = replacePlaceholdersInTemplate(
        STRINGS.TO_BRAND_WEBSITE, [manufacturerName],
    );
    const personIcon = useIconFromAssets(ICONS_NAMES.PERSON);

    const renderDrawerLinks = () => {
        return navItems.map((item) => {
            const { id, title, href } = item;

            return (
                <DrawerItem
                    key={ id }
                    title={ title }
                    link={ href }
                    onClick={ onItemClicked }
                />
            );
        });
    };

    return (
        <Style.Container $isOpen={ isOpen }>
            <Style.LinksContainer>
                { renderDrawerLinks() }
                <DrawerItem
                    title={ toBrandWebsite }
                    target="_blank"
                    link={ getManufacturerWebsite(currentBrand) }
                    onClick={ onItemClicked }
                />
                <DrawerItem
                    title={ STRINGS.AUDI_CONNECT }
                    target="_blank"
                    link={ getCustomerSiteUrlByBrand(currentBrand) }
                    icon={ personIcon }
                    onClick={ onItemClicked }
                />
            </Style.LinksContainer>
        </Style.Container>
    );
};

Drawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onItemClicked: PropTypes.func.isRequired,
};

export default Drawer;
