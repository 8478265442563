import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  text-align: center;

  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

export const ErrorMessageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 3rem;
  line-height: 100%;
`;

export const TitleSecondary = styled.span`
  display: inline-block;
  font-weight: bold;
  margin: 0 7px;
`;

export const ErrorMessage = styled.div`
  margin-top: 1rem;
  font-size: 1.5rem;
`;
