import React, {
    createRef, useRef,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LayoutGroup } from 'framer-motion';
import {
    useCurrentAppCommonData,
    useCurrentCar,
    useCurrentConfigOptions,
    useSelectedCurrentData,
} from '../../../hooks';
import { useGoToRoute } from '../../../hooks/utils';
import { isObjectEmpty } from '../../../utils/commonUtils';
import CONFIG from '../../../config';
import { CONFIGURATOR_STEPS, CONFIGURATOR_STEPS as CS } from '../../../constants';
import STRINGS from '../../../language';
import { ConfiguratorStep } from './ConfiguratorStep';
import Styled from './ConfiguratorSteps.style';

const { STEPS }  = CONFIG;

const ConfiguratorSteps = ({
    currentStep,
    currentStepHandler,
    goBackHandler,
}) => {
    const { search } = useLocation();
    const { modelGroupCode: modelGroupCodeFromParams, modelGroup } = useParams();
    const goToRoute = useGoToRoute();
    const {
        onlineCars,
    } = useCurrentAppCommonData();
    const { subModels: { subModels } } = useCurrentConfigOptions();
    const {
        selectedCar,
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
        selectedAddOnGroup,
        isInSummary,
    } = useSelectedCurrentData();
    const {
        hrefToModels, modelDescription,
    } = useCurrentCar();
    const urlParams = new URLSearchParams(search);
    const modelGroupCode =  urlParams.get('modelGroupCode') ?? modelGroupCodeFromParams;

    const carsByModelGroup = onlineCars.filter(
        (car) => car.modelGroup.toLowerCase().replaceAll(' ', '-') === modelGroup,
    );

    const carsByModelGroupCode = onlineCars.filter(
        (car) => car.modelGroupCode === parseInt(modelGroupCode),
    );

    const showModelDescription = !!modelDescription
        && currentStep !== CS.ENGINE_AND_TRIM_LEVEL;

    const scrollRefs = useRef(Object.keys(STEPS).reduce((acc) => {
        acc.push(createRef());

        return acc;
    }, []));

    const showGoBackButton = currentStep !== CONFIGURATOR_STEPS.MODEL;

    const showStepHandler = (stepId) => {
        if (
            (stepId === CS.ENGINE_AND_TRIM_LEVEL
                && ((carsByModelGroup.length  <= 1 && carsByModelGroupCode.length === 0)
                || (carsByModelGroup.length  === 0 && carsByModelGroupCode.length <= 1)))
        ) {
            return false;
        }

        if (stepId === CS.EQUIPMENT_PACKAGES && subModels.length < 2) {
            return false;
        }
        
        return true;
    };

    const showIsFinishedHandler = (stepId) => {
        if (currentStep === stepId) {
          // eslint-disable-next-line no-unused-expressions
          scrollRefs.current[stepId - 1].current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
          });
        }

        switch (stepId) {
            case CS.MODEL:
                if (currentStep === CS.ENGINE_AND_TRIM_LEVEL) {
                    return true;
                }
              
                return !isObjectEmpty(selectedCar);
            case CS.ENGINE_AND_TRIM_LEVEL:
                return !isObjectEmpty(selectedCar);
            case CS.EQUIPMENT_PACKAGES:
                if (currentStep > CS.EQUIPMENT_PACKAGES
                  || !isObjectEmpty(selectedExternalColor)) {
                    return true;
                }
              
                return false;
            case CS.COLOR:
                return !isObjectEmpty(selectedExternalColor);
            case CS.INTERNAL_COLOR:
                return !isObjectEmpty(selectedInternalColor);
            case CS.HOOPS:
                return !isObjectEmpty(selectedHoop);
            case CS.ADD_ONS:
                return !!selectedAddOnGroup;
            case CS.SUMMARY:
                return isInSummary;
            default:
                return true;
        }
    };
  
    const onSelectStep = (stepId) => {
        if (stepId === CS.MODEL) {
            goToRoute('/');
        } else if (stepId === CS.ENGINE_AND_TRIM_LEVEL) {
            goToRoute(hrefToModels);
        } else {
            currentStepHandler(stepId);
        }
    };

    return (
        <Styled.ConfiguratorStepsWrapper>
            { showModelDescription && (
                <Styled.ModelDescription>
                    {modelDescription}
                </Styled.ModelDescription>
            ) }
            <LayoutGroup>
                <Styled.ConfiguratorSteps>
                    {STEPS.map((step) => {
                        const { id, name }  = step;
                  
                        return  (
                            <ConfiguratorStep
                                onSelectStep={ () => onSelectStep(id) }
                                isFinished={ showIsFinishedHandler(id) }
                                isActive={ id === currentStep }
                                isVisible={ () => showStepHandler(id) }
                                key={ id }
                                id={ id }
                                name={ name }
                                stepRef={ scrollRefs.current[id - 1] }
                            />
                        );
                    })}
                </Styled.ConfiguratorSteps>
            </LayoutGroup>
            { showGoBackButton && (
                <Styled.GoBackButton onClick={ goBackHandler }>
                    { STRINGS.BACK }
                </Styled.GoBackButton>
            )}
        </Styled.ConfiguratorStepsWrapper>
    );
};

ConfiguratorSteps.defaultProps = {
    currentStep: null,
    currentStepHandler: () => {},
    goBackHandler: () => {},
};

ConfiguratorSteps.propTypes = {
    currentStep: PropTypes.number,
    currentStepHandler: PropTypes.func,
    goBackHandler: PropTypes.func,
};

export default React.memo(ConfiguratorSteps);
