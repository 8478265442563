import IdentificationAndRegistration from '../../../../screens/Checkout/ProgressScreenSteps/IdentificationAndRegistration/IdentificationAndRegistration';
import PickUpAndFinancing from '../../../../screens/Checkout/ProgressScreenSteps/PickUpAndFinancing/PickUpAndFinancing';
import PaymentAndDownPayment from '../../../../screens/Checkout/ProgressScreenSteps/PaymentAndDownPayment/PaymentAndDownPayment';
import PaymentSuccess from '../../../../screens/Checkout/ProgressScreenSteps/PaymentSuccess/PaymentSuccess';
import PaymentFailure from '../../../../screens/Checkout/ProgressScreenSteps/PaymentFailure/PaymentFailure';
import STRINGS from '../../../../language';
import { CHECKOUT_PAGE_PROGRESS_STEPS } from '../../../../constants';

const {
    IDENTIFICATION_AND_REGISTRATION,
    PICK_UP_AND_FINANCING,
    PAYMENT_AND_DOWN_PAYMENT,
    SUCCESS,
    FAILURE,
} = CHECKOUT_PAGE_PROGRESS_STEPS;

export const PROGRESS_STEPS = [
    {
        step: IDENTIFICATION_AND_REGISTRATION,
        title: STRINGS.IDENTIFICATION_AND_REGISTRATION,
        subTitle: STRINGS.IDENTIFICATION,
        showStep: true,
        ScreenStep: IdentificationAndRegistration,
    },
    {
        step: PICK_UP_AND_FINANCING,
        title: STRINGS.PICK_UP_AND_FINANCING,
        subTitle: null,
        showStep: true,
        ScreenStep: PickUpAndFinancing,
    },
    {
        step: PAYMENT_AND_DOWN_PAYMENT,
        title: STRINGS.PAYMENT_AND_DOWN_PAYMENT,
        subTitle: STRINGS.PAYMENT,
        showStep: true,
        ScreenStep: PaymentAndDownPayment,
    },
    {
        step: SUCCESS,
        title: '',
        subTitle: null,
        showStep: false,
        ScreenStep: PaymentSuccess,
    },
    {
        step: FAILURE,
        title: '',
        subTitle: null,
        icon: null,
        showStep: false,
        ScreenStep: PaymentFailure,
    },
];
