import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PaymentDetail } from '../../constants/propTypes';
import STRINGS from '../../language';
import {
    useCurrentCar,
    useSelectedCurrentData,
    useCurrentAppCommonData,
    useCurrentIsInSummary,
    useActionDispatch,
} from '../../hooks';
import { CONFIGURATOR_STEPS } from '../../constants';
import { getFullManufacturer } from '../../utils/commonUtils';
import PriceItem from './PriceItem/PriceItem';
import AfterDiscountPriceItem from './AfterDiscountPriceItem/AfterDiscountPriceItem';
import Specifications from './Specifications/Specifications';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import AddOns from './AddOns/AddOns';
import OriginalAdditions from './OriginalAdditions/OriginalAdditions';
import EquipmentPackageItem from './EquipmentPackageItem/EquipmentPackageItem';
import * as Styled from './PaymentDetails.styles';

const {
    YOUR_CHOICE,
    ORDER_DETAILS,
    PAYMENT_DETAILS,
    AUDI_PAYMENT_TOTAL_PRICE_LIST,
    PAYMENT_TOTAL_PRICE_LIST,
    PAYMENT_TITLE_MODEL,
    PAYMENT_TITLE_MODEL_WITH_TAXES,
    LICENSE_FEE,
    TOTAL_DISCOUNT_FOR_ONLINE_PURCHASE,
} = STRINGS;

const { SUMMARY } = CONFIGURATOR_STEPS;

const PaymentDetails = ({
    onClose,
    currentBrand,
    totalPrices,
    carPrices,
    licenseFeePrices,
    specifications,
    addOns,
    originalAdditions,
    equipmentPackage,
}) => {
    const {
        selectIsInSummary,
    } = useActionDispatch();
    const { isAudi } = useCurrentAppCommonData();
    const currentCar = useCurrentCar();
    const { selectedStep: currentStep } = useSelectedCurrentData();
    const isInSummary = useCurrentIsInSummary();
    const {
        selectedExternalColor,
        selectedInternalColor,
    } = useSelectedCurrentData();
    const { inStockString: inStockExternalString } = selectedExternalColor;
    const { inStockString: inStockInternalString } = selectedInternalColor;

    useEffect(() => {
        if (currentStep === SUMMARY) {
            selectIsInSummary(true);
        } else {
            selectIsInSummary(false);
        }
    }, [currentStep]);

    const manufacturerInEnglish = getFullManufacturer(currentBrand);
    const manufacturerInHebrew = getFullManufacturer(currentBrand, 'hebrew');
    const { price, total } = totalPrices;
    const {
        modelDescription,
        priceList,
        priceListDate,
        modelGroup,
        links,
    } = currentCar;
    const {
        interiorImage: equipmentPackageImage,
    } = links;
    const parsedBeforeDiscountPrice = parseFloat(price).toLocaleString();
    const parsedAfterDiscountPrice = parseFloat(total).toLocaleString();

    const inStockString = inStockInternalString ?? inStockExternalString;

    const TITLE = isAudi ? ORDER_DETAILS : PAYMENT_DETAILS;

    const CAR_TITLE = isAudi ?  <Styled.AudiCarTitleText $isBold $isInSummary={ isInSummary }>{`${modelGroup}`}</Styled.AudiCarTitleText>
        : <Styled.CarTitleText>{`My ${manufacturerInEnglish} `}</Styled.CarTitleText>;

    const TOTAL_SUM_STRING = isAudi ?  AUDI_PAYMENT_TOTAL_PRICE_LIST
        : PAYMENT_TOTAL_PRICE_LIST;

    const renderDefaultOrAudiTitle = () => (
        <Styled.CarTitle $isAudi={ isAudi } $isInSummary={ isInSummary }>
            {CAR_TITLE}
            <Styled.CarTitleText $isBold $isAudi={ isAudi }>{modelDescription}</Styled.CarTitleText>
        </Styled.CarTitle>
    );

    return (
        <Styled.PaymentDetails $isAudi={ isAudi } $isInSummary={ isInSummary }>
            {onClose && (
                <Styled.Header $isAudi={ isAudi }>
                    <Styled.CloseButton onClick={ onClose } $isAudi={ isAudi }>X</Styled.CloseButton>
                    <Styled.Title $isAudi={ isAudi }>{TITLE}</Styled.Title>
                </Styled.Header>
            )}
            <Styled.Content $isAudi={ isAudi } $isInSummary={ isInSummary }>
                {renderDefaultOrAudiTitle()}
                {isAudi && (
                    <Styled.Title $isAudi={ isAudi }>{YOUR_CHOICE}</Styled.Title>
                )}
                <Styled.Body $isAudi={ isAudi }>
                    <PriceItem
                        name={ isAudi ? PAYMENT_TITLE_MODEL_WITH_TAXES : PAYMENT_TITLE_MODEL }
                        prices={ carPrices }
                        isAudi={ isAudi }
                    />
                    <Specifications specifications={ specifications } />
                    { equipmentPackage && (
                        <EquipmentPackageItem
                            equipmentPackage={ equipmentPackage }
                            equipmentPackageImage={ equipmentPackageImage }
                        />
                    ) }
                    <OriginalAdditions originalAdditions={ originalAdditions } />
                    <AddOns addOns={ addOns } />
                    <PriceItem name={ LICENSE_FEE } prices={ licenseFeePrices } isAudi={ isAudi } />
                    {totalPrices.isDiscount && (
                        <AfterDiscountPriceItem
                            name={ TOTAL_DISCOUNT_FOR_ONLINE_PURCHASE }
                            prices={ totalPrices }
                        />
                    )}
                    <Styled.TotalSum $isAudi={ isAudi }>
                        <Styled.TotalString>{TOTAL_SUM_STRING}</Styled.TotalString>
                        <Styled.TotalPriceContainer>
                            {parsedBeforeDiscountPrice !== parsedAfterDiscountPrice && (
                                <Styled.TotalPriceBeforeDiscount>
                                    {parsedBeforeDiscountPrice}
                                </Styled.TotalPriceBeforeDiscount>
                            )}
                            <Styled.TotalPrice>{parsedAfterDiscountPrice}</Styled.TotalPrice>
                        </Styled.TotalPriceContainer>
                    </Styled.TotalSum>

                    { (!isInSummary || !isAudi) && (
                        <AdditionalInformation
                            priceList={ priceList }
                            manufacturerInHebrew={ manufacturerInHebrew }
                            modelDescription={ modelDescription }
                            priceListDate={ priceListDate }
                            inStockString={ inStockString }
                        />
                    )}
                    
                </Styled.Body>
            </Styled.Content>
        </Styled.PaymentDetails>
    );
};

PaymentDetails.defaultProps = {
    onClose: null,
    specifications: [],
    addOns: [],
    originalAdditions: [],
    equipmentPackage: undefined,
};

PaymentDetails.propTypes = {
    onClose: PropTypes.func,
    currentBrand: PropTypes.string.isRequired,
    totalPrices: PropTypes.object.isRequired,
    carPrices: PropTypes.object.isRequired,
    licenseFeePrices: PropTypes.object.isRequired,
    addOns: PropTypes.arrayOf(PaymentDetail),
    specifications: PropTypes.arrayOf(PaymentDetail),
    originalAdditions: PropTypes.array,
    equipmentPackage: PropTypes.string,
};

export default React.memo(PaymentDetails);
