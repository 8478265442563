import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Form from '../Form/Form';
import FormSubTitle from '../FormSubTitle/FormSubTitle';
import FormTitle from '../FormTitle/FormTitle';
import STRINGS from '../../../../../language';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import InputFormGroup from '../InputFormGroup/InputFormGroup';
import CustomTextInput from '../../../../../components/CustomTextInput/CustomTextInput';
import Loader from '../../../../../components/Loader';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import ContinueThemeButton from '../../../../../components/ContinueThemeButton/ContinueThemeButton';
import {
    CHECKOUT_PAGE_FORMS,
    ICONS_NAMES,
    IS_VERIFIED_AUTHORIZATION,
} from '../../../../../constants';
import { resendSms } from '../../../../../fetch/authentication';
import { useActionDispatch, useCurrentAppCommonData, useCurrentBrand } from '../../../../../hooks';
import IconAndSubTitle from '../../../IconAndSubTitle';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import CustomOtpInput from '../../../../../components/OtpInput/CustomOtpInput';
import useAutoOtpCredentials from '../../../../../hooks/useAutoOtpCredentials';
import Action from './Action/Action';
import { OTP_FORM_GROUP } from './OtpForm.config';
import * as Styled from './OtpForm.styles';

const { IDENTIFY_ICON, IDENTIFY_ICON_AUDI } = ICONS_NAMES;

const { REGISTRATION_FORM } = CHECKOUT_PAGE_FORMS;

const OtpForm = ({ onSelectForm }) => {
    const [input, setInput] = useState(OTP_FORM_GROUP);
    const [isResendingSms, setIsResendingSms] = useState(false);
    const currentBrand = useCurrentBrand();
    const { isAudi } = useCurrentAppCommonData();
    const [showScreenMsg, setShowScreenMsg] = useState(false);
    const { checkOtpAsync, initializeOtp } = useActionDispatch();
    const {
        authenticationReducer: authState,
    } = useSelector((state) => state);
    const { otp, auth } = authState;
    const { isLoading: isCheckingOtp, maskedPhoneNumber, error: otpError } = otp;
    const { token } = auth;
    const smsCodeFormDescription = replacePlaceholdersInTemplate(
        STRINGS.A_MESSAGE_WITH_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO,
        [maskedPhoneNumber],
    );
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const screenErrMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );

    const errorMsg = otpError?.message ?? input.errorMessage;
    const showError = otpError?.message ?? input.isErrorShown;

    const icon = isAudi ? IDENTIFY_ICON_AUDI : IDENTIFY_ICON;

    const onChangeInputHandler = useCallback((e) => {
        const $this = e.target;
        const inputValue = $this.value;
        const { validator } = input;
        const isValid = validator(inputValue);

        setInput((prevInput) => {
            return {
                ...prevInput,
                value: inputValue,
                isValid,
                isErrorShown: !isValid,
            };
        });
    }, [input]);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const otpCode = input.value;

        checkOtpAsync(otpCode, () => {
            sessionStorage.setItem(IS_VERIFIED_AUTHORIZATION, true);
        }, true);
    };

    const resendSmsAsync = async  () => {
        setIsResendingSms(true);
        const isSuccess = await resendSms();

        if (isSuccess) {
            initializeOtp();
            setInput((prevInput) => {
                return {
                    ...prevInput,
                    value: '',
                };
            });
        } else {
            setShowScreenMsg(true);
        }
        setIsResendingSms(false);
    };

    useEffect(() => {
        if (token) {
            /** listen to the token, if it changes, initialize otp state */
            initializeOtp();
        }
    }, [token]);

    useAutoOtpCredentials((otpValue) => {
        const { validator } = input;
        const isValid = validator(otpValue);

        setInput((prevInput) => {
            return {
                ...prevInput,
                value: otpValue,
                isValid,
                isErrorShown: !isValid,
            };
        });

        checkOtpAsync(otpValue, () => {
            sessionStorage.setItem(IS_VERIFIED_AUTHORIZATION, true);
        }, true);
    });

    const renderSubTitleOrError = () => {
        if (showError && !isAudi) {
            return <ErrorMessage color="red" errorMessage={ errorMsg } />;
        }
        
        return <FormSubTitle subTitle={ STRINGS.THE_CODE_I_HAVE_RECEIVED } />;
    };

    const handleOtpInputChange = (newOtp) => {
        const { validator } = input;
        const isValid = validator(newOtp);

        setInput((prevInput) => {
            return {
                ...prevInput,
                value: newOtp,
                isValid,
                isErrorShown: !isValid,
            };
        });
    };
    
    const renderInput = () => {
        const Input = isAudi ? CustomTextInput : InputFormGroup;
        const {
            name,
            value,
            label,
            isRequired,
        } = input;
        
        return (
            <>
                <Styled.DesktopWrapper $isAudi={ isAudi }>
                    <Input
                        inputName={ name }
                        inputValue={ value }
                        onChange={ onChangeInputHandler }
                        label={ label }
                        isRequired={ isRequired }
                        isErrorShown={ showError }
                        errorMessage={ errorMsg }
                        isAutoFocused
                    />
                </Styled.DesktopWrapper>
                { isAudi && (
                    <Styled.MobileWrapper>
                        <CustomOtpInput
                            value={ input.value }
                            onChange={ handleOtpInputChange }
                            numInputs={ 6 }
                            errorMessage={ errorMsg }
                            hasErrored={ showError }
                        />
                    </Styled.MobileWrapper>
                )}
            </>
        );
    };

    const renderOtpForm = () => {
        if (showScreenMsg) {
            return   <ErrorMessage color="red" errorMessage={ screenErrMsg } />;
        }
        if (isResendingSms || isCheckingOtp) {
            return (
                <Styled.LoaderContainer>
                    <Loader />
                </Styled.LoaderContainer>
            );
        }

        const {
            isValid,
        } = input;

        const STEP_TITLE = isAudi
            ? STRINGS.AUDI_HAVE_YOU_RECEIVED_AN_SMS_CODE : STRINGS.HAVE_YOU_RECEIVED_AN_SMS_CODE;

        return (
            <>
                <Styled.StepHeadContainer $isAudi={ isAudi }>
                    <Styled.IconAndSubTitleWrapper>
                        <IconAndSubTitle
                            icon={ getIconFromAssets(icon) }
                            subTitle={ STRINGS.IDENTIFICATION }
                        />
                    </Styled.IconAndSubTitleWrapper>
                    <Styled.TitleContainer>
                        <FormTitle title={ STEP_TITLE } />
                        <FormSubTitle subTitle={ smsCodeFormDescription } />
                    </Styled.TitleContainer>
                </Styled.StepHeadContainer>
                <Styled.DesktopWrapper>
                    <FormSubTitle subTitle={ STRINGS.INSERT_THE_CODE_YOU_HAVE_RECEIVED } />
                </Styled.DesktopWrapper>
                <Form onSubmit={ onSubmitHandler } action="/" >
                    { renderSubTitleOrError() }
                    { renderInput() }
                    <Action
                        actionText={ STRINGS.I_HAVE_NOT_RECEIVED_THE_CODE }
                        buttonText={ isAudi ? STRINGS.AUDI_SEND_IT_AGAIN : STRINGS.SEND_IT_AGAIN }
                        onAction={ resendSmsAsync }
                        fontSize={ 19 }
                    />
                    <Action
                        actionText={ STRINGS.I_HAVE_AN_OTHER_PHONE_NUMBER }
                        buttonText={ isAudi
                            ? STRINGS.AUDI_I_WHOULD_LIKE_TO_UPDATE
                            : STRINGS.I_WHOULD_LIKE_TO_UPDATE }
                        onAction={ () => onSelectForm(REGISTRATION_FORM) }
                        fontSize={ 18 }
                    />
                    <ContinueThemeButton
                        isDisabled={ !isValid }
                        title={ STRINGS.CONTINUE }
                        isAudi={ isAudi }
                    />
                </Form>
            </>
        );
    };

    return (
        <Styled.OtpForm $isAudi={ isAudi }>
            {renderOtpForm()}
        </Styled.OtpForm>
    );
};

OtpForm.propTypes = {
    onSelectForm: PropTypes.func.isRequired,
};

export default OtpForm;
