import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ToolTip = styled(ReactTooltip)`
  &.extraClass {
    border-radius: 8px;
    padding: 0.25rem;
    text-align: center;
    box-shadow: 5px 5px 20px 0px #63636378;
    opacity: 1 !important;
  }
`;

const DataContainer = styled.div`
  padding: 4px;
  text-align: start;
`;

const Styled = {
    ToolTip,
    DataContainer,
};

export default Styled;
