import { CONTACT_US_OPTIONS_VALUES } from '../../../../constants';
import { isEmail, isPhonenumber } from '../../../../utils/commonUtils';
import STRINGS from '../../../../language';

const {
    CUSTOMER_CARE,
    TECHNICAL,
    FUNDING_AND_INSURANCE,
    ORDER,
} = CONTACT_US_OPTIONS_VALUES;

export const INPUT_FIELDS =  [
    {
        name: 'fullName',
        type: 'text',
        value: '',
        label: STRINGS.FULL_NAME,
        isRequired: true,
        validator: (value) => value.trim().length > 0,
        isValid: false,
        errorMessage: STRINGS.THIS_IS_REQUIRED_FIELD,
        isErrorShown: false,
        maxLength: 1000,
    },
    {
        name: 'email',
        type: 'email',
        value: '',
        label: STRINGS.EMAIL_ADDRESS,
        isRequired: true,
        validator: (value) => isEmail(value),
        isValid: false,
        errorMessage: STRINGS.PLEASE_FILL_OUT_YOUR_EMAIL,
        isErrorShown: false,
        maxLength: 1000,
    },
    {
        name: 'phone',
        type: 'tel',
        value: '',
        validator: (value) => isPhonenumber(value) || value.length === 0,
        label: STRINGS.PHONE,
        isRequired: false,
        isValid: true,
        errorMessage: STRINGS.PHONE_NUM_FIELD_MUST_BE_VALID_AND_10_CHARACTERS,
        isErrorShown: false,
        maxLength: 10,
    },
];

export const SELECT_OPTIONS = [
    { text: STRINGS.ORDER, value: ORDER },
    { text: STRINGS.CUSTOMER_SERVICE, value: CUSTOMER_CARE },
    { text: STRINGS.TECHNICAL_PROBLEM, value: TECHNICAL },
    { text: STRINGS.CAR_FINANCING_AND_INSURANCE, value: FUNDING_AND_INSURANCE },
];

export const SELECT_NAME = 'requestedSubject';
export const TEXT_AREA_NAME = 'applyingContent';
