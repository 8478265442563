import styled, { css } from 'styled-components';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { pxToRem } from '../../../../../utils/commonUtils';
import {
    GoBackButton as AudiGoBackButton,
} from '../../../GoBackButton/GoBackButton.styles';

const { AUDI_ADDON_GROUP_SEPERATOR } = COLORS;

const { DEVICE_SIZE } = CONFIG;

export const MobileAddOns = styled.div`
width: 100%;
height: 100%;
padding-bottom: 60px;
@media (min-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
    visibility: hidden;
  }
`;

export const AddonsSection = styled.div`
width: 100%;
border-bottom: 1px solid ${AUDI_ADDON_GROUP_SEPERATOR};
@media (min-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;

export const AddOnGroupTitle = styled.div`
width: 100%;
margin-top: 30px;
padding-bottom: 5px;
`;

export const GoBackButtonMobileWrapper = styled.div`
 display: none;
 align-items: center;
 padding: 10px;
 @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
  }
`;

export const AudiGoBackButtonMobile = styled(AudiGoBackButton)`
  position: static;
  margin-left: 10px;
`;

export const StepTitle = styled.p`
  position: absolute;
  font-weight: 700;
  font-size:${pxToRem(17)};
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const AddOnGroupTitleAndIcon = styled.div`
margin-right: 10px;
display: flex;
align-items: center;
gap: 10px;
&:hover{
   cursor: pointer;
}
`;

export const Title = styled.h4`

`;

export const Icon = styled.span`
width: 42px;
height: 42px;
display: inline-block;
border-radius: 50%;
border: 1px solid black;
background-image: url(${({ $IconUrl }) => $IconUrl});
background-size: cover;

`;

export const AddOns = styled.div`
  max-height: 0px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  ${({ $isActive }) => $isActive && css`
    transition: all 0.3s ease-in-out;
    opacity: 1;
    max-height: 1000px;
    pointer-events: all;
  `};
`;
