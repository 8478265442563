import styled from 'styled-components';
import { COLORS, ICONS_NAMES } from '../../constants';
import {
    InputFormGroup,
    Label as InputLabel,
} from '../InputFormGroup/InputFormGroup.styles';

import { getIconFromAssets } from '../../utils/assetsUtils';

const selectArrowIcon = getIconFromAssets(ICONS_NAMES.SELECT_ARROW);

export const SelectFormGroup = styled(InputFormGroup)`
  & #requestedSubject {
    padding: 0;
    border: 0;
    border-bottom: 1px solid ${COLORS.BLACK};
    height: 28px;
    font-size: 17px;
    font-weight: 500;
    outline: none;
    background-size: 37px 70px;
    background-color: transparent;
    background: transparent url(${selectArrowIcon}) no-repeat 0% 45%;
    box-shadow: none !important;
    appearance: none;
    cursor: pointer;
    &::-ms-expand {
      display: none;
    }
  }
`;
export const Label = styled(InputLabel)``;
