import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const MainCharacteristics = styled.div``;

export const MainCharacteristicsTitle = styled.h6.attrs(() => ({
    role: 'button',
}))`
  font-weight: 500;
  font-size: ${pxToRem(13)};
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  &:before {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    background-image:url(${({ $icon })  => $icon});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    margin: 0 3px 0 6px;
    vertical-align: middle;
  }
`;

export const TitleAndSpecificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Specification  = styled.p`
  font-size: ${pxToRem(13)};
  color:${({ theme }) => theme.primaryColor};
  font-weight: 500;
  line-height: 1;
  text-decoration:none;
  display: flex;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 0;
  cursor: pointer;
  &:before{
      content:'';
      display:block;
      background-image:url(${({ $icon }) => $icon});
      background-repeat: no-repeat;
      background-position: center;
      background-size:contain;
      width:14px;
      height:14px;
      margin: 0 3px 0 3px;
  }
`;

export const CharacteristicsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-right: 6px;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  margin-top: 0;
  ${({ $isActive }) => $isActive
    && css`
      margin-top: 20px;
      margin-right: 20px;
      opacity: 1;
      max-height: 800px;
      visibility: visible;
    `}
`;

export const CharacteristicsItem = styled.li`
  font-weight: 500;
  font-size: ${pxToRem(13)};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:before {
    content: "";
    background-color: ${COLORS.COLOR_CHARACTERISTIC_ITEM_BEFORE};
    display: block;
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    margin-left: 15px;
  }
`;
