import React from 'react';
import PropTypes from 'prop-types';
import { EquipmentScreenItemType } from '../../../../../constants/propTypes';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import { isArrayEmpty } from '../../../../../utils/commonUtils';
import STRINGS from '../../../../../language';
import EquipmentScreenItem from './EquipmentScreenItem/EquipmentScreenItem';
import * as Styled from './EquipmentScreen.styles';

const EquipmentScreen = ({ screenData }) => {
    if (isArrayEmpty(screenData)) {
        return <ErrorMessage errorMessage={ STRINGS.NO_DATA_TO_DISPLAY } />;
    }

    const renderEngineData = () => {
        return screenData.map((dataItem) => {
            const { key, lineDescription, headerDescription } = dataItem;

            const isAvailable = lineDescription;
                        
            return (
                <EquipmentScreenItem
                    key={ key }
                    name={ headerDescription }
                    isAvailable={ isAvailable }
                />
            );
        });
    };
    
    return (
        <Styled.EquipmentScreen>
            {renderEngineData()}
        </Styled.EquipmentScreen>
    );
};

EquipmentScreen.defaultProps = {
    screenData: [],
};

EquipmentScreen.propTypes = {
    screenData: PropTypes.arrayOf(EquipmentScreenItemType),
};

export default EquipmentScreen;
