import styled from 'styled-components';
import CONFIG from '../../../config';
import { pxToRem } from '../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const EngineAndTrimLevel = styled.div`
    max-width: 1600px;
    margin: auto auto 30px auto;
`;
export const Header = styled.header`
  padding-top: 30px;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: ${pxToRem(37)};
  margin: 0;
  text-align: center;
`;

export const SubTitle = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem(24)};
  margin: 10px 0 0 0;
  text-align: center;
`;

export const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    justify-content: center;
  }
`;

export const ErrorWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;
