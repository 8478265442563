import styled from 'styled-components';
import { ReactComponent as Info } from '../../../../../assets/icons/info.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 24px;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.additionalOptionLabel};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
    width: 18,
    height: 18,
    stroke: theme.primaryColor,
}))`
  min-width: 18px
`;

const Dummy = styled.div`
  height: 1px;
`;

const Styled = {
    Wrapper,
    Container,
    Label,
    InfoIcon,
    Dummy,
};

export default Styled;
