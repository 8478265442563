import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../../hooks';
import { getHomePageThumbnail } from '../../../../utils/assetsUtils';
import STRINGS from '../../../../language';
import { replacePlaceholdersInTemplate } from '../../../../utils/commonUtils';
import modelCarShape from '../modelItemShape';
import MainCharacteristicsAndSpecification
    from './MainCharacteristicsAndSpecification/MainCharacteristicsAndSpecification';
import FullPriceAndTooltip from './FullPriceAndTooltip/FullPriceAndTooltipt';
import CarRank from './CarRank/CarRank';
import HeaderDesktop from './HeaderDesktop/HeaderDesktop';
import LicenseFeePrice from './LicenseFeePrice/LicenseFeePrice';
import HeaderMobile  from './HeaderMobile/HeaderMobile';
import * as Styled from './ModelItemCollapsible.styles';

const ModelItemCollapsible = ({
    model, isActive, onSelect, brand, isCupra,
}) => {
    const { isAudi } = useCurrentAppCommonData();

    const {
        price: carPrice,
        id,
        licenseFee,
        mainCharacteristics,
        modelDescription,
        modelCode,
        description,
        safetyEquipmentLevel,
        originalAdditionsCode,
        carVersion,
        pollutionLevel,
        hrefToConfigurator,
        modelGroupCode,
        trimLevelCode,
        carHasDiscount,
        arrivalDate,
    } = model;

    const price = parseFloat(carPrice).toLocaleString();
    const licenseFeePrice = parseFloat(licenseFee).toLocaleString();
    const fullPrice = parseFloat(licenseFee) + parseFloat(carPrice);
    const figCaption = modelDescription;
    const imageSrc = getHomePageThumbnail(brand, modelGroupCode, trimLevelCode);
    const imageAlt = replacePlaceholdersInTemplate(
        STRINGS.PREVIEW_OF_MODEL_DESCRIPTION,
        [modelDescription],
    );
        
    const hideTooltip = isCupra || !carHasDiscount;

    return (
        <Styled.ModelItemCollapsible $isSelected={ isActive } id={ id }>
            <Styled.Car>
                <HeaderDesktop
                    imageAlt={ imageAlt }
                    imageSrc={ imageSrc }
                    brand={ brand }
                    figCaption={ figCaption }
                    href={ hrefToConfigurator }
                />
                <HeaderMobile
                    imageAlt={ imageAlt }
                    imageSrc={ imageSrc }
                    brand={ brand }
                    title={ figCaption }
                    description={ description }
                    fullPrice={ fullPrice.toLocaleString() }
                    onSelect={ () => onSelect(id) }
                />
                <Styled.ToggleableContentMobile $isActive={ isActive }>
                    <Styled.Body>
                        <Styled.Description>{description}</Styled.Description>
                        <FullPriceAndTooltip
                            hideTooltip={ hideTooltip }
                            fullPrice={ fullPrice.toLocaleString() }
                        />
                        <Styled.ArrivelDate> { arrivalDate } </Styled.ArrivelDate>
                        <MainCharacteristicsAndSpecification
                            mainCharacteristics={ mainCharacteristics }
                            modelCode={ modelCode }
                            groupModelCode={ modelGroupCode }
                            modelDescription={ modelDescription }
                            version={ carVersion }
                            id={ id }
                            trimLevelCode={ trimLevelCode }
                            originalAdditionsCode={ originalAdditionsCode }
                        />
                    </Styled.Body>
                    <Styled.Footer>
                        <Styled.SelectButton
                            to={ hrefToConfigurator }
                            $isAudi={ isAudi }
                            $isCupra={ isCupra }
                        >
                            {STRINGS.CHOOSE}
                        </Styled.SelectButton>
                        <Styled.FullPriceDescriptionMobileOnly>
                            {STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                        </Styled.FullPriceDescriptionMobileOnly>
                        <LicenseFeePrice price={ price } licenseFee={ licenseFeePrice } />
                        <CarRank
                            safety={ safetyEquipmentLevel }
                            pollution={ pollutionLevel }
                        />
                    </Styled.Footer>
                </Styled.ToggleableContentMobile>
            </Styled.Car>
        </Styled.ModelItemCollapsible>
    );
};

ModelItemCollapsible.defaultProps = {
    isActive: false,
    onSelect: () => {},
};

ModelItemCollapsible.propTypes = {
    model: PropTypes.shape(modelCarShape).isRequired,
    isActive: PropTypes.bool,
    onSelect: PropTypes.func,
    brand: PropTypes.string.isRequired,
    isCupra: PropTypes.bool.isRequired,
};

export default ModelItemCollapsible;
