import * as actionTypes from '../actionTypes';
import { sendSms, checkOtp, resendSms } from '../../fetch/authentication';
import STRINGS from '../../language';
import {
    CUSTOMER_NAME_AUTHORIZATION,
    PHONE_NUMBER_AUTHORIZATION,
} from '../../constants';
import { saveCustomerPhoneNumber } from './checkout';

const sendSmsPending = () => ({
    type: actionTypes.SEND_SMS_PENDING,
});

const sendSmsSuccess = () => ({
    type: actionTypes.SEND_SMS_SUCCESS,
});

const sendSmsFailure = (error = null) => ({
    type: actionTypes.SEND_SMS_FAILURE,
    error,
});

export const saveToken = (token) => ({
    type: actionTypes.SAVE_TOKEN,
    token,
});

const isAuthenticatedPending = () => ({
    type: actionTypes.IS_AUTHENTICATED_PENDING,
});

export const isAuthenticatedSuccess = () => ({
    type: actionTypes.IS_AUTHENTICATED_SUCCESS,
});

const isAuthenticatedFailure = (error = null) => ({
    type: actionTypes.IS_AUTHENTICATED_FAILURE,
    error,
});

const checkOtpPending = () => ({
    type: actionTypes.CHECK_OTP_PENDING,
});

export const checkOtpSuccess = () => ({
    type: actionTypes.CHECK_OTP_SUCCESS,
});

const checkOtpFailure = (error = null, isMaxAttempts) => ({
    type: actionTypes.CHECK_OTP_FAILURE,
    error,
    isMaxAttempts,
});

export const sendSmsAsync = (fullName, phoneNumber) => {
    return async (dispatch) => {
        dispatch(sendSmsPending());
        try {
            await sendSms(fullName, phoneNumber);

            dispatch(saveCustomerPhoneNumber(phoneNumber));
            dispatch(sendSmsSuccess());
          
            return true;
        } catch (error) {
            dispatch(sendSmsFailure(error));
            sessionStorage.removeItem(PHONE_NUMBER_AUTHORIZATION);
            sessionStorage.removeItem(CUSTOMER_NAME_AUTHORIZATION);
            
            return false;
        }
    };
};

export const resendSmsAsync = () => {
    return async (dispatch) => {
        dispatch(sendSmsPending());
        try {
            await resendSms();
            dispatch(sendSmsSuccess());

            return true;
        } catch (error) {
            dispatch(sendSmsFailure(error));
            
            return false;
        }
    };
};

export const isAuthenticatedAsync = (authorizationToken) => {
    return async (dispatch) => {
        dispatch(isAuthenticatedPending());
        if (authorizationToken) {
            dispatch(isAuthenticatedSuccess());
        } else {
            dispatch(isAuthenticatedFailure(STRINGS.YOU_ARE_NOT_AUTHENTICATED));
            // throw new AppError(STRINGS.YOU_ARE_NOT_AUTHENTICATED);
        }
        // try {
        //     // const response = await isAuthenticated();
        //     const { data } = response;

        //     const parsedResponse = parseResponse(data);

        //     const { isSuccess: isUserAuthenticated } = parsedResponse;
           
        //     if (!isUserAuthenticated) {
        //         /** the user is not authenticated */
        //         throw new AppError(STRINGS.YOU_ARE_NOT_AUTHENTICATED);
        //     }

        //     dispatch(isAuthenticatedSuccess());
        // } catch (error) {
        //     dispatch(isAuthenticatedFailure(error));
        // }
    };
};

export const checkOtpAsync = (
    otp, afterSuccessCallback = undefined, shouldAuthenticate = false,
) => {
    return async (dispatch) => {
        dispatch(checkOtpPending());

        try {
            await checkOtp(otp);
            dispatch(checkOtpSuccess());
            if (shouldAuthenticate) {
                dispatch(isAuthenticatedSuccess());
            }
            if (afterSuccessCallback) {
                afterSuccessCallback();
            }
        } catch (error) {
            dispatch(checkOtpFailure(error, false));
            if (shouldAuthenticate) {
                dispatch(isAuthenticatedFailure(STRINGS.YOU_ARE_NOT_AUTHENTICATED));
            }
        }
    };
};

export const initializeAuthentication = () => ({
    type: actionTypes.INITIALIZE_AUTHENTICATION,
});

export const initializeOtp = () => ({
    type: actionTypes.INITIALIZE_OTP,
});

export const initializeOtpVerification = () => ({
    type: actionTypes.INITIALIZE_OTP_VERIFICATION,
});

export const OtpVerificationSuccess = () => ({
    type: actionTypes.OTP_VERIFICATION_SUCCESS,
});

export const setMaskedPhoneNumber = (maskedPhoneNumber) => ({
    type: actionTypes.SET_MASKED_PHONE_NUMBER,
    maskedPhoneNumber,
});

export const initializeSms = () => ({
    type: actionTypes.INITIALIZE_SMS,
});
