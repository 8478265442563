import styled from 'styled-components';
import CONFIG from '../../../../config';
import {
    GoBackButton as AudiGoBackButton,
} from '../../GoBackButton/GoBackButton.styles';

const { DEVICE_SIZE } = CONFIG;

export const ConfiguratorLayout = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
`;

export const ConfiguratorContent = styled.section`
  display: flex;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column-reverse;
  }
`;

export const AudiGoBackButtonDesktop = styled(AudiGoBackButton)`
  position: unset;
  margin-top: 50px;
  margin-right: 30px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display:none;
  }
`;
