import styled, { css } from 'styled-components';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const IconAndSubTitle = styled.div`
  font-size:${pxToRem(16)};
  font-weight:500;
  flex: 0 1 12%;
  padding: 0 20px;
  text-align:center;

  &:after{
    content:'';
    display:block;
    margin: 18px auto 8px auto;
    width:48px;
    height:48px;
    background-image:url(${({ $icon })  => $icon});
    background-position: center;
    background-repeat: no-repeat;
  }
  ${({ $isAudi }) => $isAudi && css`
    &:before{
      content:'';
      display:block;
      margin: auto 0 18px 0;
      @media(min-width:${DEVICE_SIZE.LARGE}px){
        width:75px;
      }
      width:48px;
      height:48px;
      background-image:url(${({ $icon })  => $icon});
      background-position: center;
      background-repeat: no-repeat;
    }
    &:after {
      content: none;
    }
  `}
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding: 0 0 0 20px;
    ${({ $isAudi }) => !$isAudi && css`
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding:0;
      margin-bottom:15px;
      font-size:${pxToRem(18)};
      font-weight:600;
      &:after {
        margin:0;
      }
      &:before { 
        margin: 0;
      }
  `}
 }
`;
