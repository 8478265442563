import styled, { css } from 'styled-components';
import { pxToRem } from '../../../../../../utils/commonUtils';
import CONFIG from '../../../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const AudiAddOrRemoveButton = styled.div`
  position: relative;
  width: 90px;
  padding: 2px 0;
  font-size: ${pxToRem(15)};
  font-weight: 400;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isSelected, theme }) => $isSelected ? 'white' : theme.primaryColor};
  border: 1px solid ${({ $isSelected, theme }) => $isSelected ? 'black' : theme.primaryColor};
  color: ${({ $isSelected }) => $isSelected ? 'black' : 'white'};
  cursor: pointer;
  transition: all 0.3s;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100px;
    padding: 4px 0;
    font-size: ${pxToRem(18)};
  }
  ${({ $isDisabled }) => $isDisabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;
