import styled from 'styled-components';
import { COLORS } from '../../../../../../constants';
import { pxToRem } from '../../../../../../utils/commonUtils';
import { Select } from '../../../../../Select/Select.styles';

export const PhoneInput = styled.div`
 display:flex;
 flex-direction:column;
 margin-bottom:22px;

  & .input-tel{
      font-size:${pxToRem(17)};
      border:none;
      border:1px solid ${({ $isErrorShown }) => $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD : COLORS.BLACK};
      background-color:transparent;  
  }

  ${Select}{
    height: 44px;
    width: 100%;
    border-radius: 0;
    border:1px solid ${COLORS.BLACK};
  }
`;
export const PhoneContainer = styled.div`
display: flex;
`;

export const DashSeperator = styled.div`
padding: 0 5px;
display: flex;
align-items: center;
`;

export const Label = styled.label`
 font-size:${pxToRem(16)};
 font-weight:400;
 color:${({ $isErrorShown }) =>  $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD  : COLORS.BLACK};
`;

export const ErrorMessage = styled.p`
  font-size:${pxToRem(14)};
  font-weight:500;
  margin: 7px 0 0 0;
  color:${COLORS.COLOR_ERROR_CONTACT_US_FIELD};

`;

export const Asterisk = styled.span`
 &::before{
     content:' * ';
 }
`;
