import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../components/Loader';
import { isArrayEmpty, isObjectEmpty } from '../../../../utils/commonUtils';

import InnerTabs from '../InnerTabs/InnerTabs';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import STRINGS from '../../../../language';
import EngineDataItem from './EngineDataItem/EngineDataItem';
import * as Styled from './Engine.styles';

const Engine = ({ engine }) => {
    const [selectedTab, setSelectedTab] = useState(null);
    const { data, isLoading } = engine;
    const { tabs, screenData } = data;

    const selectTabHandler = useCallback((tab) => {
        if (selectedTab !== tab) {
            setSelectedTab(tab);
        }
    }, [selectedTab]);

    useEffect(() => {
        if (!isObjectEmpty(data) && !isArrayEmpty(tabs) && !selectedTab) {
            setSelectedTab(tabs[0]);
        }
    }, [tabs]);

    if (isLoading) {
        return <Loader />;
    }

    if (isObjectEmpty(data)) {
        return <ErrorMessage errorMessage={ STRINGS.NO_DATA_TO_DISPLAY } />;
    }
  
    const renderEngineData = () => {
        if (!selectedTab || isObjectEmpty(selectedTab)) {
            return null;
        }

        const engineData = screenData[selectedTab.headerDescription];
   
        return engineData.map((dataItem) => {
            const { key, lineDescription, headerDescription } = dataItem;
            
            return (
                <EngineDataItem
                    key={ key }
                    title={ headerDescription }
                    value={ lineDescription }
                />
            );
        });
    };
    
    return (
        <Styled.Engine>
            <Styled.Header>
                <InnerTabs
                    tabs={ tabs }
                    onSelect={ selectTabHandler }
                    selectedTab={ selectedTab }
                />
            </Styled.Header>
            {renderEngineData()}
        </Styled.Engine>
    );
};

Engine.propTypes = {
    engine: PropTypes.object.isRequired,
};

export default Engine;
