/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentConfigOptions,
    useIsSpecificationsModalVisible,
} from '../../../hooks';
import { isArrayAndNotEmpty } from '../../../utils/commonUtils';
import SpecificationsModal from '../../../screens/InnerPages/Specifications/SpecificationsModal';
import { CONFIGURATOR_STEPS } from '../../../constants';
import { useDataForPaymentDetails } from '../../PaymentDetails/PaymentDetails.hooks';
import PresentationalLeft from './PresentationLeft/PresentationLeft';
import ConfigurationalRight from './ConfiguratorRight/ConfiguratorRight';
import Styled from './ConfiguratorLayout.style';
import ConfiguratorFooter from './ConfiguratorFooter/ConfiguratorFooter';

const { MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES } = CONFIGURATOR_STEPS;

const  ConfiguratorLayout = ({
    footerComponent,
    presentationalComponent,
    configurationComponent,
    nextStep,
    currentStep,
    configOptions,
    onContinue,
    onGoBack,
}) => {
    const FooterComponent =  footerComponent || (() => null);
    const isSpecificationsModalVisible = useIsSpecificationsModalVisible();
    const { toggleSpecificationsModal } = useActionDispatch();
    const {
        externalColors, internalColors, originalPackages, modelLocalGroups, externalColorsError,
    } = useCurrentConfigOptions();
    const {
        totalPricesBeforeDiscount,
    } = useDataForPaymentDetails();
    const fullPriceParsed = parseFloat(totalPricesBeforeDiscount.total).toLocaleString();
    const { isLoading: isLoadingInternalColors, error: internalColorsError } = internalColors;
    const { isLoading: isLoadingOriginalPackages, error: originalPackagesError } = originalPackages;
    const { isLoading: isLoadingLocalGroups, error: modelLocalGroupsError } = modelLocalGroups;
    const showConfiguratorFooter = ![MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES]
        .includes(currentStep);

    const disableContinueBtnHandler = () => {
        return isLoadingInternalColors
            || isLoadingOriginalPackages
            || isLoadingLocalGroups
            || !isArrayAndNotEmpty(externalColors)
            || externalColorsError
            || internalColorsError
            || originalPackagesError
            || modelLocalGroupsError;
    };

    useEffect(() => {
        if (isSpecificationsModalVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        } else {
            document.body.style.overflow = 'visible';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        }
    }, [isSpecificationsModalVisible]);

    const renderConfigurationalRight = () => {
        const doNotShowConfigurationLeft = [MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES]
            .find((step) => step === currentStep);

        if (doNotShowConfigurationLeft) {
            return null;
        }

        return (
            <ConfigurationalRight
                configOptions={ configOptions }
                nextStep={ nextStep }
                currentStep={ currentStep }
                onContinue={ onContinue }
                component={ configurationComponent }
            />
        );
    };
    
    return (
        <Styled.ConfiguratorLayout>
            <SpecificationsModal
                isVisible={ isSpecificationsModalVisible }
                onClose={ () => toggleSpecificationsModal() }
            />
            <Styled.ConfiguratorContent>
                { showConfiguratorFooter && (
                    <ConfiguratorFooter
                        fullPrice={ fullPriceParsed }
                        isContinueButtonDisabled={ disableContinueBtnHandler() }
                        nextStep={ nextStep }
                        onContinue={ onContinue }
                    />
                ) }
                {renderConfigurationalRight()}
                <PresentationalLeft
                    onGoBack={ onGoBack }
                    currentStep={ currentStep }
                    configOptions={ configOptions }
                    component={ presentationalComponent }
                />
            </Styled.ConfiguratorContent>
            <FooterComponent configOptions={ configOptions } />
        </Styled.ConfiguratorLayout>
    );
};

ConfiguratorLayout.defaultProps = {
    footerComponent: null,
    configurationComponent: null,
    presentationalComponent: null,
    currentStep: null,
    nextStep: null,
    onContinue: () => {},
    onGoBack: () => {},
};

ConfiguratorLayout.propTypes = {
    footerComponent: PropTypes.elementType,
    presentationalComponent: PropTypes.elementType,
    configurationComponent: PropTypes.elementType,
    configOptions: PropTypes.object.isRequired,
    currentStep: PropTypes.number,
    nextStep: PropTypes.number,
    onContinue: PropTypes.func,
    onGoBack: PropTypes.func,
};

export default React.memo(ConfiguratorLayout);
