import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentAppCommonData } from '../../../hooks';
import * as Styled from './IconAndSubTitle.styles';

const IconAndSubTitle = ({ icon, subTitle }) => {
    const { isAudi } = useCurrentAppCommonData();

    return (
        <Styled.IconAndSubTitle $icon={ icon } $isAudi={ isAudi }>
            {subTitle}
        </Styled.IconAndSubTitle>
    );
};

IconAndSubTitle.propTypes = {
    icon: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
};

export default IconAndSubTitle;
