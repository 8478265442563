import React from 'react';
import PropTypes from 'prop-types';
import FullPriceAndTooltip from '../../../Model/ModelItemCollapsible/FullPriceAndTooltip';
import LicenseFeePrice from '../../../Model/ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice';
import Divider from '../Divider/Divider';
import STRINGS from '../../../../../language';
import Styled from './AdditionalOptionHeader.styles';

const AdditionalOptionHeader = ({
    price,
    licenseFeePrice,
    fullPrice,
    arrivalDate,
}) =>  {
    const isInStock = arrivalDate === STRINGS.IN_STOCK;

    return (
        <Styled.Container>
            <FullPriceAndTooltip
                isShowInMobile
                hideTooltip={ false }
                fullPrice={ fullPrice.toLocaleString() }
                isShowFullPriceDescription={ false }
            />
            <LicenseFeePrice
                price={ price.toLocaleString() }
                licenseFee={ licenseFeePrice.toLocaleString() }
                showDetailedPrice={ false }
            />
            <Styled.ArrivalDate
                $isBold={ isInStock }
            >
                { arrivalDate }
            </Styled.ArrivalDate>
            <Divider />
        </Styled.Container>
    );
};

AdditionalOptionHeader.propTypes = {
    price: PropTypes.string.isRequired,
    licenseFeePrice: PropTypes.string.isRequired,
    fullPrice: PropTypes.string.isRequired,
    arrivalDate: PropTypes.string.isRequired,
};

export default AdditionalOptionHeader;
