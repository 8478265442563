import React from 'react';
import PropTypes from 'prop-types';
import { Navigation, Pagination } from 'swiper/modules';
import StepCard from '../StepCards/StepCard/StepCard';
import * as Styled from './StepsSlider.style';

const StepsSlider = ({ cards }) => {
    const pagination = {
        clickable: true,
        renderBullet(index, className) {
            return `<span class="${className}"></span>`;
        },
    };

    const _slides = cards.map((item) => {
        const {
            key, title, content, icon,
        } = item;

        const mobileTitle = title.replace('-', '\n');

        return (
            <Styled.SwiperSlideCustom>
                <StepCard key={ key } title={ mobileTitle } content={ content } icon={ icon } />
            </Styled.SwiperSlideCustom>
        );
    });

    return (
        <Styled.ImageSlider >
            <Styled.SwiperCustom
                dir="rtl"
                modules={ [Navigation, Pagination] }
                navigation={ {
                    enabled: true,
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                    disabledClass: 'swiper-button-disabled',
                } }
                pagination={ pagination }
                slidesPerView={ 1.1 }
                spaceBetween={ 30 }
                centerInsufficientSlides
            >
                {_slides}
            </Styled.SwiperCustom>
        </Styled.ImageSlider>
    );
};

StepsSlider.defaultProps = {
    cards: [],
};

StepsSlider.propTypes = {
    cards: PropTypes.array,
};

export default StepsSlider;
