import React from 'react';
import PropTypes from 'prop-types';
import MainCharacteristics  from '../../../../MainCharacteristics/MainCharacteristics';
import { useActionDispatch } from '../../../../../hooks';
import * as Styled from './MainCharacteristicsAndSpecification.styles';

const MainCharacteristicsAndSpecification = ({
    mainCharacteristics,
    groupModelCode,
    modelCode,
    modelDescription,
    version,
    id,
    trimLevelCode,
    originalAdditionsCode,
}) => {
    const { toggleSpecificationsModal, setSpecificationParams } = useActionDispatch();

    const onSpecificationLinkClick = () => {
        setSpecificationParams(
            groupModelCode,
            modelCode,
            modelDescription,
            originalAdditionsCode,
            version,
            trimLevelCode,
            id,
        );
        toggleSpecificationsModal();
    };
    
    return (
        <Styled.MainCharacteristicsAndSpecification>
            <MainCharacteristics
                characteristics={ mainCharacteristics }
                onSpecificationLinkClick={ onSpecificationLinkClick }
            />
        </Styled.MainCharacteristicsAndSpecification>
    );
};

MainCharacteristicsAndSpecification.defaultProps = {
    mainCharacteristics: [],
    originalAdditionsCode: '',
    id: '',
    trimLevelCode: '',
};
MainCharacteristicsAndSpecification.propTypes = {
    mainCharacteristics: PropTypes.array,
    groupModelCode: PropTypes.number.isRequired,
    modelCode: PropTypes.string.isRequired,
    modelDescription: PropTypes.string.isRequired,
    originalAdditionsCode: PropTypes.string,
    id: PropTypes.string,
    trimLevelCode: PropTypes.number,
    version: PropTypes.number.isRequired,
};

export default MainCharacteristicsAndSpecification;
