import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { pxToRem } from '../../../utils/commonUtils';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const EquipmentPackages = styled.div`
  max-width: 1700px;
  margin: auto;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 30px 70px 0 70px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    flex-direction: column;
    padding: 20px;
    height: initial;
  }
`;

export const HeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: ${pxToRem(36)};
  font-weight: 500;
  margin: 0;
  line-height: 1.3;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    font-size: ${pxToRem(28)};
    font-weight: 600;
    text-align: center;
  }
`;

export const SubTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-top: 0.5rem;
`;

export const SubTitle = styled.h2`
  font-size: ${pxToRem(26)};
  font-weight: 500;
  margin: 0;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    font-size:${pxToRem(20)};
    text-align: center;
  }
`;

export const InfoIcon = styled(Info).attrs(({ theme }) => ({
    width: 18,
    height: 18,
    stroke: theme.primaryColor,
}))`
  min-width: 18px;
`;

export const ToolTip = styled(ReactTooltip).attrs({
    'aria-haspopup': true,
    className: 'extraClass',
    variant: 'light',
    globalCloseEvents: {
        scroll: true,
        clickOutsideAnchor: true,
        escape: true,
        resize: true,
    },
})`
  &.extraClass {
    max-width: 30%;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    /* background-color: ${COLORS.BG_COLOR_TOOLTIP_CONTENT}; */
    opacity: 1 !important;
    box-shadow: 5px 5px 20px 0px #63636378;
    font-size: ${pxToRem(13)};
    font-weight: 400;
    line-height: 1.5;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
      max-width: 70%;
    }
  }
`;

export const Content = styled.div`
  overflow-x: auto;
  padding-top: 50px;
`;

export const LoaderContainer = styled.div`
   height:377px;
   display:flex;
   justify-content: center;
   align-items: center;
   width:100%;
`;

export const ModelImage = styled.img`
  width: 244px;
  height: 140px;
  object-fit: contain;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, 320px);
  grid-column-gap: 20px;
  justify-items: start;
`;

export const ErrorContainer = styled(LoaderContainer)``;
