import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentBrand,
    useCurrentCar,
    useCurrentExternalColor,
    useCurrentHoop,
    useCurrentInternalColor,
    useSelectedCurrentData,
    useCurrentAppCommonData,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import { isObjectEmpty } from '../../../../utils/commonUtils';
import { CONFIGURATOR_QUERY_PARAMS, DEFAULT } from '../../../../constants';
import STRINGS from '../../../../language';
import Loader from '../../../Loader';
import ConfiguratorDescriptionOnMobile from '../../../Configurator/ConfiguratorDescriptionOnMobile';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import PickerItem from '../../../PickerItem/PickerItem';
import HoopItem from './HoopItem/HoopItem';
import * as Styled from './Configuration.styles';

const Configuration = ({ configOptions }) => {
    const currentBrand = useCurrentBrand();
    const {
        selectCurrentHoop,
        selectIsInSummary,
        getOriginalPackagesAsync,
    } = useActionDispatch();
    const { isAudi } = useCurrentAppCommonData();
    const { value: externalColorCode } = useCurrentExternalColor();
    const { value: internalColorCode } = useCurrentInternalColor();
    const selectedHoop = useCurrentHoop();
    const { trimLevelCode, trimLevel, modelCode } = useCurrentCar();
    const { originalPackages } = configOptions;
    const hoopItemRef = useRef(selectedHoop);
    const { packages, isLoading, error } = originalPackages;
    const sColor = `${externalColorCode}${internalColorCode}`;
    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        getQueryParam,
        updateQueryParam,
    } = useQueryParams();
    const hoopsCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.HOOPS);
    const equipmentPackageCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.EQUIPMENT_PACKAGE);

    let hoopsCode;

    if (equipmentPackageCodeFromUrl) {
        hoopsCode =  `#${hoopsCodeFromUrl}`;
    } else {
        hoopsCode = hoopsCodeFromUrl;
    }

    const pickedHoopCode = hoopsCode || selectedHoop?.value;

    const {
        selectedCar,
    } = useSelectedCurrentData();

    const { originalAdditionsCode } = selectedCar;

    const getOriginalPackages = () => {
        getOriginalPackagesAsync(
            modelCode,
            trimLevelCode,
            externalColorCode,
            internalColorCode,
            trimLevel,
            originalAdditionsCode,
        );
    };

    const pickHoopHandler = useCallback(
        (hoop) => {
            if (pickedHoopCode !== hoop?.value || pickedHoopCode) {
                hoopItemRef.current = hoop;
                selectCurrentHoop(hoop);
                selectIsInSummary(false);
            }
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.HOOPS, hoop?.value);
        },
        [selectedHoop],
    );
    
    useEffect(() => {
        hoopItemRef.current = selectedHoop;
    }, []);
    
    useEffect(() => {
    // If there are no hoops which are relevant to the chosen sColor - only then send a request
        const firstHoop = packages[sColor]?.[0]; // todo: change!

        if (!packages[sColor]) {
            getOriginalPackages();
        } else if (!pickedHoopCode) {
            // No hoop has been selected - so select the first one
            pickHoopHandler(firstHoop);
        } else if (pickedHoopCode) {
            const isHoopMatchHoopCodeFromUrl = packages[sColor].value === pickedHoopCode;

            if (isHoopMatchHoopCodeFromUrl) {
                pickHoopHandler(firstHoop);
            }
            
            pickHoopHandler(firstHoop);
        }
    }, [packages[sColor]]);

    const DefaultHoopItemOrAudiHoopItem = isAudi ? PickerItem : HoopItem;

    const HoopPickerItem = useCallback(({
        hoopItem, pickedHoopCode: pickedCode,
    }) => {
        if (!hoopItem) {
            return null;
        }

        const {
            price, description, value, icon,
        } = hoopItem;

        return  (
            <DefaultHoopItemOrAudiHoopItem
                key={ value }
                value={ value }
                text={ description }
                price={ price }
                onClick={ () => pickHoopHandler(hoopItem) }
                selected={ pickedCode === value || pickedCode?.toUpperCase() === DEFAULT }
                image={ icon }
                isAudi={ isAudi }
                isToolipDisabled={ isAudi }
                className="PickerItem"
            />
        );
    }, [selectedHoop]);

    const renderHoopItem = (hoopItem) => {
        return (
            <HoopPickerItem
                hoopItem={ hoopItem }
                pickedHoopCode={ pickedHoopCode }
            />
        );
    };

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage
                    color="red"
                    errorMessage={ screenErrMsg }
                />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.HoopsConfiguration>
            { isAudi && <Styled.HoopsPickerHeader>{STRINGS.HOOPS_KIND}</Styled.HoopsPickerHeader> }
            <Styled.Hoops $isAudi={ isAudi }>
                {renderHoopItem(packages[sColor]?.[0])} {/* todo: change!!! */}
            </Styled.Hoops>
            {!isObjectEmpty(hoopItemRef.current) && (
                <ConfiguratorDescriptionOnMobile
                    item={ hoopItemRef.current }
                    isAudi={ isAudi }
                />
            )}
        </Styled.HoopsConfiguration>
    );
};

Configuration.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Configuration;
