import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Loader from '../Loader';
import * as Styled from './Modal.styles';

const Modal = ({
    children,
    width,
    height,
    isVisible,
    onClose,
    isLoading,
    closeButtonPosition,
    isAudi,
    isShowCloseButton,
}) => {
    const renderContentOrLoader = (isLoad) => {
        if (isLoad) {
            return <Styled.LoaderContainer><Loader /></Styled.LoaderContainer>;
        }
        
        return (
            <>
                { isShowCloseButton && (
                    <Styled.CloseButton
                        $position={ closeButtonPosition }
                        onClick={ onClose }
                    />
                ) }
                <Styled.Content $isAudi={ isAudi }>
                    {children}
                </Styled.Content>
            </>
        );
    };

    if (isVisible) {
        return ReactDOM.createPortal(
            <Styled.ModalContainer $isVisible={ isVisible }>
                <Styled.Layer onClick={ onClose } />
                <Styled.Modal height={ height } width={ width }>
                    {renderContentOrLoader(isLoading)}
                </Styled.Modal>
            </Styled.ModalContainer>,
            document.body,
        );
    }

    return null;
};

export default Modal;

Modal.defaultProps = {
    width: null,
    height: null,
    isVisible: false,
    onClose: () => {},
    isLoading: false,
    closeButtonPosition: 'right',
    isAudi: false,
    isShowCloseButton: true,
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    closeButtonPosition: PropTypes.string,
    isAudi: PropTypes.bool,
    isShowCloseButton: PropTypes.bool,
};
