import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Styled from './AddOnItem.style';

const AddOnItem = ({
    onSelect,
    isSelected,
    isDisabled,
    title,
    groupDescription,
    price,
    image,
    local,
    isGroupActive,
    priceAfterDiscount,
    isDiscount,
    groupCode,
}) => {
    const selectItemHandler = () => {
        if (isDisabled) {
            return false;
        }
        
        return onSelect({
            title,
            groupDescription,
            price,
            image,
            local,
            priceAfterDiscount,
            isDiscount,
            groupCode,
        });
    };

    const { auth }  = useSelector(({ authenticationReducer }) => authenticationReducer);
    const { isAuthenticated } = auth;
    const showDiscount = isAuthenticated && isDiscount;

    return (
        <Styled.AddOnItem
            $isDisabled={ isDisabled }
            $isActive={ isGroupActive }
            $isSelected={ isSelected }
            onClick={ selectItemHandler }
        >
            <Styled.RowContainer>
                <Styled.PickAndTitleContainer>
                    <Styled.PickIndicator $isSelected={ isSelected }>
                        <Styled.CheckMarkIcon />
                    </Styled.PickIndicator>
                    <Styled.ItemName>{ title }</Styled.ItemName>
                </Styled.PickAndTitleContainer>
                <Styled.PricesContainer>
                    <Styled.Price $showDiscount={ showDiscount }>{ price }</Styled.Price>
                    {showDiscount && <Styled.Price>{ priceAfterDiscount }</Styled.Price>}
                </Styled.PricesContainer>
            </Styled.RowContainer>
        </Styled.AddOnItem>
        
    );
};

AddOnItem.defaultProps = {
    onSelect: () => {},
    isSelected: false,
    isDisabled: false,
    isGroupActive: false,
};

AddOnItem.propTypes  = {
    title: PropTypes.string.isRequired,
    groupDescription: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    local: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isGroupActive: PropTypes.bool,
    onSelect: PropTypes.func,
    priceAfterDiscount: PropTypes.number.isRequired,
    groupCode: PropTypes.string.isRequired,
    isDiscount: PropTypes.bool.isRequired,
};

export default AddOnItem;
