import React, {
    useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentBrand, useCurrentCar,
    useSelectedCurrentData,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
    isArrayEmpty,
    isObjectEmpty,
} from '../../../../utils/commonUtils';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import { getMetaDataByModel } from '../../../../utils/seoUtils';
import { COLORS, CONFIGURATOR_QUERY_PARAMS } from '../../../../constants';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import HelmetTitleDescription from '../../../HelmetTitleDescription/HelmetTitleDescription';
import Loader from '../../../Loader';
import { Picker } from '../../Picker';
import Styled from './Color.style';

const Color = ({ configOptions }) => {
    const { externalColors, error, isLoading } = configOptions;
    const {
        selectCurrentInternalColor,
        selectCurrentExternalColor,
        selectCurrentHoop,
        selectCurrentAddOnGroup,
        selectCurrentAddOn,
        selectIsInSummary,
        getExternalColorsAsync,
    } = useActionDispatch();
    const {
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
        selectedAddOnGroup,
        selectedAddOns,
    } = useSelectedCurrentData();
    const currentBrand = useCurrentBrand();
    const { trimLevelCode } = useCurrentCar();
    const {
        getQueryParam,
        updateQueryParam,
    } = useQueryParams();

    const modelCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL);
    const trimLevelCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.TRIM_LEVEL);
    const modelGroupCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL_GROUP);
    const originalAdditionsCode = getQueryParam(CONFIGURATOR_QUERY_PARAMS.ORIGINAL_ADDITIONS);
    const externalColorCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.EXTERNAL_COLOR);

    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        title,
        description,
    } = getMetaDataByModel(currentBrand, modelGroupCode, trimLevelCodeFromUrl);

    const pickedColorCode = selectedExternalColor?.value || externalColorCodeFromUrl;
  
    const initState = () => {
        if (!isObjectEmpty(selectedInternalColor)) {
            selectCurrentInternalColor({});
        }
      
        if (!isObjectEmpty(selectedHoop)) {
            selectCurrentHoop({});
        }
        if (selectedAddOnGroup) {
            selectCurrentAddOnGroup(null);
        }
        if (!isObjectEmpty(selectedAddOns)) {
            selectCurrentAddOn({});
        }
        selectIsInSummary(false);
    };

    const pickColorHandler = useCallback(
        (color) => {
            const { value, isPackageRelated } = color;

            if (selectedExternalColor.value !== value) {
                if (isPackageRelated) {
                    /**
               * 1. select current external color
               * 2. if the chosen color had changed, then initilize internal color, hoops and addons
               */
                    selectCurrentExternalColor(color);
                    initState();
                } else {
                    // select previouly selected color - which make no state changes
                    selectCurrentExternalColor(selectedExternalColor);
                }
            }
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.EXTERNAL_COLOR, value);
        },
        [selectedExternalColor],
    );

    useEffect(() => {
        if (isArrayEmpty(externalColors)) {
            getExternalColorsAsync(modelCode, trimLevelCode, originalAdditionsCode);
        }

        const firstChosenColor = externalColors?.find(
            (color) => color.isPackageRelated,
        );

        const chosenColor = externalColors?.find(
            (color) => color.value === pickedColorCode,
        );
        
        if (chosenColor || firstChosenColor) {
            pickColorHandler(chosenColor ?? firstChosenColor);
        }
    }, [externalColors]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage color={ COLORS.RED } errorMessage={ screenErrMsg } />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.ColorConfiguration>
            <HelmetTitleDescription
                title={ title }
                description={ description }
                canonical={ window.location.href }
            />
            <Picker
                items={ externalColors }
                onPick={ pickColorHandler }
                pickedItem={ selectedExternalColor }
            />
        </Styled.ColorConfiguration>
    );
};

Color.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Color;
