import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentBrand,
    useCurrentCar,
    useCurrentExternalColor,
    useCurrentHoop,
    useCurrentInternalColor,
    useSelectedCurrentData,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import {
    COLORS, CONFIGURATOR_QUERY_PARAMS,
} from '../../../../constants';
import Loader from '../../../Loader';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { Picker } from '../../Picker';
import Styled from './Hoops.style';

const Hoops = ({ configOptions }) => {
    const currentBrand = useCurrentBrand();
    const {
        selectCurrentHoop,
        selectIsInSummary,
        getOriginalPackagesAsync,
    } = useActionDispatch();
    const { value: externalColorCode } = useCurrentExternalColor();
    const { value: internalColorCode } = useCurrentInternalColor();
    const selectedHoop = useCurrentHoop();
    const { trimLevelCode, trimLevel, modelCode } = useCurrentCar();
    const { originalPackages } = configOptions;
    const hoopItemRef = useRef(selectedHoop);
    const { packages, isLoading, error } = originalPackages;
    const sColor = `${externalColorCode}${internalColorCode}`;
    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        getQueryParam,
        updateQueryParam,
    } = useQueryParams();
    const hoopsCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.HOOPS);
    const equipmentPackageCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.EQUIPMENT_PACKAGE);

    let hoopsCode;

    if (equipmentPackageCodeFromUrl) {
        hoopsCode =  `#${hoopsCodeFromUrl}`;
    } else {
        hoopsCode = hoopsCodeFromUrl;
    }

    const pickedHoopCode = hoopsCode || selectedHoop?.value;

    const {
        selectedCar,
    } = useSelectedCurrentData();

    const { originalAdditionsCode } = selectedCar;

    const getOriginalPackages = () => {
        getOriginalPackagesAsync(
            modelCode,
            trimLevelCode,
            externalColorCode,
            internalColorCode,
            trimLevel,
            originalAdditionsCode,
        );
    };

    const pickHoopHandler = useCallback(
        (hoop) => {
            if (pickedHoopCode !== hoop?.value || pickedHoopCode) {
                hoopItemRef.current = hoop;
                selectCurrentHoop(hoop);
                selectIsInSummary(false);
            }
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.HOOPS, hoop?.value);
        },
        [selectedHoop],
    );
    
    useEffect(() => {
        hoopItemRef.current = selectedHoop;
    }, []);
    
    useEffect(() => {
    // If there are no hoops which are relevant to the chosen sColor - only then send a request
        const firstHoop = packages[sColor]?.[0]; // todo: change!

        if (!packages[sColor]) {
            getOriginalPackages();
        } else if (!pickedHoopCode) {
            // No hoop has been selected - so select the first one
            pickHoopHandler(firstHoop);
        } else if (pickedHoopCode) {
            const isHoopMatchHoopCodeFromUrl = packages[sColor].value === pickedHoopCode;

            if (isHoopMatchHoopCodeFromUrl) {
                pickHoopHandler(firstHoop);
            }
            
            pickHoopHandler(firstHoop);
        }
    }, [packages[sColor]]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage
                    color={ COLORS.RED }
                    errorMessage={ screenErrMsg }
                />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.HoopsConfiguration>
            <Picker
                items={ packages[sColor] }
                pickedItem={ selectedHoop }
                onPick={ pickHoopHandler }
            />
        </Styled.HoopsConfiguration>
    );
};

Hoops.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Hoops;
