/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { spreadAllManufacturingDates } from '../../../../utils/commonUtils';

const CarInfoVolkswagenCommercial = ({ ManufacturingDates }) => {
    return (
        <>
            {spreadAllManufacturingDates(ManufacturingDates)}
            <p>מצב מכני של הרכב - מצב חדש ללא נזק בטיחותי</p>
            <p>הרכב לא נרשם במדינת חוץ</p>
            <p> ללא תקלות בטיחותיות סדרתיות </p>
            <p>מספר הקילומטרים של כלל נסיעות הרכב ממועד ייצורו לא עולה על 150 ק"מ</p>
            <p>
                תקופת האחריות: האחריות הניתנת לרכב זה הינה אחריות שנתן היצרן, כמפורט
                בתעודת האחריות הניתנת להורדה מתחתית האתר ובכפוף לאמור בה ובתנאיה (להלן
                "תעודת האחריות").
            </p>
        </>
    );
};

CarInfoVolkswagenCommercial.propTypes  = {
    ManufacturingDates: PropTypes.array.isRequired,
};

export default CarInfoVolkswagenCommercial;
