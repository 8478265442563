import React from 'react';
import PropTypes from 'prop-types';
import onlinePngSrc from '../../../assets/logos/online.png';
import { ROUTES } from '../../../constants';
import * as Styled from './LogoWrapper.styles';

const { HOME } = ROUTES;
const LogoWrapper = ({ logoSrc, onCloseNav }) => {
    return (
        <Styled.LogoWrapper onClick={ onCloseNav } to={ HOME }>
            <Styled.Logo src={ onlinePngSrc } />
            <Styled.Logo src={ logoSrc } />
        </Styled.LogoWrapper>
    );
};

LogoWrapper.defaultProps = {
    logoSrc: '',
    onCloseNav: () => {},
};

LogoWrapper.propTypes = {
    logoSrc: PropTypes.string,
    onCloseNav: PropTypes.func,
};

export default LogoWrapper;
