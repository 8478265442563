import React, {
    useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentBrand,
    useActionDispatch,
    useSelectedCurrentData,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
    getScreenBlockErrorMsg,
} from '../../../../utils/configuratorUtils';
import { isObjectEmpty } from '../../../../utils/commonUtils';
import { AppError } from '../../../../constants/errors';
import { COLORS, CONFIGURATOR_QUERY_PARAMS } from '../../../../constants';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Loader from '../../../Loader';
import { Picker } from '../../Picker';
import Styled from './InternalColor.style';

const InternalColor = ({ configOptions }) => {
    const currentBrand = useCurrentBrand();
    const {
        selectCurrentInternalColor,
        getInternalColorsAsync,
        selectCurrentHoop,
        getInternalColorsFailure,
        selectCurrentAddOnGroup,
        selectCurrentAddOn,
        selectIsInSummary,
    } = useActionDispatch();
    const {
        selectedExternalColor,
        selectedInternalColor,
        selectedCar,
        selectedHoop,
        selectedAddOnGroup,
        selectedAddOns,
    } = useSelectedCurrentData();
    const [noColorsToChooseFrom, setNoColorsToChooseFrom] = useState(false);
    const { trimLevelCode, modelCode, originalAdditionsCode } = selectedCar;
    const { internalColors } = configOptions;
    const { colors, isLoading, error } = internalColors;
    const { value: externalColorCode } = selectedExternalColor;
    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        getQueryParam,
        updateQueryParam,
    } = useQueryParams();
    const internalColorCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.INTERNAL_COLOR);
    const pickedColorCode = selectedInternalColor?.value || internalColorCodeFromUrl;

    const pickColorHandler = useCallback(
        (color) => {
            const { value, isPackageRelated } = color;

            if (selectedInternalColor.value !== value) {
                if (isPackageRelated) {
                    /**
                     * 1. select current internal color
                     * 2. if the chosen color had changed, then initilize , hoops and addons
               */
                    selectCurrentInternalColor(color);
                    if (!isObjectEmpty(selectedHoop)) {
                        selectCurrentHoop({});
                    }
                    if (selectedAddOnGroup) {
                        selectCurrentAddOnGroup(null);
                    }
                    if (!isObjectEmpty(selectedAddOns)) {
                        selectCurrentAddOn({});
                    }
                    selectIsInSummary(false);
                } else {
                    // select previouly selected color - which make no state changes
                    selectCurrentInternalColor(selectedInternalColor);
                }
            }
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.INTERNAL_COLOR, value);
        },
        [selectedInternalColor],
    );

    useEffect(() => {
        const firstChosenColor = colors[externalColorCode]?.find(
            (color) => color.isPackageRelated,
        );
          
        if (externalColorCode && !colors[externalColorCode]) {
            // If there are no internal colors which are relevant to the chosen externalColorCode - only then send a request
            getInternalColorsAsync(
                modelCode,
                externalColorCode,
                trimLevelCode,
                originalAdditionsCode,
            );
        } else if (!pickedColorCode) {
            // No color has been selected - so select the first one
            if (firstChosenColor) {
                pickColorHandler(firstChosenColor);
            } else {
                // If there are no colors at all display an error
                setNoColorsToChooseFrom(true);
                getInternalColorsFailure(new AppError(screenErrMsg));
            }
        } else if (pickedColorCode) {
            // If there is a color code find a matching one from the state
            const chosenColor = colors[externalColorCode]?.find(
                (color) => color.value === pickedColorCode,
            );

            if (!chosenColor) {
                // If there is no matching color pick the first one in the list
                if (firstChosenColor) {
                    pickColorHandler(firstChosenColor);
                } else {
                    // If there are no colors at all display an error
                    setNoColorsToChooseFrom(true);
                    getInternalColorsFailure(new AppError(screenErrMsg));
                }
            }

            if (chosenColor) {
                // If there is a matching color choose is and move to next step.
                pickColorHandler(chosenColor);
            }
        }
    }, [colors[externalColorCode]]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error || noColorsToChooseFrom) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage color={ COLORS.RED } errorMessage={ screenErrMsg } />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.InternalColorConfiguration>
            <Picker
                items={ colors[externalColorCode] }
                pickedItem={ selectedInternalColor }
                onPick={ pickColorHandler }
            />
        </Styled.InternalColorConfiguration>
    );
};

InternalColor.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default InternalColor;
