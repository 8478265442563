import STRINGS from '../../../../../../language';
import IMAGES from '../../../../../../assets';

const {
    STEP_ONE_BUILD_AND_PICK,
    STEP_ONE_BUILD_AND_PICK_CONTENT,
    STEP_TWO_REGISTER,
    STEP_TWO_REGISTER_CONTENT,
    STEP_THREE_PAYMENT,
    STEP_THREE_PAYMENT_CONTENT,
    STEP_FOUR_FINISH,
    STEP_FOUR_FINISH_CONTENT,
} = STRINGS;

const {
    STEP_1,
    STEP_2,
    STEP_3,
    STEP_4,
} = IMAGES.HOW_DOES_IT_WORK;

export const CARDS_CONFIG = [
    {
        key: 0,
        title: STEP_ONE_BUILD_AND_PICK,
        content: STEP_ONE_BUILD_AND_PICK_CONTENT,
        icon: STEP_1,
    },
    {
        key: 1,
        title: STEP_TWO_REGISTER,
        content: STEP_TWO_REGISTER_CONTENT,
        icon: STEP_2,
    },
    {
        key: 2,
        title: STEP_THREE_PAYMENT,
        content: STEP_THREE_PAYMENT_CONTENT,
        icon: STEP_3,
    },
    {
        key: 3,
        title: STEP_FOUR_FINISH,
        content: STEP_FOUR_FINISH_CONTENT,
        icon: STEP_4,
    },
];
