import styled from 'styled-components';
import { getIconFromAssets } from '../../../utils/assetsUtils';
import CONFIG from '../../../config';
import { ICONS_NAMES } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

const arrowRightIcon = getIconFromAssets(ICONS_NAMES.THIN_ARROW_RIGHT);

export const ConfiguratorStepsWrapper = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.configuratorStepperBGColor};
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: auto;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    position: sticky;
    top: 0;
    z-index: 130;
  }
`;

export const ConfiguratorSteps = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  @media(max-width:${DEVICE_SIZE.SMALL}px){
    display: inline-flex;
  }
`;

const GoBackButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  line-height: 19px;
  text-decoration: none;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: 16px;
  color: ${({ theme }) => theme.primaryColor};
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  transition: all;
  &:before{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${arrowRightIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    text-decoration: underline;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display: none;
  }
`;

export const ModelDescription = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 500;
  font-size:${pxToRem(14)};
  margin: 0;
  display: inline-block;
  position: absolute;
  left: 79%;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    display:none;
  }
`;

const Styled = {
    ConfiguratorStepsWrapper,
    ConfiguratorSteps,
    GoBackButton,
    ModelDescription,
};

export default Styled;
