/* eslint-disable max-len */
import React, {
    useRef, useCallback, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PaymentDetails from '../../PaymentDetails/PaymentDetails';
import { useDataForPaymentDetails } from '../../../PaymentDetails/PaymentDetails.hooks';
import Message from '../../../ErrorMessage/ErrorMessage';
import AdditionalInformation from '../../../PaymentDetails/AdditionalInformation/AdditionalInformation';
import {
    getManufacturerContactInfo,
    isArrayEmpty,
    replacePlaceholdersInTemplate,
    getFullManufacturer,
} from '../../../../utils/commonUtils';
import STRINGS from '../../../../language';
import {
    useActionDispatch,
    useCurrentAppCommonData,
    useIsSpecificationsModalVisible,
    useSelectedCurrentData,
    useCurrentCar,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import Loader from '../../../Loader';
import {
    sendLeadWithOrderDetails,
} from '../../../../fetch/leads';
import {
    CONFIGURATOR_QUERY_PARAMS,
    CUSTOMER_NAME_AUTHORIZATION,
    PHONE_NUMBER_AUTHORIZATION,
} from '../../../../constants';
import SpecificationsModal from '../../../../screens/InnerPages/Specifications/SpecificationsModal';
import ExternalInternalMediaToggle from '../../../ExternalInternalMediaToggle/ExternalInternalMediaToggle';
import useAutoOtpCredentials from '../../../../hooks/useAutoOtpCredentials';
import {
    CHECKBOX_FIELDS,
    INPUT_FIELDS,
    REVEAL_UNIQUE_ONLINE_PRICE,
    SPEAK_WITH_REPRESENTATIVE,
} from '../../../Steps/Summary/ModalForms/ModalForm/ModalForm.fields';
import { UNIQUE_ONLINE_PRICE_SMS_CODE, INPUT_FIELDS as SMS_INPUT_FIELD } from '../../../Steps/Summary/SmsCodeForm/SmsCodeForm.fields';
import { parseMultipleFormsToState, parseSelectedAddOnsGroupDescriptionToString, parseSelectedAddOnsToArray } from '../../../Steps/Summary/Summary.utils';
import { useModalFormVisibility } from '../../../Steps/Summary/Summary.hooks';
import ModalForms from '../../../Steps/Summary/ModalForms/ModalForms';
import SmsCodeForm from '../../../Steps/Summary/SmsCodeForm/SmsCodeForm';
import OpenModalFormsButtons from './OpenModalFormsButtons/OpenModalFormsButtons';
import * as Styled from './Summary.styles';

const formNames = [
    SPEAK_WITH_REPRESENTATIVE,
    REVEAL_UNIQUE_ONLINE_PRICE,
    UNIQUE_ONLINE_PRICE_SMS_CODE,
];
const NO_EMAIL_FIELD = true;

const showPricesAfterDiscount = true;

const initFormInputs = parseMultipleFormsToState(INPUT_FIELDS, formNames, NO_EMAIL_FIELD);
const initFormCheckBoxInputs = parseMultipleFormsToState(CHECKBOX_FIELDS, formNames);
const initFormSmsInput = parseMultipleFormsToState(SMS_INPUT_FIELD, [UNIQUE_ONLINE_PRICE_SMS_CODE]);

const Summary = ({ footerComponent, configOptions }) => {
    const { currentBrand } = useCurrentAppCommonData();
    const [inputs, setInputs] = useState(initFormInputs);
    const [checkBoxInputs, setCheckBoxInputs] = useState(initFormCheckBoxInputs);
    const [isContactSuccessful, setIsContactSuccessful] = useState(false);
    const [isModalFormVisible, setIsModalFormVisible] = useModalFormVisibility(formNames);
    const [smsCodeInput, setSmsCodeInput] = useState(initFormSmsInput);
    const currentCar = useCurrentCar();
    const {
        priceList, priceListDate,
    } = currentCar;
    const {
        selectIsInSummary,
        checkOtpAsync,
        getFinalDiscountPriceAsync,
        initializeOtp,
        initializeSms,
        sendSmsAsync,
        resendSmsAsync,
        initializeCheckout,
        toggleSpecificationsModal,
    } = useActionDispatch();
    const [isScreenErrMsg, setIsScreenErrMsg] = useState(false);
    const [isSendingCustomerInfo, setIsSendingCustomerInfo] = useState(false);
    const currentActiveForm = useRef(null);
    const smsCredentialsRef = useRef({});
    const FooterComponent = footerComponent || (() => null);
    const manufacturerInHebrew = getFullManufacturer(currentBrand, 'hebrew');
    const isSpecificationsModalVisible = useIsSpecificationsModalVisible();
    const {
        selectedCar,
        selectedAddOns,
        selectedHoop,
        selectedExternalColor,
        selectedInternalColor,
        selectedEquipmentPackage,
    } = useSelectedCurrentData();
    const { inStockString: inStockExternalString } = selectedExternalColor;
    const { inStockString: inStockInternalString } = selectedInternalColor;
    const inStockString = inStockInternalString ?? inStockExternalString;
    const {
        modelCode,
        carVersion,
        modelGroup,
        trimLevel,
        modelDescription,
    } = selectedCar;
    const authentication = useSelector(({ authenticationReducer }) => authenticationReducer);
    const { sms: smsState, auth: authState, otp: otpState } = authentication;
    const { value: outColor } = selectedExternalColor;
    const { value: inColor } = selectedInternalColor;
    const {
        specifications,
        addOns,
        originalAdditions,
        equipmentPackage,
        carPrices,
        licenseFeePrices,
        totalPrices,
        isDiscountExist,
    } = useDataForPaymentDetails(showPricesAfterDiscount);
    const { addHash } = useQueryParams();
    const { finalDiscount } = configOptions;
    const {  isLoading: isLoadingDiscount } = finalDiscount;
    const { isLoading: isSendingSms, isSuccess: smsWasSentSuccessfully } = smsState;
    const {
        isLoading: isCheckingOtp,
        error: otpError,
        isVerified: isOtpVerified,
    } = otpState;
    const { isLoading: isLoadingAuth, isAuthenticated, token } = authState;

    const { value: selectedHoopsString } = selectedHoop;
    const selectedAddOnsArray = parseSelectedAddOnsToArray(selectedAddOns);
    const selectedAddOnsDescriptionString = parseSelectedAddOnsGroupDescriptionToString(selectedAddOns);
    const selectedEquipmentPackageString = selectedEquipmentPackage?.id ?? '';
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const summaryScreenErrMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );
    const formNamesToUse = [REVEAL_UNIQUE_ONLINE_PRICE, SPEAK_WITH_REPRESENTATIVE];

    const getOrderDetailsDescriptions = () => {
        const modelAndTrimLevel = `${modelGroup}-${trimLevel}`;

        const orderDescriptions = {
            modelDescription: modelAndTrimLevel,
            colorDescription: outColor,
            upholsteryDescription: inColor,
            rimDescription: selectedHoopsString,
            upgradeDescriptions: [
                selectedAddOnsDescriptionString,
            ],
            equipmentPackageDescription: selectedEquipmentPackageString,
        };

        return orderDescriptions;
    };
    
    const setModalVisibilityHandler = useCallback((formName) => {
        if (formName === REVEAL_UNIQUE_ONLINE_PRICE
             &&  smsWasSentSuccessfully
             && !isModalFormVisible[formName]) {
            currentActiveForm.current = UNIQUE_ONLINE_PRICE_SMS_CODE;
        } else {
            currentActiveForm.current = formName;
        }
     
        setIsModalFormVisible((prevState) => ({
            ...prevState,
            [currentActiveForm.current]: !prevState[currentActiveForm.current],
        }));
    }, [smsWasSentSuccessfully, isModalFormVisible, setIsModalFormVisible]);

    const sendLeadWithCloseReasonId = () => {
        const { fullName, phone, isAcceptAdvertising } = smsCredentialsRef.current;
        const lastName = '';

        sendLeadWithOrderDetails({
            firstName: fullName ?? sessionStorage.getItem(CUSTOMER_NAME_AUTHORIZATION),
            lastName,
            phone: phone ?? sessionStorage.getItem(PHONE_NUMBER_AUTHORIZATION),
            orderDescriptions: getOrderDetailsDescriptions(),
            hasRequestedMarketing: isAcceptAdvertising,
        });
    };

    const revealUniqueOnlinePrice = async () => {
        await getFinalDiscountPriceAsync(
            modelCode,
            outColor,
            inColor,
            carVersion,
            selectedHoopsString,
            selectedAddOnsArray,
        );

        // TODO: remove when we get the new requirements
        // if (response) {
        //     sendLeadWithCloseReasonId();
        // }
        setIsModalFormVisible((prevState) => ({
            ...prevState,
            [UNIQUE_ONLINE_PRICE_SMS_CODE]: false,
        }));
    };

    const sendSmsCodeHandler = (fullName, phone, isAcceptAdvertising, resendSms = false) => {
        sendSmsAsync(fullName, phone).then((response) => {
            if (response) {
                /**
                 * sms was sent successfully - save user credentials that were entered
                 */
                smsCredentialsRef.current.fullName = fullName;
                smsCredentialsRef.current.phone = phone;
                smsCredentialsRef.current.isAcceptAdvertising = isAcceptAdvertising;
                if (!resendSms) {
                    setModalVisibilityHandler(REVEAL_UNIQUE_ONLINE_PRICE);
                    setTimeout(() => {
                        setModalVisibilityHandler(UNIQUE_ONLINE_PRICE_SMS_CODE);
                    }, 0);
                }
            } else {
                /**
                 * sms was not sent successfully
                   1. close reveal unique online price modal
                   2. show error message
                 */

                setModalVisibilityHandler(REVEAL_UNIQUE_ONLINE_PRICE);
                setIsScreenErrMsg(true);
            }
        });
    };

    const onChangeInputHandler = useCallback((event) => {
        const $this = event.target;
        const { name: formName } = $this.closest('form');
        const { name: inputName, value } = $this;

        setInputs((prevState) => {
            const currentInput = prevState[formName][inputName];
            const isValid = currentInput.validator?.(value) ?? true;
            const formattedValue = currentInput.formatter?.(value) ?? value;
            const encodedValue = currentInput.encoder?.(value) ?? value;

            return {
                ...prevState,
                [formName]: {
                    ...prevState[formName],
                    [inputName]: {
                        ...currentInput,
                        value: formattedValue,
                        isValid,
                        isErrorShown: false,
                        encodedValue,
                    },
                },
            };
        });
    }, []);

    const onCheckHandler = (event) => {
        const $this = event.target;
        const { name: formName } = $this.closest('form');
        const { id } = $this;

        setCheckBoxInputs((prevState) => {
            const currentCheckBoxInput = prevState[formName][id];
            const { isChecked } = currentCheckBoxInput;

            return {
                ...prevState,
                [formName]: {
                    ...prevState[formName],
                    [id]: {
                        ...currentCheckBoxInput,
                        isChecked: !isChecked,
                        isValid: !isChecked,
                        isErrorShown: false,
                    },
                },
            };
        });
    };

    const onChangeSmsInputHandler = useCallback((event) => {
        const $this = event.target;
        const { name: formName } = $this.closest('form');
        const { name: inputName, value } = $this;

        setSmsCodeInput((prevState) => {
            const currentInput = prevState[formName][inputName];

            return {
                ...prevState,
                [formName]: {
                    ...prevState[formName],
                    [inputName]: {
                        ...currentInput,
                        value,
                        isErrorShown: false,
                    },
                },
            };
        });
    }, []);

    const setErrorOnInput = (inputName, formName, inputType) => {
    // If inputType is not checkbox, then it is regular input(text,tel or email)
        const setStateMethod = inputType === 'checkbox' ? setCheckBoxInputs : setInputs;

        setStateMethod((prevState) => ({
            ...prevState,
            [formName]: {
                ...prevState[formName],
                [inputName]: {
                    ...prevState[formName][inputName],
                    isErrorShown: true,
                },
            },
        }));
    };

    const getInvalidInputFields = (inputFields) => {
        return inputFields.filter((inputField) => {
            const { isRequired, isValid } = inputField;

            if (isRequired && !isValid) {
                return true;
            }

            return false;
        });
    };

    const speakWithRepresentative = async (firstName, lastName, phone, email, isAdvertisingAccept) => {
        setIsSendingCustomerInfo(true);
        const result = await sendLeadWithOrderDetails({
            firstName,
            lastName,
            phone,
            email,
            orderDescriptions: getOrderDetailsDescriptions(),
            hasRequestedMarketing: isAdvertisingAccept,
        });

        setIsSendingCustomerInfo(false);
        if (result.isSuccess) {
            setIsContactSuccessful(true);
        } else {
            setIsScreenErrMsg(true);
        }

        setModalVisibilityHandler(SPEAK_WITH_REPRESENTATIVE);
        setInputs(initFormInputs);
        setCheckBoxInputs(initFormCheckBoxInputs);
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();
        const { current: formName } = currentActiveForm;
        const formInputs = Object.values(inputs[formName]);
        const formCheckBoxInputs = Object.values(checkBoxInputs[formName]);
        const allFormInputsCombined = formInputs.concat(formCheckBoxInputs);
        const unValidFields = getInvalidInputFields(allFormInputsCombined);

        if (!isArrayEmpty(unValidFields)) {
            unValidFields.forEach((unValidField) => {
                const { type: inputType, name: inputName } = unValidField;

                setErrorOnInput(inputName, formName, inputType);
            });
        } else {
            if (formName === SPEAK_WITH_REPRESENTATIVE) {
                const [firstName, lastName, phone, email, , acceptAdvertising] = allFormInputsCombined;

                speakWithRepresentative(
                    firstName.value,
                    lastName.value,
                    phone.value,
                    email.value,
                    acceptAdvertising.isChecked);
            }
            if (formName === REVEAL_UNIQUE_ONLINE_PRICE) {
                const [fullName, phone, , acceptAdvertising] = allFormInputsCombined;

                sendSmsCodeHandler(fullName.value, phone.value, acceptAdvertising.isChecked);
            }
        }
    };

    const onSubmitSmsInputHandler = (event) => {
        event.preventDefault();
        const { current: formName } = currentActiveForm;
        const { smsCode } = smsCodeInput[formName];
        const { phone, fullName } = inputs[REVEAL_UNIQUE_ONLINE_PRICE];

        checkOtpAsync(smsCode.value, () => {
            setModalVisibilityHandler(UNIQUE_ONLINE_PRICE_SMS_CODE);
            sessionStorage.setItem(PHONE_NUMBER_AUTHORIZATION, phone.value);
            sessionStorage.setItem(CUSTOMER_NAME_AUTHORIZATION, fullName.value);
        });
    };

    const resendSmsCodeHandler = () => {
        resendSmsAsync();
    };

    const notMyNumberHandler = useCallback(() => {
        /**
           1. closing unique online price price sms code Modal
           2. showing unique online price modal
         */
        currentActiveForm.current = REVEAL_UNIQUE_ONLINE_PRICE;
        setIsModalFormVisible((prevState) => ({
            ...prevState,
            [UNIQUE_ONLINE_PRICE_SMS_CODE]: false,
            [REVEAL_UNIQUE_ONLINE_PRICE]: true,
        }));
    }, []);

    const renderFooterButtons = () => {
        return (
            <OpenModalFormsButtons
                continueToPayment={ !isDiscountExist || isOtpVerified }
                onOpen={ setModalVisibilityHandler }
                isDisabled={ isScreenErrMsg }
            />
        );
    };
    
    const renderAdditionalInformation = () => {
        return (
            <AdditionalInformation
                priceList={ priceList }
                manufacturerInHebrew={ manufacturerInHebrew }
                modelDescription={ modelDescription }
                priceListDate={ priceListDate }
                inStockString={ inStockString }
            />
        );
    };

    const renderPaymentSummaryOrLoader = () => {
        if (isLoadingAuth || isLoadingDiscount) {
            return <Styled.LoaderContainer><Loader /></Styled.LoaderContainer>;
        }
            
        return (
            <>
                <PaymentDetails
                    carPrices={ carPrices }
                    specifications={ specifications }
                    addOns={ addOns }
                    originalAdditions={ originalAdditions }
                    equipmentPackage={ equipmentPackage }
                    licenseFeePrices={ licenseFeePrices }
                    totalPrices={ totalPrices }
                    currentBrand={ currentBrand }
                />
                { (isScreenErrMsg || isContactSuccessful) && (
                    <Styled.MessageContainer>
                        {isScreenErrMsg && (
                            <Message
                                color="red"
                                errorMessage={ summaryScreenErrMsg }
                            />
                        )}
                        {isContactSuccessful && (
                            <Message
                                color="red"
                                errorMessage={ STRINGS.THANK_YOU_WE_WILL_CONTACT_YOU_SOON }
                            />
                        )}
                    </Styled.MessageContainer>
                )}
                {renderFooterButtons()}
            </>
        );
    };

    const encodedPhoneNumber =    inputs[REVEAL_UNIQUE_ONLINE_PRICE].phone?.encodedValue;
    const smsCodeFormDescription = replacePlaceholdersInTemplate(
        STRINGS.A_MESSAGE_WITH_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO,
        [encodedPhoneNumber],
    );

    useEffect(() => {
        if (token) {
            /** listen to the token, if it changes, initialize otp state */
            initializeOtp();
        }
        addHash(CONFIGURATOR_QUERY_PARAMS.SUMMARY);
        selectIsInSummary(true);
    }, [token]);
    
    useEffect(() => {
        if (isSpecificationsModalVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        } else {
            document.body.style.overflow = 'visible';
            document.body.dataset.moreInfo = isSpecificationsModalVisible;
        }
    }, [isSpecificationsModalVisible]);
    
    useEffect(() => {
        revealUniqueOnlinePrice();
    }, [isAuthenticated, isOtpVerified]);

    useEffect(() => {
        initializeCheckout();
        
        return () => {
            initializeSms();
        };
    }, []);

    useAutoOtpCredentials((otpValue) => {
        setSmsCodeInput((prevState) => {
            const currentInput = prevState[UNIQUE_ONLINE_PRICE_SMS_CODE].smsCode;

            return {
                ...prevState,
                [UNIQUE_ONLINE_PRICE_SMS_CODE]: {
                    ...prevState[UNIQUE_ONLINE_PRICE_SMS_CODE],
                    smsCode: {
                        ...currentInput,
                        value: otpValue,
                        isErrorShown: false,
                    },
                },
            };
        });
        const { phone, fullName } = inputs[REVEAL_UNIQUE_ONLINE_PRICE];

        checkOtpAsync(otpValue, () => {
            setModalVisibilityHandler(UNIQUE_ONLINE_PRICE_SMS_CODE);
            sessionStorage.setItem(PHONE_NUMBER_AUTHORIZATION, phone.value);
            sessionStorage.setItem(CUSTOMER_NAME_AUTHORIZATION, fullName.value);
        });
    });

    return (
        <Styled.Summary>
            <Styled.Content>
                <Styled.PaymentSummary>
                    <Styled.Title>
                        <Styled.TitleText $isBold>{modelDescription}</Styled.TitleText>
                    </Styled.Title>
                    { renderPaymentSummaryOrLoader() }
                    { renderAdditionalInformation() }
                </Styled.PaymentSummary>
                <Styled.Media>
                    <Styled.StickyContent>
                        <ExternalInternalMediaToggle />
                        {/* <ShareButtons shareUrl={ shareUrl } /> */}
                    </Styled.StickyContent>
                </Styled.Media>
            </Styled.Content>
            <Styled.FooterContainer>
                <FooterComponent configOptions={ configOptions } />
            </Styled.FooterContainer>
            <SpecificationsModal
                isVisible={ isSpecificationsModalVisible }
                onClose={ () => toggleSpecificationsModal() }
            />
            <ModalForms
                formNames={ formNamesToUse }
                textInputs={ inputs }
                checkBoxInputs={ checkBoxInputs }
                onChange={ onChangeInputHandler }
                onCheck={ onCheckHandler }
                onSubmit={ onSubmitHandler }
                isVisible={ isModalFormVisible }
                onClose={ setModalVisibilityHandler }
                isLoading={ isSendingSms || isSendingCustomerInfo }
            />
            <SmsCodeForm
                title={ STRINGS.HAVE_YOU_RECEIVED_AN_SMS_CODE }
                formName={ UNIQUE_ONLINE_PRICE_SMS_CODE }
                description={ smsCodeFormDescription }
                otpError={ otpError }
                isVisible={ isModalFormVisible[UNIQUE_ONLINE_PRICE_SMS_CODE] }
                onSubmit={ onSubmitSmsInputHandler }
                onResendSmsCode={ resendSmsCodeHandler }
                onNotMyPhone={ notMyNumberHandler }
                submitButtonTitle={ STRINGS.LETS_CONTINUE }
                onClose={ () => setModalVisibilityHandler(UNIQUE_ONLINE_PRICE_SMS_CODE) }
                onChangeInput={ onChangeSmsInputHandler }
                input={ smsCodeInput[UNIQUE_ONLINE_PRICE_SMS_CODE] }
                isLoading={ isCheckingOtp || isSendingSms || isLoadingDiscount }
            />
        </Styled.Summary>
    );
};

Summary.propTypes = {
    footerComponent: PropTypes.elementType.isRequired,
    configOptions: PropTypes.object.isRequired,
};

export default Summary;
