import * as actionTypes from '../../actionTypes';
import { getOriginalPackages } from '../../../fetch/configurator_options';
import { parseOriginalPackageToHoops } from '../../dispatchers/utils';

const getOriginalPackagesPending = () => ({
    type: actionTypes.GET_ORIGINAL_PACKAGES_PENDING,
});

const getOriginalPackagesSuccess = (
    data,
    externalColorCode,
    internalColorCode,
    trimLevel,
    originalAdditionsCode,
) => ({
    type: actionTypes.GET_ORIGINAL_PACKAGES_SUCCESS,
    data,
    sColor: `${externalColorCode}${internalColorCode}`,
    trimLevel,
    originalAdditionsCode,
});

const getOriginalPackagesFailure = (error) => ({
    type: actionTypes.GET_ORIGINAL_PACKAGES_FAILURE,
    error,
});

export const getOriginalPackagesAsync = (
    modelCode,
    trimLevelCode,
    externalColorCode,
    internalColorCode,
    trimLevel,
    originalAdditionsCode,
) => {
    return async (dispatch) => {
        dispatch(getOriginalPackagesPending());
        try {
            const response = await getOriginalPackages(
                modelCode,
                trimLevelCode,
                externalColorCode,
                internalColorCode,
                originalAdditionsCode,
            );
            const { data } = response;

            const parsedData = data.map(
                (hoop) => parseOriginalPackageToHoops(hoop, trimLevel, originalAdditionsCode));

            dispatch(getOriginalPackagesSuccess(parsedData, externalColorCode, internalColorCode));

            return parsedData;
        } catch (error) {
            dispatch(getOriginalPackagesFailure(error));

            return [];
        }
    };
};
