import React, {
    useState,
    createContext,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as TP, createGlobalStyle } from 'styled-components';
import { MENUFACTURS } from '../constants';

const defaultTheme = require('./themes/champion.json');

const THEMES = {
    [MENUFACTURS.SEAT]: require('./themes/seat.json'),
    [MENUFACTURS.SKODA]: require('./themes/skoda.json'),
    [MENUFACTURS.AUDI]: require('./themes/audi.json'),
    [MENUFACTURS.VOLKSWAGEN]: require('./themes/volkswagen.json'),
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: require('./themes/volkswagenCommercial.json'),
    [MENUFACTURS.CUPRA]: require('./themes/cupra.json'),
};

const GlobalStyle = createGlobalStyle`
  body{
      font-family: ${({ theme }) => theme?.['font-family']}
  }
`;

export const ThemeContext = createContext({
    /**
     * 
     * @param {keyof MENUFACTURS} brandName 
     */
    setTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
    const [theme, _setTheme] = useState(null);

    /**
     * 
     * @param {keyof MENUFACTURS} brandName 
     */
    const setTheme = useCallback(
        
        (brandName, subBrand) => {
            if (!brandName) {
                return;
            }
           
            const isCupra = brandName === MENUFACTURS.SEAT && subBrand === MENUFACTURS.CUPRA;
            const brand = isCupra ? subBrand : brandName;

            switch (brand) {
                case MENUFACTURS.SKODA:
                    _setTheme(MENUFACTURS.SKODA);
                    break;
                case MENUFACTURS.SEAT:
                    _setTheme(MENUFACTURS.SEAT);
                    break;
                case MENUFACTURS.AUDI:
                    _setTheme(MENUFACTURS.AUDI);
                    break;
                case MENUFACTURS.VOLKSWAGEN:
                    _setTheme(MENUFACTURS.VOLKSWAGEN);
                    break;
                case MENUFACTURS.VOLKSWAGEN_COMMERCIAL:
                    _setTheme(MENUFACTURS.VOLKSWAGEN_COMMERCIAL);
                    break;
                case MENUFACTURS.CUPRA:
                    _setTheme(MENUFACTURS.CUPRA);
                    break;
                default:
                    break;
            }
        }, []);

    const value = useMemo(() => ({ setTheme }), [setTheme]);
        
    return (
        <ThemeContext.Provider displayName="ThemeContext" value={ value }>
            <TP theme={ THEMES[theme] ?? defaultTheme }>
                <GlobalStyle />
                {children}
            </TP>
        </ThemeContext.Provider>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ThemeContext;
