import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';
import { ContinueThemeButton } from
    '../../../../components/ContinueThemeButton/ContinueThemeButton.styles';

export const PaymentSuccess = styled.div`
  ${ContinueThemeButton}{
       margin-right:auto;
  }
`;
export const Title = styled.h1`
  margin:0 0 15px 0;
  font-size:${pxToRem(55)};
  line-height: 1;
  max-width:55%;
`;
export const SubTitle = styled.h2`
  margin:0;
  font-size:${pxToRem(25)};
  max-width: ${({ isMobile }) => isMobile ? '100%' : '43%'};
  font-weight:500;
`;
export const Disclaimer = styled.p`
  margin:15px 0 0 0;
  font-weight:400;
  font-size:${pxToRem(15)};

`;

export const ActionContainer = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const Action = styled.button`
border:none;
outline:none;
background-color:transparent;
padding:0;
font-family: ${({ theme }) => theme['font-family']};
font-size:${pxToRem(15)};
font-weight:bold;
text-decoration:underline;
display:flex;
align-items:center;
margin-bottom:10px;
cursor:pointer;

&:before{
    content:'';
    width:24px;
    height:24px;
    display:block;
    background-image:url(${({ $icon }) => $icon});
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    margin-left:10px;
}
`;
