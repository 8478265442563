import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Image.styles';

const Image = ({ image, isDefaultImage }) => {
    return <Styled.Image $isDefaultImage={ isDefaultImage } $image={ image } />;
};

Image.defaultProps = {
    isDefaultImage: false,
};

Image.propTypes = {
    image: PropTypes.string.isRequired,
    isDefaultImage: PropTypes.bool,
};

export default React.memo(Image);
