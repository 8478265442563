import styled from 'styled-components';
import { COLORS } from '../../constants';

export const CheckBox = styled.div.attrs(({ $isRequired }) => ({
    'data-required': $isRequired,
}))`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ $isErrorShown }) =>  $isErrorShown ? COLORS.COLOR_ERROR_CONTACT_US_FIELD  : COLORS.BLACK};
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    background-image: url(${({ $checkMarkIcon }) => $checkMarkIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85% 75%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
