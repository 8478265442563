import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Styled from './ImagesSlider.style';

const ImagesSlider = ({
    images,
}) => {
    const slides = images?.map((image) => {
        return (
            <SwiperSlide>
                <Styled.Image key={ image } src={ image } />
            </SwiperSlide>
        );
    });

    return (
        <Styled.Container>
            <Swiper
                dir="rtl"
                modules={ [Navigation, Pagination] }
                navigation={ {
                    enabled: true,
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                    disabledClass: 'swiper-button-disabled',
                } }
                pagination={ {
                    dynamicBullets: true,
                    clickable: true,
                } }
                slidesPerView={ 1 }
                centerInsufficientSlides
                loop
            >
                <Styled.NavigationButton className="custom-swiper-button-prev right">
                    <Styled.RightIcon />
                </Styled.NavigationButton>
                <Styled.NavigationButton className="custom-swiper-button-next left">
                    <Styled.LeftIcon />
                </Styled.NavigationButton>
                { slides }
            </Swiper>
        </Styled.Container>
    );
};

ImagesSlider.propTypes = {
    images: PropTypes.array.isRequired,
};

export default ImagesSlider;
