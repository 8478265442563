import styled from 'styled-components';
import { configurationLeftScrollBar } from '../../../components/common/styles';
import { pxToRem } from '../../../utils/commonUtils';
import CONFIG from '../../../config';
import { COLORS, MENUFACTURS  } from '../../../constants';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  position: fixed;
  z-index: 150 ;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.show-up-enter .modal-content {
    transform: translateX(100%);
  }
  
  &.show-up-enter-active .modal-content {
    transform: translateX(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .modal-content {
    transform: translateX(100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    justify-content: center;
    align-items: flex-end;
    &.show-up-enter .modal-content {
      transform: translateY(100%);
    }
    
    &.show-up-enter-active .modal-content {
      transform: translateY(0);
      transition: all 400ms;
    }
    
    &.show-up-exit-active .modal-content {
      transform: translateY(100%);
      transition: all 400ms;
    }
    &.show-up-exit-active {
      transition: all 500ms;
    }
  }
`;

export const SpecificationsModalContainer = styled.div`
  position: relative;
  width: 45%;
  height: 100%;
  z-index: 200;
  padding-top: 40px;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 50%);
  border-radius: 2px;
  background-color: ${COLORS.BG_COLOR_MODAL};
  display: flex;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex-direction: column-reverse;
    padding: 5px;
    width: calc(100% - 1rem);
    height: calc(100% - 5rem);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

  }
`;

export const CloseButton = styled.p`
  position: absolute;
  top: -10px;
  left: 30px;
  align-self: center;
  font-weight: 500;
  font-size: ${pxToRem(16)};
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "\\00d7";
    padding: ${({ $brand }) => $brand === MENUFACTURS.SEAT ? '0 0 3px 0' : '0 0 0 0'};
    font-size: ${pxToRem(28)};
    font-weight: 300;
    margin-left: 3px;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    left: auto;
  }
`;

export const SpecificationsContainer = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${configurationLeftScrollBar};
  overflow-y: scroll;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    overflow-x: hidden;
    padding:5px;
    display:block;
    max-height: 95%;
  }
`;
