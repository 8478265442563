import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider/Divider';
import Styled from './OriginalAdditionSection.style';

const OriginalAdditionSection = ({
    isIncluded,
}) => {
    return (
        <Styled.Container>
            <Styled.Dummy />
            { isIncluded ? (
                <Styled.CheckMarkIcon />
            ) : <Styled.CloseIcon /> }
            <Divider />
        </Styled.Container>
    );
};

OriginalAdditionSection.propTypes = {
    isIncluded: PropTypes.bool.isRequired,
};

export default OriginalAdditionSection;
