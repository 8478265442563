import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIconFromAssets } from '../../hooks';
import { ICONS_NAMES } from '../../constants';
import STRINGS from '../../language';
import { isArrayAndNotEmpty } from '../../utils/commonUtils';
import * as Styled from './MainCharacteristics.styles';

const MainCharacteristics = ({
    characteristics,
    onSpecificationLinkClick,
}) => {
    const newWindowLinkIcon = useIconFromAssets(ICONS_NAMES.NEW_WINDOW_LINK);
    const [isActive, setIsActive] = useState(false);
    const toggleListHandler = () => {
        setIsActive((p) => !p);
    };
    const plusIcon = useIconFromAssets(ICONS_NAMES.PLUS);
    const closeIcon = useIconFromAssets(ICONS_NAMES.CLOSE);

    const renderMainCharacteristics = () => {
        if (isArrayAndNotEmpty(characteristics)) {
            return (
                <>
                    <Styled.TitleAndSpecificationWrapper>
                        <Styled.MainCharacteristicsTitle
                            $icon={ !isActive ? plusIcon : closeIcon }
                            onClick={ toggleListHandler }
                        >
                            {STRINGS.MAIN_CHARACTERISTIC}
                        </Styled.MainCharacteristicsTitle>
                        <Styled.Specification
                            onClick={ onSpecificationLinkClick }
                            $icon={ newWindowLinkIcon }
                        >
                            {STRINGS.TO_SPECIFICATIONS}
                        </Styled.Specification>
                    </Styled.TitleAndSpecificationWrapper>
                    <Styled.CharacteristicsList $isActive={ isActive }>
                        {characteristics.map((characteristic) => (
                            <Styled.CharacteristicsItem key={ characteristic }>
                                {characteristic}
                            </Styled.CharacteristicsItem>
                        ))}
                    </Styled.CharacteristicsList>
                </>
            );
        }

        return null;
    };

    return (
        <Styled.MainCharacteristics>
            {renderMainCharacteristics()}
        </Styled.MainCharacteristics>
    );
};

MainCharacteristics.defaultProps = {
    characteristics: [],
};

MainCharacteristics.propTypes = {
    characteristics: PropTypes.array,
    onSpecificationLinkClick: PropTypes.func.isRequired,
};

export default MainCharacteristics;
