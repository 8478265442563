import styled from 'styled-components';

export const Body = styled.div`
    padding: 1rem 3rem;

    @media (max-width: 550px) {
        padding: 1rem;
    }
`;

export const Title = styled.h1`
    margin: 20px auto;
    font-size: 3rem;
    font-weight: 800;
    text-align: right;
    color: ${({ theme }) => theme?.primaryColor ?? 'inherit'};
    line-height: 100%;
`;

export const SubTitle = styled.h2`
    width: 80%;
    margin: 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: right;
    line-height: 100%;

    @media (max-width: 1100px) {
        width: 100%;
    }
`;

export const TextLine = styled.p`
    width: 80%;
    font-size: 1.3rem;
    font-weight: normal;
    text-align: right;
    line-height: 120%;
    margin: 20px 0;

    @media (max-width: 1100px) {
        width: 100%;
    }
`;

export const LegalText = styled(TextLine)`
    margin: 0;
    font-size: 1rem;
`;

export const CarImageContainer = styled.div`
    display: block;
    margin: 100px auto;
    width: 100%;
    max-width: 500px;
    float: left;
    text-align: center;
`;

export const ActionsBlock = styled.div`
    margin-top: 100px;
    padding: 0 4rem;
    border-right: 4px solid #AAAAAA;

    @media (max-width: 550px) {
        margin-top: 40px;
        padding: 0;
        border: 0;
    }
`;

export const Link = styled.a.attrs(() => ({ target: '_blank' }))`
    display: inline-block;
    color: ${({ theme }) => theme?.primaryColor ?? 'inherit'};
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.3rem;
    transition: 0.2s all;

    &:hover {
        color: ${({ theme }) => theme?.secondaryColor ?? 'inherit'};
    }
`;

export const TextLineSpan = styled.span`
    margin-inline-end: 0.5ch;
`;

export const BoldTextLineSpan = styled(TextLineSpan)`
    font-weight: bold;
`;

export const IconRow = styled(TextLine)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    :before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(${({ $icon }) => $icon}) no-repeat center center;
        background-size: ${({ width = 18, height = 18 }) => `${width}px ${height}px`};
        margin-left: 15px;
        vertical-align: middle;
        border: 2px solid #333333;
        border-radius: 100%;
        padding: 7px;
    }
`;

export const TextLineContainer = styled.div`
    flex: 1;
`;
