import React from 'react';
import PropTypes from 'prop-types';
import ModelImage from '../../../../../Steps/Model/ModelImage/ModelImage';
import {
    getCarPlaceholderImageUrl,
    getOnelineCarMenuThumbail,
} from '../../../../../../utils/assetsUtils';
import STRINGS from '../../../../../../language';
import * as Styled from './OnlineCarItem.style';

const OnlineCarItem = ({
    hebrewModelGroup, href, onCloseNav, brand, modelGroupCode, furtherInfoHref,
}) => {
    const imageSrc = getOnelineCarMenuThumbail(brand, modelGroupCode);
    const renderFurtherInfoHref = () => {
        if (!furtherInfoHref) {
            return null;
        }

        return (
            <Styled.MoreInfoLink target="_blank" to={ { pathname: furtherInfoHref } }>
                {STRINGS.FOR_FURTHER_INFORMATION_REGARDING_THIS_MODEL}
            </Styled.MoreInfoLink>
        );
    };

    return (
        <Styled.OnlineCarItem>
            <Styled.OnlineCarLink to={ href }>
                <Styled.OnLineCar onClick={ onCloseNav }>
                    <Styled.ModelName>
                        { hebrewModelGroup }
                    </Styled.ModelName>
                    <ModelImage
                        alt={ hebrewModelGroup }
                        src={ imageSrc }
                        fallbackSrc={ getCarPlaceholderImageUrl(brand) }
                    />
                </Styled.OnLineCar>
            </Styled.OnlineCarLink>
            {renderFurtherInfoHref()}
        </Styled.OnlineCarItem>
    );
};

OnlineCarItem.defaultProps = {
    hebrewModelGroup: '',
    href: '',
    onCloseNav: () => {},
    furtherInfoHref: null,
};

OnlineCarItem.propTypes = {
    hebrewModelGroup: PropTypes.string,
    href: PropTypes.string,
    onCloseNav: PropTypes.func,
    brand: PropTypes.string.isRequired,
    modelGroupCode: PropTypes.number.isRequired,
    furtherInfoHref: PropTypes.string,

};

export { OnlineCarItem as AudiOnlineCarItem };
