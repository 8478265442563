import styled from 'styled-components';

export const Navigation = styled.nav``;
export const NavigationMenu = styled.ul`
 margin:0;
 padding:0;
 list-style-type:  none;
 columns:2;
 column-gap:40px;
`;
