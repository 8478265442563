import styled from 'styled-components';
import { COLORS } from '../../constants';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

export const OnlineCarsMenu = styled.ul`
/**
initial state set to display:none; it changes to display:flex upon hover (on desktop and higher resolutions)
*/
  display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none '};
  padding: 25px;
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: 65px;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.BORDER_COLOR_ONLINE_CARS_MENU};
  background-color: ${COLORS.BG_COLOR_ONLINE_CARS};
  &::before {
    content: "";
    background: #fff;
    border: 1px solid;
    border-color: ${COLORS.BORDER_COLOR_ONLINE_CARS_MENU} transparent
      transparent ${COLORS.BORDER_COLOR_ONLINE_CARS_MENU};
    width: 8px;
    height: 8px;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    z-index: 1;
    right: 105px;
  }
  @media(min-width:${DEVICE_SIZE.LARGE}px){
    max-width: 1152px; // Sum of the width of 4 OnlineCarItems + gap between them + some padding
    gap: 10px;
  }
  @media(max-width:${DEVICE_SIZE.LARGE}px){
    max-width:96%;
    justify-content: center;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    /**
       handle isVisible prop which is being handled by click event only on mobile
    */
    display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none '};
    position: static;
    padding: 40px 10px;
    border: none;
    &::before {
      content: '';
      display: none;
    }
  }
  
`;
