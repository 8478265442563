import React from 'react';
import STRINGS from '../../../language';
import { ROUTES } from '../../../constants';
import FooterNavigation from '../FooterNavigation/FooterNavigation';
import FooterNavigationItem from '../FooterNavigationItem/FooterNavigationItem';
import { useCurrentBrand } from '../../../hooks';
import { getAccessibilityDeclarationLink, getWarrentyLink } from '../../../utils/assetsUtils';

const navItems = (brand) => [
    {
        title: STRINGS.CAR_BOOKING_AGREEMENT_AND_CANCELLATION_POLICY,
        href: ROUTES.ORDER_AND_CANCEL_TERMS,
    },
    { title: STRINGS.TERMS_OF_WARRANTY, href: getWarrentyLink(brand) },
    { title: STRINGS.TERMS, href: ROUTES.TAKANON },
    { title: STRINGS.PRIVACY_POLICY, href: ROUTES.PRIVACY_POLICY },
    { title: STRINGS.QUESTIONS_AND_ANSWERS, href: ROUTES.FAQ },
    { title: STRINGS.CONTACT, href: ROUTES.CONTACT },

];

const OnLinePurchaseNav = () => {
    const currentBrand = useCurrentBrand();

    const renderFooterNavigationItems = (_navItems) => {
        return _navItems.map((navItem) => {
            const { title, href } = navItem;
            const target = title === STRINGS.TERMS_OF_WARRANTY ? '_blank' : null;
            
            return (
                <FooterNavigationItem
                    key={ title }
                    title={ title }
                    href={ href }
                    target={ target }
                />
            );
        });
    };
    
    return (
        <FooterNavigation>
            {renderFooterNavigationItems(navItems(currentBrand))}
            <FooterNavigationItem
                title={ STRINGS.ACCESSIBILITY_STATMENT }
                target="_blank"
                href={ getAccessibilityDeclarationLink(currentBrand) }
            />
        </FooterNavigation>
    );
};

export default OnLinePurchaseNav;
