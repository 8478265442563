import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { pxToRem } from '../../../utils/commonUtils';

export const AdditionalInformation = styled.div`
  margin-top: ${({ $isAudi }) => $isAudi ? '70px' : '25px'};
  ${({ $isInSummary }) => $isInSummary && css`
    padding: 0 20px;
  `}
`;
export const StockDetails = styled.p`
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: ${pxToRem(14)};
`;

export const AdditionalInformationList = styled.ul`
  padding: 10px 40px 10px 0;
  ${({ $isAudi }) => $isAudi
    && css`
      list-style-type: none;
      padding: 0;
    `}
`;
export const AdditionalInformationItem = styled.li`
  font-weight: 500;
  font-size: ${pxToRem(12)};
  margin-bottom:10px;
  line-height: 1.6;
`;
export const CarInfoLink = styled(Link)`
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor};
  padding:0 5px;
`;
