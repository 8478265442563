import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import * as Styled from './LicenseFeePrice.styles';

const LicenseFeePrice = ({
    licenseFee,
    price,
    showDetailedPrice,
}) => {
    return (
        <Styled.LicenseFeePrice>
            {showDetailedPrice && `${STRINGS.DETAILED_PRICE}: `}
            <Styled.LicenseFeePriceSpan>{ `${STRINGS.CAR_PRICE} ₪${price} `}</Styled.LicenseFeePriceSpan>
            <Styled.LicenseFeePriceSpan>{ `${STRINGS.LICENSE_FEE} ₪${licenseFee} `}</Styled.LicenseFeePriceSpan>
        </Styled.LicenseFeePrice>
    );
};

LicenseFeePrice.propTypes = {
    licenseFee: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    showDetailedPrice: PropTypes.bool,
};

LicenseFeePrice.defaultProps = {
    showDetailedPrice: true,
};

export default LicenseFeePrice;
