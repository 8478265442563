import styled from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const ConfiguratorLayout = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

export const ConfiguratorContent = styled.section`
  display: flex;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;
