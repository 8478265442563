import styled from 'styled-components';
import { pxToRem } from '../../../../../utils/commonUtils';
import { Select } from '../../../../../components/Select/Select.styles';
import { COLORS, ICONS_NAMES, MENUFACTURS } from '../../../../../constants';
import CONFIG from '../../../../../config';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';

const { DEVICE_SIZE, brand } = CONFIG;

const MARGIN_RIGHT = brand === MENUFACTURS.VOLKSWAGEN_COMMERCIAL ? '0' : '5.5rem';

const arrowIcon = getIconFromAssets(ICONS_NAMES.MENU_ARROW_DOWN_BLACK);

export const PickUp = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #e1e1e1;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding-bottom: 15px;
  }
`;
export const Content = styled.div`
  margin-right: ${({ $isAudi }) => $isAudi ? '7.5rem' : MARGIN_RIGHT};
  ${Select} {
    border: 1px solid ${COLORS.BLACK};
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    outline: none;
    box-shadow: none;
    width: 100%;
    max-width: 380px;
    appearance: none;
    cursor: pointer;
    margin-bottom: 12px;
    background-image: url(${arrowIcon});
    background-repeat: no-repeat;
    background-size: 12px;
    background-position-y: 50%;
    background-position-x: 12px;
    &::-webkit-scrollbar {
      width: ${({ isScroller }) => isScroller ? null : 0}; // 0 = hide scroller
    }
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-right: 0;
  }
`;

export const Title = styled.h2`
  margin: 0 0 8px 0;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: 26px;
`;
export const Label = styled.legend`
  margin: 0 0 12px 0;
  font-weight: 400;
  font-size: ${pxToRem(19)};
  line-height: 27px;
`;

export const Disclaimer = styled.p`
  margin: 5px 0;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: 23px;
`;

export const StepHeadContainer = styled.div`
  display: inline-flex;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    flex-direction: ${({ $isAudi }) => !$isAudi && 'column'};
  }
`;

export const TitleContainer = styled.div``;
