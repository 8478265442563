import React from 'react';
import PropTypes from 'prop-types';
import { LTR_DATA_ITEMS } from './EngineDataItem.config';
import * as Styled from './EngineDataItem.styles';

const EngineDataItem = ({ title, value }) => {
    return (
        <Styled.EngineDataItem>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Value
                isLtr={ LTR_DATA_ITEMS.includes(title) }
            >
                {value}
            </Styled.Value>
        </Styled.EngineDataItem>
    );
};

EngineDataItem.defaultProps = {
    title: '',
    value: '',
};

EngineDataItem.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

export default EngineDataItem;
