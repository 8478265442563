import React from 'react';
import PropTypes from 'prop-types';
import CONFIG from '../../config';
import STRING from '../../language';
import Styled from './Loader.style';

const Loader = ({ size, loadingMessage }) => {
    return (
        <Styled.Container size={ size }>
            <Styled.Loader size={ size }>
                <div />
                <div />
                <div />
                <div />
            </Styled.Loader>
            {!!loadingMessage && <Styled.Message>{loadingMessage}</Styled.Message>}
        </Styled.Container>
    );
};

export default Loader;

Loader.propTypes = {
    size: PropTypes.number,
    loadingMessage: PropTypes.string,
};

Loader.defaultProps = {
    size: CONFIG.LOADER.SIZE,
    loadingMessage: STRING.LOADING_MESSAGE,
};
