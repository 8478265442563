import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
  max-width: 1600px;
  margin: auto auto 30px auto;
`;

const ErrorWrapper = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.ul`
  display: flex;
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
  gap: 56px;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    justify-content: center;
  }
`;

const Styled = {
    Container,
    ErrorWrapper,
    ListWrapper,
};

export default Styled;
