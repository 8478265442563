import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './EquipmentPackageItem.styles';

const EquipmentPackageItem = ({
    equipmentPackage,
    equipmentPackageImage,
}) => {
    return (
        <Styled.SingleEquipmentPackageContainer>
            <Styled.EquipmentPackageItem>
                <Styled.EquipmentPackageNameAndImage
                    $image={ equipmentPackageImage }
                >
                    { equipmentPackage }
                </Styled.EquipmentPackageNameAndImage>
            </Styled.EquipmentPackageItem>
        </Styled.SingleEquipmentPackageContainer>
    );
};

EquipmentPackageItem.defaultProps = {
    equipmentPackage: undefined,
    equipmentPackageImage: undefined,
};

EquipmentPackageItem.propTypes = {
    equipmentPackage: PropTypes.string,
    equipmentPackageImage: PropTypes.string,
};

export default EquipmentPackageItem;
