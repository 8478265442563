import React from 'react';
import PropTypes from 'prop-types';
import CarRank from '../../../Model/ModelItemCollapsible/CarRank/CarRank';
import STRINGS from '../../../../../language';
import SelectButton from '../SelectButton/SelectButton';
import Styled from './AdditionalOptionFooter.styles';

const AdditionalOptionFooter = ({
    hrefToConfigurator,
    onClick,
    safetyLevel,
    pollutionLevel,
}) =>  {
    return (
        <Styled.Container>
            <SelectButton
                to={ hrefToConfigurator }
                onClick={ onClick  }
            >
                {STRINGS.PICK}
            </SelectButton>
            <CarRank
                safety={ safetyLevel }
                pollution={ pollutionLevel }
            />
        </Styled.Container>
    );
};

AdditionalOptionFooter.propTypes = {
    hrefToConfigurator: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    safetyLevel: PropTypes.array.isRequired,
    pollutionLevel: PropTypes.array.isRequired,
};

export default AdditionalOptionFooter;
