/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { getHomePageThumbnail } from '../../../../utils/assetsUtils';
import STRINGS from '../../../../language';
import {
    getCurrentBrandFromEnv,
    replacePlaceholdersInTemplate,
} from '../../../../utils/commonUtils';
import modelCarShape from '../modelItemShape';
import { useCurrentConfigOptions } from '../../../../hooks';

import Header from '../ModelItemCollapsible/HeaderDesktop/HeaderDesktop';
import MainCharacteristicsAndSpecification from '../ModelItemCollapsible/MainCharacteristicsAndSpecification/MainCharacteristicsAndSpecification';
import FullPriceAndTooltip from '../ModelItemCollapsible/FullPriceAndTooltip/FullPriceAndTooltipt';
import CarRank from '../ModelItemCollapsible/CarRank/CarRank';
import LicenseFeePrice from '../ModelItemCollapsible/LicenseFeePrice/LicenseFeePrice';
import * as Styled from './ModelItem.styles';

const ModelItem = ({
    model, isCupra,
}) => {
    const {
        price: carPrice,
        id,
        licenseFee,
        mainCharacteristics,
        originalAdditionsCode,
        modelDescription,
        modelCode,
        description,
        safetyEquipmentLevel,
        pollutionLevel,
        modelGroupCode,
        trimLevelCode,
        hrefToConfigurator,
        carHasDiscount,
        arrivalDate,
        carVersion,
    } = model;

    const { equipmentPackages } = useCurrentConfigOptions();
    const { isLoading } = equipmentPackages;

    const price = parseFloat(carPrice).toLocaleString();
    const licenseFeePrice = parseFloat(licenseFee).toLocaleString();
    const fullPrice = parseFloat(licenseFee) + parseFloat(carPrice);
    const figCaption = modelDescription;
    const brand = getCurrentBrandFromEnv();
    const imageSrc = getHomePageThumbnail(brand, modelGroupCode, trimLevelCode);
    const imageAlt = replacePlaceholdersInTemplate(
        STRINGS.PREVIEW_OF_MODEL_DESCRIPTION,
        [modelDescription],
    );
    const hideTooltip = isCupra || !carHasDiscount;

    return (
        <Styled.ModelItem id={ id }>
            <Styled.Car>
                <Styled.UpperPart>
                    <Header
                        imageAlt={ imageAlt }
                        imageSrc={ imageSrc }
                        brand={ brand }
                        figCaption={ figCaption }
                        href={ hrefToConfigurator }
                    />
                    <Styled.Body>
                        <Styled.Description>{description}</Styled.Description>
                        <FullPriceAndTooltip
                            hideTooltip={ hideTooltip }
                            fullPrice={ fullPrice.toLocaleString() }
                        />
                        <Styled.ArrivelDate>{ arrivalDate }</Styled.ArrivelDate>
                        <MainCharacteristicsAndSpecification
                            mainCharacteristics={ mainCharacteristics }
                            groupModelCode={ modelGroupCode }
                            modelCode={ modelCode }
                            modelDescription={ modelDescription }
                            version={ carVersion }
                            id={ id }
                            trimLevelCode={ trimLevelCode }
                            originalAdditionsCode={ originalAdditionsCode }
                        />
                    </Styled.Body>
                </Styled.UpperPart>
                
                <Styled.Footer>
                    <Styled.SelectButton to={ hrefToConfigurator }
                        $disabled={ isLoading }
                        $isCupra={ isCupra }
                    >
                        {STRINGS.CHOOSE}
                    </Styled.SelectButton>
                    <Styled.FullPriceDescriptionMobileOnly>
                        {STRINGS.PRICE_INCLUDES_VAR_AND_LICENSING_FEE}
                    </Styled.FullPriceDescriptionMobileOnly>
                    <LicenseFeePrice price={ price } licenseFee={ licenseFeePrice } />
                    <CarRank
                        safety={ safetyEquipmentLevel }
                        pollution={ pollutionLevel }
                    />
                </Styled.Footer>
            </Styled.Car>
        </Styled.ModelItem>
    );
};

ModelItem.propTypes = {
    model: PropTypes.shape(modelCarShape).isRequired,
    isCupra: PropTypes.bool.isRequired,
};

export default ModelItem;
