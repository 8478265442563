import styled, { css } from 'styled-components';
import { COLORS } from '../../constants';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { pxToRem } from '../../utils/commonUtils';

export const LeftArrowIcon = styled(LeftArrow)`
  transition: transform .2s ease-in-out;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: 'white';
`;

export const ContinueThemeButton = styled.button.attrs(({ $isDisabled }) => ({
    disabled: $isDisabled,
}))`
flex: 1.5;
width: 100%;
max-width: 265px;
padding: 0 23px;
font-family: ${({ theme }) => theme['font-family']};
font-weight: 700;
font-size: ${pxToRem(15)};
border: none;
border-radius: ${({ theme }) => theme.buttonButtonRadius};
outline: none;
background-color: ${({ theme }) => theme.buttonColor};
height: ${({ height }) => `${height}px`};
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
color: ${({ theme }) => theme.buttonTextColor};
${({ $isSubmitting }) => !$isSubmitting && css`
@media (hover: hover){
  &:hover{
    ${LeftArrowIcon} {
      transform:translateX(-5px);
    }
    color: ${({ theme }) => theme.buttonTextHoverColor};
    background-color: ${({ theme }) =>  theme.buttonHoverBackgroundColor};
    border: ${({ $isCupra }) =>  $isCupra ? `2px solid ${COLORS.BLACK}` : 'none'};
    transition: background-color 0.6s;
  }
}
`};
//if button disabled
${({ $isDisabled }) => $isDisabled
  && css`
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  `}

  ${({ $isAudi }) => $isAudi && css`
    flex: auto;
    width: 260px;
  `}
`;
