import React from 'react';
import PropTypes from 'prop-types';
import ModelImage from '../../Steps/Model/ModelImage/ModelImage';
import {
    getCarPlaceholderImageUrl,
    getOnelineCarMenuThumbail,
} from '../../../utils/assetsUtils';
import STRINGS from '../../../language';
import * as Styled from './OnlineCarItem.styles';
/**
 * TODO : replace all the hrefs with real ones
 */

const OnlineCarItem = ({
    hebrewModelGroup, href, onCloseNav, brand, modelGroupCode, furtherInfoHref,
}) => {
    const imageSrc = getOnelineCarMenuThumbail(brand, modelGroupCode);
    const renderFurtherInfoHref = () => {
        if (!furtherInfoHref) {
            return null;
        }

        return (
            <Styled.MoreInfoLink target="_blank" to={ furtherInfoHref }>
                {STRINGS.FOR_FURTHER_INFORMATION_REGARDING_THIS_MODEL}
            </Styled.MoreInfoLink>
        );
    };

    return (
        <Styled.OnlineCarItem>
            <Styled.OnlineCarLink to={ href }>
                <Styled.OnLineCar onClick={ onCloseNav }>
                    <ModelImage
                        alt={ hebrewModelGroup }
                        src={ imageSrc }
                        fallbackSrc={ getCarPlaceholderImageUrl(brand) }
                        figcaption={ hebrewModelGroup }
                    />
                    <Styled.ToPurchase>
                        <Styled.Text>
                            {STRINGS.FOR_FURTHER_INFORMATION_AND_PURCHASE}
                        </Styled.Text>
                    </Styled.ToPurchase>
                </Styled.OnLineCar>
            </Styled.OnlineCarLink>
            {renderFurtherInfoHref()}
        </Styled.OnlineCarItem>
    );
};

OnlineCarItem.defaultProps = {
    hebrewModelGroup: '',
    href: '',
    onCloseNav: () => {},
    furtherInfoHref: null,
};

OnlineCarItem.propTypes = {
    hebrewModelGroup: PropTypes.string,
    href: PropTypes.string,
    onCloseNav: PropTypes.func,
    brand: PropTypes.string.isRequired,
    modelGroupCode: PropTypes.number.isRequired,
    furtherInfoHref: PropTypes.string,

};

export default OnlineCarItem;
