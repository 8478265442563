import styled, { css }  from 'styled-components';
import { Link } from 'react-router-dom';
import CONFIG from '../../../../config';
import { pxToRem } from '../../../../utils/commonUtils';
import { COLORS } from '../../../../constants';
import { ImageBox, FigCaption } from '../ModelImage/ModelImage.styles';
import { HeaderDesktop } from '../ModelItemCollapsible/HeaderDesktop/HeaderDesktop.styles';
import {
    FullPriceDescription,
    FullPriceAndTooltip,
    PriceTitle,
} from '../ModelItemCollapsible/FullPriceAndTooltip/FullPriceAndTooltip.styles';

const { DEVICE_SIZE } = CONFIG;

export const ModelItem = styled.li`
  flex: 0 0 25%;
  max-width: 400px;
  min-width: 400px;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;

    ${HeaderDesktop}{
      display: block;
      & header{
        max-width:100%;
      }
    }
    ${FullPriceAndTooltip}{
      display: flex;
      justify-content: center;
      margin-top:10px;
    }
    ${ImageBox}{
      max-width:200px;
    }
    ${FigCaption}{
      justify-content: center;
    }
    ${PriceTitle}{
      display:none;
    }

  }

`;

export const Car = styled.div`
  padding: 35px 25px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding: 25px 15px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UpperPart = styled.div`
`;

export const Body = styled.div`
  max-width: 250px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
  }
`;

export const SelectButton = styled(Link)`
  width: 100%;
  min-width: 108px;
  max-width: 280px;
  height: 38px;
  font-weight: 700;
  line-height: 1;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  border: none;
  outline: none;
  padding: 0 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.buttonTextColor};
  font-size: ${pxToRem(16)};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.buttonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 30px;

  @media (min-width: ${DEVICE_SIZE.LARGE}px) {
    &:hover{
    color: ${({ theme }) => theme.buttonTextHoverColor};
    background-color: ${({ theme }) =>  theme.buttonHoverBackgroundColor};
      border: ${({ $isCupra }) => $isCupra ?  `2px solid ${COLORS.BLACK}` : 'none'};;
      transition: background-color 0.5s;
    }
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: auto;
    max-width: 50%;
    height: 50px;
    margin:0 auto 25px auto;
  }

 ${({ $disabled }) => $disabled && css`
  background-color: gray;
  pointer-events: none;
 `};

`;
export const Description = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${pxToRem(13)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    text-align:center;
  }
`;

export const ArrivelDate = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(13)};
  margin: 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const FullPriceDescriptionMobileOnly = styled(FullPriceDescription)`
  font-size: ${pxToRem(16)};
  margin-bottom: 9px;
  display: none;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;
export const Footer = styled.footer`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    text-align: center;
  }
`;
