import styled from 'styled-components';
import { pxToRem } from '../../../../utils/commonUtils';

export const Engine = styled.div`
//
`;
export const Text = styled.p`
  margin:0;
  font-size:${pxToRem(15)};
  font-weight:400;
  padding:10px;
`;

export const Title = styled(Text)`

`;

export const Header = styled.header`
  margin-bottom: 1rem;
`;
