export const getOriginalPackagesPending = (state) => ({
    ...state,
    originalPackages: {
        ...state.originalPackages,
        isLoading: true,
    },
});

export const getOriginalPackagesSuccess = (state, action) => {
    const {
        data,
        sColor,
    } = action;
    
    return {
        ...state,
        originalPackages: {
            ...state.originalPackages,
            packages: {
                ...state.originalPackages.packages,
                [sColor]: data,
            },
            isLoading: false,
            hoops: data,
        },
    };
};

export const getOriginalPackagesFailure = (state, action) => {
    const { error } = action;
  
    return {
        ...state,
        originalPackages: {
            ...state.originalPackages,
            error,
            isLoading: false,
        },
    };
};
