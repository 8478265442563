import React from 'react';
import PropTypes from 'prop-types';
import TogglePresentationBtn from '../TogglePresentationBtn/TogglePresentationBtn';
import  * as Styled from './Presentation.styles';

const Presentation = ({
    children,
    onToggle,
    isMediaVisible,
    isButtonShown,
    isSingleImage,
}) => {
    const renderToggleButton = () => {
        if (isButtonShown) {
            return (
                <TogglePresentationBtn
                    isMediaVisible={ isMediaVisible }
                    isVisible={ isButtonShown }
                    onToggle={ onToggle }
                />
                
            );
        }

        return null;
    };

    return (
        <Styled.Presentation $isSingleImage={ isSingleImage }>
            {renderToggleButton()}
            {children}
        </Styled.Presentation>
    );
};

Presentation.defaultProps = {
    onToggle: () => {},
    isMediaVisible: false,
    isButtonShown: false,
    isSingleImage: false,
    children: null,
};

Presentation.propTypes = {
    onToggle: PropTypes.func,
    children: PropTypes.node,
    isMediaVisible: PropTypes.bool,
    isButtonShown: PropTypes.bool,
    isSingleImage: PropTypes.bool,
};

export default Presentation;
