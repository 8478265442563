import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import STRINGS from '../../../../../language';
import {
    GTM_EVENT_TYPES,
    ICONS_NAMES,
} from '../../../../../constants';
import {
    consoleLogInDev, getManufacturerContactInfo, replacePlaceholdersInTemplate,
} from '../../../../../utils/commonUtils';
import InputFormGroup from '../InputFormGroup/InputFormGroup';
import CustomTextInput from '../../../../../components/CustomTextInput';
import FormTitle from '../FormTitle/FormTitle';
import FormSubTitle from '../FormSubTitle/FormSubTitle';
import Form from '../Form/Form';
import ContinueThemeButton from '../../../../../components/ContinueThemeButton/ContinueThemeButton';
import Loader from '../../../../../components/Loader';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import { useActionDispatch, useCurrentAppCommonData, useCurrentBrand } from '../../../../../hooks';
import { useGA4Gtm } from '../../../../../utils/gtm.hooks';
import { getIconFromAssets } from '../../../../../utils/assetsUtils';
import IconAndSubTitle from '../../../IconAndSubTitle';
import { IDENTIFICATION_FORM_GROUP } from './IdentificationForm.config';
import * as Styled from './IdentificationForm.styles';

const { IDENTIFY_ICON, IDENTIFY_ICON_AUDI } = ICONS_NAMES;

const IdentificationForm = () => {
    const [input, setInput] = useState(IDENTIFICATION_FORM_GROUP);
    const { customerFirstIdentificationAsync } = useActionDispatch();
    const currentBrand = useCurrentBrand();
    const { isAudi } = useCurrentAppCommonData();
    const checkoutState = useSelector(({ checkoutReducer }) => checkoutReducer);
    const navigate = useNavigate();
    const gtmStartIdentification = useGA4Gtm({ eventType: GTM_EVENT_TYPES.ADD_TO_CART });
    const { identification } = checkoutState;
    const {
        isLoading: isIdentifying,
        error,
    } = identification;
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const screenErrorMessage = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );
    const {
        name, value, label, isRequired, isErrorShown, errorMessage,
    } = input;

    const icon = isAudi ? IDENTIFY_ICON_AUDI : IDENTIFY_ICON;
    
    useEffect(() => {
        gtmStartIdentification();
    }, []);

    const checkCustomerIdentification = async (customerId) => {
        customerFirstIdentificationAsync(customerId);
    };

    const changeInputHandler = (event) => {
        const { value: inputValue } = event.target;

        setInput((prevInput) => {
            const isValid = prevInput.validator(inputValue);

            return {
                ...prevInput,
                isValid,
                value: inputValue,
            };
        });
    };

    const setErrorOnInputHandler = (isError) => {
        setInput({
            ...input,
            isErrorShown: isError,
        });
    };

    const submitFormHandler = (event) => {
        event.preventDefault();
        setErrorOnInputHandler(false);
        const { isValid } = input;

        if (!isValid) {
            setErrorOnInputHandler(true);
        } else {
            // Submit form
            const { value: customerId } = input;

            consoleLogInDev('Identification form submited');
            checkCustomerIdentification(customerId.trim());
        }
    };

    const handleGoBack = () => {
        navigate.goBack();
    };

    const renderFormAndButton = () => {
        const Input = isAudi ? CustomTextInput : InputFormGroup;

        return isAudi ? (
            <Styled.FormAndButton>
                <Form onSubmit={ submitFormHandler } action="/">
                    <Input
                        inputName={ name }
                        inputValue={ value }
                        onChange={ changeInputHandler }
                        label={ label }
                        isRequired={ isRequired }
                        isErrorShown={ isErrorShown }
                        errorMessage={ errorMessage }
                        isAutoFocused
                    />
                </Form>
                <ContinueThemeButton
                    onClick={ submitFormHandler }
                    title={ STRINGS.CONTINUE }
                    isAudi={ isAudi }
                />
            </Styled.FormAndButton>
        ) : (
            <Form onSubmit={ submitFormHandler } action="/">
                <Input
                    inputName={ name }
                    inputValue={ value }
                    onChange={ changeInputHandler }
                    label={ label }
                    isRequired={ isRequired }
                    isErrorShown={ isErrorShown }
                    errorMessage={ errorMessage }
                    isAutoFocused
                />
                <ContinueThemeButton title={ STRINGS.CONTINUE } />
            </Form>
        );
    };

    const renderIdentificationForm = () => {
        return (
            <>
                <Styled.StepHeadContainer $isAudi={ isAudi }>
                    <Styled.IconAndSubTitleWrapper>
                        <IconAndSubTitle
                            icon={ getIconFromAssets(icon) }
                            subTitle={ STRINGS.IDENTIFICATION }
                        />
                    </Styled.IconAndSubTitleWrapper>
                    <Styled.TitleContainer>
                        <FormTitle title={ STRINGS.HELLO_HAVE_WE_MET } />
                        <FormSubTitle subTitle={ isAudi
                            ? STRINGS.AUDI_FILL_OUT_YOUR_ID_FOR_IDENTIFICATION
                            : STRINGS.FILL_OUT_YOUR_ID_FOR_IDENTIFICATION }
                        />
                    </Styled.TitleContainer>
                </Styled.StepHeadContainer>

                {renderFormAndButton()}
                { isAudi && (
                    <Styled.GoBackButton
                        onClick={ handleGoBack }
                    >
                        { STRINGS.BACK_TO_ORDER }
                    </Styled.GoBackButton>
                )}
            </>
        );
    };

    if (isIdentifying) {
        return <Styled.LoaderContainer><Loader /></Styled.LoaderContainer>;
    }

    return (
        <Styled.IdentificationForm>
            {renderIdentificationForm()}
            {error
            && (
                <Styled.ErrorContainer>
                    <ErrorMessage color="red" errorMessage={ screenErrorMessage } />
                </Styled.ErrorContainer>
            )}
        </Styled.IdentificationForm>
    );
};

export default IdentificationForm;
