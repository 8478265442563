import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './AddOnGroupTitleAndIcon.styles';

const AddOnGroupTitleAndIcon = ({
    groupDescription,
    IconUrl,
    scrollToAddOnsGroup,
}) => {
    return (
        <Styled.AddOnGroupTitleAndIcon onClick={ scrollToAddOnsGroup }>
            <Styled.Icon $IconUrl={ IconUrl } />
            <Styled.Title>{groupDescription}</Styled.Title>
        </Styled.AddOnGroupTitleAndIcon>
    );
};

AddOnGroupTitleAndIcon.propTypes = {
    groupDescription: PropTypes.string.isRequired,
    IconUrl: PropTypes.string.isRequired,
    scrollToAddOnsGroup: PropTypes.func.isRequired,
};

export default AddOnGroupTitleAndIcon;
