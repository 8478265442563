import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

const AddOnsConfiguration = styled.div`
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding: 0 16px;
    margin-bottom: 10%;
  }
`;

const AddOns = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ErrorWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Styled = {
    AddOnsConfiguration,
    AddOns,
    ErrorWrapper,
    LoaderWrapper,
};

export default Styled;
