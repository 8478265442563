import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import CONFIG from '../../../../config';
import { staggerParentConfig } from '../../../../utils/animationsConfig';

const { DEVICE_SIZE } = CONFIG;

export const HoopsConfiguration = styled.div`
  /* border:10px solid red; */
`;
export const Hoops = styled(motion.ul).attrs({
    ...staggerParentConfig,
})`
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* border:1px solid red; */
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:flex;
    justify-content:  ${({ $isAudi }) => $isAudi ? 'right' : 'center'};
    /* To Do: As soon as there will be more than one set of hoops add overflow-x: scroll */
    /* overflow-x: scroll; */
  }
  ${({ $isAudi }) => $isAudi
  && css`
    display: flex;
    align-items: right;
    gap: 5px;
    @media(min-width:${DEVICE_SIZE.LARGE}px){
      flex-wrap: wrap;
  }
  `};
  .PickerItem-inner {
    margin: ${({ $isAudi }) => $isAudi ? '0' : '10px'};
    height:${({ theme }) => theme.steps.color.pickerItemHeight};
    width:${({ theme }) => theme.steps.color.pickerItemWidth};
    min-height:${({ theme }) => theme.steps.color.pickerItemHeight};
    min-width:${({ theme }) => theme.steps.color.pickerItemWidth};
  }
`;

export const LoaderWrapper = styled.div`
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const ErrorWrapper = styled(LoaderWrapper)``;

export const HoopsPickerHeader = styled.h2`
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 4px;
`;
