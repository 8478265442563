import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import Divider from '../Divider/Divider';
import ColorsBarTooltip from './ColorsBarTooltip/ColorsBarTooltip';
import Styled from './ColorsBar.styles';

const ColorsBar = ({
    externalColors,
    internalColors,
}) => {
    const getExternalColorsTitle = () => {
        return externalColors.length > 1
            ? `${externalColors.length} ${STRINGS.EXTERNAL_COLORS_TO_BE_CHOSEN}`
            : STRINGS.EXTERNAL_COLOR_TO_BE_CHOSEN;
    };

    const getInternalColorsTitle = () => {
        return internalColors.length > 1
            ? `${internalColors.length} ${STRINGS.INTERNALS_COLORS_TO_BE_CHOSEN}`
            : STRINGS.INTERNAL_COLOR_TO_BE_CHOSEN;
    };

    const renderExternalColors = () => {
        const key = (Date.now() + Math.random()).toString();

        return (
            <Styled.ColorsContainer>
                <Styled.Text>
                    { getExternalColorsTitle() }
                </Styled.Text>
                <Styled.InfoIcon
                    data-tooltip-id={ key }
                    data-for={ key }
                />
                <ColorsBarTooltip id={ key } colors={ externalColors } />
            </Styled.ColorsContainer>
        );
    };

    const renderInternalColors = () => {
        const key = (Date.now() + Math.random()).toString();

        return (
            <Styled.ColorsContainer>
                <Styled.Text>
                    { getInternalColorsTitle() }
                </Styled.Text>
                <Styled.InfoIcon
                    data-tooltip-id={ key }
                    data-for={ key }
                />
                <ColorsBarTooltip id={ key } colors={ internalColors } />
            </Styled.ColorsContainer>
        );
    };

    return (
        <Styled.Container>
            <Styled.Dummy />
            <Styled.InnerContainer>
                { renderExternalColors() }
                <Styled.VerticalDivider />
                { renderInternalColors() }
            </Styled.InnerContainer>
            <Divider />
        </Styled.Container>
    );
};

ColorsBar.propTypes = {
    externalColors: PropTypes.array.isRequired,
    internalColors: PropTypes.array.isRequired,
};

export default ColorsBar;
