import styled from 'styled-components';

const Container = styled.div`
  overflow-x: auto;
  padding-top: 50px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ $length }) => $length}, 320px);
  grid-column-gap: 20px;
  justify-items: start;
`;

const Dummy = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  position: sticky;
  right: 0;
`;

const Styled = {
    Container,
    GridContainer,
    Dummy,
};

export default Styled;
