import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { COLORS } from '../../../constants';
import { ReactComponent as BeforeArrowBlack } from '../../../assets/icons/before_arrow_black.svg';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

const SwiperContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const SwiperCustom = styled(Swiper).attrs((props) => ({
    ...props,
}))`
    width: 100%;
    height: 100%;
    .swiper-pagination {
      background-color: white;
      padding: 8px 0;
      border-radius: 12px;
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
      left: 60px !important;
    }
}
`;

const SwiperSlideCustom = styled(SwiperSlide)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.ul`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-image: url(${({ $src }) => $src});
    background-repeat: no-repeat;
    background-size: ${({ $view }) => $view};
    background-position: center;
    height:${({ theme }) => theme.presentation.desktop.height};
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    height:${({ theme }) => theme.presentation.mobile.height};
  }
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 44px;
  height: 44px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  z-index: 50;
  &.custom-swiper-button-prev {
    right: 10px;
  }
  &.custom-swiper-button-next {
    left: 10px;
  }
  &.swiper-button-disabled {
    opacity: 0;
  }
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    &.custom-swiper-button-prev {
    right: 0px;
  }
  &.custom-swiper-button-next {
    left: 0px;
  }
  }
`;

const PaginationContainer = styled.div`
`;

const LeftIcon = styled(BeforeArrowBlack)`
`;

const RightIcon = styled(BeforeArrowBlack)`
transform: rotate(180deg);
`;

const Styled = {
    SwiperContainer,
    SwiperCustom,
    SwiperSlideCustom,
    Image,
    PaginationContainer,
    NavigationButton,
    LeftIcon,
    RightIcon,
};

export default Styled;
