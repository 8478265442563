import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ConfiguratorStep.styles';

const ConfiguratorStep = ({
    name,
    isActive,
    isFinished,
    onSelectStep,
    checkMarkIcon,
    id,
    isVisible,
    stepRef,
}) => {
    return (
        <Styled.ConfiguratorStep
            ref={ stepRef }
            $id={ id }
            $icon={ checkMarkIcon }
            onClick={ isFinished ? onSelectStep : null }
            $isFinished={ isFinished }
            $isActive={ isActive }
            $isVisible={ isVisible }
        >
            {name}
            <Styled.UnderlineContainer $isFinished={ isFinished }>
                {isActive && (
                    <Styled.Underline
                        $isFinished={ isFinished }
                        layoutId="underline"
                    />
                )}
            </Styled.UnderlineContainer>
        </Styled.ConfiguratorStep>
    );
};

ConfiguratorStep.defaultProps = {
    isActive: false,
    isFinished: false,
    onSelectStep: () => {},
};

ConfiguratorStep.propTypes =  {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isFinished: PropTypes.bool,
    onSelectStep: PropTypes.func,
    checkMarkIcon: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    stepRef: PropTypes.object.isRequired,
};

export default ConfiguratorStep;
