import styled, { css } from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { motion } from 'framer-motion';
import { ReactComponent as CheckMarkSvg } from '../../../../assets/icons/svg-check-mark.svg';
import { NoEntranceIcon } from '../../../common/styles';
import { staggerVerticalChildVariants } from '../../../../utils/animationsConfig';

const PickerItemContainer = styled(motion.div).attrs({
    variants: staggerVerticalChildVariants,
})`
  position: relative;
  min-width: 56px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #97A3A8;
  border-radius: 50%;
  ${({ $isSelected }) => $isSelected && css`
    border: 2px solid #10833B;
  `}
  ${NoEntranceIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const PickerItem = styled.div.attrs({
    role: 'button',
})`
  min-width: 50px;
  min-height: 50px;
  background-color: transparent;
  outline: none;
  border-radius: 50%;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ $icon }) => $icon});
  background-clip: content-box;
  transition: all .2s ease-in-out;
`;

const CheckMark = styled.span`
  position: absolute;
  left: -4px;
  top: -4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #10833B;
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
  display: inline-block;
  width: 12px;
  height: 12px;
  path {
    fill: white;
  }
`;

const ToolTip = styled(Tooltip)`
  &.extraClass {
    border: 5px solid #fff;
    border-radius: 15px;
    padding: 1rem;
    text-align: center;
    box-shadow: 5px 5px 20px 0px #63636378;
    &.place-top {
    }
  }
`;

const TooltipTitle = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 1.05rem;
  margin: 0 0 0.7rem;
`;

const TooltipDescription = styled.div`
  color: #eee;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0 0 0.7rem;
`;

const TooltipInStock = styled(TooltipDescription)``;

const NoPackageTooltipContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  ${NoEntranceIcon} {
    position: static;
    margin-left: 10px;
    transform: translate(0);
  }
  ${TooltipDescription}{
    margin: 0;
  }
`;

const Styled = {
    PickerItemContainer,
    PickerItem,
    CheckMark,
    CheckMarkIcon,
    ToolTip,
    TooltipTitle,
    TooltipDescription,
    TooltipInStock,
    NoPackageTooltipContainer,
};

export default Styled;
