import styled from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const TableData = styled.td`
    border:1px solid ${COLORS.BORDER_TABLE_DATA_COLOR};
    font-size:${pxToRem(12)};
    padding:10px;
    background-color:${({ $bg }) => $bg || null};
    color:${({ $fontColor }) => $fontColor || null};
    text-align:center;
`;
