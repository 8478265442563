import { API_METHODS, NEW_API_METHODS, OtpHeaders } from '../constants';
import { AppError } from '../constants/errors';
import STRINGS from '../language';
import axios from './axios';

const {
    GET_AUTHENTICATED,
} = API_METHODS;
const {
    REQUEST_OTP,
    VALIDATE_OTP,
    REGENERATE_OTP,
} = NEW_API_METHODS.AUTH;

/**
 * @POST request
 * @param {string} fullName (champion equivalent - sCustName)
 * @param {string} phoneNumber (champion equivalent - sPhoneNum) 
 * @returns {string} some unique code
 */

export const sendSms = async (fullName, phoneNumber) => {
    const formattedPhone = phoneNumber.replace('-', '');

    const result = await axios.get(REQUEST_OTP, {
        headers: OtpHeaders,
        params: {
            customerName: fullName,
            phoneNumber: formattedPhone,
        },
    });

    return result;
};

/**
 * @POST request
 * @param {string} session 
 * @returns {boolean}
 */

export const resendSms = async () => {
    try {
        await axios.get(REGENERATE_OTP, {
            headers: OtpHeaders,
        });
        
        return true;
    } catch (error) {
        return false;
    }
};

export const isAuthenticated = async () => {
    const result = await axios.post(GET_AUTHENTICATED);

    return result;
};

/**
 * 
 * @param {string} otp 
 * @param {number} otp number (1 || 2)
 * @returns {Promise} true upon success, false otherwise
 */
export const checkOtp = async (otp) => {
    const data = {
        otp,
    };

    try {
        const result = await axios.post(VALIDATE_OTP, data);
        
        return result;
    } catch (error) {
        throw new AppError(STRINGS.SOMETHING_IS_WRONG_WITH_YOUR_CODE_PLESAE_TRY_AGAIN);
    }
};
