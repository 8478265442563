import styled from 'styled-components';
import { COLORS, MENUFACTURS } from '../../../../../constants';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';
import { configurationLeftScrollBar, nisCurrencySign } from '../../../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const LoaderContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
`;
export const NoDataContainer = styled(LoaderContainer)`
  margin: 80px 0;
  font-size:28px;
  font-weight:bold;
`;

export const Container = styled.div`
  position: fixed;
  z-index: 150 ;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.show-up-enter .modal-content {
    transform: translateX(-100%);
  }
  
  &.show-up-enter-active .modal-content {
    transform: translateX(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .modal-content {
    transform: translateX(-100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }


  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    justify-content: center;
    align-items: flex-end;
    top: 7.5rem;
    height: calc(100% - 7.5rem);
    flex: 2 1;
    &.show-up-enter .modal-content {
      transform: translateY(100%);
    }
    
    &.show-up-enter-active .modal-content {
      transform: translateY(0);
      transition: all 400ms;
    }
    
    &.show-up-exit-active .modal-content {
      transform: translateY(100%);
      transition: all 400ms;
    }
    &.show-up-exit-active {
      transition: all 500ms;
    }
  }
`;

export const MoreInformationModal = styled.div`
  z-index: 200;
  padding: 15px;
  background-color: ${COLORS.BG_COLOR_MODAL};
  border-radius: 1px;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 50%);
  width: 30%;
  height: 100%;
  position: relative;
  display: flex;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   flex-direction: column-reverse;
   width: calc(100% - 2rem);
   padding-top: 0;
   margin-bottom: auto;
   border-radius: 10px 10px 0 0;
  }
`;

export const CloseButton = styled.p`
  position: absolute;
  top: 10px;
  right: 30px;
  align-self: center;
  font-weight: 500;
  font-size: ${pxToRem(16)};
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "\\00d7";
    padding: ${({ $brand }) => $brand === MENUFACTURS.SEAT ? '0 0 3px 0' : '0 0 0 0'};
    font-size: ${pxToRem(28)};
    font-weight: 300;
    margin-left: 3px;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    left: auto;
    margin: 0;
    top: 5px;
    right: 20px;
  }
`;

export const Data = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding: 5px 10px;
    height: 100%;
   overflow: scroll;
  }
`;

export const TitleAndMoreInformationContainer = styled.div`
@media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
 flex: 3;
 margin-top: 50px;
 overflow-x: hidden;
 overflow-y: scroll;
 max-width: 100%;
  }
`;

export const Title = styled.h2`
  margin: 20px 0;
  font-weight: 700;
  font-size: ${pxToRem(32)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin: 5px 0;
    font-size: ${pxToRem(20)};
  }
`;

export const MoreInformation = styled.div`
${configurationLeftScrollBar};
  overflow-y: auto;
  max-width: 45ch;
  margin-bottom: 5px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-bottom: 0px;
  }
`;

export const Hr = styled.hr`
width: 100%;
height: 1px;
opacity: 0.4;
border-left: none;
`;

export const BottomContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
flex: 1;
@media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
  }
`;

export const BottomContentContainer = styled.div`
margin-top: 30px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
@media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
display: block;
margin-top: 5px;
  }
`;

export const Price = styled.p`
  margin:0;
  font-weight: 700;
  font-size: ${pxToRem(18)};
  ${nisCurrencySign}
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    font-size: ${pxToRem(20)};
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
