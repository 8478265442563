import { useMemo } from 'react';
import { getCarPlaceholderImageUrl, getInCarImageUrl, getOutCarImageUrl } from './assetsUtils';

export const useOutCarImageUrl = (currentBrand,
    modelGroupCode,
    trimLevelCode,
    selectedColorValue,
) => {
    const carImageUrl =    useMemo(() => {
        return getOutCarImageUrl(currentBrand, modelGroupCode, trimLevelCode, selectedColorValue);
    }, [selectedColorValue]);

    return carImageUrl;
};

export const useInCarImageUrl = (currentBrand,
    modelGroupCode,
    trimLevelCode,
    selectedColorValue,
) => {
    const carImageUrl =    useMemo(() => {
        return getInCarImageUrl(currentBrand, modelGroupCode, trimLevelCode, selectedColorValue);
    }, [selectedColorValue]);

    return carImageUrl;
};

export const useCarPlaceHolderImageUrl = (currentBrand) => {
    const fallbackSrc =    useMemo(() => {
        return     getCarPlaceholderImageUrl(currentBrand);
    }, [currentBrand]);

    return fallbackSrc;
};
