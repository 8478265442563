import styled from 'styled-components';
import { ReactComponent as Info } from '../../../../../assets/icons/info.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 0;
  background-color: white;
  position: sticky;
  right: 0;
  z-index: 1;
  border-left: 8px solid white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.additionalOptionLabel};
  font-size: 16px;
  font-weight: bold;
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
    width: 18,
    height: 18,
    stroke: theme.primaryColor,
}))`
  min-width: 18px
`;

const Dummy = styled.div`
  height: 1px;
`;

const Styled = {
    Wrapper,
    Container,
    Label,
    InfoIcon,
    Dummy,
};

export default Styled;
