import * as actionTypes from '../actionTypes';
import * as dispatchers from '../dispatchers';

const initialState = {
    onlineCars: [],
    currentBrand: null,
    currentSubBrand: null,
    isCupra: false,
    isAudi: false,
    salesCenters: [],  // maybe it wont be sent in the init call
    isLoadingAppCommonData: true,
    videoForFaq: null,
    ManufacturingDates: null,
    error: null,

};

export const appCommonData = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_APP_COMMON_DATA_PENDING:
            return dispatchers.getCommonDataPending(state);
        case actionTypes.GET_APP_COMMON_DATA_SUCCESS:
            return dispatchers.getCommonDataSuccess(state, action);
        case actionTypes.GET_APP_COMMON_DATA_FAILURE:
            return dispatchers.getCommonDataFailure(state, action);
        case actionTypes.SET_CURRENT_BRAND:
            return dispatchers.setCurrentBrand(state, action);
        case actionTypes.SET_VIDEO_FOR_FAQ:
            return dispatchers.setVideoForFaq(state, action);
        case actionTypes.SET_MANUFACTURING_DATES:
            return dispatchers.setManufacturingDates(state, action);
        default:
            return state;
    }
};
