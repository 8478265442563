import styled from 'styled-components';
import { ReactComponent as External360 } from '../../../../assets/icons/external-360.svg';
import { ReactComponent as Internal360 } from '../../../../assets/icons/internal-360.svg';
import { ReactComponent as StaticImages } from '../../../../assets/icons/static-images.svg';

const Container = styled.div`
  display: inline-flex;
  padding: 6px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.95);
`;

const ModeButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  :hover {
    background-color: #F3F4F5;
  }
`;

const External360Icon = styled(External360).attrs(({ $isActive, theme }) => ({
    width: 24,
    height: 24,
    fill: $isActive ? '#183FBE' : theme.primaryColor,
}))``;

const Internal360Icon = styled(Internal360).attrs(({ $isActive, theme }) => ({
    width: 24,
    height: 24,
    fill: $isActive ? '#183FBE' : theme.primaryColor,
}))``;

const StaticImagesIcon = styled(StaticImages).attrs(({ $isActive, theme }) => ({
    width: 24,
    height: 24,
    fill: $isActive ? '#183FBE' : theme.primaryColor,
}))``;

const Styled = {
    Container,
    ModeButton,
    External360Icon,
    Internal360Icon,
    StaticImagesIcon,
};

export default Styled;
