import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../../../components/ColorPicker';
import { ColorPickerItemType } from '../../../constants';
import CarImage from '../../../components/CarImage/CarImage';
import * as Styled from './BrandImageWithSocial.style';
/**
 *
 * @param {{
 *  brand: string;
 *  outColor: string;
 *  colors: any[];
 *  onPickOutColor: (value: string) => void;
 * }} param0
 */
function BrandImageWithSocial({
    brand,
    outColor,
    colors,
    onPickOutColor,
    modelGroup,
    trimLevel,
}) {
    return (
        <Styled.Container>
            <Styled.CarImageContainer>
                <CarImage
                    brand={ brand }
                    outColor={ outColor }
                    modelGroup={ modelGroup }
                    trimLevel={ trimLevel }
                />
            </Styled.CarImageContainer>

            <Styled.ColorPickerWrapper>
                {colors?.length > 0 && (
                    <ColorPicker
                        onPick={ onPickOutColor }
                        pickedColor={ outColor }
                        colors={ colors }
                    />
                )}
            </Styled.ColorPickerWrapper>
        </Styled.Container>
    );
}

BrandImageWithSocial.propTypes = {
    brand: PropTypes.string.isRequired,
    outColor: PropTypes.string,
    colors: PropTypes.arrayOf(ColorPickerItemType),
    onPickOutColor: PropTypes.func.isRequired,
    modelGroup: PropTypes.string.isRequired,
    trimLevel: PropTypes.string.isRequired,
};

BrandImageWithSocial.defaultProps = {
    outColor: null,
    colors: [],
};

export default BrandImageWithSocial;
