import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox/CheckBox';
import STRINGS from '../../language';
import { LABEL_MAX_LENGTH } from '../../constants';
import * as Styled from './CheckBoxFormGroup.styles';

const CheckBoxFormGroup = ({
    isChecked,
    onCheck,
    label,
    isRequired,
    isErrorShown,
    errorMessage,
    id,
}) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const renderLabel = () => {
        if (typeof label === 'string') {
            const isShowReadMoreOrLessButton = label.length > LABEL_MAX_LENGTH;

            return (
                <>
                    { isReadMore && isShowReadMoreOrLessButton ? `${label.slice(0, LABEL_MAX_LENGTH)}...` : label }
                    { isShowReadMoreOrLessButton && (
                        <Styled.MoreInfoButton
                            $isReadMoreVisible={ isReadMore }
                            onClick={ toggleReadMore }
                        >{isReadMore ? STRINGS.SHOW_MORE : STRINGS.READ_LESS}
                        </Styled.MoreInfoButton>
                    )}
                </>
            );
        }

        return label;
    };

    return (
        <Styled.CheckBoxFormGroup>
            <CheckBox
                isErrorShown={ isErrorShown }
                isRequired={ isRequired }
                id={ id }
                isChecked={ isChecked }
                onCheck={ onCheck }
            />
            <Styled.Label $isErrorShown={ isErrorShown } htmlFor={ id }>
                { renderLabel() }
            </Styled.Label>
            {isErrorShown && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}
        </Styled.CheckBoxFormGroup>
    );
};

CheckBoxFormGroup.defaultProps = {
    onCheck: () => {},
    isChecked: false,
    isRequired: false,
    isErrorShown: false,
    errorMessage: null,
    
};

CheckBoxFormGroup.propTypes = {
    onCheck: PropTypes.func,
    isChecked: PropTypes.bool,
    isRequired: PropTypes.bool,
    isErrorShown: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    id: PropTypes.string.isRequired,
};

export default CheckBoxFormGroup;
