import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import TabItem from './TabItem/TabItem';
import * as Styled from './MainTabs.styles';

const MainTabs = ({
    tabs, onSelect, selectedTab, catalogUrl,
}) => {
    const renderTabs = () => {
        return tabs.map((tab) => (
            <TabItem
                key={ tab }
                isSelected={ selectedTab === tab }
                onSelect={ () => onSelect(tab) }
                name={ tab }
            />
        ));
    };

    const renderCatalogLink = () => {
        if (!catalogUrl) {
            return null;
        }
        
        return (
            <Styled.CatalogLink
                target="_blank"
                to={ catalogUrl }
            >
                {STRINGS.SUCCESSS_PDF_TEXT}
            </Styled.CatalogLink>
        );
    };

    return (
        <Styled.MainTabs>
            <Styled.Tabs>
                {renderTabs()}
                {renderCatalogLink()}
            </Styled.Tabs>
        </Styled.MainTabs>
    );
};

MainTabs.defaultProps = {
    selectedTab: null,
};

MainTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedTab: PropTypes.string,
    catalogUrl: PropTypes.string.isRequired,
};

export default MainTabs;
