import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetTitleDescription = ({
    title, description, canonical, children,
}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={ description } />
            <link rel="canonical" href={ canonical } />
            {children}
        </Helmet>
    );
};

HelmetTitleDescription.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

export default HelmetTitleDescription;
