import * as actionTypes from '../actionTypes';
import {
    getAppCommonData,
    getMutagVideo,
    GetModelGroupManufacturingDates,
} from '../../fetch/app_common_data';
import { MENUFACTURS } from '../../constants';

const getCommonDataPending = () => ({
    type: actionTypes.GET_APP_COMMON_DATA_PENDING,
});

const getCommonDataSuccess = (data) => ({
    type: actionTypes.GET_APP_COMMON_DATA_SUCCESS,
    data,
});

const getCommonDataFailure = (error) => ({
    type: actionTypes.GET_APP_COMMON_DATA_FAILURE,
    error,
});

export const setCurrentBrand = (brand, subBrand) => {
    const isCupra = brand === MENUFACTURS.SEAT && subBrand === MENUFACTURS.CUPRA;
    const isAudi = brand === MENUFACTURS.AUDI;

    return {
        type: actionTypes.SET_CURRENT_BRAND,
        brand: isCupra ? subBrand : brand,
        subBrand,
        isCupra,
        isAudi,
    };
};

export const getCommonData = (brand, subBrand) => {
    return async (dispatch) => {
        dispatch(getCommonDataPending());
        try {
            const response = await getAppCommonData(subBrand ?? brand);
            const { data } = response;

            return dispatch(getCommonDataSuccess(data));
        } catch (error) {
            return dispatch(getCommonDataFailure(error));
        }
    };
};

const setMutagVideoForFAQSuccess = (link) => ({
    type: actionTypes.SET_VIDEO_FOR_FAQ,
    link,
});

export const getMutagVideoForFAQ = (brand) => {
    return async (dispatch) => {
        try {
            const response = await getMutagVideo(brand);

            if (response !== null && response) {
                const { data } = response;

                return dispatch(setMutagVideoForFAQSuccess(data));
            }

            return dispatch(setMutagVideoForFAQSuccess(null));
        } catch (error) {
            return dispatch(setMutagVideoForFAQSuccess(null));
        }
    };
};

const setModelGroupManufacturingDatesSuccess = (ManufacturingDates) => ({
    type: actionTypes.SET_MANUFACTURING_DATES,
    ManufacturingDates,
});

export const GetManufacturingDates = (brand) => {
    return async (dispatch) => {
        try {
            const response = await GetModelGroupManufacturingDates(brand);

            if (response !== null && response) {
                const { data } = response;

                return dispatch(setModelGroupManufacturingDatesSuccess(data));
            }

            return dispatch(setModelGroupManufacturingDatesSuccess(null));
        } catch (error) {
            return dispatch(setModelGroupManufacturingDatesSuccess(null));
        }
    };
};
