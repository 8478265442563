import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { pxToRem } from '../../../../utils/commonUtils';
import { COLORS } from '../../../../constants';

export const ConfiguratorStep = styled.li`
  color: ${({ theme }) => theme.footerTopNavItemColor};
  font-size: ${pxToRem(14)};
  font-weight: 400;
  padding: 7px 15px;
  line-height: 1;
  display: ${({ $isVisible }) => $isVisible ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;
  position: relative;
  ${({
        $isFinished, $isActive,
    }) => $isFinished && !$isActive
    && css`
      position: relative;
      cursor: pointer;
    `}
  ${({ isActive, theme }) => isActive
  && css`
    position: relative;
    cursor: default;
    color: ${COLORS.WHITE};
    background-color: ${theme.footerTopNavItemColor};
    border-radius: 30px;
    font-weight: 700;
  `}
`;

export const Label = styled.span`
  position: relative;
  z-index: 5;
  color: ${({ $isActive, theme }) => $isActive ? COLORS.WHITE : theme.footerTopNavItemColor};
`;

export const BackgroundChip = styled(motion.span)`
  background-color: ${({ theme }) => theme.footerTopNavItemColor};
  border-radius: 30px;
  font-weight: 700;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ $isActive }) => $isActive ? 1 : 0};
`;
