export const CUSTOMER_FIRST_IDENTIFICATION_PENDING = 'CUSTOMER_FIRST_IDENTIFICATION_PENDING';
export const CUSTOMER_FIRST_IDENTIFICATION_SUCCESS = 'CUSTOMER_FIRST_IDENTIFICATION_SUCCESS';
export const CUSTOMER_FIRST_IDENTIFICATION_FAILURE = 'CUSTOMER_FIRST_IDENTIFICATION_FAILURE';
/** *************************************************************************************** */
export const CUSTOMER_IDENTIFICATION_PENDING = 'CUSTOMER_IDENTIFICATION_PENDING';
export const CUSTOMER_IDENTIFICATION_SUCCESS = 'CUSTOMER_IDENTIFICATION_SUCCESS';
export const CUSTOMER_IDENTIFICATION_FAILURE = 'CUSTOMER_IDENTIFICATION_FAILURE';
/** *************************************************************************************** */
export const CUSTOMER_REGISTRATION_PENDING = 'CUSTOMER_REGISTRATION_PENDING';
export const CUSTOMER_REGISTRATION_SUCCESS = 'CUSTOMER_REGISTRATION_SUCCESS';
export const CUSTOMER_REGISTRATION_FAILURE = 'CUSTOMER_REGISTRATION_FAILURE';
/** *************************************************************************************** */
export const SAVE_CUSTOMER_ID_NUMBER = 'SAVE_CUSTOMER_ID_NUMBER';
/** *************************************************************************************** */
export const GET_DEALERSHIPS_PENDING = 'GET_DEALERSHIPS_PENDING';
export const GET_DEALERSHIPS_SUCCESS = 'GET_DEALERSHIPS_SUCCESS';
export const GET_DEALERSHIPS_FAILURE = 'GET_DEALERSHIPS_FAILURE';
/** *************************************************************************************** */
export const SELECT_DEALERSHIP = 'SELECT_DEALERSHIP';
/** *************************************************************************************** */
export const SET_IS_FINANCING = 'SET_IS_FINANCING';
export const SET_IS_FINISHED = 'SET_IS_FINISHED';
export const SET_IS_IN_CHECKOUT = 'SET_IS_IN_CHECKOUT';
export const SET_IS_PAYMENT_FAILED = 'SET_IS_PAYMENT_FAILED';
export const SET_EDITED_ORDER_ID = 'SET_EDITED_ORDER_ID';
/** *************************************************************************************** */
export const HOLD_COMMISION_PENDING = 'HOLD_COMMISION_PENDING';
export const HOLD_COMMISION_SUCCESS = 'HOLD_COMMISION_SUCCESS';
export const HOLD_COMMISION_FAILURE = 'HOLD_COMMISION_FAILURE';
/** *************************************************************************************** */
export const INITALIZE_CHECKOUT = 'INITALIZE_CHECKOUT';
/** *************************************************************************************** */
export const SET_CURRENT_IDENTIFICATION_FORM = 'SET_CURRENT_IDENTIFICATION_FORM';
export const SAVE_PHONE_NUMBER = 'SAVE_PHONE_NUMBER';
export const SET_REGISTER_REASON = 'SET_REGISTER_REASON';
