import styled from 'styled-components';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const SpecificationsData = styled.div`
 max-width:1200px;
 padding:40px;
 margin:auto;
   @media(max-width:${DEVICE_SIZE.LARGE}px){
       padding:30px 10px;
   }
`;
