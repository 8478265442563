import styled from 'styled-components';
import { COLORS, MENUFACTURS } from '../../../../../constants';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 201;
  background-color: rgba(0, 0, 0, 0.5);
  
  &.show-up-enter .portal-content {
    transform: translateY(100%);
  }
  
  &.show-up-enter-active .portal-content {
    transform: translateY(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .portal-content {
    transform: translateY(100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }
`;

export const Container = styled.div`
  position: fixed;
  z-index: 201;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);

  &.show-up-enter .modal-content {
    transform: translateX(-100%);
  }
  
  &.show-up-enter-active .modal-content {
    transform: translateX(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .modal-content {
    transform: translateX(-100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }


  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    justify-content: center;
    align-items: flex-end;
    flex: 2 1;
    &.show-up-enter .modal-content {
      transform: translateY(100%);
    }
    
    &.show-up-enter-active .modal-content {
      transform: translateY(0);
      transition: all 400ms;
    }
    
    &.show-up-exit-active .modal-content {
      transform: translateY(100%);
      transition: all 400ms;
    }
    &.show-up-exit-active {
      transition: all 500ms;
    }
  }
`;

export const PaymentDetailsModal = styled.div`
  z-index: 200;
  padding: 15px;
  background-color: ${COLORS.BG_COLOR_MODAL};
  border-radius: 1px;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 50%);
  width: 30%;
  height: 100%;
  position: relative;
  display: flex;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
   flex-direction: column-reverse;
   width: calc(100% - 2rem);
   height: calc(100% - 7.5rem);
   padding-top: 0;
   border-radius: 10px 10px 0 0;
  }
`;

export const CloseButton = styled.p`
  position: absolute;
  top: 10px;
  right: 30px;
  align-self: center;
  font-weight: 500;
  font-size: ${pxToRem(16)};
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    content: "\\00d7";
    padding: ${({ brand }) => brand === MENUFACTURS.SEAT ? '0 0 3px 0' : '0 0 0 0'};
    font-size: ${pxToRem(28)};
    font-weight: 300;
    margin-left: 3px;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    left: auto;
    margin: 0;
    top: 5px;
    right: 20px;
  }
`;
