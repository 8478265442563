import React from 'react';

const WhatShouldIBringWithMe = () => {
    return (
        <>
            <p dir="rtl">
                בעת קבלת הרכב יש להגיע לאולם התצוגה הנבחר עם המסמכים הבאים:
            </p>
            <ul style={ { listStyleType: 'disc' } }>
                <li dir="rtl">
                    <p dir="rtl">טופס ר.ש חתום - מקור</p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">הסכם הזמנת הרכב חתום &ndash; מקור</p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">
                        אם קיבלת מימון - מסמכי מימון ושעבוד של הבנק המממן חתומים -מקור
                        &nbsp;
                    </p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">
                        תעודת ביטוח חובה &ndash; עלייך לבטח את הרכב לפני מועד המסירה ולהציג
                        לנציג באולם התצוגה אסמכתא לביטוח הרכב. (אנו כמובן נעדכן במספר לוחית
                        הרישוי)
                    </p>
                </li>
            </ul>
        </>
    );
};

export default WhatShouldIBringWithMe;
