import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import confetti from 'canvas-confetti';
import { ThemeContext } from 'styled-components';
import STRINGS from '../../language';
import PageContainer from '../../components/PageContainer';
import { Centered } from '../../components/common/styles';
import Loader from '../../components/Loader';
import CONFIG from '../../config';
import FailureScreen from '../FailureScreen';
import { fetchCompletePayment } from '../../fetch/fetch';
import { AppError } from '../../constants/errors';
import SuccessBody from './SuccessBody';

const CONFETI_INTERVAL = 20;
const CONFETI_PARTICAL_COUNT = 2;

// todo: change params in OK.jsp page, from <%= %> params and not url
// todo: remove unnecessary params from OK.jsp

function Success({
    cardToken,
    orderId,
    cardExp,
    authNumber,
    saleCenter,
    salesMan,
}) {
    const [isLoading, setLoading] = useState(true);
    const [successData, setSuccessData] = useState(false);
    const [errorData, setErrorData] = useState(null);

    const themeContext = useContext(ThemeContext);
    const colors = [themeContext.primaryColor, themeContext.secondaryColor];

    // ---------------------------------------
    // SYNC & SAVE SUCCESS DATA WITH SERVER
    // ---------------------------------------
    useEffect(() => {
        fetchCompletePayment(orderId, '', cardToken, cardExp, authNumber)
            .then((response) => {
                setSuccessData(response);
            })
            .catch((error) => {
                let errorMessage = STRINGS.ERRMSG_NOT_SUCCESS;

                if (error instanceof AppError && error?.message) {
                    errorMessage = error.message;
                }

                setErrorData(new Error(errorMessage));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [orderId, cardToken, cardExp, authNumber]);

    // ---------------------------------------
    // SHOW CONFETTI AFTER SUCCESS RESULT FROM SERVER
    // ---------------------------------------
    useEffect(() => {
        const frame = () => {
            confetti({
                particleCount: CONFETI_PARTICAL_COUNT,
                angle: 60,
                spread: 55,
                origin: { x: 0, y: 1 },
                colors,
                startVelocity: 90,
            });
            confetti({
                particleCount: CONFETI_PARTICAL_COUNT,
                angle: 120,
                spread: 55,
                origin: { x: 1, y: 1 },
                colors,
                startVelocity: 90,
            });
        };

        let confetiAnimation;

        let conftiTimeout;

        if (!isLoading && successData) {
            confetiAnimation = setInterval(frame, CONFETI_INTERVAL);

            conftiTimeout = setTimeout(() => {
                clearInterval(confetiAnimation);
            }, CONFIG.SUCCESS_CONFETI_DURATION);
        }

        return () => {
            clearTimeout(conftiTimeout);
            clearInterval(confetiAnimation);
            confetti.reset();
        };
    }, [colors, successData, isLoading]);

    // ---------------------------------------
    // DEFINE CONTENT TO RENDER
    // ---------------------------------------
    const defineContent = () => {
        if (isLoading) {
            return <Centered><Loader /></Centered>;
        }

        if (errorData || !successData) {
            return (
                <FailureScreen
                    error={ errorData ?? new Error(STRINGS.SERVER_ERROR) }
                    isPaymentError  // hide champion logo
                />
            );
        }

        return (
            <SuccessBody data={ {
                salesCenterName: saleCenter.name,
                salesMail: saleCenter.mail,
                salesMan: salesMan.name,
                salesPhone: saleCenter.phone,
                OrderIdForContact: successData.OrderEdited,
            } }
            />
        );
    };

    return (
        <PageContainer>
            {defineContent()}
        </PageContainer>
    );
}

Success.propTypes = {
    orderId: PropTypes.string.isRequired,
    cardToken: PropTypes.string.isRequired,
    cardExp: PropTypes.string.isRequired,
    authNumber: PropTypes.string.isRequired,
    saleCenter: PropTypes.shape({
        id: PropTypes.string.isRequired,
        mail: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
    }).isRequired,
    salesMan: PropTypes.shape({
        mail: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
    }).isRequired,
};

export default Success;
