import React, { useState, useEffect } from 'react';
import HamburgerButton from '../HamburgerButton/HamburgerButton';
import STRINGS from '../../language';
import OnlineCarsMenu from '../OnlineCarsMenu/OnlineCarsMenu';
import {
    ROUTES,
    HEADER_NAV_ITEMS_IDS,
} from '../../constants';
import {
    useCurrentBrand,
    useCurrentAppCommonData,
} from '../../hooks';
import useIsMobile from '../../hooks/useWindowSize';
import { useClickOutside, useGoToRoute } from '../../hooks/utils';
import {
    getFullManufacturerForDisclaimer,
    getManufacturerWebsite,
    replacePlaceholdersInTemplate,
} from '../../utils/commonUtils';
import { getMutagLogo } from '../../utils/assetsUtils';
import CONFIG from '../../config';
import Drawer from '../Drawer/Drawer';
import LogoWrapper from './LogoWrapper/LogoWrapper';
import *  as Styled from './Header.styles';

const { DEVICE_SIZE } = CONFIG;

const navItems = [
    {
        id: HEADER_NAV_ITEMS_IDS.ONLINE_MODELS,
        title: STRINGS.OUR_MODELS_ONLINE,
        href: ROUTES.MODELS,
    },
    {
        id: HEADER_NAV_ITEMS_IDS.FAQ,
        title: STRINGS.HOW_DOES_IT_WORKS,
        href: ROUTES.FAQ,
    },
    {
        id: HEADER_NAV_ITEMS_IDS.CONTACT,
        title: STRINGS.TALK_TO_US,
        href: ROUTES.CONTACT,
    },
];

const Header = () => {
    const { isAudi } = useCurrentAppCommonData();
    const [isNavigationVisible, setNavigationVisible] = useState(false);
    const [isOnlineCarsMenuVisible, setOnlineCarsMenuVisible] = useState(false);
    const currentBrand = useCurrentBrand();
    const goToRoute = useGoToRoute();
    const currentLogoSrc = getMutagLogo(currentBrand);
    const manufacturerName = getFullManufacturerForDisclaimer(currentBrand, 'hebrew');
    const toBrandWebsite = replacePlaceholdersInTemplate(
        STRINGS.TO_BRAND_WEBSITE, [manufacturerName],
    );
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const domNode = useClickOutside(() => {
        setOnlineCarsMenuVisible(false);
        setNavigationVisible(false);
    });

    useEffect(() => {
        if (isNavigationVisible) {
            document.body.style.overflow = 'hidden';
            document.body.dataset.moreInfo = isNavigationVisible;
        } else {
            document.body.style.overflow = 'visible';
            document.body.dataset.moreInfo = isNavigationVisible;
            setOnlineCarsMenuVisible(false);
        }
    }, [isNavigationVisible]);

    const toggleNavbar = () => {
        setNavigationVisible((prevState) => !prevState);
    };

    const toggleNavigationHandler = () => {
        if (isMobile) {
            setNavigationVisible((p) => !p);
            
            return true;
        }
        
        return false;
    };

    /**
   * Opens Or Closes the OnlineCarsMenu only on Mobile/>
   */
    const toggleOnlineCarsMenuMobileHandler = () => {
        if (isMobile) {
            setOnlineCarsMenuVisible((isVisible) => !isVisible);
        }
    };

    /**
   * Opens Or Closes the OnlineCarsMenu only on Desktop/>
   */
    const toggleOnlineCarsMenuDesktopHandler = (isVisible) => {
        if (!isMobile) {
            setOnlineCarsMenuVisible(isVisible);
        }
    };

    const onClickLinkHandler = (event, navItemId) => {
        if (navItemId === HEADER_NAV_ITEMS_IDS.ONLINE_MODELS) {
            event.preventDefault();
        } else {
            event.stopPropagation();
            toggleNavigationHandler();
        }
    };

    const onClickLogoHandler = () => {
        setNavigationVisible(false);
        goToRoute('/');
    };
    
    const onClickDrawerItem = () => {
        setNavigationVisible(false);
    };

    const renderNavItems = () => {
        return navItems.map((navItem) => {
            const { id, href, title } = navItem;
            const onlineModelsItem = id === HEADER_NAV_ITEMS_IDS.ONLINE_MODELS;

            return (
                <Styled.NavigationItem
                    id={ id }
                    $isActive={ isOnlineCarsMenuVisible }
                    onClick={ toggleOnlineCarsMenuMobileHandler }
                    onMouseEnter={ () => onlineModelsItem
                        && toggleOnlineCarsMenuDesktopHandler(true) }
                    onMouseLeave={ () => onlineModelsItem
                        && toggleOnlineCarsMenuDesktopHandler(false) }
                    $isAudi={ isAudi }
                    key={ id }
                >
                    <Styled.Link
                        className="NavigationItem__Link"
                        onClick={ (e) => onClickLinkHandler(e, id) }
                        to={ href }
                    >
                        {title}
                    </Styled.Link>
                    {onlineModelsItem && (
                        <OnlineCarsMenu
                            onCloseNav={ () => {
                                setOnlineCarsMenuVisible(false);
                                setNavigationVisible(false);
                            } }
                            isVisible={ isOnlineCarsMenuVisible }
                        />
                    )}
                </Styled.NavigationItem>
            );
        });
    };

    return (
        <Styled.Header $isNavigationVisible={ isNavigationVisible }>
            <Styled.Content ref={ domNode }>
                <LogoWrapper
                    onCloseNav={ onClickLogoHandler }
                    logoSrc={ currentLogoSrc }
                />
                <Styled.DesktopWrapper $isAudi={ isAudi }>
                    <Styled.Navigation $isNavigationVisible={ isNavigationVisible }>
                        <Styled.NavigationMenu $isNavigationVisible={ isNavigationVisible }>
                            {renderNavItems()}
                            <Styled.NavigationItem>
                                <Styled.Link
                                    target="_blank"
                                    to={ getManufacturerWebsite(currentBrand) }
                                >
                                    {toBrandWebsite}
                                </Styled.Link>
                            </Styled.NavigationItem>
                        </Styled.NavigationMenu>
                    </Styled.Navigation>
                </Styled.DesktopWrapper>
                <HamburgerButton onClick={ toggleNavbar } />
                <Styled.MobileWrapper $isAudi={ isAudi }>
                    <Drawer
                        isOpen={ isNavigationVisible }
                        onItemClicked={ onClickDrawerItem }
                    />
                </Styled.MobileWrapper>
            </Styled.Content>
        </Styled.Header>
    );
};

export default Header;
