import React from 'react';
import InnerPagesHeader from '../../../components/InnerPages/InnerPagesHeader/InnerPagesHeader';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import STRINGS from '../../../language';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import TermsBody from './TermsBody/TermsBody';
import * as Styled from './Terms.styles';

const Terms = () => {
    return (
        <Styled.Terms>
            <HelmetTitleDescription canonical={ window.location.href } />
            <InnerPageContainer>
                <InnerPagesHeader title={ STRINGS.TERMS } />
                <TermsBody />
            </InnerPageContainer>
        </Styled.Terms>
    );
};

export default Terms;
