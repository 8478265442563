import * as actionTypes from '../../actionTypes';
import { getEquipmentPackages  } from '../../../fetch/cupra/equipment_packages';

const getEquipmentPackagesPending = () => ({
    type: actionTypes.GET_EQUIPMENT_PACKAGE_PENDING,
});

const getEquipmentPackagesSuccess = (data, brand, trimLevelCode) => ({
    type: actionTypes.GET_EQUIPMENT_PACKAGE_SUCCESS,
    equipmentPackages: data,
    brand,
    trimLevelCode,
});

const getEquipmentPackagesFailure = (error) => ({
    type: actionTypes.GET_EQUIPMENT_PACKAGE_FAILURE,
    error,
});

export const getEquipmentPackagesAsync = (brand, trimLevelCode, modelCode, OriginalAdditions) => {
    return async (dispatch) => {
        dispatch(getEquipmentPackagesPending());
        try {
            const response = await  getEquipmentPackages(
                brand,
                trimLevelCode,
                modelCode,
                OriginalAdditions,
            );
            const { data } = response;
        
            dispatch(getEquipmentPackagesSuccess(data, brand, trimLevelCode));

            return true;
        } catch (error) {
            dispatch(getEquipmentPackagesFailure(error));

            return false;
        }
    };
};
