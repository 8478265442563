import styled from 'styled-components';

export const FaqItem = styled.li`
cursor: pointer;
margin: 0 2rem 0 2rem;

&:first-child{
    border-top: 1px solid #C4C4C4;
}
  border-bottom: 1px solid #C4C4C4;
`;
export const Faq = styled.div`
  padding: 7px 0;
  transition: background 0.15s ease-in;
  @media (hover: hover) {
    &:hover{
        background-color:#e8e8e8;
    }
  }
`;
