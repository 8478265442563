import styled from 'styled-components';
import { ReactComponent as Phone } from '../../../../../assets/icons/phone.svg';
import CONFIG from '../../../../../config';
import { COLORS } from '../../../../../constants';
import { CheckBox } from '../../../../CheckBox/CheckBox.styles';
import {
    CheckBoxFormGroup,
    Label,
    MoreInfoButton,
} from '../../../../CheckBoxFormGroup/CheckBoxFormGroup.styles';
import {
    ContinueThemeButton,
} from '../../../ConfiguratorLayout/ContinueButton/ContinueButton.style';

const { DEVICE_SIZE } = CONFIG;

export const MobileWrapper = styled.div`
  width: 100%;
  ${ContinueThemeButton} {
    display: none;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      min-height: 44px;
      display: flex;
      margin-left: 2%;
    }
  }
  > * ${ContinueThemeButton} {
    display: flex;
    @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
      display: none;
    }
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    position: fixed;
    z-index: 140;
    width: 100%;
    bottom: -1px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    ${CheckBoxFormGroup} {
      margin-bottom: 0;
    }
    ${CheckBox} {
      border: 1px solid ${COLORS.WHITE} !important;
      &::before {
        filter: brightness(50);
      }
    }
    ${Label} {
      color: ${COLORS.WHITE};
      a {
        color: ${COLORS.WHITE};
      }
    }
    ${MoreInfoButton} {
      color: ${COLORS.WHITE};
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    background-color: ${({ theme }) => theme.primaryColor};
    border-top: 1px solid #cecece;
    padding: 15px;
  }
`;

export const OpenModalFormsButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PhoneIcon = styled(Phone).attrs({
    width: 20,
})`
  fill: ${({ theme }) => theme.primaryColor};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    fill: ${COLORS.WHITE};
  }
`;

export const SpeakWithRepresentativeButton = styled.button`
  display: flex;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  text-decoration: underline;
  font-family: ${({ theme }) => theme['font-family']};
  font-weight: normal;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  gap: 3px;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    color: ${COLORS.WHITE};
  }
`;

export const CheckboxLabel = styled.span``;

export const CheckboxLink = styled.a.attrs({
    target: '_blank',
    rel: 'noreferrer',
})``;

export const PriceDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const FullPrice = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.WHITE};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: flex;
  }
`;
