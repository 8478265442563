import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AddOrRemoveButton from '../../../../../AddOrRemoveButton/AddOrRemoveButton';
import STRINGS from '../../../../../../language';
import * as Styled from './AddOnItem.styles';

const AddOnItem = ({
    onSelect,
    isSelected,
    isDisabled,
    title,
    groupDescription,
    price,
    image,
    local,
    isGroupActive,
    onOpenMoreInfoModal,
    priceAfterDiscount,
    familyCode,
    isDiscount,
    groupCode,
    isGroupSelected,
}) => {
    const selecteItemHandler = () => {
        if (isDisabled) {
            return false;
        }
        
        return onSelect({
            title,
            groupDescription,
            price,
            image,
            local,
            priceAfterDiscount,
            isDiscount,
            groupCode,
        });
    };
    const moreInformationModalHandler = () => {
        onOpenMoreInfoModal({
            title,
            price,
            local,
            familyCode,
        });
    };
    const { otp }  = useSelector(({ authenticationReducer }) => authenticationReducer);
    const { isVerified } = otp;
    const showDiscount = isVerified && isDiscount && price !== priceAfterDiscount;

    return (
        <Styled.AddOnItem
            $isDisabled={ isDisabled }
            $isActive={ isGroupActive }
            $isGroupSelected={ isGroupSelected }
        >
            <Styled.AddOn>
                <Styled.Picker
                    $image={ image }
                    onClick={ selecteItemHandler }
                    $isSelected={ isSelected }
                    $isDisabled={ isDisabled }
                />
                <Styled.Details>
                    <Styled.Title>{title}</Styled.Title>
                    <Styled.MoreInformation onClick={ moreInformationModalHandler }>
                        {STRINGS.MORE_INFORMATION}
                    </Styled.MoreInformation>
                    <Styled.PricesContainer>
                        <Styled.Price $showDiscount={ showDiscount }>{price}</Styled.Price>
                        {showDiscount && <Styled.Price>{priceAfterDiscount}</Styled.Price>}
                    </Styled.PricesContainer>
                    <AddOrRemoveButton
                        onClickHandler={ selecteItemHandler }
                        isSelected={ isSelected }
                    />
                </Styled.Details>
            </Styled.AddOn>
        </Styled.AddOnItem>
        
    );
};

AddOnItem.defaultProps = {
    onSelect: () => {},
    isSelected: false,
    isDisabled: false,
    isGroupActive: false,
    familyCode: null,
    onOpenMoreInfoModal: () => {},
    isGroupSelected: false,
};

AddOnItem.propTypes  = {
    title: PropTypes.string.isRequired,
    groupDescription: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    local: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isGroupActive: PropTypes.bool,
    onSelect: PropTypes.func,
    onOpenMoreInfoModal: PropTypes.func,
    priceAfterDiscount: PropTypes.number.isRequired,
    familyCode: PropTypes.string,
    groupCode: PropTypes.string.isRequired,
    isDiscount: PropTypes.bool.isRequired,
    isGroupSelected: PropTypes.bool,
};

export default AddOnItem;
