import React, { useContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import CONFIG from '../../config';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ThemeContext from '../../ThemeProvider';
import { useActionDispatch, useCurrentAppCommonData } from '../../hooks';
import { ROUTES } from '../../constants';
import Loader from '../Loader';
import * as Styled from './AppLayout.styles';

const AppLayout = () => {
    const {
        getCommonData, setCurrentBrand,
    } = useActionDispatch();
    const { pathname } = useLocation();
    const appCommonData = useCurrentAppCommonData();
    const appLayoutRef = useRef();

    const { isLoadingAppCommonData } = appCommonData;
    const routesForHeaderVisibility = [];
    const routesForFooterVisibility = [ROUTES.CHECKOUT];
    const { brand, subBrand } = CONFIG;
 
    const { setTheme } = useContext(ThemeContext);

    useEffect(() => {
        setCurrentBrand(brand, subBrand);
        getCommonData(brand, subBrand);
        // setFavicon();
    }, []);

    useEffect(() => {
        if (brand) {
            setTheme(brand, subBrand);
        }
    }, [brand, subBrand]);

    const setHeaderOrFooterVisibilityOnRoute = (routes, path) => {
        return !routes.find((route) => route === path);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const isHeaderShown = setHeaderOrFooterVisibilityOnRoute(routesForHeaderVisibility, pathname);
    const isFooterShown = setHeaderOrFooterVisibilityOnRoute(routesForFooterVisibility, pathname);

    if (isLoadingAppCommonData) {
        return (
            <Styled.CustomLoader>
                <Loader />
            </Styled.CustomLoader>
        );
    }

    return (
        <>
            {isHeaderShown && <Header />}
            <Styled.Main ref={ appLayoutRef }>
                <Outlet />
            </Styled.Main>
            {isFooterShown && <Footer />}
        </>
    );
};

export default AppLayout;
