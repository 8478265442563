import styled from 'styled-components';
import { ConfiguratorFooterTitle } from '../../../../../components/common/styles';
import { COLORS } from '../../../../../constants';
import CONFIG from '../../../../../config';
import { pxToRem } from '../../../../../utils/commonUtils';

const { DEVICE_SIZE } = CONFIG;

export const SystemDetailsLegend = styled.div`
  margin-top: auto;
  @media (max-width: ${DEVICE_SIZE.LARGE}px) {
    margin-top: 30px;
  }
`;
export const Title = styled(ConfiguratorFooterTitle)`
  border-bottom: 1px solid ${COLORS.BLACK};
  display: inline-block;
`;

export const LgendList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const LegendItem = styled.li`
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: 20px;
  &:before {
    width: 10px;
    margin-left: 20px;
    text-align: center;
    display: inline-block;
  }
  &:nth-child(1):before {
    content: "●";
    color: #4187ba;
  }
  &:nth-child(2):before {
    content: "x";
    color: #aa1f24;
  }
  &:nth-child(3):before {
    content: "○";
    color: #4187ba;
  }
`;
