import React, {
    useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    useActionDispatch,
    useCurrentBrand,
    useSelectedCurrentData,
    useCurrentAppCommonData,
    useCurrentCar,
} from '../../../../hooks';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
    isArrayEmpty,
    isObjectEmpty,
} from '../../../../utils/commonUtils';
import { getScreenBlockErrorMsg } from '../../../../utils/configuratorUtils';
import { getMetaDataByModel } from '../../../../utils/seoUtils';
import { CONFIGURATOR_QUERY_PARAMS } from '../../../../constants';
import STRINGS from '../../../../language';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ColorPicker from '../../../Configurator/ColorPicker/ColorPicker';
import ConfiguratorDescriptionOnMobile from '../../../Configurator/ConfiguratorDescriptionOnMobile';
import HelmetTitleDescription from '../../../HelmetTitleDescription/HelmetTitleDescription';
import Loader from '../../../Loader';
import * as Styled from './Configuration.styles';

const Configuration = ({ configOptions }) => {
    const { isAudi } = useCurrentAppCommonData();
    const { externalColors, error, isLoading } = configOptions;
    const {
        selectCurrentInternalColor,
        selectCurrentExternalColor,
        selectCurrentHoop,
        selectCurrentAddOnGroup,
        selectCurrentAddOn,
        selectIsInSummary,
        getExternalColorsAsync,
    } = useActionDispatch();
    const {
        selectedExternalColor,
        selectedInternalColor,
        selectedHoop,
        selectedAddOnGroup,
        selectedAddOns,
    } = useSelectedCurrentData();
    const currentBrand = useCurrentBrand();
    const { originalAdditionsCode } = useCurrentCar();
    const {
        getQueryParam,
        updateQueryParam,
    } = useQueryParams();

    const modelCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL);
    const trimLevelCodeFromUrl  = getQueryParam(CONFIGURATOR_QUERY_PARAMS.TRIM_LEVEL);
    const modelGroupCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.MODEL_GROUP);
    const originalAdditionsCodeFromUrl = originalAdditionsCode || getQueryParam(
        CONFIGURATOR_QUERY_PARAMS.ORIGINAL_ADDITIONS);
    const externalColorCodeFromUrl = getQueryParam(CONFIGURATOR_QUERY_PARAMS.EXTERNAL_COLOR);

    const screenErrMsg = getScreenBlockErrorMsg(currentBrand);
    const {
        title,
        description,
    } = getMetaDataByModel(currentBrand, modelGroupCodeFromUrl, trimLevelCodeFromUrl);

    const pickedColorCode = selectedExternalColor?.value || externalColorCodeFromUrl;
    
    const initState = () => {
        if (!isObjectEmpty(selectedInternalColor)) {
            selectCurrentInternalColor({});
        }
        
        if (!isObjectEmpty(selectedHoop)) {
            selectCurrentHoop({});
        }
        if (selectedAddOnGroup) {
            selectCurrentAddOnGroup(null);
        }
        if (!isObjectEmpty(selectedAddOns)) {
            selectCurrentAddOn({});
        }
        selectIsInSummary(false);
    };

    const pickColorHandler = useCallback(
        (color) => {
            const { value, isPackageRelated } = color;

            if (selectedExternalColor.value !== value) {
                if (isPackageRelated) {
                    /**
                 * 1. select current external color
                 * 2. if the chosen color had changed, then initilize internal color, hoops and addons
                 */
                    selectCurrentExternalColor(color);
                    initState();
                } else {
                    // select previouly selected color - which make no state changes
                    selectCurrentExternalColor(selectedExternalColor);
                }
            }
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.EXTERNAL_COLOR, value);
        },
        [selectedExternalColor],
    );

    useEffect(() => {
        if (originalAdditionsCode) {
            updateQueryParam(CONFIGURATOR_QUERY_PARAMS.ORIGINAL_ADDITIONS, originalAdditionsCode);
        }
        
        if (isArrayEmpty(externalColors)) {
            getExternalColorsAsync(
                modelCodeFromUrl,
                trimLevelCodeFromUrl,
                originalAdditionsCodeFromUrl,
            );
        }

        const firstChosenColor = externalColors?.find(
            (color) => color.isPackageRelated,
        );

        const chosenColor = externalColors?.find(
            (color) => color.value === pickedColorCode,
        );
        
        if (chosenColor || firstChosenColor) {
            pickColorHandler(chosenColor ?? firstChosenColor);
        }
    }, [externalColors]);

    if (isLoading) {
        return (
            <Styled.LoaderWrapper>
                <Loader />
            </Styled.LoaderWrapper>
        );
    }

    if (error) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage color="red" errorMessage={ screenErrMsg } />
            </Styled.ErrorWrapper>
        );
    }

    return (
        <Styled.ColorConfiguration>
            <HelmetTitleDescription
                title={ title }
                description={ description }
                canonical={ window.location.href }
            />
            { isAudi
                && <Styled.ColorPickerHeader>{STRINGS.EXTERNAL_COLORS}</Styled.ColorPickerHeader>}
            <ColorPicker
                colors={ externalColors }
                onPick={ pickColorHandler }
                pickedColorCode={ pickedColorCode }
                isAudi={ isAudi }
            />
            {!isObjectEmpty(selectedExternalColor) && (
                <ConfiguratorDescriptionOnMobile
                    item={ selectedExternalColor }
                    isAudi={ isAudi }
                />
            )}
        </Styled.ColorConfiguration>
    );
};

Configuration.propTypes = {
    configOptions: PropTypes.object.isRequired,
};

export default Configuration;
