import * as actionTypes from '../actionTypes';
import {
    customerIdentification,
    getDealerships,
    holdCommision,
    createOrder,
    customerRegistration,
} from '../../fetch/checkout';
import STRINGS from '../../language';
import {
    CHECKOUT_PAGE_FORMS,
    ERROR_CODES,
    STATUS_CODES,
    MENUFACTURS,
    REGISTER_REASON,
    IS_VERIFIED_AUTHORIZATION,
} from '../../constants';
// eslint-disable-next-line import/no-cycle
import {
    checkOtpSuccess,
    initializeAuthentication,
    isAuthenticatedSuccess,
    setMaskedPhoneNumber,
} from './authentication';

const { OTP_FORM, REGISTRATION_FORM } = CHECKOUT_PAGE_FORMS;

const { NOT_FOUND, CONFLICT, PURCHASE_LIMIT } = ERROR_CODES;
const { SUCCESS, VERY_SUCCESS } = STATUS_CODES;
// const { AUTHORIZATION } = HEADERS;

const customerFirstIdentificationPending = () => ({
    type: actionTypes.CUSTOMER_FIRST_IDENTIFICATION_PENDING,
});

const customerFirstIdentificationSuccess = (response) => ({
    type: actionTypes.CUSTOMER_FIRST_IDENTIFICATION_SUCCESS,
    response,
});

const customerFirstIdentificationFailure = (error = null) => ({
    type: actionTypes.CUSTOMER_FIRST_IDENTIFICATION_FAILURE,
    error,
});

export const saveCustomerIdNumber = (idNumber) => ({
    type: actionTypes.SAVE_CUSTOMER_ID_NUMBER,
    idNumber,
});

const customerRegistrationPending = () => ({
    type: actionTypes.CUSTOMER_REGISTRATION_PENDING,
});

const customerRegistrationSuccess = (customer) => ({
    type: actionTypes.CUSTOMER_REGISTRATION_SUCCESS,
    customer,
});

const customerRegistrationFailure = (error = null) => ({
    type: actionTypes.CUSTOMER_REGISTRATION_FAILURE,
    error,
});

const getDealershipsPending = () => ({
    type: actionTypes.GET_DEALERSHIPS_PENDING,
});

const getDealershipsSuccess = (dealerships, isAudi) => ({
    type: actionTypes.GET_DEALERSHIPS_SUCCESS,
    dealerships,
    isAudi,
});

const getDealershipsFailure = (error) => ({
    type: actionTypes.GET_DEALERSHIPS_FAILURE,
    error,
});

export const selectDealership = (dealership) => ({
    type: actionTypes.SELECT_DEALERSHIP,
    dealership,
});

export const setIsFinancing = (isFinancing) => ({
    type: actionTypes.SET_IS_FINANCING,
    isFinancing,
});

export const setIsFinished = () => ({
    type: actionTypes.SET_IS_FINISHED,
});

export const setIsPaymentFailed = () => ({
    type: actionTypes.SET_IS_PAYMENT_FAILED,
});

export const holdCommisionPending = () => ({
    type: actionTypes.HOLD_COMMISION_PENDING,
});

export const holdCommisionSuccess = (creditGuardUrl, totalPayment) => ({
    type: actionTypes.HOLD_COMMISION_SUCCESS,
    creditGuardUrl,
    totalPayment,
});

export const holdCommisionFailure = (error) => ({
    type: actionTypes.HOLD_COMMISION_FAILURE,
    error,
});

export const setCurrentIdentificationForm = (form) => ({
    type: actionTypes.SET_CURRENT_IDENTIFICATION_FORM,
    form,
});

export const setRegisterReason = (reason) => ({
    type: actionTypes.SET_REGISTER_REASON,
    reason,
});

export const customerFirstIdentificationAsync = (sIdCard) => {
    return async (dispatch) => {
        dispatch(customerFirstIdentificationPending());
        try {
            const response = await customerIdentification(sIdCard);
            
            dispatch(customerFirstIdentificationSuccess({ customerId: sIdCard }));
            if (response.status === VERY_SUCCESS) {
                dispatch(checkOtpSuccess());
                dispatch(isAuthenticatedSuccess());
                sessionStorage.setItem(IS_VERIFIED_AUTHORIZATION, true);
            } else if (response.status === SUCCESS) {
                const { maskedPhoneNumber } = response.data;

                dispatch(setCurrentIdentificationForm(OTP_FORM));
                dispatch(setMaskedPhoneNumber(maskedPhoneNumber));
                dispatch(setRegisterReason(REGISTER_REASON.NEW_CUSTOMER));
            }
        } catch (error) {
            dispatch(customerFirstIdentificationSuccess({ customerId: sIdCard }));
            if (error?.response.status === NOT_FOUND) {
                dispatch(setCurrentIdentificationForm(REGISTRATION_FORM));
                dispatch(setRegisterReason(REGISTER_REASON.NEW_CUSTOMER));
            }
            if (error?.response.status === CONFLICT) {
                dispatch(setCurrentIdentificationForm(REGISTRATION_FORM));
                dispatch(setRegisterReason(REGISTER_REASON.DATA_CONFLICT));
            } else {
                dispatch(customerFirstIdentificationFailure(error));
                dispatch(setRegisterReason(REGISTER_REASON.NEW_CUSTOMER));
            }
        }
    };
};

export const customerRegistrationAsync = (customerRegObject) => {
    return async (dispatch) => {
        dispatch(customerRegistrationPending());
        try {
            const {
                brand,
                customerFirstName,
                customerLastName,
                customerPhoneNumber,
                sIdCard,
                customerEmail,
                isAgreeToTerms,
                isAgreeToMarketing,
            } = customerRegObject;

            await customerRegistration(
                brand,
                customerFirstName,
                customerLastName,
                customerPhoneNumber,
                sIdCard,
                customerEmail,
                isAgreeToTerms,
                isAgreeToMarketing);

            dispatch(customerRegistrationSuccess({ ...customerRegObject }));
            
            return true;
        } catch (error) {
            dispatch(customerRegistrationFailure(error));

            return false;
        }
    };
};

export const getDealershipsAsync = (brand, subBrand) => async (dispatch) => {
    dispatch(getDealershipsPending());
    try {
        const response = await getDealerships(brand, subBrand);
        const { data } = response;
        const isAudi = brand === MENUFACTURS.AUDI;

        dispatch(getDealershipsSuccess(data, isAudi));
    } catch (error) {
        dispatch(initializeAuthentication());
        dispatch(getDealershipsFailure(error));
    }
};

export const holdCommisionAsync = (
    modelCode,
    externalColorCode,
    internalColorCode,
    version,
    addOnsCodes,
    equipmentPackageCode,
) => async (dispatch) => {
    dispatch(holdCommisionPending());

    try {
        const response = await holdCommision(
            modelCode,
            externalColorCode,
            internalColorCode,
            version,
            addOnsCodes,
            equipmentPackageCode,
        );
        const { data } = response;
        const { paymentFrame, price } = data;

        dispatch(holdCommisionSuccess(paymentFrame, price));
        
        return {
            isSuccess: true,
            error: null,
        };
    } catch (error) {
        if (error?.response?.status === PURCHASE_LIMIT) {
            error.message = STRINGS.ERRMSG_PROBLEM_IN_PURCHASE_ALREADY_PURCHASED;
        } else {
            error.message = STRINGS.ERRMSG_PROBLEM_IN_PURCHASE;
        }
        
        dispatch(holdCommisionFailure(error));
        
        return {
            isSuccess: false,
            error: error.message,
        };
    }
};

export const setEditedOrderId = (orderId) => ({
    type: actionTypes.SET_EDITED_ORDER_ID,
    orderId,
});

export const createOrderAsync = (
    confirmationCode,
    cardToken,
    cardExp,
    destinationDealership,
    localAdditions,
    hasRequestedFunding,
) => async (dispatch) => {
    try {
        const order = {
            confirmationCode,
            cardToken,
            cardExp,
            destinationDealership,
            localAdditions,
            hasRequestedFunding,
        };

        const response = await createOrder(order);

        dispatch(setEditedOrderId(response.data));
        dispatch(setIsFinished());

        return true;
    } catch {
        dispatch(setIsPaymentFailed());
        
        return false;
    }
};

export const initializeCheckout = () => ({
    type: actionTypes.INITALIZE_CHECKOUT,
});

export const setIsInCheckout = (isInCheckout) => ({
    type: actionTypes.SET_IS_IN_CHECKOUT,
    isInCheckout,
});

export const saveCustomerPhoneNumber = (phoneNumber) => ({
    type: actionTypes.SAVE_PHONE_NUMBER,
    phoneNumber,
});
