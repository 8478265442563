import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import CONFIG from '../../../../config';
import { pxToRem } from '../../../../utils/commonUtils';
import { COLORS } from '../../../../constants';
import { FullPriceDescription } from './FullPriceAndTooltip/FullPriceAndTooltip.styles';

const { DEVICE_SIZE } = CONFIG;

export const ModelItemCollapsible = styled.li`
  flex: 0 0 25%;
  max-width: 400px;
  min-width: 400px;

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    flex: 1 1 100%;
    max-width: initial;
    min-width: initial;
    background-color:${({ $isSelected }) => $isSelected ? COLORS.BG_COLOR_MODEL_ITEM_SELECTED : 'transparent'};
    &:not(:first-child){
      margin-top:${({ $isSelected }) => $isSelected ? '-26px' : 0};
    }
  }
`;

export const Car = styled.div`
  padding: 35px 25px;
  overflow: hidden;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    padding: 25px 15px;
  }
`;

export const Body = styled.div`
  max-width: 250px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
  }
`;

export const SelectButton = styled(Link)`
  width:100%;
  min-width: 108px;
  max-width: 280px;
  height: 38px;
  font-weight: 700;
  line-height: 1;
  border-radius: ${({ theme }) => theme.buttonButtonRadius};
  border: none;
  outline: none;
  padding: 0 20px;
  cursor: pointer;
  color: ${COLORS.COLOR_TEXT_ITEM_CAR_SELECT_BUTTON};
  font-size: ${pxToRem(16)};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.buttonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 30px;
  
  @media (min-width: ${DEVICE_SIZE.LARGE}px) {
    &:hover{
      color: ${({ theme }) => theme.buttonTextHoverColor};
      background-color: ${({ theme }) =>  theme.buttonHoverBackgroundColor};
      border: ${({ $isAudi, $isCupra }) => $isAudi || $isCupra ? `2px solid ${COLORS.BLACK}` : 'none'};
      transition: background-color 0.5s;
    }
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    min-width: auto;
    max-width: 100%;
    height: 50px;
    margin-bottom: 52px;
  }
`;
export const Description = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: ${pxToRem(13)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: none;
  }
`;

export const ArrivelDate = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(13)};
  margin: 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const FullPriceDescriptionMobileOnly = styled(FullPriceDescription)`
  font-size: ${pxToRem(16)};
  margin-bottom: 9px;
  display: none;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;
export const Footer = styled.footer`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    text-align: center;
  }
`;

export const ToggleableContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-height: 0;
    visibility: hidden;
    transition: max-height 0.25s ease-out;
  ${({ $isActive }) => $isActive
    && css`
      max-height: 500px;
      transition: max-height 0.25s ease-in;
      visibility: visible;
    `
};
  }

`;
