/**
 * Input Field Object Example:
 @{../screens/InnerPages/ContactUs/Form/Form.fields}
 */

/**
 * receives an array in the inputFields format
 * returns an object with the following format : {field_1:'',field_2:''} etc...
 * @param {array} inputFields 
 */
export const parseInputFieldsToState = (inputFields) => {
    return inputFields.reduce((acc, item) => {
        acc[item.name ?? item.id] = item;
        
        return acc;
    }, {});
};
