import styled from 'styled-components';
import { COLORS } from '../../constants';
import { pxToRem } from '../../utils/commonUtils';

export const SubmitButton = styled.button.attrs(() => ({
    type: 'submit',
}))`
  margin-top: 40px;
  background-color: ${({ theme }) => theme.buttonColor};
  color: ${({ theme }) => theme.buttonTextColor};
  width: 100%;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: ${pxToRem(20)};
  font-weight: 700;
  height: 56px;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
    color: ${({ theme }) => theme.buttonTextHoverColor};
    border: ${({ isCupra }) => isCupra ? `2px solid ${COLORS.BLACK}` : 'none'};
    transition:  background-color 0.6s;
  }
`;
