import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { replacePlaceholdersInTemplate } from '../../../../utils/commonUtils';
import SafetyModel from './SafetyModel/SafetyModel';
import SafetyRange from './SafetyRange/SafetyRange';
import SystemDetails from './SystemDetails/SystemDetails';
import SystemDetailsLegend from './SystemDetailsLegend/SystemDetailsLegend';
import * as Styled from './Safety.styles';

const Safety = ({
    cars,
    safetyEquipmentLevel,
    pollutionAndSafetyDetails,
}) => {
    const { dataList } = pollutionAndSafetyDetails;
    const isManyModels = cars?.length > 1;

    const renderSafetySystemDetails = useCallback(() => {
        return dataList?.map((item) => {
            const {
                styleLevelDescription, groupModelDescription, safetySystems, key,
            } = item;
                
            const isSafetySystemDetails = safetySystems?.length > 1;
            const title = replacePlaceholdersInTemplate(
                STRINGS.SAFETY_SYSTEMS_DETAILS_AT_TRIM_LEVEL,
                [groupModelDescription, styleLevelDescription]);
                
            return (
                isSafetySystemDetails
                && (
                    <div key={ key }>
                        <Styled.Title>
                            {title}
                        </Styled.Title>
                        <Styled.SafetySystemDetails>
                            <SystemDetails safetySystemDetails={ safetySystems } />
                        </Styled.SafetySystemDetails>
                    </div>
                )
            );
        });
    }, [pollutionAndSafetyDetails]);

    return (

        <Styled.Safety>
            <Styled.Title>{STRINGS.SAFETY}:</Styled.Title>
            <Styled.SafetyModelAndRange>
                {isManyModels && <SafetyModel cars={ cars } />}
                <SafetyRange
                    safetyEquipmentLevel={ safetyEquipmentLevel }
                    isManyModels={ isManyModels }
                />
            </Styled.SafetyModelAndRange>
            <Styled.SafetySystemDetailsContainer>
                {renderSafetySystemDetails()}
                <SystemDetailsLegend  />
            </Styled.SafetySystemDetailsContainer>
        </Styled.Safety>
    );
};

Safety.propTypes = {
    safetyEquipmentLevel: PropTypes.number.isRequired,
    pollutionAndSafetyDetails: PropTypes.object.isRequired,
    cars: PropTypes.array.isRequired,
};

export default Safety;
