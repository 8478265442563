import styled from 'styled-components';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: calc(100% - 60px);
  }
`;

export const Logo = styled.img`
  object-fit: cover;
  max-height: 30px;
  max-width: 150px;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 50%;
  }
  &:first-child {
    margin-left: 8px;
  }
`;
