import styled from 'styled-components';
import { ReactComponent as Info } from '../../../../assets/icons/info.svg';
import CONFIG from '../../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Footer = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 8px 0;
  margin-top: auto;
  display: flex;
  align-items: center;
  z-index: 150;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    position: fixed;
    bottom: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    margin: 0;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex: 1;
  max-width: 40%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2%;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex: 1;
  max-width: 60%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const PaymentDetailsContainer = styled.div`
  width: 400px;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: #F4F4F4;
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    width: 100%;
    height: 96px;
    padding: 0 28px;
    background: #FFF;
    border-top: 1px solid #DFE4E8;
    border-radius: 0;
  }
`;

export const PriceDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const FullPrice = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
`;

export const PaymentDetailsButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};
  text-align: right;
  font-family: ${({ theme }) => theme['font-family']};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    margin-right: auto;
    margin-left: 5px;
  }
`;

export const InfoIcon = styled(Info)``;
