import React, { useState } from 'react';
import { useCurrentAppCommonData } from '../../../../hooks';
import STRINGS from '../../../../language';
import {
    FILTER_BY,
} from '../../../../constants';
import {
    isArrayAndNotEmpty,
} from '../../../../utils/commonUtils';
import ScrollMoreOverlay from '../../../ScrollMoreOverlay/ScrollMoreOverlay';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { parseOnlineCarsByModelGroups } from '../../../Steps/Model/Model.utils';
import ModelItem from './ModelItem/ModelItem';
import Styled from './Model.style';

const Model = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const appCommonData = useCurrentAppCommonData();
    const {
        onlineCars,
    } = appCommonData;
    const modelGroupsItemsByModel = parseOnlineCarsByModelGroups(onlineCars, FILTER_BY.BY_MODEL);

    if (!isArrayAndNotEmpty(onlineCars)) {
        return (
            <Styled.ErrorWrapper>
                <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />
            </Styled.ErrorWrapper>
        );
    }
  
    return (
        <Styled.Container>
            <Styled.ListWrapper>
                { modelGroupsItemsByModel.map((model) => {
                    return (
                        <ModelItem id={ model.id } model={ model } />
                    );
                })}
            </Styled.ListWrapper>
            <ScrollMoreOverlay
                isVisible={ isOverlayVisible }
                text={ STRINGS.MORE_MODELS }
            />
        </Styled.Container>
    );
};

export default Model;
