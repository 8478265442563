/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import EngineAndTrimLevelModelItem from '../EngineAndTrimLevelModelItem/EngineAndTrimLevelModelItem';
import CONFIG from '../../../../../config';
import Styled from './ModelsSlider.style';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const { DEVICE_SIZE } = CONFIG;

const ModelsSlider = ({ models, brand }) => {
    const _slides = models.map((model) => {
        return (
            <SwiperSlide>
                <EngineAndTrimLevelModelItem
                    key={ model.id }
                    model={ model }
                    brand={ brand }
                />
            </SwiperSlide>
        );
    });

    return (
        <Styled.Container>
            <Swiper
                dir="rtl"
                modules={ [Navigation, Pagination] }
                pagination={ {
                    type: 'fraction',
                } }
                navigation={ {
                    enabled: true,
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                    disabledClass: 'swiper-button-disabled',
                } }
                spaceBetween={ 20 }
                slidesPerView={ 1 }
                centerInsufficientSlides
                breakpoints={ {
                    [DEVICE_SIZE.MEDIUM]: {
                        slidesPerView: 2,
                    },
                    [DEVICE_SIZE.LARGE]: {
                        slidesPerView: 3,
                    },
                } }
            >
                <Styled.NavigationButtonsContainer>
                    <Styled.NavigationButton className="custom-swiper-button-prev right" />
                    <Styled.NavigationButton className="custom-swiper-button-next left" />
                </Styled.NavigationButtonsContainer>
                {_slides}
            </Swiper>
        </Styled.Container>
    );
};

ModelsSlider.defaultProps = {
    models: [],
};

ModelsSlider.propTypes = {
    models: PropTypes.array,
    brand: PropTypes.string.isRequired,
};

export default ModelsSlider;
