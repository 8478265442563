/* eslint-disable max-len */
import React from 'react';
import InnerPagesHeader from '../../../components/InnerPages/InnerPagesHeader/InnerPagesHeader';
import InnerPageContainer from '../../../components/InnerPages/InnerPageContainer';
import STRINGS from '../../../language';
import HelmetTitleDescription from '../../../components/HelmetTitleDescription/HelmetTitleDescription';
import * as Styled from './PrivacyPolicy.styles';
import PrivacyPolicyData from './PrivacyPolicyData';

const PrivacyPolicy = () => {
    return (
        <Styled.PrivacyPolicy>
            <HelmetTitleDescription canonical={ window.location.href } />
            <InnerPageContainer>
                <InnerPagesHeader title={ STRINGS.PRIVACY_POLICY } />
                <Styled.Body>
                    <PrivacyPolicyData />
                </Styled.Body>
            </InnerPageContainer>
        </Styled.PrivacyPolicy>
    );
};

export default PrivacyPolicy;
