import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import PaymentDetailItem from '../PaymentDetailItem/PaymentDetailItem';
import AudiPaymentDetailItem from '../../AudiConfigurator/AudiPaymentDetails/AudiPaymentDetailItem/AudiPaymentDetailItem';
import { useCurrentAppCommonData } from '../../../hooks';
import * as Styled from './Specifications.styles';

const Specifications = ({ specifications }) => {
    const { isAudi } = useCurrentAppCommonData();

    if (specifications.length <= 0) {
        return null;
    }
    const _specifications = specifications.map((specification) => {
        const {
            name, price, image, discount, isDiscount, total, type,
        } = specification;

        const AudiOrDefault = isAudi ? AudiPaymentDetailItem : PaymentDetailItem;
    
        return (
            <AudiOrDefault
                discount={ discount }
                isDiscount={ isDiscount }
                total={ total }
                name={ name }
                price={ price }
                image={ image }
                type={ type }
                key={ name }
            />
        );
    });
    
    return (
        <Styled.Specifications>
            <Styled.Title $isAudi={ isAudi }>{STRINGS.PAYMENT_TITLE_SPECIFICATION}</Styled.Title>
            <Styled.List>
                {_specifications}
            </Styled.List>
        </Styled.Specifications>
    );
};

Specifications.defaultProps = {
    specifications: [],
};

Specifications.propTypes = {
    specifications: PropTypes.array,
};

export default Specifications;
