import React, { useEffect, useState } from 'react';
import IMAGES from '../../assets';
import { LOGOS_PATHS, MENUFACTURS } from '../../constants';
import STRINGS from '../../language/hebrew';
import * as Styled from './Maintenance.styles';

const { REACT_APP_BRAND, REACT_APP_SUB_BRAND } = process.env;

const Maintenance = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState(IMAGES.MAINTENANCE_IMAGE_SMALL);

    const fetchImage = () => {
        const image = new Image();

        image.src = IMAGES.MAINTENANCE_IMAGE;
        image.onload = () => {
            setImageSrc(image.src);
            setIsLoading(false);
        };
    };

    useEffect(() => {
        fetchImage();
    }, []);

    return (
        <Styled.Body>
            <Styled.MainContainer>
                <Styled.Image
                    src={ imageSrc }
                    isLoading={ isLoading }
                />
                <Styled.Container>
                    <Styled.Text>
                        { STRINGS.SITE_IS_UNDER_MAINTENANCE }
                        <br />
                        { STRINGS.SORRY_FOR_INCONVENIENCE }
                        <br />
                        <br />
                        <br />
                        { STRINGS.WE_WILL_BE_BACK_SOON }
                    </Styled.Text>
                </Styled.Container>
                <Styled.Logo
                    isSquare={ REACT_APP_BRAND === MENUFACTURS.VOLKSWAGEN }
                    src={
                        LOGOS_PATHS[REACT_APP_SUB_BRAND ?? REACT_APP_BRAND].maintenance
                    }
                />
            </Styled.MainContainer>
        </Styled.Body>
    );
};

export default Maintenance;
