import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Form.styles';

const Form = ({ children, onSubmit, isAudi }) => {
    return <Styled.Form onSubmit={ onSubmit } $isAudi={ isAudi }>{children}</Styled.Form>;
};

Form.defaultProps = {
    onSubmit: () => {},
    isAudi: false,
};
Form.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    onSubmit: PropTypes.func,
    isAudi: PropTypes.bool,
};
export default Form;
