import React, { useState } from 'react';
import Car360 from '../Configurator/Car360/Car360';
import { useCurrentBrand, useSelectedCurrentData } from '../../hooks';
import { useInCarImageUrl, useOutCarImageUrl } from '../../utils/assets.hooks';
import * as Styled from './ExternalInternalMediaToggle.styles';

const ExternalInternalMediaToggle = () => {
    const [isExternalMediaShown, setIsExternalMediaShown] = useState(true);
    const {
        selectedCar,
        selectedExternalColor,
        selectedInternalColor,
    } = useSelectedCurrentData();
    const currentBrand = useCurrentBrand();
    const { link360: externalLink360, value: externalColorValue } = selectedExternalColor;
    const { link360: internalLink360, value: internalColorValue } = selectedInternalColor;
    const { modelGroupCode, trimLevelCode } = selectedCar;

    const externalImageUrl = useOutCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        externalColorValue);

    const internalImageUrl = useInCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        internalColorValue);

    return (
        <Styled.Container key={ isExternalMediaShown }>
            <Car360
                src={ isExternalMediaShown ? externalLink360 : internalLink360 }
                fallbackSrc={ isExternalMediaShown ? externalImageUrl : internalImageUrl }
            />
            <Styled.ButtonWrapper
                onClick={ () => setIsExternalMediaShown(!isExternalMediaShown) }
            >
                <Styled.SmallImage
                    src={ isExternalMediaShown ? internalImageUrl : externalImageUrl }
                />
            </Styled.ButtonWrapper>
        </Styled.Container>
    );
};

export default ExternalInternalMediaToggle;
