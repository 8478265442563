import React from 'react';
import PropTypes from 'prop-types';
import { NoEntranceIcon } from '../../../../common/styles';
import STRINGS from '../../../../../language';
import Tooltip from '../../../../Configurator/Tooltip/Tooltip';
import * as Styled from './InternalColorItem.styles';

const InternalColorItem = ({
    onClick,
    selected,
    inStockString,
    description,
    price,
    image,
    isPackageRelated,
    isAudi,
}) => {
    const isAdditionalPayment = price !== 0;
    const additionalPaymentText = !isAdditionalPayment
        ? STRINGS.NO_ADDITIONAL_PAYMENT
        : `${STRINGS.ADDITION_PAYMENT}: ${price} ₪`;

    const renderTooltipContent = () => {
        return (
            <>
                <Styled.TooltipTitle>{description}</Styled.TooltipTitle>
                <Styled.NoPackageTooltipContainer>
                    <NoEntranceIcon />
                    <Styled.TooltipInnerText>
                        {STRINGS.THIS_COLOR_IS_NOT_INCLUDED_IN_THE_MODEL_YOU_CHOSE}
                    </Styled.TooltipInnerText>
                </Styled.NoPackageTooltipContainer>
            </>
        );
    };
    
    return (
        <Styled.InternalColorItem $isAudi={ isAudi }>
            <Styled.Content $isPackageRelated={ isPackageRelated }>
                <Styled.Picker
                    $image={ image }
                    onClick={ onClick }
                    $selected={ selected }
                    $isPackageRelated={ isPackageRelated }
                    $isAudi={ isAudi }
                >
                    {!isPackageRelated && <NoEntranceIcon />}
                </Styled.Picker>
                <Styled.Details $isAudi={ isAudi }>
                    <Styled.Description>{description}</Styled.Description>
                    <Styled.Price> {additionalPaymentText} </Styled.Price>
                    <Styled.InStock>{inStockString}</Styled.InStock>
                </Styled.Details>
            </Styled.Content>
            {!isPackageRelated && <Tooltip>{renderTooltipContent()}</Tooltip>}
        </Styled.InternalColorItem>
    );
};

InternalColorItem.defaultProps = {
    onClick: () => {},
    selected: false,
    isPackageRelated: true,
    isAudi: false,
};

InternalColorItem.propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    inStockString: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    isPackageRelated: PropTypes.bool,
    isAudi: PropTypes.bool,
};

export default InternalColorItem;

///   <Tooltip text="צבע זה אינו כלול בחבילת האבזור שבחרת"/>
