import React, {
    useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentBrand,
    useActionDispatch,
    useCurrentAddOns,
} from '../../../../../hooks';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { CONFIGURATOR_QUERY_PARAMS } from '../../../../../constants';
import AddOnItem from '../Components/AddOnItem/AddOnItem';
import * as Styled from './AddOnsList.styles';

const AddOnsList = ({
    modelLocalGroups,
    hoopCode,
    addOnListContainerRef,
    addOnGroupRef,
}) => {
    const currentBrand = useCurrentBrand();
    const {
        getAddOnsExtraInfoAsync,
        selectCurrentAddOn,
        selectIsInSummary,
        selectCurrentAddOnGroup,
    } = useActionDispatch();
    const selectedAddOns = useCurrentAddOns();
    const {
        addQueryParam,
        deleteQueryParam,
    } = useQueryParams();
    const { addOns } = modelLocalGroups;
    const { addOnsExtraInformation } = modelLocalGroups;

    const addToRefs = (el, groupCode) => {
        if (el && !addOnGroupRef.current[groupCode]) {
            if (addOnGroupRef) {
                addOnGroupRef.current[groupCode] = el;
            }
        }
    };

    const selectAddOnHandler = useCallback((addOn) => {
        const addOnsCodesArray = Object.keys(selectedAddOns).map(
            (key) => selectedAddOns[key].local);

        const addOnKey = `${addOn.groupCode}-${addOn.local}`;

        const isAddOnExist = addOnsCodesArray.includes(addOn.local);

        if (isAddOnExist) {
            deleteQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        } else {
            addQueryParam(CONFIGURATOR_QUERY_PARAMS.ADDONS, addOnKey);
        }

        selectCurrentAddOnGroup(addOn);
        selectCurrentAddOn(addOn);
        selectIsInSummary(false);
    }, [selectedAddOns]);

    useEffect(() => {
        if (addOns[hoopCode]) {
            addOns[hoopCode].forEach((AddOnGroup) => {
                const { listLocalPackages } = AddOnGroup;

                const { groupCode } = AddOnGroup;

                getAddOnsExtraInfoAsync(currentBrand, groupCode, listLocalPackages);
            });
        }
    }, [addOns[hoopCode]]);

    const renderAddOnsGroup = useCallback(() => {
        if (addOns[hoopCode]) {
            return addOns[hoopCode].map((AddOnGroup) => {
                const { listLocalPackages, groupDescription, groupCode } = AddOnGroup;

                const renderAddons = () => {
                    return  listLocalPackages.map((AddOn, index) => {
                        const {
                            description,
                            price,
                            local,
                            image,
                            priceAfterDiscounts,
                            isDiscount,
                        } = AddOn;

                        return (
                            <AddOnItem
                                key={ local }
                                description={ description }
                                groupDescription={ groupDescription }
                                groupCode={ groupCode }
                                localCode={ local }
                                addOnsExtraInformation={ addOnsExtraInformation }
                                index={ index }
                                price={ parseInt(price) }
                                image={  image }
                                priceAfterDiscounts={ priceAfterDiscounts }
                                isDiscount={ isDiscount }
                                onSelect={ selectAddOnHandler }
                            />
                        );
                    });
                };

                return (
                    <div key={ groupCode }>
                        <Styled.AddOnGroupTitle
                            ref={ (el) => addToRefs(el, groupCode) }
                            key={ groupCode }
                        >
                            {groupDescription}
                        </Styled.AddOnGroupTitle>
                        {renderAddons()}
                    </div>
                );
            });
        }
            
        return null;
    }, [modelLocalGroups, addOnsExtraInformation, selectedAddOns]);

    return (
        <Styled.AddOnsList ref={ addOnListContainerRef }>
            {renderAddOnsGroup()}
        </Styled.AddOnsList>
    );
};

AddOnsList.propTypes = {
    modelLocalGroups: PropTypes.object.isRequired,
    hoopCode: PropTypes.string.isRequired,
    addOnGroupRef: PropTypes.object.isRequired,
    addOnListContainerRef: PropTypes.object.isRequired,
};

export default AddOnsList;
