import styled from 'styled-components';
import CONFIG from '../../../config';
import { COLORS } from '../../../constants';
import { pxToRem } from '../../../utils/commonUtils';
import {
    Label,
    InputFormGroup,
    Asterisk,
    ErrorMessage,
} from '../../InputFormGroup/InputFormGroup.styles';
import Styled from '../../CustomTextInput/CustomTextInput.style';

const { DEVICE_SIZE } = CONFIG;

export const Container = styled.div`
  position: fixed;
  z-index: 150 ;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.show-up-enter .modal-content {
    transform: translateX(-100%);
  }
  
  &.show-up-enter-active .modal-content {
    transform: translateX(0);
    transition: all 400ms;
  }
  
  &.show-up-exit-active .modal-content {
    transform: translateX(-100%);
    transition: all 400ms;
  }
  &.show-up-exit-active {
    transition: all 500ms;
  }


  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    justify-content: center;
    align-items: flex-end;
    flex: 2 1;
    &.show-up-enter .modal-content {
      transform: translateY(100%);
    }
    
    &.show-up-enter-active .modal-content {
      transform: translateY(0);
      transition: all 400ms;
    }
    
    &.show-up-exit-active .modal-content {
      transform: translateY(100%);
      transition: all 400ms;
    }
    &.show-up-exit-active {
      transition: all 500ms;
    }
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
  z-index: 200;
  padding: 15px;
  background-color: ${COLORS.BG_COLOR_MODAL};
  border-radius: 1px;
  box-shadow: 3px 5px 14px rgb(0 0 0 / 50%);

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    top: 7.5rem;
    height: calc(100% - 7.5rem);
    width: calc(100% - 2rem);
    padding-top: 0;
    margin-bottom: auto;
    border-radius: 10px 10px 0 0;
  }
`;

export const CloseButton = styled.p`
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 500;
  font-size: ${pxToRem(26)};
  cursor: pointer;
  &::before {
    content: "\\00d7";
    padding: 0;
    font-size: ${pxToRem(50)};
    font-weight: 300;
    margin-left: 3px;
  }

  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    left: 10px;
    margin: 0;
    right: auto;
  }
`;

export const FormContainer = styled.div`
  flex:1;
  ${Label} {
    font-size: ${pxToRem(14)};
    margin-bottom: 7px;
  }
  ${InputFormGroup} {
    margin-bottom: 10px;
  }
  ${Asterisk} {
    display: none;
  }
  ${ErrorMessage} {
    margin-top: 0;
  }
`;

export const ModalFormContent = styled.div`
  display: block;
  margin-top: 2rem;
  overflow: auto;
`;

export const ImageContainer = styled.figure`
  margin:0;
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width:465px;
    width: 100%;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:none;
  }
`;

export const LogoContainer = styled.figure`
  margin: 0;
  flex: 1;
  display: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  img {
    max-width:100px;
    width: 100%;
  }
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:flex;
  }
`;

export const Title = styled.h2`
  margin: 0 0 5px 0;
  font-size: ${pxToRem(26)};
`;

export const SmallTitle = styled.h2`
  margin: 0 0 5px 0;
  font-size: ${pxToRem(18)};
  text-align: center;
`;

export const Form = styled.div`
// Styled = CustomTextInput
  ${Styled.Container} {
    max-width: 100%;
  }
`;

export const InputFieldsContainer = styled.div`
`;

export const Terms = styled.div`
  margin-top: 10px;
  position: relative;
`;

export const MessageContainer = styled.div`
  padding:20px;
`;

export const SuccessContainer = styled.h1`
  font-size: 24px;
  color: red;
  text-align: center;
  margin: auto;
`;
