import { useSelector } from 'react-redux';

export const useAuthentication = () => {
    return useSelector(({ authenticationReducer }) => authenticationReducer);
};

export const useAuthenticationSms = () => {
    const { sms } = useAuthentication();

    return sms;
};

export const useAuthenticationOtp = () => {
    const { otp } = useAuthentication();

    return otp;
};

export const useAuthenticationAuth = () => {
    const { auth } = useAuthentication();

    return auth;
};

// -------------------------- //

export const useOtpCorrect = () => {
    const { isCorrect } = useAuthenticationOtp();

    return isCorrect;
};
