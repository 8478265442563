import styled from 'styled-components';
import { COLORS } from '../../../../constants';
import { pxToRem } from '../../../../utils/commonUtils';

export const FormContainer = styled.div`
    padding:20px 55px 55px;
    flex:1 1 50%;
`;

export const Title = styled.h1`
font-size:${pxToRem(54)};
font-weight:800;
margin:0;
`;

export const Text = styled.p`
 margin:6px 0 19px 0;
 font-size:${pxToRem(14)};
font-weight:500;
line-height:22px;
`;

export const Form = styled.form`
`;

export const FieldSet = styled.fieldset`
 border:none;
 padding:0;
 margin:0;
`;

export const EmailTo = styled.a``;
export const Tel = styled.a``;
export const Error = styled.p`
  font-size:${pxToRem(14)};
  color:${COLORS.COLOR_ERROR_LABEL_INPUT_FIELD};
  font-weight:500;  
`;

export const SubmitButton = styled.button.attrs(() => ({
    type: 'submit',
}))`
height:52px;
background-color:${COLORS.BLACK};
color:${COLORS.WHITE};
border:none;
width:100%;
outline:none;
border-radius: .2rem;
font-family: ${({ theme }) => theme['font-family']};
font-size:${pxToRem(21)};
cursor: pointer;
&:hover{
    background: #007674;
}
`;
