import React from 'react';
import PropTypes from 'prop-types';
import { CONFIGURATOR_STEPS } from '../../../../constants';
import { getStepTitle } from '../../../../utils/configuratorUtils';
import { useCurrentAppCommonData, useSelectedCurrentData } from '../../../../hooks';
import * as Styled from './PresentationLeft.style';

const { MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES } = CONFIGURATOR_STEPS;
const fullScreenlist = [MODEL, ENGINE_AND_TRIM_LEVEL, EQUIPMENT_PACKAGES];
const  PresentationalLeft = ({
    component, configOptions, currentStep, onGoBack,
}) => {
    const  { selectedCar } =  useSelectedCurrentData();
    const { isAudi } = useCurrentAppCommonData();
    const PresentationComponent =  component || (() => null);
    const isGoBackButton = currentStep !== MODEL
    || currentStep === ENGINE_AND_TRIM_LEVEL;
    const { modelDescription } = selectedCar;
    const isFullScreen = fullScreenlist.find((screen) => currentStep === screen);
    const showModelDescription = !!modelDescription && currentStep !== ENGINE_AND_TRIM_LEVEL;

    const renderHeaderOnMobile = () => {
        return (
            <Styled.HeaderOnMobile
                $isAudi={ isAudi }
            >
                {isGoBackButton && (
                    <Styled.GoBackButtonMobileWrapper>
                        <Styled.AudiGoBackButtonMobile onClick={ onGoBack } />
                        <Styled.StepTitle
                            $isAudi={ isAudi }
                        >
                            {getStepTitle(currentStep)}
                        </Styled.StepTitle>
                    </Styled.GoBackButtonMobileWrapper>
                )}
                {showModelDescription && !isAudi && (
                    <Styled.ModelDescription>{modelDescription}</Styled.ModelDescription>
                )}
            </Styled.HeaderOnMobile>
        );
    };

    return (
        <Styled.PresentationalLeft $isFullScreen={ isFullScreen }>
            {renderHeaderOnMobile()}
            <PresentationComponent configOptions={ configOptions } />
        </Styled.PresentationalLeft>
    );
};

PresentationalLeft.defaultProps = {
    component: null,
    currentStep: null,
    onGoBack: () => {},
};

PresentationalLeft.propTypes = {
    component: PropTypes.elementType,
    onGoBack: PropTypes.func,
    currentStep: PropTypes.number,
    configOptions: PropTypes.object.isRequired,
};

export default PresentationalLeft;
