import CONFIG from '../config';
import {
    LANGUAGE,
} from '../constants/constants';
import heb from './hebrew';

const STRING = (locale = CONFIG.LANGUAGE) => {
    switch (locale) {
        case LANGUAGE.HEBREW:
        default:
            return heb;
    }
};

export default STRING();
