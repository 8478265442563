import styled, { css } from 'styled-components';
import { LoaderContainer as LD } from '../IdentificationForm/IdentificationForm.styles';
import CONFIG from '../../../../../config';
import { Form } from '../Form/Form.styles';
import { Action, Button } from './Action/Action.styles';

const { DEVICE_SIZE } = CONFIG;

export const OtpForm = styled.div`
  padding-bottom: 30px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    padding-bottom: 0;
  }
  ${Form}{
      margin-top:25px;
  }
  ${({ $isAudi }) => $isAudi && css`
    ${Action} {
      margin: 1rem 0;
      ${Button} {
        color: black;
      }
    }
  `}
`;

export const LoaderContainer = styled(LD)``;

export const ErrorContainer = styled.div`
   margin-bottom:10px;
`;

export const StepHeadContainer = styled.div`
  display: inline-flex;
  @media(max-width: ${DEVICE_SIZE.SMALL}px) {
    flex-direction: ${({ $isAudi }) => !$isAudi && 'column'};
  }
`;

export const TitleContainer = styled.div`
  display: block;
`;

export const IconAndSubTitleWrapper = styled.div`
  display: none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: block;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    display: ${({ $isAudi }) => $isAudi && 'none'};
  }
`;
