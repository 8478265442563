/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

const IconAndSubTitleMock = ({ icon, subTitle }) => {
    return null;
};

IconAndSubTitleMock.propTypes = {
    icon: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
};

export default IconAndSubTitleMock;
