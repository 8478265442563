import React from 'react';
import { useCurrentBrand } from '../../../../hooks';
import { getFullManufacturer } from '../../../../utils/commonUtils';
import { LANGUAGE } from '../../../../constants';
import STRINGS from '../../../../language';
import * as Styled from './HowToBuyAVehicle.styles';

const HowToBuyAVehicle = () => {
    const currentBrand = useCurrentBrand();
    const manufacturerInHebrew = getFullManufacturer(
        currentBrand,
        LANGUAGE.HEBREW,
    );

    const title = STRINGS.HOW_EASY_TO_BUY(manufacturerInHebrew);
    const subTitle = STRINGS.ORDER_IN_FOUR_STEPS(manufacturerInHebrew);

    return (
        <Styled.Container>
            <Styled.SmallTitle>{ title }</Styled.SmallTitle>
            <Styled.BigTitle>{ subTitle }</Styled.BigTitle>
        </Styled.Container>
    );
};

export default HowToBuyAVehicle;
