import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../../Configurator/Tooltip/Tooltip';
import STRINGS from '../../../../../language';
import { NoEntranceIcon } from '../../../../common/styles';
import * as Styled from './HoopItem.styles';

const isPackageRelated = true; // todo::it should be passed as a prop when real data is ready

const HoopItem = ({
    onClick,
    selected,
    text,
    price,
    image,
}) => {
    const isAdditionalPayment = price !== 0;
    const additionalPaymentText = !isAdditionalPayment
        ? STRINGS.NO_ADDITIONAL_PAYMENT
        : `${STRINGS.ADDITION_PAYMENT}: ${price} ₪`;

    const renderTooltipContent = () => {
        return (
            <>
                <Styled.TooltipTitle>{text}</Styled.TooltipTitle>
                <Styled.NoPackageTooltipContainer>
                    <NoEntranceIcon />
                    <Styled.TooltipInnerText>
                        {STRINGS.THESE_HOOPS_ARE_NOT_INCLUDED_IN_THE_CHOSEN_EQUIPMENT_PACKAGE}
                    </Styled.TooltipInnerText>
                </Styled.NoPackageTooltipContainer>
            </>
        );
    };

    const renderTooltip = () => {
        if (isPackageRelated) {
            return null;
        }
        
        return <Tooltip>{renderTooltipContent()}</Tooltip>;
    };
        
    return (
        <Styled.HoopItem>
            <Styled.Content $isPackageRelated={ isPackageRelated }>
                <Styled.Picker
                    $isPackageRelated={ isPackageRelated }
                    $image={ image }
                    onClick={ isPackageRelated ? onClick : null }
                    $selected={ selected }
                >
                    {!isPackageRelated && <NoEntranceIcon />}
                </Styled.Picker>
                
                <Styled.Details>
                    <Styled.Name>{text}</Styled.Name>
                    <Styled.Price>{additionalPaymentText}</Styled.Price>
                </Styled.Details>
            </Styled.Content>
            {renderTooltip()}
        </Styled.HoopItem>
    );
};

HoopItem.defaultProps = {
    onClick: () => {},
    selected: false,
};

HoopItem.propTypes  = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
};

export default HoopItem;
