import styled, { css } from 'styled-components';
import { pxToRem } from '../../utils/commonUtils';
import { COLORS, TITLE_POSITION } from '../../constants';
import CONFIG from '../../config.js';

const { BOTTOM, CENTER, TOP } = TITLE_POSITION;

const { DEVICE_SIZE } = CONFIG;

const defineAlignItems = (postion) => {
    let style = css``;
  
    switch (postion) {
        case BOTTOM: {
            style = css`
              align-items: flex-end;
              padding-bottom: 3rem;
            `;
            break;
        }
        case CENTER: {
            style = css`
              align-items: center;
            `;
            break;
        }
        case TOP: {
            style = css`
              align-items: flex-start;
              justify-content: flex-start;
              padding-right: 3rem;
              padding-top: 3rem;
            `;
            break;
        }
        default:
            style = css``;
    }

    return style;
};

export const ImageBlockWithTextInTheMiddle = styled.div`
  height: 450px;
  width: 100%;
  background-image: url(${({ $image }) => $image});
  background-repeat: no-repeat;
  background-size: ${({ $isDefaultImage }) => $isDefaultImage ? 'auto' : 'cover'};
  background-position: center;
  display: flex;
  justify-content: center;
  margin: auto;
  ${({ $position }) => defineAlignItems($position)};
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    height: 280px;
    background-position: left 35% bottom 45%;
  }
  

`;
export const Title = styled.h1`
  font-size: ${pxToRem(38)};
  color: ${COLORS.WHITE};
  font-weight: 500;
  box-shadow: 0 0 #000;
  text-shadow: 2px 2px 12px #000;
  margin: 0;
`;
