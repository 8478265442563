import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loader from '../Loader';

const CustomLoader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index:1;
`;

const Image = ({
    src, fallbackSrc, showLoading, alt, onImageError, className, ...rest
}) => {
    const [_src, setSrc] = useState(src);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const onError = () => {
        setHasError(true);
        onImageError();
    };
    
    useEffect(() => {
        if (hasError) {
            setSrc(fallbackSrc);
        } else {
            setSrc(src);
        }
    }, [fallbackSrc, hasError, src]);

    useEffect(() => {
        setSrc(src);
    }, [src]);
    
    const onLoad = () => {
        setLoading(false);
    };
  
    return (
        <>
            {showLoading && loading && (
                <CustomLoader>
                    <Loader />
                </CustomLoader>
            )}
            <img
                src={ _src }
                className={ className }
                onLoad={ onLoad }
                onError={ onError }
                alt={ alt }
                { ...rest }
            />
        </>
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string.isRequired,
    className: PropTypes.string,
    alt: PropTypes.string,
    showLoading: PropTypes.bool,
    onImageError: PropTypes.func,
};

Image.defaultProps = {
    alt: '',
    className: '',
    showLoading: true,
    onImageError: () => {},
};

export default Image;
