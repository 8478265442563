import { CONFIGURATOR_STEPS, MONTHS_IN_HEBREW } from '../constants';
import STRINGS from '../language';
import {
    getManufacturerContactInfo,
    replacePlaceholdersInTemplate,
    switchObjValuesAndKeys,
} from './commonUtils';

/**
 * takes in the step id, return the title of the relevant config step screen
 * @param {number} stepId
 * @returns {string} with the following structure : בחירת צבע
 */

export const getStepTitle = (stepId, isAudi = false) => {
    const configSteps = switchObjValuesAndKeys(CONFIGURATOR_STEPS);

    if (isAudi) {
        return `${STRINGS.SELECTION} ${STRINGS.AUDI[configSteps[stepId]]}`;
    }

    return `${STRINGS.SELECTION} ${STRINGS[configSteps[stepId]]}`;
};

/**
 * takes in the step id, return the button title(innterHtml) of the relevant config next step screen
 * @param {number} stepId
 * @returns {string} with the following structure : המשך לצבע פנים
 */

export const getStepContinueBtnTitle = (stepId, steps = CONFIGURATOR_STEPS) => {
    const configSteps = switchObjValuesAndKeys(steps);

    if (stepId === CONFIGURATOR_STEPS.ENGINE_AND_TRIM_LEVEL) {
        return 'המשך לצבע';
    }

    return `${STRINGS.CONTINUE_TO}${STRINGS[configSteps[stepId]]}`;
};

/**
 * takes in the step id, return the button title(innterHtml) of the relevant config next step screen
 * @param {number} stepId
 * @returns {string} with the following structure : המשך לצבע פנים
 */

export const getAudiStepContinueBtnTitle = (stepId, steps = CONFIGURATOR_STEPS) => {
    const configSteps = switchObjValuesAndKeys(steps);

    if (stepId === CONFIGURATOR_STEPS.ENGINE_AND_TRIM_LEVEL) {
        return 'המשך לצבע';
    }
    if (stepId === CONFIGURATOR_STEPS.SUMMARY) {
        return 'המשך לסיכום ותשלום';
    }

    return `${STRINGS.CONTINUE_TO_PICK}${STRINGS[configSteps[stepId]]}`;
};

/**
 *
 * @param {number} currentStepId
 *  @param {string} direction
 * @returns {number} the next step if there is any || current step
 */

export const getNextOrPrevStep = (currentStepId, direction, steps = CONFIGURATOR_STEPS) => {
    const configSteps = Object.values(steps);

    if (direction !== 'prev') {
        direction = 'next';
    }

    if (!configSteps.includes(currentStepId)) {
        return currentStepId;
    }
    let stepIndex = configSteps.indexOf(currentStepId);

    stepIndex = direction === 'next' ? stepIndex + 1 : stepIndex - 1;

    if (!configSteps[stepIndex]) {
        return currentStepId;
    }

    return configSteps[stepIndex];
};

/**
 *
 * @param {string} arrivalDate in the following format : 22/4/2021
 * @returns {string} 2021-4-22
 */

export const parseArrivalDateToJsReadable = (arrivalDate) => {
    return arrivalDate.split('/').reverse().join('-');
};

/**
 *
 * @param {string} arrivalDate in the following format : 2021-4-22
 * @returns {string} in the following format: צפי משוער להגעה לארץ: אפריל 2021
 */

export const getItemInStockSentence = (arrivalDate, isAudi = false) => {
    if (!arrivalDate) {
        return null;
    }
    const dateInReadableForm = parseArrivalDateToJsReadable(arrivalDate);
    const date = new Date(dateInReadableForm);

    if (date <= new Date()) {
        return STRINGS.IN_STOCK;
    }
    const monthInHebrew = MONTHS_IN_HEBREW[date.getMonth()];
    const year = date.getFullYear();

    if (isAudi) {
        return `מועד אספקה משוער: ${monthInHebrew} ${year}`;
    }

    return `צפי משוער להגעה לארץ: ${monthInHebrew} ${year}`;
};

/**
 * 
 * @param {string} brand 
 * @returns error msg with dynamic contact details
 */

export const getScreenBlockErrorMsg = (brand) => {
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(brand);
    const summaryScreenErrMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );

    return summaryScreenErrMsg;
};
