import React from 'react';
import PropTypes from 'prop-types';
import {
    createStore, compose, applyMiddleware, combineReducers,
} from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import {
    appCommonDataReducer,
    selectedCurrentDataReducer,
    configuratorOptionsReducer,
    specificationsReducer,
    authenticationReducer,
    checkoutReducer,
} from './reducers';

const isDevelopmentEnv = process.env.NODE_ENV === 'development';

const composeEnhancers = isDevelopmentEnv
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer = combineReducers({
    appCommonDataReducer,
    selectedCurrentDataReducer,
    configuratorOptionsReducer,
    specificationsReducer,
    authenticationReducer,
    checkoutReducer,
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

const StoreProvider = ({ children }) => {
    return <Provider store={ store }>{children}</Provider>;
};

StoreProvider.propTypes = {
    children: PropTypes.array.isRequired,
};

export { StoreProvider };
