/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const NessaryDocuments = () => {
    return (
        <>
            <div>טופס ר.ש &ndash; טופס רישום הרכב במשרד הרישוי</div>
            <p dir="rtl">
                בטופס יש למלא: שם מלא, מס ת.ז, כתובת (פרטים אלו יהיו רשומים ברישיון
                הרכב)
            </p>
            <ul style={ { listStyleType: 'disc' } }>
                <li dir="rtl">
                    <p dir="rtl">
                        הסכם הזמנת רכב - הסכם בין הלקוח לצ'מפיון מוטורס בע"מ המכיל את פרטי
                        ההזמנה שביצעת ואת תנאי רכישת הרכב. לאחר שתקרא את כל תנאי החוזה,
                        עלייך לחתום במקום המסומן "חתימת המזמין".
                    </p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">
                        להסכם הזמנת הרכב מצורף תעודת אחריות לרכב המפרטת את אחריות יצרן הרכב
                        לרכב שהזמנת. לאחר שתקרא את תנאי האחריות, עלייך לחתום במקום המסומן
                        "חתימת המזמין".
                    </p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">
                        צילום ת.ז או רישיון נהיגה של האדם שעל שמו יירשם הרכב. יש להקפיד
                        לשלוח צילום ברור.
                    </p>
                </li>
                <li dir="rtl">
                    <p dir="rtl">
                        טופס הפנייה לקבלת מימון &ndash; מסמך בו צ'מפיון מוטורס בע"מ מפנה את
                        הלקוח לבנק לקבלת הלוואה מהבנק לרכישת הרכב.
                    </p>
                </li>
            </ul>
        </>
    );
};

export default NessaryDocuments;
