import styled from 'styled-components';
import { COLORS } from '../../../constants';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

export const Terms = styled.div`
  padding:10px  0 40px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    background-color: ${COLORS.BG_COLOR_INNER_PAGE_MOBILE}
  }
  
  /* li{
    font-size:14px;
    line-height:20px;
    margin-bottom:10px;
  } */

`;
