import styled, { css } from 'styled-components';
import CONFIG from '../../../config';
import { ContinueThemeButton } from '../../ContinueThemeButton/ContinueThemeButton.styles';
import { CarTitle } from '../../PaymentDetails/PaymentDetails.styles';
import { pxToRem } from '../../../utils/commonUtils';
import { Car360 } from '../../Configurator/Car360/Car360.styles';
import { StickyConfigLeftFooterOnMobile } from '../../common/styles';

const { DEVICE_SIZE } = CONFIG;

export const Summary = styled.div`
  max-width: 1920px;
  margin: auto;
`;
export const Media = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  flex: 1;
  max-width: ${({ $isAudi }) => $isAudi ? '100%' : '50%'};
  padding: 100px 30px 160px;
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
    max-width: 100%;
    padding: 0;
    padding-top: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  max-width: 1500px;
  margin: auto;
  flex-wrap: nowrap;
  min-height:1000px;
  @media(min-width:${DEVICE_SIZE.LARGE}px){
  ${({ $isAudi }) => $isAudi && css`
    flex-direction: row-reverse;
    max-width: none;
    justify-content: space-between;
    margin: 0 10%;
    height: 100%;
  `}
  }
  
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    flex-direction:column;
  }

  ${ContinueThemeButton}{
    max-width:100%;
  }
`;

export const StickyContent = styled.div`
  position: sticky;
  top: 100px;
  height: 400px;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    height: 100%;
  }
`;

export const MediaContainer = styled.div`
   height:100%;
   max-height:500px;
   position:relative;

   @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    max-width:100%;
    max-height:100;
    min-height: 135px;
    height:135px;
    display:block;

    ${Car360}{
      display:flex;
    }
  }

`;
export const PaymentSummary = styled.aside`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 100px 7% 100px 7%;
  ${({ $isAudi }) => $isAudi && css`
    padding: 50px 0 50px 7%;
    flex-direction: row-reverse;
    max-width: 40%;
  `}
  ${({ $isAudi }) => !$isAudi && css`
  ${CarTitle}{
    display:none;
  }
  `}

  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    max-width:100%;
    padding: 30px 0;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
`;

export const MessageContainer = styled.div`
 padding:20px;
`;

export const LoaderContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h4`
  margin: 0 0 24px 0;
  font-weight: normal;
  font-size: ${pxToRem(33)};
  text-align: center;
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    font-size:25px;
  }
`;
export const TitleText = styled.span`
  font-weight: ${({ $isBold }) => ($isBold ? 700 : '400')};
  @media(max-width:${DEVICE_SIZE.MEDIUM}px){
    display:block;
  }
`;

export const ContinueThemeButtonSticky = styled.div`
  @media (max-width: ${DEVICE_SIZE.MEDIUM}px) {
    ${StickyConfigLeftFooterOnMobile};
  }
`;
