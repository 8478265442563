import STRINGS from '../../../../language';

export const UNIQUE_ONLINE_PRICE_SMS_CODE = 'uniqueOnlinePriceSmsCode';

export const INPUT_FIELDS =  [
    {
        name: 'smsCode',
        type: 'text',
        value: '',
        label: STRINGS.INSERT_THE_CODE_YOU_HAVE_RECEIVED,
        isRequired: true,
        isValid: false,
        errorMessage: null,
        isErrorShown: false,
    },
];
