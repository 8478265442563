import styled from 'styled-components';
import { getIconFromAssets } from '../../../../utils/assetsUtils';
import { ICONS_NAMES } from '../../../../constants';
import CONFIG from '../../../../config';

const arrowRightIcon = getIconFromAssets(ICONS_NAMES.THIN_ARROW_RIGHT);

const { DEVICE_SIZE } = CONFIG;

export const CheckoutPage = styled.div``;

export const Container = styled.div`
    padding-top: 45px;
    display: flex;
    max-width: 1200px;
    margin: auto;
    width: 95%;
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px){
        flex-direction: column;
    }
`;

export const Bar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    background-color: #F4F4F4;
    border-top: 1px solid #DFE4E8;
`;

export const GoBackButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    line-height: 19px;
    text-decoration: none;
    font-family: ${({ theme }) => theme['font-family']};
    font-size: 16px;
    color: ${({ theme }) => theme.primaryColor};
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    transition: all;
    &:before{
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(${arrowRightIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: 0.2s all ease-in-out;
    }
    &:hover {
        text-decoration: underline;
    }
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        display: none;
    }
`;

export const CheckoutProgressContainer = styled.div`
    flex: 1;
    margin-left: auto;
    @media(max-width:${DEVICE_SIZE.MEDIUM}px){
        margin-left: 0;
        padding-top: 25px;
    }
`;
