import React from 'react';
import PropTypes from 'prop-types';
import { useIsInCheckout } from '../../../../hooks';
import { TYPES } from '../../../../constants';
import STRINGS from '../../../../language';
import * as Styled from './PaymentDetailItem.styles';

const {
    COLOR_INT, COLOR_OUT, JANT, EQUIPMENT_PACKAGE, MEMIS, ORIGINAL_ADDITION,
} = TYPES;

const PaymentDetailItem = ({
    name, price, image, isDiscount, total, type, onRemove,
}) => {
    const totalPrice = parseFloat(total).toLocaleString();
    const isInCheckout = useIsInCheckout();
    const isEquipmentPackage = type === EQUIPMENT_PACKAGE;
    const renderPriceBeforeDiscount = () => {
        if (!isDiscount) {
            return null;
        }
        const priceBeforeDiscount = parseFloat(price).toLocaleString();
        
        return (
            <Styled.PriceBeforeDiscount>
                {priceBeforeDiscount}
            </Styled.PriceBeforeDiscount>
        );
    };

    const renderColorPrefix = () => {
        let colorPrefix;

        switch (type) {
            case COLOR_INT:
                colorPrefix = STRINGS.ORDER_DETAILS_INTERNAL_COLOR_TEXT;
                break;
            case COLOR_OUT:
                colorPrefix = STRINGS.ORDER_DETAILS_COLOR_TEXT;
                break;
            default:
                colorPrefix = null;
        }

        return colorPrefix;
    };

    const renderDeleteOption = () => {
        if (![COLOR_INT, COLOR_OUT, JANT, EQUIPMENT_PACKAGE, MEMIS, ORIGINAL_ADDITION]
            .includes(type) && !isInCheckout) {
            return (
                <Styled.DeleteOption onClick={ onRemove } />
            );
        }

        return null;
    };

    const renderPrice = () => {
        return !isEquipmentPackage ? (
            <>
                {renderPriceBeforeDiscount()}
                <Styled.Price>{totalPrice}</Styled.Price>
            </>
        ) : null;
    };
    
    return (
        <Styled.PaymentDetailItem>
            <Styled.Item>
                <Styled.NameAndImageContainer>
                    <Styled.Image $image={ image } />
                    <Styled.Name >
                        {renderColorPrefix()} {name}
                    </Styled.Name>
                    { renderDeleteOption() }
                </Styled.NameAndImageContainer>
                <Styled.PriceContainer>
                    { renderPrice() }
                </Styled.PriceContainer>
            </Styled.Item>
        </Styled.PaymentDetailItem>
    );
};

PaymentDetailItem.defaultProps = {
    isDiscount: false,
    type: null,
    onRemove: () => {},
};
PaymentDetailItem.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    isDiscount: PropTypes.bool,
    total: PropTypes.number.isRequired,
    type: PropTypes.string,
    onRemove: PropTypes.func,
};

export default PaymentDetailItem;
