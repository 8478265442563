import styled from 'styled-components';
import { getIconFromAssets } from '../../../utils/assetsUtils';
import { COLORS, ICONS_NAMES } from '../../../constants';
import STRINGS from '../../../language';
import CONFIG from '../../../config';

const { DEVICE_SIZE } = CONFIG;

const arrowRightIcon = getIconFromAssets(ICONS_NAMES.THIN_ARROW_RIGHT);

export const GoBackButton = styled.button`
  border: none;
  outline: none;
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${COLORS.BG_COLOR_DISABLED_BUTTON};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: absolute;
  top: 50px;
  right: 30px;
  z-index: 10;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${arrowRightIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    filter: opacity(0.5);
    transition: 0.2s all ease-in-out;
  }
  &:after{
    content: '${STRINGS.BACK}';
    font-size: 14px;
    position: absolute;
    margin-top: 55px;
    font-family: ${({ theme }) => theme['font-family']};
    color: ${COLORS.BG_COLOR_DISABLED_BUTTON};
    transition: 0.2s all ease-in-out;
  }
  &:hover:before{
    filter: opacity(1);
    transition: 0.2s all ease-in-out;
  }
  &:hover:after{
    color: ${COLORS.BLACK};
  }
  :hover{
    border-color: ${COLORS.BLACK};
  }
  @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
    &:after {
      content: '';
    }
  }
`;
