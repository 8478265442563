import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import PollutionModel from './PollutionModel/PollutionModel';
import PollutionRange from './PollutionRange/PollutionRange';
import * as Styled from './Pollution.styles';

const Pollution = ({
    cars,
    pollutionLevel,
    regulation,
    isBeforeProduction,
}) => {
    return (
        <Styled.Pollution>
            <Styled.Title>{STRINGS.AIR_POLLUTION}:</Styled.Title>
            <Styled.PollutionModelAndRange>
                <PollutionModel
                    cars={ cars }
                />
                <PollutionRange
                    regulation={ regulation }
                    pollutionLevel={ pollutionLevel }
                    isManyModels={ cars.length > 1 }
                />
            </Styled.PollutionModelAndRange>
            {isBeforeProduction && (
                <Styled.Text>
                    {STRINGS.POLLUTION_DISCLAIMER}
                </Styled.Text>
            )}
        </Styled.Pollution>
    );
};

Pollution.propTypes = {
    cars: PropTypes.array.isRequired,
    pollutionLevel: PropTypes.number.isRequired,
    regulation: PropTypes.string.isRequired,
    isBeforeProduction: PropTypes.bool.isRequired,
};

export default Pollution;
