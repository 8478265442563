import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TableHeadData.styles';

const TableHeadData = ({ data, bg, colspan }) => {
    return <Styled.TableHeadData $colspan={ colspan } $bg={ bg }>{data}</Styled.TableHeadData>;
};

TableHeadData.defaultProps = {
    bg: null,
    data: null,
    colspan: 1,
};
TableHeadData.propTypes = {
    data: PropTypes.string,
    bg: PropTypes.string,
    colspan: PropTypes.number,
};

export default TableHeadData;
