import React from 'react';
import PropTypes from 'prop-types';
import EquipmentScreen from '../EquipmentScreen/EquipmentScreen';
import * as Styled from './EquipmentData.styles';

const EquipmentData = ({ selectedTab, data }) => {
    const renderEquipmentScreen = () => {
        if (selectedTab?.headerDescription) {
            const screenData = data[selectedTab?.headerDescription];

            return <EquipmentScreen screenData={ screenData } />;
        }
        
        return null;
    };

    return (
        <Styled.EquipmentData>
            {renderEquipmentScreen()}
        </Styled.EquipmentData>
    );
};

EquipmentData.defaultProps = {
    selectedTab: null,
    data: {},
};
EquipmentData.propTypes = {
    selectedTab: PropTypes.object,
    data: PropTypes.object,
   
};

export default EquipmentData;
