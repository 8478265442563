import { mapOnlineCars } from './utils';

export const getCommonDataPending = (state) => ({
    ...state,
    isLoadingAppCommonData: true,
});

export const getCommonDataSuccess = ((state, action) => {
    const { data } = action;

    const onlineCars = data.map(mapOnlineCars).flat();
    
    return {
        ...state,
        onlineCars,
        isLoadingAppCommonData: false,
    };
});

export const getCommonDataFailure = ((state, action) => {
    const { error } = action;
    
    return {
        ...state,
        isLoadingAppCommonData: false,
        error,
    };
});

export const setCurrentBrand = ((state, action) => {
    const {
        brand,
        subBrand,
        isCupra,
        isAudi,
    } = action;

    return {
        ...state,
        currentBrand: brand,
        currentSubBrand: subBrand ?? '',
        isCupra,
        isAudi,
    };
});

export const setVideoForFaq = ((state, action) => {
    const { link } = action;

    return {
        ...state,
        videoForFaq: link,
    };
});

export const setManufacturingDates = ((state, action) => {
    const { ManufacturingDates } = action;

    return {
        ...state,
        ManufacturingDates,
    };
});
