import React, { useState, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import STRINGS from '../../../language';
import CheckBoxFormGroup from '../../CheckBoxFormGroup/CheckBoxFormGroup';
import Message from '../../ErrorMessage/ErrorMessage';
import SubmitButton from '../../SubmitButton/SubmitButton';
import Image from '../../Image';
import {
    SPEAK_WITH_REPRESENTATIVE,
    CHECKBOX_FIELDS,
} from '../../Steps/Summary/ModalForms/ModalForm/ModalForm.fields';
import {
    AUDI_INPUT_FIELDS,
} from '../Steps/Summary/ContactRepresentativeModal/AudiModalForm.fields';
import {
    sendLeadWithOrderDetails,
} from '../../../fetch/leads';
import { useCarPlaceHolderImageUrl } from '../../../utils/assets.hooks';
import { useCurrentAppCommonData, useSelectedCurrentData } from '../../../hooks';
import { parseInputFieldsToState } from '../../../utils/formUtils';
import {
    isArrayEmpty,
    replacePlaceholdersInTemplate,
    getManufacturerContactInfo,
} from '../../../utils/commonUtils';
import {
    parseSelectedAddOnsGroupDescriptionToString, parseSelectedAddOnsToString,
} from '../../Steps/Summary/Summary.utils';
import { getMutagLogo } from '../../../utils/assetsUtils';
import CustomTextInput from '../../CustomTextInput';
import PhoneInput from '../Steps/Summary/ContactRepresentativeModal/PhoneIntput/PhoneInput';
import Loader from '../../Loader';
import { LoaderContainer } from '../../Modal/Modal.styles';
import * as Styled from './ContactRepresentativeModal.styles';

const ContactRepresentativeModal = ({
    isVisible,
    onClose,
}) => {
    const {
        selectedCar,
        selectedAddOns,
        selectedHoop,
        selectedExternalColor,
        selectedInternalColor,
        selectedEquipmentPackage,
    } = useSelectedCurrentData();
    const {
        modelGroup,
        trimLevel,
    } = selectedCar;
    const { currentBrand, isAudi } = useCurrentAppCommonData();
    const selectedEquipmentPackageString = selectedEquipmentPackage?.id ?? '';
    const selectedAddOnsDescriptionString = parseSelectedAddOnsGroupDescriptionToString(selectedAddOns);
    const fallbackSrc = useCarPlaceHolderImageUrl(currentBrand);
    const inputFields = AUDI_INPUT_FIELDS.filter((input) => input.name !== 'fullName');
    const initInputFieldsState = parseInputFieldsToState(inputFields);
    const [inputFieldsState, setInputFieldsState] = useState(initInputFieldsState);
    const initcheckBoxInputsState = parseInputFieldsToState(CHECKBOX_FIELDS);
    const [checkBoxInputs, setCheckBoxInputs] = useState(initcheckBoxInputsState);
    const [isSendingCustomerInfo, setIsSendingCustomerInfo] = useState(false);
    const { PHONE: contactPhoneNumber } = getManufacturerContactInfo(currentBrand);
    const summaryScreenErrMsg = replacePlaceholdersInTemplate(
        STRINGS.ERRMSG_PROBLEM_IN_PURCHASE,
        [contactPhoneNumber],
    );
    const [isContactSuccessfull, setIsContactSuccessfull] = useState(false);
    const [isScreenErrMsg, setIsScreenErrMsg] = useState(false);
    const backdrop = useRef(null);
    const clickHandler = (e) => e.target === backdrop.current && onClose();

    const getCarFinalChoiceInfoString = () => {
        const { description: outColor } = selectedExternalColor;
        const { description: inColor } = selectedInternalColor;
        const { name: selectedHoopsString } = selectedHoop;
        const modelAndTrimLevel = `${STRINGS.MODEL_AND_TRIM_LEVEL}:${modelGroup}-${trimLevel}`;
        const equipmentPackage = `${STRINGS.EQUIPMENT_PACKAGES}:${selectedEquipmentPackageString}`;
        const out = `${STRINGS.OUT}:${outColor}`;
        const internal = `${STRINGS.INTERNAL}:${inColor}`;
        const hoop = `${STRINGS.HOOP}:${selectedHoopsString}`;
        const localAdditions = `${STRINGS.LOCAL_ADDITIONS}:${selectedAddOnsDescriptionString}`;

        return `${modelAndTrimLevel} ${equipmentPackage} ${out} ${internal} ${hoop} ${localAdditions}`;
    };
    
    const getAvailableChoicesInfo = () => {
        const { description: colorDescription } = selectedExternalColor;
        const { description: upholsteryDescription } = selectedInternalColor;
        const { modelDescription, equipmentPackageDescription } = selectedCar;
        const { name: rimDescription } = selectedHoop;
        const parsedSelectedAddOns = parseSelectedAddOnsToString(selectedAddOns);

        const upgradeDescriptions = [];

        upgradeDescriptions.push(parsedSelectedAddOns);

        const orderDescriptions = {
            modelDescription,
            colorDescription,
            upholsteryDescription,
            rimDescription,
            upgradeDescriptions,
            equipmentPackageDescription,
        };

        return orderDescriptions;
    };

    const onChangeInputHandler = useCallback((event, audiPhoneNumber, type) => {
        if (type === 'audiTel') {
            setInputFieldsState((prevState) => {
                const currentInput = prevState.phone;
                const isValid = currentInput.validator?.(audiPhoneNumber) ?? true;
                const formattedValue = currentInput.formatter?.(audiPhoneNumber) ?? audiPhoneNumber;
                const encodedValue = currentInput.encoder?.(audiPhoneNumber) ?? audiPhoneNumber;
    
                return {
                    ...prevState,
                    phone: {
                        ...currentInput,
                        value: formattedValue,
                        isValid,
                        isErrorShown: false,
                        encodedValue,
                    },
                   
                };
            });

            return;
        }

        const $this = event.target;
        const { name: inputName, value } = $this;

        setInputFieldsState((prevState) => {
            const currentInput = prevState[inputName];
            const isValid = currentInput.validator?.(value) ?? true;
            const formattedValue = currentInput.formatter?.(value) ?? value;
            const encodedValue = currentInput.encoder?.(value) ?? value;

            return {
                ...prevState,
                [inputName]: {
                    ...currentInput,
                    value: formattedValue,
                    isValid,
                    isErrorShown: false,
                    encodedValue,
                },
               
            };
        });
    }, []);
    
    const onCheckHandler = (event) => {
        const $this = event.target;
        const { id } = $this;

        setCheckBoxInputs((prevState) => {
            const currentCheckBoxInput = prevState[id];
            const { isChecked } = currentCheckBoxInput;

            return {
                ...prevState,
                [id]: {
                    ...currentCheckBoxInput,
                    isChecked: !isChecked,
                    isValid: !isChecked,
                    isErrorShown: false,
                },
            };
        });
    };

    const setErrorOnInput = (inputName, inputType) => {
        // If inputType is not checkbox, then it is regular input(text,tel or email)
        const setStateMethod = inputType === 'checkbox' ? setCheckBoxInputs : setInputFieldsState;
    
        setStateMethod((prevState) => ({
            ...prevState,
            [inputName]: {
                ...prevState[inputName],
                isErrorShown: true,
            },
        }));
    };

    const getUnvalidInputFields = (inputFieldsAndCheckBoxes) => {
        return inputFieldsAndCheckBoxes.filter((inputField) => {
            const { isRequired, isValid } = inputField;

            if (isRequired && !isValid) {
                return true;
            }

            return false;
        });
    };

    const speakWithRepresenative = async (
        firstName,
        lastName,
        phone,
        email,
        isAdvertisingAccept,
    ) => {
        setIsSendingCustomerInfo(true);
        const result = await sendLeadWithOrderDetails({
            firstName,
            lastName,
            phone,
            email,
            orderDescriptions: getAvailableChoicesInfo(),
            hasRequestedMarketing: isAdvertisingAccept,
        });

        setIsSendingCustomerInfo(false);
        if (result.isSuccess) {
            setIsContactSuccessfull(true);
        } else {
            setIsScreenErrMsg(true);
        }

        setInputFieldsState(initInputFieldsState);
        setCheckBoxInputs(initcheckBoxInputsState);
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();
        const formInputs = Object.values(inputFieldsState);
        const formCheckBoxInputs = Object.values(checkBoxInputs);
        const allFormInputsCombined = formInputs.concat(formCheckBoxInputs);
        const unValidFields = getUnvalidInputFields(allFormInputsCombined);

        if (!isArrayEmpty(unValidFields)) {
            unValidFields.forEach((unValidField) => {
                const { type: inputType, name: inputName } = unValidField;

                setErrorOnInput(inputName, inputType);
            });
        } else {
            const [firstName, lastName, phone, email, , acceptAdvertising] = allFormInputsCombined;

            speakWithRepresenative(
                firstName.value,
                lastName.value,
                phone.value,
                email.value,
                acceptAdvertising.isChecked);
        }
    };

    const renderInputFormGroups = () => {
        return inputFields.map((inputField, index) => {
            const {
                name,
                label,
                errorMessage,
                type,
                isRequired,
                maxlength,
            } = inputField;

            if (type === 'tel') {
                return (
                    <PhoneInput
                        key={ name }
                        maxlength={ maxlength }
                        inputName={ name }
                        label={ label }
                        errorMessage={ errorMessage }
                        inputType={ type }
                        isRequired={ isRequired }
                        inputValue={ inputFieldsState[name].value }
                        isErrorShown={ inputFieldsState[name].isErrorShown }
                        onChange={ onChangeInputHandler }
                    />
                );
            }

            return (
                <CustomTextInput
                    key={ name }
                    maxlength={ maxlength }
                    inputName={ name }
                    label={ label }
                    errorMessage={ errorMessage }
                    inputType={ type }
                    isRequired={ isRequired }
                    inputValue={ inputFieldsState[name].value }
                    isErrorShown={ inputFieldsState[name].isErrorShown }
                    onChange={ onChangeInputHandler }
                    isAutoFocused={ index === 0 }
                />
            );
        });
    };

    const renderCheckBoxFormGroups = () => {
        return CHECKBOX_FIELDS.map((checkBoxField) => {
            const {
                id, label, errorMessage, isRequired,
            } = checkBoxField;

            return (
                <CheckBoxFormGroup
                    onCheck={ onCheckHandler }
                    id={ id }
                    key={ id }
                    errorMessage={ errorMessage }
                    isErrorShown={ checkBoxInputs[id].isErrorShown }
                    isRequired={ isRequired }
                    label={ label }
                    isChecked={ checkBoxInputs[id].isChecked }
                />
            );
        });
    };

    const renderTitle = () => {
        if (isAudi) {
            return (
                <Styled.SmallTitle>
                    { STRINGS.LEAVE_DETAILS_FOR_AUDI_REPRESENTATIVE }
                </Styled.SmallTitle>
            );
        }
        
        return (
            <Styled.Title>{ STRINGS.SPEAK_WITH_REPRESENTATIVE }</Styled.Title>
        );
    };

    const renderContentOrLoader = () => {
        if (isSendingCustomerInfo) {
            return (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            );
        }

        if (isContactSuccessfull) {
            return (
                <Styled.SuccessContainer>
                    { STRINGS.AUDI_THANK_YOU_WE_WILL_CONTACT_YOU_SOON }
                </Styled.SuccessContainer>
            );
        }

        return (
            <Styled.ModalFormContent>
                <Styled.LogoContainer>
                    <Image
                        src={ getMutagLogo(currentBrand) }
                        fallbackSrc={ fallbackSrc }
                    />
                </Styled.LogoContainer>
                <Styled.FormContainer>
                    { renderTitle() }
                    <Styled.InputFieldsContainer>
                        <Styled.Form name={ SPEAK_WITH_REPRESENTATIVE } action="/">
                            { renderInputFormGroups() }
                            <Styled.Terms>{renderCheckBoxFormGroups()}</Styled.Terms>
                            <SubmitButton onSubmit={ onSubmitHandler } title={ STRINGS.CONTACT_ME_BY_PHONE } />

                            { (isScreenErrMsg || isContactSuccessfull) && (
                                <Styled.MessageContainer>
                                    {isScreenErrMsg && (
                                        <Message
                                            color="red"
                                            errorMessage={ summaryScreenErrMsg }
                                        />
                                    )}
                                </Styled.MessageContainer>
                            )}

                        </Styled.Form>
                    </Styled.InputFieldsContainer>
                </Styled.FormContainer>
            </Styled.ModalFormContent>
        );
    };

    const modal = (
        <CSSTransition in={ isVisible } timeout={ 500 } classNames="show-up" unmountOnExit>
            <Styled.Container ref={ backdrop } onClick={ clickHandler }>
                <Styled.InnerContainer className="modal-content">
                    <Styled.CloseButton onClick={ onClose } />
                    { renderContentOrLoader() }
                </Styled.InnerContainer>
            </Styled.Container>
        </CSSTransition>
    );

    return ReactDOM.createPortal(modal, document.body);
};

ContactRepresentativeModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContactRepresentativeModal;
