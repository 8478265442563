import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGoToRoute } from '../../../../hooks/utils';
import STRINGS from '../../../../language';
import {
    SPEAK_WITH_REPRESENTATIVE,
    REVEAL_UNIQUE_ONLINE_PRICE,
} from '../ModalForms/ModalForm/ModalForm.fields';
import {
    useCurrentAppCommonData,
} from '../../../../hooks';
import { ROUTES } from '../../../../constants';
import CheckBoxFormGroup from '../../../CheckBoxFormGroup/CheckBoxFormGroup';
import * as Styled from './OpenModalFormsButtons.styles';

const OpenModalFormsButtons = ({ onOpen, continueToPayment, isDisabled }) => {
    const goToRoute = useGoToRoute();
    const { isAudi, isCupra } = useCurrentAppCommonData();
    const [isAcceptOrderTerms, setIsAcceptOrderTerms] = useState();
    const [acceptOrderTermsError, setAcceptOrderTermsError] = useState(null);

    const continueToPaymentHandler = () => {
        if (isAcceptOrderTerms) {
            goToRoute(ROUTES.CHECKOUT);
            setAcceptOrderTermsError(null);
        } else {
            setAcceptOrderTermsError(STRINGS.MUST_FILL_YOU_READ_ORDER_AGREEMENT);
        }
    };

    const renderDiscountBtn = () => {
        return (
            <Styled.RevealUniqueOnlinePriceButton
                disabled={ isDisabled }
                onClick={ () => onOpen(REVEAL_UNIQUE_ONLINE_PRICE)  }
                $isAudi={ isAudi }
                $isCupra={ isCupra }
            >
                {STRINGS.REVEAL_UNIQUE_ONLINE_PRICE}
                { !isAudi && <Styled.LeftArrowIcon /> }
            </Styled.RevealUniqueOnlinePriceButton>
        );
    };

    const renderContinueBtn = () => {
        return (
            <Styled.ContinueToPaymentButton
                disabled={ isDisabled }
                onClick={ continueToPaymentHandler }
                $isAudi={ isAudi }
                $isCupra={ isCupra }
            >
                {isAudi ? STRINGS.CONTINUE_TO_PAYMENT_AND_DOWN_PAYMENT
                    : STRINGS.CONTINUE_TO_PAYMENT}
            </Styled.ContinueToPaymentButton>
        );
    };

    const orderAgreementLabel = (
        <Styled.CheckboxLabel>
            {`${STRINGS.I_READ_AND_ACCEPT} `}
            <Styled.CheckboxLink href={ ROUTES.ORDER_AND_CANCEL_TERMS } >
                {STRINGS.ORDER_AGREEMENT}
            </Styled.CheckboxLink>
        </Styled.CheckboxLabel>
    );

    const continueOrDiscountBtn = continueToPayment ? renderContinueBtn : renderDiscountBtn;

    return (
        <Styled.Container $isAudi={ isAudi }>
            { continueToPayment && (
                <CheckBoxFormGroup
                    isChecked={ isAcceptOrderTerms }
                    onCheck={ () => {
                        setAcceptOrderTermsError(null);
                        setIsAcceptOrderTerms(!isAcceptOrderTerms);
                    } }
                    errorMessage={ acceptOrderTermsError }
                    isErrorShown={ acceptOrderTermsError }
                    label={ orderAgreementLabel }
                />
            )}
            <Styled.OpenModalFormsButtons $isAudi={ isAudi }>
                <Styled.SpeakWithRepresentativeButton
                    onClick={ () => onOpen(SPEAK_WITH_REPRESENTATIVE) }
                    $isAudi={ isAudi }
                >
                    {isAudi ? STRINGS.SPEAK_WITH_REPRESENTATIVE
                        : STRINGS.SPEAK_WITH_SALES_REPRESENTATIVE}
                </Styled.SpeakWithRepresentativeButton>
                {continueOrDiscountBtn()}
            </Styled.OpenModalFormsButtons>
        </Styled.Container>
    );
};

OpenModalFormsButtons.defaultProps = {
    onOpen: () => {},
    continueToPayment: false,
    isDisabled: false,
};

OpenModalFormsButtons.propTypes = {
    onOpen: PropTypes.func,
    continueToPayment: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default OpenModalFormsButtons;
