import styled from 'styled-components';

export const Main = styled.main`
  position:relative;
`;
export const CustomLoader = styled.div`
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
`;
