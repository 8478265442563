import React from 'react';
import PropTypes from 'prop-types';
import {
    useCurrentAppCommonData,
    useUniqueCars,
} from '../../hooks';
import {
    getModelFurtherInfoLink,
    isArrayEmpty,
} from '../../utils/commonUtils';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import STRINGS from '../../language';
import {
    AudiOnlineCarItem,
} from '../AudiConfigurator/Steps/Model/OnlineCarMenu/OnlineCarItem/OnlineCarItem';
import OnlineCarItem from './OnlineCarItem/OnlineCarItem';
import * as Styled from './OnlineCarsMenu.styles';

const OnlineCarsMenu = ({ isVisible, onCloseNav }) => {
    const appCommonData = useCurrentAppCommonData();
    const { onlineCars, currentBrand, isAudi } = appCommonData;
    const carItemsByModelGroup = useUniqueCars('modelGroup');

    const renderOnlineCars = (cars) => {
        if (!cars || isArrayEmpty(cars)) {
            return <ErrorMessage errorMessage={ STRINGS.NO_CARS_TO_DISPLAY } />;
        }

        const ComponentToRender = isAudi ? AudiOnlineCarItem : OnlineCarItem;

        return cars.map((car) => {
            const {
                id,
                modelGroup,
                hebrewModelGroup,
                modelGroupCode,
                trimLevelCode,
                modelCode,
            } = car;

            const isManyModels = onlineCars.filter(
                (model) => modelGroup === model.modelGroup).length > 1;
            
            return (
                <ComponentToRender
                    brand={ currentBrand }
                    modelGroupCode={ modelGroupCode }
                    onCloseNav={ onCloseNav }
                    hebrewModelGroup={ hebrewModelGroup }
                    furtherInfoHref={ getModelFurtherInfoLink(currentBrand, modelGroupCode) }
                    key={ id }
                    href={ isManyModels ? car.hrefToModels : car.hrefToConfigurator }
                    trimLevelCod={ trimLevelCode }
                    modelCode={ modelCode }
                />
            );
        });
    };

    return (
        <Styled.OnlineCarsMenu $isVisible={ isVisible } $isAudi={ isAudi }>
            {renderOnlineCars(carItemsByModelGroup)}
        </Styled.OnlineCarsMenu>
    );
};

OnlineCarsMenu.defaultProps = {
    isVisible: false,
    onCloseNav: () => {},
};

OnlineCarsMenu.propTypes = {
    isVisible: PropTypes.bool,
    onCloseNav: PropTypes.func,
};
export default OnlineCarsMenu;
