import React from 'react';
import PropTypes from 'prop-types';
import useIsMobile from '../../hooks/useWindowSize';
import CONFIG from '../../config';
import * as Styled from './ScrollMoreOverlay.styles';

const { DEVICE_SIZE } = CONFIG;

const ScrollMoreOverlay = ({ isVisible, text }) => {
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);

    if (!isMobile) {
        return null;
    }

    return (
        <Styled.Container $isVisible={ isVisible }>
            <Styled.Text>{ text }</Styled.Text>
            <Styled.ArrowsContainer>
                <Styled.Arrow />
                <Styled.Arrow />
                <Styled.Arrow />
            </Styled.ArrowsContainer>
        </Styled.Container>
    );
};

ScrollMoreOverlay.defaultProps = {
    text: '',
};

ScrollMoreOverlay.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    text: PropTypes.string,
};

export default ScrollMoreOverlay;
