import styled from 'styled-components';
import { COLORS } from '../../constants';
import CONFIG from '../../config';

const { DEVICE_SIZE } = CONFIG;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 260px;
    width: 100%;
    height: 125px;
    @media(max-width: ${DEVICE_SIZE.MEDIUM}px) {
      max-width: initial;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end ;
    height: 60px;
`;

const Label = styled.label`
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    top: 28px;
    right: 0;
    padding-right: 10px;
    transition: 0.2s ease all;
    color: ${({ readOnly }) => readOnly && 'gray'}
  `;

const Input = styled.input`
    font-size: 18px;
    padding: 0 10px;
    width: 100%;
    height: 40px;
    color: ${({ readOnly }) => readOnly && 'gray'};
    border: 1px solid ${({ hasError }) => hasError
        ? `${COLORS.COLOR_ERROR_LABEL_INPUT_FIELD}`
        : `${COLORS.BLACK}`
};
    direction: rtl;
    &:focus {
      outline: 0;
    }
    &:focus + ${Label},
    :not(:placeholder-shown) + ${Label} {
      top: 0;
      right: -10px;
      font-size: 18px;
      color: ${COLORS.INPUT_PLACEHOLDER_COLOR};
    }
  `;

const ErrorMessageStyle = styled.p`
    font-size: 16px;
    direction: rtl;
    color: ${COLORS.COLOR_ERROR_LABEL_INPUT_FIELD};
    padding: 0;
    margin: 10px 0;
  `;

const Styled = {
    Container,
    InputContainer,
    Label,
    ErrorMessageStyle,
    Input,
};

export default Styled;
