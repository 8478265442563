/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { redirect, useNavigate } from 'react-router';
import {
    useActionDispatch,
    useCheckoutIsFinished,
    useCurrentCar,
    useCurrentIsInSummary,
} from '../../../../hooks';
import { ROUTES } from '../../../../constants';
import STRINGS from '../../../../language';
import HelmetTitleDescription from '../../../HelmetTitleDescription/HelmetTitleDescription';
import ProgressScreenSteps  from './ProgressScreenSteps/ProgressScreenSteps';
import CheckoutSideBar from './CheckoutSideBar/CheckoutSideBar';
import * as Styled from './Checkout.styles';

const Checkout = () => {
    const currentCar = useCurrentCar();
    const { isSelected: isCarSelected } = currentCar;
    const isInSummary = useCurrentIsInSummary();
    const isCheckoutFinished = useCheckoutIsFinished();
    const { setIsInCheckout } = useActionDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.body.dataset.pageName = 'checkout';
        setIsInCheckout(true);
        
        return () => {
            document.body.dataset.pageName = '';
            setIsInCheckout(false);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isCheckoutFinished]);
    
    /**
     * in case we go to checkout without going through summary,
     * go back to configurator
     */
    if (!isInSummary) {
        // return <Redirect to={ currentCar.hrefToConfigurator ?? ROUTES.HOME } />;
        return redirect(currentCar.hrefToConfigurator ?? ROUTES.HOME);
    }

    if (!isCarSelected) {
        return redirect(ROUTES.HOME);
    }
    
    return (
        <Styled.CheckoutPage>
            <HelmetTitleDescription canonical={ window.location.href } />
            <Styled.Bar>
                <Styled.GoBackButton onClick={ () => navigate.goBack() }>
                    { STRINGS.BACK }
                </Styled.GoBackButton>
            </Styled.Bar>
            <Styled.Container>
                <Styled.CheckoutProgressContainer>
                    <ProgressScreenSteps />
                </Styled.CheckoutProgressContainer>
                <CheckoutSideBar />
            </Styled.Container>
        </Styled.CheckoutPage>
    );
};

export default Checkout;
