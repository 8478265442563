import React from 'react';
import { useCurrentBrand, useSelectedCurrentData } from '../../hooks';
import Car360 from '../Configurator/Car360/Car360';
import { useOutCarImageUrl } from '../../utils/assets.hooks';

const Car360Presentation = () => {
    const { selectedCar, selectedExternalColor } = useSelectedCurrentData();
    const currentBrand = useCurrentBrand();
    const { link360, value: selectedColorValue } = selectedExternalColor;
    const { modelGroupCode, trimLevelCode } = selectedCar;

    const carImageUrl = useOutCarImageUrl(
        currentBrand,
        modelGroupCode,
        trimLevelCode,
        selectedColorValue);

    return (
        <Car360 src={ link360 } fallbackSrc={ carImageUrl } />
    );
};

export default Car360Presentation;
