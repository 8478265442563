/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import useIsMobile from '../../../../../../hooks/useWindowSize';
import CONFIG from '../../../../../../config';
import Styled from './ColorsBarTooltip.styles';

const { DEVICE_SIZE } = CONFIG;

const ColorsBarTooltip = ({
    id,
    colors,
}) => {
    const isMobile = useIsMobile(DEVICE_SIZE.MEDIUM);
    const renderTooltipContent = () => {
        return colors.map((colorImage, index) => {
            return <Styled.Color key={ index } $icon={ colorImage } />;
        });
    };

    return (
        <Styled.ToolTip
            aria-haspopup="true"
            className="extraClass"
            variant="light"
            globalCloseEvents={ {
                scroll: true,
                clickOutsideAnchor: true,
                escape: true,
                resize: true,
            } }
            isCapture={ isMobile }
            id={ id }
        >
            <Styled.Colors>
                { renderTooltipContent() }
            </Styled.Colors>
        </Styled.ToolTip>
    );
};

ColorsBarTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    colors: PropTypes.arrayOf(String).isRequired,
};

export default ColorsBarTooltip;
