import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
    useCurrentCar, useCurrentIsInSummary, useCheckoutIsFinished, useActionDispatch,
} from '../../hooks';
import HelmetTitleDescription from '../../components/HelmetTitleDescription/HelmetTitleDescription';
import ContactRepresentativeModal from '../../components/AudiConfigurator/ContactRepresentativeModal/ContactRepresentativeModal';
import { ROUTES } from '../../constants';
import CheckoutSiderBar from '../Checkout/CheckoutSiderBar/CheckoutSiderBar';
import CheckoutProgress from '../Checkout/CheckoutProgress/CheckoutProgress';
import HEBREW from '../../language/hebrew';
import STRINGS from '../../language';
import * as Styled from './AudiCheckout.style';

const { SPEAK_WITH_REPRESENTATIVE } = STRINGS;

const AudiCheckout = () => {
    const currentCar = useCurrentCar();
    const { isSelected: isCarSelected } = currentCar;
    const isInSummary = useCurrentIsInSummary();
    const isCheckoutFinished = useCheckoutIsFinished();
    const { setIsInCheckout, initializeOtp } = useActionDispatch();
    const [
        isSpeakWithRepresentativeModalOpen,
        setIsSpeakWithRepresentativeModalOpen,
    ] = useState(false);

    const openSpeakWithRepresentativeModal = () => {
        setIsSpeakWithRepresentativeModalOpen(true);
    };

    const closeSpeakWithRepresentativeModal = () => {
        setIsSpeakWithRepresentativeModalOpen(false);
    };

    useEffect(() => {
        document.body.dataset.pageName = 'audi-checkout';
        setIsInCheckout(true);
        
        return () => {
            document.body.dataset.pageName = '';
            setIsInCheckout(false);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isCheckoutFinished]);
    
    /**
     * in case we go to checkout without going through summary,
     * go back to configurator
     */
    if (!isInSummary) {
        return <Navigate to={ currentCar.hrefToConfigurator ?? ROUTES.HOME } />;
    }

    if (!isCarSelected) {
        return <Navigate to={ ROUTES.HOME } />;
    }
    
    return (
        <Styled.CheckoutPage>
            <HelmetTitleDescription canonical={ window.location.href } />
            <Styled.Header $isVisible={ !isCheckoutFinished }>
                <Styled.HeaderContent $isVisible={ !isCheckoutFinished }>
                    <Styled.HeaderText>{HEBREW.AUDI_LAST_DETAILS}</Styled.HeaderText>
                    <Styled.CallToRepresentative
                        onClick={ openSpeakWithRepresentativeModal }
                    >
                        { SPEAK_WITH_REPRESENTATIVE }
                    </Styled.CallToRepresentative>
                </Styled.HeaderContent>
            </Styled.Header>
            <Styled.Container>
                <CheckoutProgress />
                <CheckoutSiderBar />
            </Styled.Container>
            <ContactRepresentativeModal
                isVisible={ isSpeakWithRepresentativeModalOpen }
                onClose={ closeSpeakWithRepresentativeModal }
            />
        </Styled.CheckoutPage>
    );
};

export default AudiCheckout;
