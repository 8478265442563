import { AppError } from '../constants/errors';
import STRING from '../language';
import { isArrayAndNotEmpty } from '../utils/commonUtils';
import { defaultParams } from './defaultParams';

export const parseResponse = ({ oExeption, oResult }) => {
    if (!oExeption || !oResult) {
        throw new AppError(STRING.SERVER_ERROR);
    }

    if (oExeption.ErrorCode !== 0 || oExeption.Message) {
        const message = oExeption?.Message ?? STRING.SERVER_ERROR;

        throw new AppError(message);
    }

    return JSON.parse(oResult);
};

/**
 *
 * @param {string} url
 */
export const fetchServer = async (url) => {
    const response = await fetch(url, defaultParams);

    const isOk = response.ok && response.status === 200;

    const responseJson = await response.json();

    if (!isOk) {
        // return response as error
        throw responseJson;
    } else {
        // return responseJson || {};
        return parseResponse(responseJson);
    }
};

/**
 *
 * @param {string} url
 * @param {{[x: string]: any}} body
 */
export const postServer = async (url, body) => {
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
    });

    const isOk = response.ok && response.status === 200;

    let responseJson = null;
    
    try {
        responseJson = await response.json();
    } catch (error) {
        console.warn(response.url, 'parse response json failed');
    }

    if (!isOk) {
        throw responseJson;
    } else {
        if (!responseJson) {
            // empty json
            return {};
        }

        return parseResponse(responseJson);
    }
};

export const throwErrorIfNoData = (data) => {
    if (!isArrayAndNotEmpty(data)) {
        throw new AppError(STRING.NO_DATA_TO_DISPLAY);
    }
    
    return true;
};

export const parseMockResponseToCTResult = (response) => {
    const res =  {
        oExeption: {
            ErrorCode: 0,
            ExceptionTypeName: '',
            Message: '',
            StackTrace: '',
            Type: 6,
        },
        oResult: JSON.stringify(response),
        sSessionID: '',
    };
    
    return res;
};
