import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../language';
import { getComparisonLinkByBrandOrModelGroupCode } from '../../../../utils/commonUtils';
import { MENUFACTURS } from '../../../../constants';
import * as Styled from './Comparison.styles';

const Comparison = ({ brand, modelGroupDescription, modelGroupCode }) => {
    const isVwc = brand === MENUFACTURS.VOLKSWAGEN_COMMERCIAL;
    const disclaimerItems = [
        {
            id: 1,
            text: isVwc ? STRINGS.CONFIGURATOR_DISCLAIMER_VWC : STRINGS.CONFIGURATOR_DISCLAIMER,
        },
    ];
    const comparisonLinkText = `
     ${STRINGS.FOR_COMPARISON_BETWEEN_TRIM_LEVELS} ${modelGroupDescription} >>
    `;
    const linkToComparison = getComparisonLinkByBrandOrModelGroupCode(brand, modelGroupCode);

    const renderLinkToComparison = () => {
        if (linkToComparison) {
            return (
                <Styled.LinkToComparison target="_blank" to={ linkToComparison }>
                    {comparisonLinkText}
                </Styled.LinkToComparison>
            );
        }
        
        return null;
    };

    const _disclaimerItems =  disclaimerItems.map((item) => {
        const { id, text } = item;
        
        return <Styled.Item key={ id }>{text}</Styled.Item>;
    });
    
    return (
        <Styled.Comparison>
            {renderLinkToComparison()}
            <Styled.List>{_disclaimerItems}</Styled.List>
        </Styled.Comparison>
    );
};

Comparison.propTypes = {
    modelGroupDescription: PropTypes.string.isRequired,
    modelGroupCode: PropTypes.number.isRequired,
    brand: PropTypes.string.isRequired,
};

export default Comparison;
