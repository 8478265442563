import React from 'react';
import CONFIG from '../../../config';
import { useCurrentAppCommonData, useCurrentBrand } from '../../../hooks';
import * as Styled from './SocialLinks.styles';

const { SOCIAL_LINKS } = CONFIG;

const SocialLinks = () => {
    const currentBrand = useCurrentBrand();
    const { isCupra } = useCurrentAppCommonData();
    const _socialLinks = SOCIAL_LINKS(currentBrand);

    const renderSocialLink = (socialLinks) => {
        return socialLinks
            .filter((socialLink) => socialLink.link)
            .map((socialLink) => {
                const { icon, link } = socialLink;

                return (
                    <Styled.SocialLinkItem $isCupra={ isCupra } key={ link }>
                        <Styled.SocialLink
                            target="_blank"
                            $icon={ icon }
                            to={ link }
                        />
                    </Styled.SocialLinkItem>
                );
            });
    };

    return (
        <Styled.SocialLinks>{renderSocialLink(_socialLinks)}</Styled.SocialLinks>
    );
};

export default SocialLinks;
