import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CAR_TYPE, POLLUTION } from '../../../../../constants';
import STRINGS from '../../../../../language';
import TableData from '../../../../../components/TableData/TableData';
import TableHeadData from '../../../../../components/TableHeadData/TableHeadData';
import {
    getBgColorForRuler,
    renderUniqueSafetyAndPollutionTableRows,
} from '../../../../../utils/commonUtils';
import * as Styled from './PollutionModel.styles';

const PollutionModel = ({
    cars,
}) => {
    const defaultCrsTypeState = {
        [CAR_TYPE.GASOLINE]: null,
        [CAR_TYPE.ELECTRIC]: null,
        [CAR_TYPE.HYBRID]: null,
    };
    const [carsType, setCarsType] = useState(defaultCrsTypeState);
    const showTableData = cars.length !== 1;
    const displayedModelsArray = [];
    const engineTypesArr = cars.map((car) => car.engineType);
    const uniqueEngineTypes = Array.from(new Set(engineTypesArr));

    const TableHead = {
        [CAR_TYPE.HYBRID]: (
            <TableHeadData
                colspan={ showTableData ? 5 : 3 }
                data={ STRINGS.ELECTRIC_AND_FUEL_CONSUMPTION_DATA }
            />
        ),
        [CAR_TYPE.ELECTRIC]: (
            <TableHeadData
                colspan={ showTableData ? 4 : 2 }
                data={ STRINGS.ELECTRIC_CONSUMPTION_DATA }
            />
        ),
        [CAR_TYPE.GASOLINE]: null,
    };

    const TableSubHeader = {
        [CAR_TYPE.HYBRID]: (
            <Styled.ModelTr>
                { showTableData && <TableHeadData data={ STRINGS.MODEL } /> }
                <TableHeadData data={ STRINGS.FUEL_CONSUMPTION_COMBINED_PER_LITER } />
                <TableHeadData data={ STRINGS.ELECTRIC_CONSUMPTION_PER_KM } />
                <TableHeadData data={ STRINGS.ELECTRIC_RANGE } />
                { showTableData && <TableHeadData data={ STRINGS.POLLUTION_LEVEL } /> }
            </Styled.ModelTr>
        ),
        [CAR_TYPE.ELECTRIC]: (
            <Styled.ModelTr>
                { showTableData && <TableHeadData data={ STRINGS.MODEL } /> }
                <TableHeadData data={ STRINGS.ELECTRIC_CONSUMPTION_PER_KM } />
                <TableHeadData data={ STRINGS.ELECTRIC_RANGE } />
                { showTableData && <TableHeadData data={ STRINGS.POLLUTION_LEVEL } /> }
            </Styled.ModelTr>
        ),
        [CAR_TYPE.GASOLINE]: (
            <Styled.ModelTr>
                { showTableData && <TableHeadData data={ STRINGS.MODEL } /> }
                <TableHeadData
                    colspan={ 2 }
                    data={ STRINGS.AVERAGE_FUEL_CONSUMPTION_PER_LITER }
                />
                { showTableData && <TableHeadData data={ STRINGS.POLLUTION_LEVEL } /> }
            </Styled.ModelTr>
        ),
    };

    const renderPollutionDataRow = (_cars = cars) => {
        return _cars.map((car) => {
            const {
                pollutionLevel,
                modelDescription,
                avergaeFuelConsumption,
                engineType,
                electricConsumption,
                range,
                modelCode,
            } = car;
        
            const renderTableBody = (_pollutionLevel) => {
                const pollutionLevelBgColor = getBgColorForRuler(_pollutionLevel, POLLUTION);

                switch (engineType) {
                    case CAR_TYPE.HYBRID: {
                        return (
                            <>
                                { showTableData && <TableData data={ modelDescription } /> }
                                <TableData data={ avergaeFuelConsumption } />
                                <TableData data={ electricConsumption } />
                                <TableData data={ range } />
                                { showTableData && (
                                    <TableData
                                        data={ _pollutionLevel }
                                        bg={ pollutionLevelBgColor }
                                    />
                                ) }
                            </>
                        );
                    }
                    case CAR_TYPE.ELECTRIC: {
                        return (
                            <>
                                { showTableData && <TableData data={ modelDescription } /> }
                                <TableData data={ electricConsumption } />
                                <TableData data={ range } />
                                { showTableData && (
                                    <TableData
                                        data={ _pollutionLevel }
                                        bg={ pollutionLevelBgColor }
                                    />
                                ) }
                            </>
                        );
                    }
                    default: {
                        return (
                            <>
                                { showTableData && <TableData data={ modelDescription } /> }
                                <TableData data={ avergaeFuelConsumption } />
                                <TableData data={ STRINGS.COMBINED } />
                                { showTableData && (
                                    <TableData
                                        data={ _pollutionLevel }
                                        bg={ pollutionLevelBgColor }
                                    />
                                ) }
                            </>
                        );
                    }
                }
            };
            
            return pollutionLevel.map((_pollutionLevel) => {
                const modelToDisplay = {
                    modelCode,
                    modelDescription,
                    pollutionLevel: _pollutionLevel,
                    avergaeFuelConsumption,
                    electricConsumption,
                    range,
                };
    
                if (!renderUniqueSafetyAndPollutionTableRows(
                    displayedModelsArray, modelToDisplay,
                )) {
                    displayedModelsArray.push(modelToDisplay);
    
                    return (
                        <Styled.ModelTr key={ `${modelCode}-${_pollutionLevel}` }>
                            { renderTableBody(_pollutionLevel) }
                        </Styled.ModelTr>
                    );
                }

                return null;
            });
        });
    };

    const renderTable = (engineType) => {
        const _cars = carsType[engineType];

        return (
            <Styled.ModelTable>
                <Styled.ModelThead>
                    { TableHead[engineType] }
                </Styled.ModelThead>
                <Styled.ModelThead>
                    { TableSubHeader[engineType] }
                </Styled.ModelThead>
                <Styled.ModelTbody>
                    {renderPollutionDataRow(_cars ?? [])}
                </Styled.ModelTbody>
            </Styled.ModelTable>
        );
    };

    useEffect(() => {
        setCarsType(defaultCrsTypeState);
        cars.forEach((car) => {
            const { engineType } = car;

            setCarsType((prev) => ({
                ...prev,
                [engineType]: prev[engineType] ? [...prev[engineType], car] : [car],
            }));
        });
    }, [cars]);

    return (
        <Styled.PollutionModel>
            { uniqueEngineTypes.map((engineType) => renderTable(engineType)) }
        </Styled.PollutionModel>
    );
};

PollutionModel.propTypes = {
    cars: PropTypes.array.isRequired,
};

export default PollutionModel;
