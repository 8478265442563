import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../language';
import * as Styled from './ConfiguratorDescriptionOnMobile.styles';

const ConfiguratorDescriptionOnMobile = ({ item, isAudi }) => {
    const {
        isPackageRelated,
        arrivalDate,
        description,
        name,
        price,
    } = item;

    const hasPrice = price !== 0;

    const priceOrString = hasPrice ? ` ${parseFloat(price).toLocaleString()} ₪` : STRINGS.WITH_NO_EXTRA_COST;
    const itemName = description ?? name;
    
    const AudiDescription = () => {
        return (
            <>
                <Styled.Title $isAudi={ isAudi }>{itemName}</Styled.Title>
                <Styled.TooltipInStock $isAudi={ isAudi }>{priceOrString}</Styled.TooltipInStock>
                <Styled.TooltipInStock $isAudi={ isAudi }>{arrivalDate}</Styled.TooltipInStock>
            </>
        );
    };

    const DefaultDescription = () => {
        return (
            <>
                <Styled.Title $isAudi={ isAudi }>{itemName} - {priceOrString}</Styled.Title>
                <Styled.TooltipInStock $isAudi={ isAudi }>{arrivalDate}</Styled.TooltipInStock>
            </>
        );
    };

    const Description = isAudi ?  <AudiDescription /> : <DefaultDescription />;
    
    const renderContent = () => {
        if (!isPackageRelated) {
            return (
                <>
                    <Styled.Title>{itemName}</Styled.Title>
                    <Styled.NoPackageRelated>
                        {STRINGS.THIS_COLOR_IS_NOT_INCLUDED_IN_THE_MODEL_YOU_CHOSE}!
                    </Styled.NoPackageRelated>
                </>
            );
        }
        
        return Description;
    };
    
    return (
        <Styled.ConfiguratorDescriptionOnMobile $isAudi={ isAudi }>
            {renderContent()}
        </Styled.ConfiguratorDescriptionOnMobile>
    );
};

ConfiguratorDescriptionOnMobile.defaultProps = {
    isAudi: false,
};

ConfiguratorDescriptionOnMobile.propTypes = {
    item: PropTypes.shape({
        isPackageRelated: PropTypes.bool.isRequired,
        inStock: PropTypes.bool,
        arrivalDate: PropTypes.string,
        description: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number.isRequired,
    }).isRequired,
    isAudi: PropTypes.bool,
};

export default ConfiguratorDescriptionOnMobile;
