import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
// import Loader from '../../../Loader';
// import {
//     getInColorIconUrl,
// } from '../../../../utils/assetsUtils';
import {
    useCurrentConfigOptions,
    useCurrentAddOns,
} from '../../../../../../hooks';
import AudiAddOrRemoveButton from '../AudiAddOrRemoveButton/AudiAddOrRemoveButton';
import STRINGS from '../../../../../../language';
import * as Styled from './AddOnItem.styles';

const { READ_MORE, READ_LESS } = STRINGS;

const AddOnItem = ({
    description,
    groupCode,
    localCode,
    index,
    price,
    groupDescription,
    image,
    priceAfterDiscounts,
    isDiscount,
    onSelect,
    isGroupSelected,
}) => {
    const { modelLocalGroups } = useCurrentConfigOptions();
    const { addOnsExtraInformation } = modelLocalGroups;
    const selectedAddOns = useCurrentAddOns();
    const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
    
    const toggleBtn = () => {
        setIsReadMoreVisible((prevState) => !prevState);
    };

    const isSelected = selectedAddOns[groupCode]?.title
    === description;
    const isDisabled = !!selectedAddOns[groupCode] && !isSelected;

    const selectItemHandler = () => {
        const priceAfterDiscount = parseInt(priceAfterDiscounts);

        return onSelect({
            title: description,
            groupDescription,
            price,
            image,
            local: localCode,
            priceAfterDiscount,
            isDiscount,
            groupCode,
        });
    };

    let moreInfo;

    if (addOnsExtraInformation[groupCode]) {
        moreInfo = addOnsExtraInformation[groupCode][index][localCode].value;
    }

    return (
        <Styled.AddOnItem $isGroupSelected={ isGroupSelected }>
            <Styled.CheckBox
                $isSelected={ isSelected }
                onClick={ selectItemHandler }
            />
            <Styled.InformationContainer>
                <Styled.Title>{description}</Styled.Title>
                <Styled.MoreInfoContainer>
                    <Styled.MoreInfo $isReadMoreVisible={ isReadMoreVisible }>
                        {moreInfo && parse(moreInfo)}
                    </Styled.MoreInfo>
                    {moreInfo && (
                        <Styled.MoreInfoButton
                            $isReadMoreVisible={ isReadMoreVisible }
                            onClick={ toggleBtn }
                        >{isReadMoreVisible ? READ_LESS : READ_MORE}
                        </Styled.MoreInfoButton>
                    )}
                </Styled.MoreInfoContainer>
                <Styled.Price>{price.toLocaleString()}</Styled.Price>
                <AudiAddOrRemoveButton
                    onClickHandler={ selectItemHandler }
                    isSelected={ isSelected }
                    isDisabled={ isDisabled }
                />
            </Styled.InformationContainer>
        </Styled.AddOnItem>
    );
};

AddOnItem.defaultProps = {
    isGroupSelected: false,
};

AddOnItem.propTypes = {
    description: PropTypes.string.isRequired,
    groupCode: PropTypes.string.isRequired,
    localCode: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    groupDescription: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    priceAfterDiscounts: PropTypes.number.isRequired,
    isDiscount: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    isGroupSelected: PropTypes.bool,
};

export default AddOnItem;
